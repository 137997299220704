import { createSlice } from "@reduxjs/toolkit";
import {
  fetchReminders,
  dismissedReminder,
  doneReminder,
} from "../service/reminderService";

const initialReminderState = {
  reminders: null,
  isLoading: false,
  error: null,
};

export const reminderSlice = createSlice({
  name: "reminder",
  initialState: initialReminderState,
  reducers: {},
  extraReducers: {
    //TODO: fetch reminders
    [fetchReminders.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchReminders.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.reminders = action.payload.data;
    },
    [fetchReminders.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //TODO: dissmiss reminder
    [dismissedReminder.pending]: (state) => {
      state.isLoading = true;
    },
    [dismissedReminder.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [dismissedReminder.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //TODO: done reminder
    [doneReminder.pending]: (state) => {
      state.isLoading = true;
    },
    [doneReminder.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [doneReminder.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});
export const reminderAction = reminderSlice.actions;
export default reminderSlice.reducer;
