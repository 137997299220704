import React from "react";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import Styled from "./AddClause";

const AddClause = () => {
  return (
    <div>
      <Styled.AddSectionBox>
        <span>
          <ControlPointRoundedIcon sx={{ color: Colors.primary }} />
        </span>
        <span>
          <TextStyles.FontSize14px
            style={{
              color: Colors.primary,
              display: "inline-block",
              marginLeft: 3,
            }}
          >
            Add a clause
          </TextStyles.FontSize14px>
        </span>
      </Styled.AddSectionBox>
    </div>
  );
};

export default AddClause;
