import {
  deleteRequest,
  postRequest,
  putRequest,
  getRequest,
} from "../../axios/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchInvoices = createAsyncThunk(
  "invoices/fetch_invoices",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest("invoices/fetch_invoices", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createInvoice = createAsyncThunk(
  "invoices/create_invoice",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("invoices/create_invoice", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const editInvoiceTitle = createAsyncThunk(
  "invoices/edit_invoice_ttile",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/edit_invoice_title", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const editInvoiceDates = createAsyncThunk(
  "invoices/edit_invoice_dates",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/edit_invoice_dates", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const editClientInfo = createAsyncThunk(
  "invoices/edit_client_info",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/edit_client_info", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const editInvoiceService = createAsyncThunk(
  "invoices/edit_invoice_services",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/edit_invoice_services", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const editLineItemService = createAsyncThunk(
  "invoices/edit_line_item_service",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/edit_line_item_service", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const addLineItemService = createAsyncThunk(
  "invoices/add_line_item_service",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/add_line_item_service", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteLineItemService = createAsyncThunk(
  "invoices/delete_line_item_service",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/delete_line_item_service", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const addAndEditInvoiceTaxes = createAsyncThunk(
  "invoices/add_eidt_invoice_taxes",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/add_edit_invoice_taxes", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteInvoiceTax = createAsyncThunk(
  "invoices/delete_invoice_tax",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/delete_invoice_tax", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const addAndEditInvoiceDiscount = createAsyncThunk(
  "invoices/add_eidt_invoice_discount",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("invoices/add_edit_invoice_discount", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const changeInvoiceCurrency = createAsyncThunk(
  "invoices/change_invoice_currency",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/change_invoice_currency", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
export const deleteInvoiceDiscount = createAsyncThunk(
  "invoices/delete_invoice_discount",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/delete_invoice_discount", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const addAndEditInvoiceNotes = createAsyncThunk(
  "invoices/add_edit_invoice_notes",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/add_edit_invoice_notes", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteInvoiceNotes = createAsyncThunk(
  "invoices/delete_invoice_notes",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/delete_invoice_notes", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

// create contact for a specific client
export const createContact = createAsyncThunk(
  "invoices/create_contact",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("invoices/create_contact", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const emailSendInvoice = createAsyncThunk(
  "invoices/send_email",
  async (data) => {
    let token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("invoices/send_email", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteInvoice = createAsyncThunk(
  "invoices/delete_invoice",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      deleteRequest("invoices/delete_invoice", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const paidInvoice = createAsyncThunk(
  "invoices/paid_invoice",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("invoices/paid_invoice", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchInvoiceCounts = createAsyncThunk(
  "invoices/fetch_invoice_counts",
  async () => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      getRequest("invoices/fetch_invoice_counts", token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
