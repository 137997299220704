import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: "1px solid grey !important",
    backgroundColor: Colors.white,
    fontWeight: "400 !important",
    borderRadius: "0.75rem !important",
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
    padding: "15px 32px 15px 12px !important",
  },

  listMenu: {
    color: "grey",
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

export default function SelectProjectTerminationDays(props) {
  const value = props.defaultValue;
  const classes = useStyles();
  const options = [
    { _id: "1", value: "1 week", label: "1 week" },
    { _id: "2", value: "2 weeks", label: "2 weeks" },
    { _id: "3", value: "3 weeks", label: "3 weeks" },
    { _id: "4", value: "1 month", label: "1 month" },
    { _id: "5", value: "Custom", label: "Custom" },
  ];

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        defaultValue={props.defaultValue ? value : options[0].value}
        id="grouped-select"
        sx={{ width: props.width || "100%" }}
        onChange={props.onChange}
        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
      >
        {options &&
          options.map((data, index) => (
            <MenuItem
              key={index}
              value={data.value}
              className={classes.listMenu}
            >
              {data.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
