import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const SliderBox = styled.div`
  width: fit-content;
  // border-radius: 12px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  background-color: ${Colors.white};
`;

const SelectedItem = styled.div`
  padding: 0.875rem 1.5rem;
  cursor: pointer;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => (props.bgColor ? `${props.bgColor}` : Colors.primary)};
  border-bottom: 3px Solid
    ${(props) => (props.bgColor ? `${props.bgColor}` : Colors.primary)};
`;

const NotSelectedItem = styled(SelectedItem)`
  color: ${Colors.font2};
  border-bottom: none;
`;

export default {
  SliderBox,
  SelectedItem,
  NotSelectedItem,
};
