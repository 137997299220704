import React from "react";
import LandingPage from "./LandingPage/LandingPage";
import Features from "./Features/Features";

import Footer from "../../Footer/Footer";
import SignupForm from "../../UIs/SignupForm";

const Statements = () => {
  return (
    <div>
      <LandingPage />
      <Features />
      <SignupForm />
      <Footer />
    </div>
  );
};

export default Statements;
