import styled from "styled-components";
import Colors from "../../../../../../Constants/Colors";

const ReviewContentBox = styled.div`
  width: 100%;
  position: relative;
  height: auto;
  background-color: ${Colors.white};
`;

export default {
  ReviewContentBox,
};
