import React from "react";
import styles from "./CTAOne.module.css";
import { NavLink } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const CTAOne = () => {
  return (
    <div className={styles.second_section}>
      <div className="container-fluid py-3 py-md-4">
        <div className={styles.content_box} data-aos={"fade-up"}>
          <div className="d-flex gap-3 gap-md-4 flex-wrap flex-md-nowrap justify-content-center justify-content-md-between">
            <div className="w-100">
              <div
                className={`${styles.heading} text-center text-md-left mb-2`}
              >
                Whether you're a solo entrepreneur or a small team.
              </div>
              <div
                className={`${styles.heading_text} text-center text-md-left`}
              >
                Let us show you why Zodot is perfect for your workspace.
              </div>
            </div>
            <div
              className={`d-flex justify-content-end ${styles.button_width} w-100 `}
            >
              <NavLink
                className={styles.more_button}
                style={{ textDecoration: "none" }}
                to="/db/signup"
              >
                Try Zodot for free!
              </NavLink>
            </div>
          </div>
          <hr className={`${styles.hr}`} />
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-6 col-lg-4 mb-2 mb-lg-0">
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <CheckCircleRoundedIcon className={styles.icons} />
                </div>
                <div className={`${styles.text}`}>
                  Accessibility: Use Zodot on all devices and platforms.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-2 mb-lg-0">
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <CheckCircleRoundedIcon className={styles.icons} />
                </div>
                <div className={`${styles.text}`}>
                  GDPR-Compliant: Your data is protected with us.
                </div>
              </div>
            </div>{" "}
            <div className="col-12 col-md-6 col-lg-4 mb-2 mb-lg-0">
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <CheckCircleRoundedIcon className={styles.icons} />
                </div>
                <div className={`${styles.text}`}>
                  24/7 Support: Enjoy around-the-clock assistance.
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTAOne;
