import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageModals";
import { updateLatePaymentPC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";
import SwitchButton from "../../../Commons/SwitchButton";

const LatePaymentModal = ({ show, handleClose, heading, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const [selector, setSelector] = useState(
    selectedData &&
      selectedData.late_payment_fee &&
      selectedData.late_payment_fee.late_payment_deposit_require === true
      ? true
      : false
  );

  const [amount, setAmount] = useState(
    selectedData &&
      selectedData.late_payment_fee &&
      selectedData.late_payment_fee.late_payment === "Amount"
      ? selectedData.late_payment_fee.late_payment_amount
      : "50"
  );

  const [percentage, setPercentage] = useState(
    selectedData &&
      selectedData.late_payment_fee &&
      selectedData.late_payment_fee.late_payment === "Percentage"
      ? selectedData.late_payment_fee.late_payment_percentage
      : "1.50"
  );

  const [paymentMethod, setPaymentMethod] = useState(
    selectedData &&
      selectedData.late_payment_fee &&
      selectedData.late_payment_fee.late_payment
      ? selectedData.late_payment_fee.late_payment
      : "Percentage"
  );

  const paragraph =
    percentage && selector === true && paymentMethod === "Percentage"
      ? `In case of delayed payment of an invoice, the Contractor reserves the right to charge a late payment fee of ${parseFloat(
          percentage
        ).toFixed(
          2
        )}% per month. Acceptance of these service charges by Contractor does not waive any rights or remedies in the event of Client's breach of this Agreement. All payment obligations are non-cancellable, and fees paid are non-refundable.`
      : selectedData &&
        selectedData.currency &&
        selectedData.currency.symbol &&
        amount &&
        selector === true &&
        paymentMethod === "Amount"
      ? `In case of delayed payment of an invoice, the Contractor reserves the right to charge a late payment fee of ${
          selectedData.currency.symbol
        } ${parseFloat(amount).toFixed(
          2
        )} as late payment fee. Acceptance of these service charges by Contractor does not waive any rights or remedies in the event of Client's breach of this Agreement. All payment obligations are non-cancellable, and fees paid are non-refundable.`
      : "Note: This section shows if only applicable.";

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
      late_payment: paymentMethod,
      late_payment_amount: amount,
      late_payment_percentage: percentage,
    },
  });

  const latePayment = watch("late_payment");
  const late_payment_percentage = watch("late_payment_percentage");
  const late_payment_amount = watch("late_payment_amount");

  useEffect(() => {
    setPaymentMethod(latePayment);
    setPercentage(late_payment_percentage);
    setAmount(late_payment_amount);
  }, [latePayment, late_payment_amount, late_payment_percentage]);

  useEffect(() => {
    if (paymentMethod === "Amount") {
      setValue(
        "late_payment_amount",
        selectedData &&
          selectedData.late_payment_fee &&
          selectedData.late_payment_fee.late_payment_amount
      );
      setValue(
        "late_payment_percentage",
        selectedData &&
          selectedData.late_payment_fee &&
          selectedData.late_payment_fee.late_payment_percentage
      );
    }
    if (paymentMethod === "Percentage") {
      setValue(
        "late_payment_percentage",
        selectedData &&
          selectedData.late_payment_fee &&
          selectedData.late_payment_fee.late_payment_percentage
      );
      setValue(
        "late_payment_amount",
        selectedData &&
          selectedData.late_payment_fee &&
          selectedData.late_payment_fee.late_payment_amount
      );
    }
  }, [latePayment, selector]);

  useEffect(() => {
    setValue("paragraph", paragraph);
  }, [amount, percentage, paymentMethod, paymentMethod, selector]);

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      late_payment_fee: {
        required: true,
        late_payment_deposit_require: selector === true ? true : false,
        late_payment: selector === true ? item.late_payment : "Percentage",

        late_payment_amount:
          selector === true && latePayment === "Amount"
            ? item.late_payment_amount
            : "50",
        late_payment_percentage:
          selector === true && latePayment === "Percentage"
            ? item.late_payment_percentage
            : "1.50",
      },
    };

    dispatch(updateLatePaymentPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            Clause updated successfully!
          </TextStyles.FontSize14px>,
          { toastId: "updateLatePaymentSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateLatePaymentFail" }
        );
      }
    });
  };

  return (
    <Modal
      scrollable={true}
      show={show}
      onHide={handleClose}
      fullscreen
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Modal.Header className="d-flex align-items-center">
          <TextStyles.FontSize20px className="d-flex justify-content-start">
            Edit contract
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline">Headline</label>
            <TextStyles.InputRectangle
              placeholder={heading}
              id="headline"
              name="heading"
              style={{ opacity: 0.5 }}
              {...register("heading")}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline">Paragraph</label>
            <TextStyles.Textarea
              rows={6}
              style={{ opacity: 0.5 }}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
              readOnly
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize18px className="mb-3">
            Options
          </TextStyles.FontSize18px>
          <TextStyles.GreyFont14px className="d-flex gap-3 justify-content-between align-items-center">
            <div className="d-flex" style={{ minWidth: 100 }}>
              <span className="three-dots">Late payment fee?</span>
            </div>
            <div className="d-flex justify-content-end">
              <SwitchButton
                defaultChecked={selector}
                onChange={(event) => setSelector(event.target.checked)}
              />
            </div>
          </TextStyles.GreyFont14px>
          {selector === true && (
            <TextStyles.FontSize14px className="d-flex justify-content-end">
              <div style={{ maxWidth: 280, width: "100%" }}>
                <TextStyles.RadioButton className="form-check my-3 d-flex justify-content-between align-items-center">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="late_payment"
                    value="Amount"
                    checked={latePayment === "Amount" ? true : false}
                    name="late_payment"
                    {...register("late_payment")}
                  />
                  <label className="form-check-label" htmlFor="late_payment">
                    <div className="d-flex align-items-center">
                      <TextStyles.FontSize14px htmlFor="late_payment_amount">
                        Amount
                      </TextStyles.FontSize14px>
                    </div>
                  </label>
                  <Styled.inputBox invalid={errors.late_payment_amount}>
                    <span>
                      {selectedData &&
                        selectedData.currency &&
                        selectedData.currency.symbol}
                    </span>
                    <input
                      type="text"
                      id="late_payment_amount"
                      placeholder="00.00"
                      style={{ width: 45, background: "transparent" }}
                      name="late_payment_amount"
                      {...register("late_payment_amount", {
                        required: "Please enter amount.",
                        min: {
                          value: 1,
                          message: "Please enter more than zero.",
                        },
                        pattern: {
                          value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                          message: "Please enter a valid number",
                        },
                      })}
                    />
                  </Styled.inputBox>
                </TextStyles.RadioButton>
                {errors.late_payment_amount && (
                  <TextStyles.InValidFeedback>
                    {errors.late_payment_amount.message}
                  </TextStyles.InValidFeedback>
                )}

                <TextStyles.RadioButton
                  className="form-check my-3 d-flex justify-content-between align-items-center"
                  style={{ maxWidth: 300 }}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    value="Percentage"
                    checked={latePayment === "Percentage" ? true : false}
                    id="late_payment"
                    name="late_payment"
                    {...register("late_payment")}
                  />
                  <label className="form-check-label" htmlFor="late_payment">
                    <div className="d-flex">
                      <TextStyles.FontSize14px
                        className="d-inline"
                        htmlFor="late_payment_percentage"
                      >
                        Percentage
                      </TextStyles.FontSize14px>
                    </div>
                  </label>
                  <Styled.inputBox invalid={errors.late_payment_percentage}>
                    <input
                      type="text"
                      id="late_payment_percentage"
                      placeholder="0.00"
                      style={{
                        width: 41,
                        textAlign: "right",
                        background: "transparent",
                      }}
                      name="late_payment_percentage"
                      {...register("late_payment_percentage", {
                        required: "Please enter percentage.",
                        min: {
                          value: 1,
                          message: "Please enter more than zero.",
                        },
                        pattern: {
                          value: /^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                          message: "Please enter a valid number",
                        },
                      })}
                    />
                    <span>%</span>
                  </Styled.inputBox>
                </TextStyles.RadioButton>
                {errors.late_payment_percentage && (
                  <TextStyles.InValidFeedback>
                    {errors.late_payment_percentage.message}
                  </TextStyles.InValidFeedback>
                )}
              </div>
            </TextStyles.FontSize14px>
          )}
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading ? true : false}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default LatePaymentModal;
