import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NoTaskImage from "../../../../../Assets/assetsnew/no-task.svg";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Pagination from "../../../Commons/Pagination";

import DeleteModal from "../../../Commons/DeleteModal";

import Styled from "./TodoStyles";
import TextStyles from "../../../../../Constants/TextStyles";

import {
  deleteTask,
  fetchTasks,
  updateTaskStatus,
  editTaskDueDate,
} from "../../../../../store/service/dashboardService";
import Colors from "../../../../../Constants/Colors";
import EditTask from "../../../dashboard/fourthrow/EditTask";
import LoaderSpin from "../../../Commons/LoaderSpin";

const Done = () => {
  const dispatch = useDispatch();
  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleClose = () => setShow(false);

  const c_data_position = useSelector((state) => state.clients.c_data_position);
  const isLoading = useSelector((state) => state.dashboard.isLoading);
  const taskData = useSelector((state) => state.dashboard.taskData);
  const taskTotalCount = useSelector((state) => state.dashboard.taskTotalCount);

  const defaultData = useMemo(() => {
    return (
      taskData &&
      taskData.map((item) => {
        const repeat_task_id = item.repeat_task_id;
        const _id = item._id;
        const due_date = item.due_date ? new Date(item.due_date) : null;
        const hide_until_date = item.hide_until_date
          ? new Date(item.hide_until_date)
          : null;
        const client_id = item.client_id ? item.client_id : null;
        const project_id = item.project_id ? item.project_id : null;
        const status = item.status;
        const task_name = item.task_name ? item.task_name : null;
        const repeat_task = item.repeat_task ? item.repeat_task : null;

        return {
          _id,
          due_date,
          hide_until_date,
          client_id,
          project_id,
          status,
          task_name,
          repeat_task,
          repeat_task_id,
        };
      })
    );
  }, [taskData]);

  const { control: control2, reset: reset2 } = useForm({
    defaultValues: {
      update_task: defaultData && defaultData,
    },
  });

  const { fields: fields2 } = useFieldArray({
    control: control2,
    name: "update_task",
  });

  useEffect(() => {
    reset2({
      update_task: defaultData && defaultData,
    });
  }, [taskData]);

  const [updateRefresh, setUpdateRefresh] = useState(false);

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 3;

  useEffect(() => {
    setPage(1);
  }, [updateRefresh, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const Obj = {
      page: page,
      perPage: itemsPerPage,
      client_id: c_data_position._id,
      status: "done",
    };
    dispatch(fetchTasks(Obj));
  }, [updateRefresh, itemsPerPage, page]);

  const setDueDateApiCall = (newDate, data) => {
    const Obj = {
      _id: data._id,
      due_date: new Date(newDate),
    };
    dispatch(editTaskDueDate(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "dueDateSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "dueDateFail" }
        );
      }
    });
  };

  const todoTaskCallApi = (data) => {
    let Obj = {
      task_id: data._id,
      status: "todo",
    };
    dispatch(updateTaskStatus(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            Task undo successfully!
          </TextStyles.FontSize14px>,
          { toastId: "taskDoneSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "taskDoneFail" }
        );
      }
    });
  };

  const deleteTaskHandler = (data) => {
    const Obj = {
      task_id: data && data._id,
    };
    dispatch(deleteTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleCloseDeleteModal();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "deleteTaskSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "deleteTaskFail" }
        );
      }
    });
  };

  return (
    <div>
      {fields2 && fields2.length > 0 ? (
        fields2.map((data, index) => {
          return (
            <Styled.TodoRectangle
              key={index}
              style={{
                marginTop: 2,
                borderRadius:
                  taskData && taskData.length === 1
                    ? "12px"
                    : taskData && taskData.length - 1 === index
                    ? "0 0 12px 12px"
                    : taskData && taskData.length - taskData.length === index
                    ? "12px 12px 0  0"
                    : "",
              }}
              onClick={() => {
                setShow(true);
                setSelectedTaskData(data);
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <div
                  className="d-flex justify-content-between align-items-center three-dots w-100"
                  style={{ maxWidth: 900 }}
                >
                  <TextStyles.FontSize14px className="d-flex align-items-center three-dots w-100">
                    <div
                      className="d-flex align-items-center"
                      style={{ width: 30 }}
                    >
                      <TaskAltIcon
                        fontSize="medium"
                        sx={{
                          color: Colors.primary,
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div
                      className="d-flex flex-column w-100 ml-2"
                      style={{ minWidth: 50 }}
                    >
                      <TextStyles.FontSize14px className="text-start three-dots w-100">
                        {data.task_name}
                      </TextStyles.FontSize14px>
                      <TextStyles.GreyFont14px className="text-start three-dots w-100">
                        {data.project_id && data.project_id.project_name}
                      </TextStyles.GreyFont14px>
                    </div>
                  </TextStyles.FontSize14px>

                  <div className="d-flex align-items-center justify-content-end w-100">
                    <TextStyles.GreyFont14px
                      className="mr-3 three-dots"
                      style={{ minWidth: 50 }}
                    >
                      {data.client_id && data.client_id.company_name}
                    </TextStyles.GreyFont14px>

                    <TextStyles.FontSize14px
                      style={{ maxWidth: 90 }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Controller
                        control={control2}
                        name={`update_task.${index}.due_date`}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            className="widthofdatepicker three-dots"
                            onChange={(value) => {
                              onChange(value);
                              setDueDateApiCall(value, data);
                            }}
                            selected={value}
                            placeholderText="Set due date"
                            todayButton="Today"
                            dateFormat="dd MMM yyyy"
                            disabled={isLoading ? true : false}
                            minDate={new Date()}
                          />
                        )}
                      />
                    </TextStyles.FontSize14px>
                  </div>
                </div>

                <div className="w-100 d-flex justify-content-end task-moreButton">
                  <div>
                    <div
                      className="dropdown"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <MoreHorizIcon
                          fontSize="large"
                          sx={{
                            background: Colors.white,
                            cursor: "pointer",
                            borderRadius: "6px",
                            padding: "2px 4px",
                            height: "30px",
                            color: "grey",
                          }}
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                todoTaskCallApi(data);
                              }}
                            >
                              Send to To do
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                setConfirmDialog({
                                  onConfirm: () => deleteTaskHandler(data),
                                });
                              }}
                            >
                              Delete
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Styled.TodoRectangle>
          );
        })
      ) : isLoading && taskData === null ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        !isLoading && (
          <Styled.ListBox>
            <img src={NoTaskImage} alt="no-task-img" />
            <TextStyles.GreyFont18px className="mt-2">
              There are no tasks created yet.
            </TextStyles.GreyFont18px>
          </Styled.ListBox>
        )
      )}

      <div className="d-flex justify-content-center pt-4">
        {taskTotalCount > itemsPerPage && (
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(taskTotalCount / itemsPerPage)}
            page={page}
          />
        )}
      </div>
      {show && (
        <EditTask
          setUpdateRefresh={setUpdateRefresh}
          updateRefresh={updateRefresh}
          selectedTaskData={selectedTaskData}
          handleClose={handleClose}
          show={show}
        />
      )}
      <DeleteModal
        title="task"
        confirmDialog={confirmDialog}
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
    </div>
  );
};

export default Done;
