import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Agreements.module.css";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import HomePageAgreementSectionImage from "../../../../Assets/frontendImages/agreement_homepage.avif";

const Agreements = () => {
  return (
    <div
      className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center"
      style={{ overflow: "hidden" }}
    >
      <div className="d-flex justify-content-center justify-content-md-start w-100 mt-3 mt-md-0 pb-3">
        <div
          data-aos={"fade-right"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 550,
            maxHeight: 550,
          }}
        >
          <img
            src={HomePageAgreementSectionImage}
            alt="HomePageAgreementSectionImage"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
      <div className="text-start w-100 mt-3 mt-md-0 pb-3">
        <div data-aos={"fade-left"}>
          <div className={`${styles.heading} mb-4`}>
            Simplify paperwork chaos.
          </div>
          <div className={`${styles.text_font1} mb-4`}>
            Craft proposals & contracts effortlessly with Zodot. Our free
            software simplifies the process, helping you generate professional
            agreements in a few clicks. Whether freelancing or sealing deals,
            Zodot has you covered.
          </div>
          <div className="d-flex mb-4">
            <NavLink
              className={styles.more_button}
              style={{ textDecoration: "none" }}
              to="/features/agreements"
            >
              More about easy agreements
              <span className={`${styles.cta_icons}`}>
                <ArrowForwardIosRoundedIcon sx={{ fontSize: 17 }} />
              </span>
            </NavLink>
          </div>

          <div
            className={`${styles.text_font1} ${styles.border_left_side_of_content}`}
          >
            “Zodot has saved me so much time and hassle. I used to dread
            creating agreements, but now it's effortless!”
            <span className={`${styles.text_font2}`}> -Sarah M.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agreements;
