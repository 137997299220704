import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const AddSectionBox = styled.div`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  border: solid 1px ${Colors.primary};
  cursor: pointer;
  position: relative;
  color: ${Colors.primary};
  background-color: ${Colors.backgroundPink};
`;

export default {
  AddSectionBox,
};
