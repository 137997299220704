import React, { useMemo } from "react";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import TextStyles from "../../../../Constants/TextStyles";
import { useSelector } from "react-redux";
import Colors from "../../../../Constants/Colors";

const CustomizedTooltip = ({ active, label, payload }) => {
  if (active) {
    return (
      <div
        style={{
          width: "fill",
          height: "fill",
          background: Colors.tableHeadColor,
          borderRadius: 6,
          padding: 15,
          border: `1px solid ${Colors.primary}`,
        }}
      >
        <TextStyles.GreyFont14px className="mt-2">
          {label}
        </TextStyles.GreyFont14px>
        {payload && payload[0] && payload[0].value > 0 && (
          <TextStyles.FontSize14px className="mt-2" style={{ color: "red" }}>
            <span>{payload && payload[0] ? `${payload[0].name}:` : ""} </span>
            <span>
              {payload && payload[0]
                ? `${payload[0].payload.currencySymbol}${parseFloat(
                    payload[0].value
                  ).toFixed(2)}`
                : ""}
            </span>
          </TextStyles.FontSize14px>
        )}

        {payload && payload[1] && payload[1].value > 0 && (
          <TextStyles.FontSize14px
            className="mt-2"
            style={{ color: Colors.success }}
          >
            <span>{payload && payload[1] ? `${payload[1].name}:` : ""} </span>
            <span>
              {payload && payload[1]
                ? `${payload[1].payload.currencySymbol}${parseFloat(
                    payload[1].value
                  ).toFixed(2)}`
                : ""}
            </span>
          </TextStyles.FontSize14px>
        )}
      </div>
    );
  }
  return null;
};

const TrCharts = () => {
  const trDataForGraph = useSelector(
    (state) => state.transaction.trDataForGraph
  );
  const userCurrency = useSelector((state) => state.auth.userCurrency);
  const currencySymbol = useMemo(() => {
    return (
      userCurrency && userCurrency.currency && userCurrency.currency.symbol
    );
  }, [userCurrency]);

  let transactionData =
    trDataForGraph &&
    trDataForGraph.map((d) => {
      return {
        date: d.paid_date
          ? moment(new Date(d.paid_date)).format("DD MMM YYYY")
          : null,
        Income:
          d.transaction_type === "income" && d.total_amount_in_user_currency
            ? Number(parseFloat(d.total_amount_in_user_currency).toFixed(2))
            : 0.0,
        Expenses:
          d.transaction_type === "expense" && d.total_amount_in_user_currency
            ? Number(parseFloat(d.total_amount_in_user_currency).toFixed(2))
            : 0.0,
        currencySymbol: currencySymbol,
      };
    });

  return (
    <>
      <ResponsiveContainer width="100%" minHeight="180px">
        <LineChart
          data={transactionData && transactionData}
          margin={{
            top: 5,
            right: 15,
            left: 15,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="6 6" />
          <XAxis dataKey="date" hide={true} />
          <YAxis hide={true} />
          <Tooltip content={<CustomizedTooltip />} />
          <Line
            type="monotone"
            dataKey="Expenses"
            stroke={`${Colors.red}`}
            activeDot={{ r: 5 }}
          />
          <Line
            type="monotone"
            dataKey="Income"
            stroke={Colors.success}
            activeDot={{ r: 5 }}
          />
          <Legend wrapperStyle={{ bottom: -10 }} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default TrCharts;
