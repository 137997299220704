import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const SliderBox = styled.div`
  width: 12.187rem;
  border-radius: 0.75rem;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
`;

const SelectedItem = styled.div`
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.15rem;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: ${Colors.primary};
  color: ${Colors.white};

  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const NotSelectedItem = styled(SelectedItem)`
  color: ${Colors.font2} !important;
  background-color: ${Colors.white} !important;

  &:hover {
    background-color: ${Colors.backgroundPink} !important;
  }
`;

const TodoRectangle = styled.div`
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  contain: inline-size;
  & .radioCheckedButton {
    display: none;
  }

  & .radioButtonWrapper:hover > .radioButton {
    display: none;
  }

  & .radioButtonWrapper:hover > .radioCheckedButton {
    display: inline;
  }

  &:hover {
    background: ${Colors.backgroundPink};
  }

  .dropdown-menu {
    border: 2px solid #f9f9f9;
    padding: 6px 6px;
    border-radius: 6px;
    box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.2);
  }

  .dropdown-item {
    padding: 7px 12px;
    border-radius: 4px;
  }

  .dropdown-item:hover {
    background: ${Colors.backgroundPink};
  }
`;

const Input = styled.input`
  width: 100%;
  max-width: 500px;
  margin: 0 1.125rem;
  background-color: ${(props) => (props.invalid ? Colors.red : Colors.white)};
  caret-color: ${Colors.font2};
`;

const Sorting = styled.div`
  display: flex;
  min-width: 13.75rem;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
  border-radius: 6px;

  label {
    display: flex;
    width: 100%;
    cursor: pointer;
  }

  .form-check-input {
    width: 1rem;
    height: 1rem;
    box-shadow: none !important;
    border: 2px solid ${Colors.font2};
    cursor: pointer;
  }

  .form-check-input:checked {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }

  &:hover {
    background: ${Colors.backgroundPink};
  }

  &:hover svg {
    color: ${Colors.primary};
  }
`;
const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

export default {
  SliderBox,
  SelectedItem,
  NotSelectedItem,
  TodoRectangle,
  Input,
  Sorting,
  ListBox,
};
