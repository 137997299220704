import React, { useEffect } from "react";
import Terms from "./terms.jsx";
import Tasks from "./task/task.jsx";
import { useHistory } from "react-router-dom";
import Notes from "./Notes/notes.jsx";
import Timers from "./Timer/Timer.jsx";
import WeekViews from "./Weekview/Weekview.jsx";
import Reports from "./Reports/Reports.jsx";
import Invoices from "./Invoices/Invoices.jsx";
import Transaction from "./Transactions/Transaction.jsx";
import Colors from "../../../../Constants/Colors";
import Styled from "./editProjectStyles";
import { BackButton } from "../../Commons/BackAndForwardButton.jsx";
import ProtectedRoute from "../../../auth/ProtectedRoute.js";
import { fetchProjectById } from "../../../../store/service/projectService.js";
import { useDispatch } from "react-redux";

export default function editProject() {
  const history = useHistory();
  const dispatch = useDispatch();
  let urlElements = window.location.href.split("/");
  const id = urlElements[5];

  useEffect(() => {
    dispatch(fetchProjectById({ project_id: id }));
  }, []);

  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        padding: "105px 0 60px 0",
        contain: "content",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center px-2 px-sm-3 px-md-4 px-lg-4"
        style={{ height: "4rem" }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            overflowY: "hidden",
            height: "100%",
          }}
        >
          <Styled.SliderBox
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-flex flex-row">
              <div
                onClick={() => {
                  history.push(`/db/projects/${id}/terms`);
                }}
              >
                {urlElements[6] === "terms" ? (
                  <Styled.SelectedItem>Terms</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Terms</Styled.NotSelectedItem>
                )}
              </div>

              <div
                onClick={() => {
                  history.push(`/db/projects/${id}/tasks`);
                }}
              >
                {urlElements[6] === "tasks" ? (
                  <Styled.SelectedItem>Tasks</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Tasks</Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/projects/${id}/timers`);
                }}
              >
                {urlElements[6] === "timers" ? (
                  <Styled.SelectedItem>Timer</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Timer</Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/projects/${id}/weekviews`);
                }}
              >
                {urlElements[6] === "weekviews" ? (
                  <Styled.SelectedItem>Week View</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Week View</Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/projects/${id}/reports`);
                }}
              >
                {urlElements[6] === "reports" ? (
                  <Styled.SelectedItem>Reports</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Reports</Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/projects/${id}/invoices`);
                }}
              >
                {urlElements[6] === "invoices" ? (
                  <Styled.SelectedItem>Invoices</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Invoices</Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/projects/${id}/transactions`);
                }}
              >
                {urlElements[6] === "transactions" ? (
                  <Styled.SelectedItem>Transactions</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Transactions</Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/projects/${id}/notes`);
                }}
              >
                {urlElements[6] === "notes" ? (
                  <Styled.SelectedItem>Notes</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Notes</Styled.NotSelectedItem>
                )}
              </div>
            </div>
          </Styled.SliderBox>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 25,
          }}
          onClick={() => history.push("/db/projects")}
        >
          <BackButton />
        </div>
      </div>
      <div className="px-2 px-sm-3 px-md-4 px-lg-4">
        <ProtectedRoute path="/db/projects/:id/terms" exact component={Terms} />
        <ProtectedRoute path="/db/projects/:id/tasks" exact component={Tasks} />
        <ProtectedRoute
          path="/db/projects/:id/timers"
          exact
          component={Timers}
        />
        <ProtectedRoute
          path="/db/projects/:id/weekviews"
          exact
          component={WeekViews}
        />
        <ProtectedRoute
          path="/db/projects/:id/reports"
          exact
          component={Reports}
        />
        <ProtectedRoute
          path="/db/projects/:id/invoices"
          exact
          component={Invoices}
        />
        <ProtectedRoute
          path="/db/projects/:id/transactions"
          exact
          component={Transaction}
        />
        <ProtectedRoute path="/db/projects/:id/notes" exact component={Notes} />
      </div>
    </div>
  );
}
