import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const EntireContract = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.entire_contract &&
    selectedData.entire_contract.heading
      ? selectedData.entire_contract.heading
      : "Entire Contract";

  const paragraph =
    selectedData &&
    selectedData.entire_contract &&
    selectedData.entire_contract.paragraph
      ? selectedData.entire_contract.paragraph
      : `This Agreement, together with Attachment B and any exhibits, schedules, or attachments, prevails over any prior oral or written arrangements and may only be modified by a future written agreement signed by both Parties.`;

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.entire_contract &&
          selectedData.entire_contract.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default EntireContract;
