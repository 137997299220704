import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./CustomWeekPicker.css";

dayjs.extend(customParseFormat);

const CustomWeekPicker = (props) => {
  const customWeekStartEndFormat = (value) =>
    `${dayjs(value).startOf("week").format("DD MMM YYYY")} ~ ${dayjs(value)
      .endOf("week")
      .format("DD MMM YYYY")}`;

  return (
    <div>
      <DatePicker
        presets={[
          {
            label: "This week",
            value: dayjs().add(+0, "w"),
          },
          {
            label: "Last week",
            value: dayjs().subtract(1, "w"),
          },
        ]}
        allowClear={false}
        defaultValue={props.defaultValue ? dayjs(props.defaultValue) : dayjs()}
        format={customWeekStartEndFormat}
        picker="week"
        onChange={props.onChange}
        className="custum-week-picker"
        popupClassName="custum-week-picker-popup"
      />
    </div>
  );
};

export default CustomWeekPicker;
