import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: "1px solid grey !important",
    backgroundColor: Colors.white,
    fontWeight: "400 !important",
    borderRadius: `${sizes.formInputBorderRadius} !important`,
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
    padding: "15px 32px 15px 12px !important",
  },

  listMenu: {
    color: "grey",
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

export default function SelectDueDays(props) {
  const value = props.defaultValue;
  const classes = useStyles();
  const options = [
    { _id: "1", value: "Upon receipt", label: "Upon receipt" },
    { _id: "2", value: "7 days", label: "7 days" },
    { _id: "3", value: "15 days", label: "15 days" },
    { _id: "4", value: "30 days", label: "30 days" },
    { _id: "5", value: "45 days", label: "45 days" },
    { _id: "6", value: "Custom", label: "Custom" },
  ];

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        defaultValue={props.defaultValue ? value : options[0].value}
        id="grouped-select"
        sx={{ width: props.width || "100%" }}
        onChange={props.onChange}
        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
      >
        {options &&
          options.map((data, index) => (
            <MenuItem
              key={index}
              value={data.value}
              className={classes.listMenu}
            >
              {data.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
