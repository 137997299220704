import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateResponsibilityPC } from "../../../../../../store/service/proposalAndContractService";
import ResponsibilityModal from "../../PageModals/ResponsibilityModal";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

const Responsibility = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const heading =
    selectedData &&
    selectedData.responsibility &&
    selectedData.responsibility.heading
      ? selectedData &&
        selectedData.responsibility &&
        selectedData.responsibility.heading
      : "Responsibility of contractor";
  const paragraph =
    selectedData &&
    selectedData.responsibility &&
    selectedData.responsibility.paragraph
      ? selectedData &&
        selectedData.responsibility &&
        selectedData.responsibility.paragraph
      : `A. Contractor agrees to deliver the services in accordance with the Delivery Schedule provided for in Schedule A attached hereto. Contractor’s failure to meet the dates provided for in this Schedule A shall constitute a material breach of this Agreement.\n\nB. Contractor shall or shall cause one of its clients to attend Clients’ regularly scheduled meetings relating to the status of the Project. Moreover, Contractor shall provide Client with a written report each month on the status of the development of the services.
`;

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      responsibility: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateResponsibilityPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Cluase hidden successfully!
          </TextStyles.FontSize14px>,
          { toastId: "updateResponsibilitySuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateResponsibilityFail" }
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.responsibility &&
          selectedData.responsibility.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ml-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ml-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <ResponsibilityModal
          selectedData={selectedData}
          paragraph={paragraph}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default Responsibility;
