import styled from "styled-components";
import Colors from "../../../Constants/Colors";

const NewCreate = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  height: 3.15rem;
  padding: 0 1.25rem 0 0.625rem;
  border-radius: 0.75rem;
  background-color: ${Colors.primary};
  cursor: pointer;
  color: ${Colors.white};
  font-size: 0.875rem;

  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const TableHead = styled.div`
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);

  .visible-project,
  .visible-status,
  .visible-amount,
  .visible-start-date,
  .visible-end-date,
  .visible-company {
    visibility: hidden;
  }

  .hover-project:hover .visible-project,
  .hover-status:hover .visible-status,
  .hover-amount:hover .visible-amount,
  .hover-start-date:hover .visible-start-date,
  .hover-end-date:hover .visible-end-date,
  .hover-company:hover .visible-company {
    visibility: visible;
  }

  .project-actions {
    position: relative;
    max-width: 5rem;
    min-width: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 576px) {
    .project-actions {
      max-width: 2.5rem;
      min-width: 2.5rem;
      justify-content: end;
    }
  }
`;

const TableCell = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  margin: 0 0 2px 0;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  &:hover {
    background: ${Colors.backgroundPink};
  }
  .dropdown-menu {
    border: 2px solid #f9f9f9;
    padding: 6px 6px;
    border-radius: 6px;
    box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.2);
  }

  .dropdown-item {
    padding: 7px 12px;
    border-radius: 4px;
  }
  .dropdown-item:hover {
    background: ${Colors.backgroundPink};
  }
  .project-actions {
    position: relative;
    max-width: 5rem;
    min-width: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .editBtn_desktop {
    display: flex;
  }

  .editBtn_mobile {
    display: none;
  }

  @media (max-width: 576px) {
    .project-actions {
      max-width: 2.5rem;
      min-width: 2.5rem;
      justify-content: end;
    }
    .editBtn_desktop {
      display: none;
    }
    .editBtn_mobile {
      display: inline;
    }
  }
`;
const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;
const SetupBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: solid 2px #b2b6c5;
  box-shadow: 3px 2px 5px 1px rgb(183 192 205 / 89%);
  background-color: ${Colors.white};
`;

const Oval = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${Colors.primary};
`;

const Sorting = styled.div`
  display: flex;
  min-width: 13.75rem;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
  border-radius: 6px;

  label {
    display: flex;
    width: 100%;
    cursor: pointer;
  }
  .form-check-input {
    width: 1rem;
    height: 1rem;
    box-shadow: none !important;
    border: 2px solid ${Colors.font2};
    cursor: pointer;
  }
  .form-check-input:checked {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }

  &:hover {
    background: ${Colors.backgroundPink};
  }
  &:hover svg {
    color: ${Colors.primary};
  }
`;

export default {
  NewCreate,
  TableHead,
  TableCell,
  ListBox,
  SetupBox,
  Oval,
  Sorting,
};
