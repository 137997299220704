import React from "react";

const LoaderSpin = (props) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <span
          style={{ width: 21, height: 21, color: props.color }}
          className="spinner-border"
          role="status"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden ml-2">Saving...</span>
      </div>
    </>
  );
};

export default LoaderSpin;
