import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const Input = styled.input`
  width: 100%;
  margin: 0 0 1rem 0;
  background-color: ${(props) =>
    props.invalid ? Colors.inputErrorBg : Colors.white};
`;

export default {
  Input,
};
