import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const HealthInsurance = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.health_insurance &&
    selectedData.health_insurance.heading
      ? selectedData &&
        selectedData.health_insurance &&
        selectedData.health_insurance.heading
      : "Health insurance and business liability insurance";
  const paragraph =
    selectedData &&
    selectedData.health_insurance &&
    selectedData.health_insurance.paragraph
      ? selectedData &&
        selectedData.health_insurance &&
        selectedData.health_insurance.paragraph
      : "In its capacity of an independent contractor, Contractor agrees that the Client is not obligated to procure business liability insurance for the Contractor and/or medical insurance for Contractor and/or Contractor’s family and that Contractor may procure such medical and/or business liability insurance at Contractor’s expense without any claim for reimbursement from the Client.";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.health_insurance &&
          selectedData.health_insurance.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default HealthInsurance;
