import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateForceMejeurePC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import ForceMejeureModal from "../../PageModals/ForceMejeureLawModal";
import Colors from "../../../../../../Constants/Colors";

const ForceMejeure = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData &&
    selectedData.forceMejeure &&
    selectedData.forceMejeure.heading
      ? selectedData.forceMejeure.heading
      : "Force Majeure";

  const paragraph =
    selectedData &&
    selectedData.forceMejeure &&
    selectedData.forceMejeure.paragraph
      ? selectedData.forceMejeure.paragraph
      : `If a party's failure or omission in fulfilling any obligation under this Agreement is due to causes beyond their reasonable control, and such causes cannot be overcome through reasonable diligence, including but not limited to strikes, riots, war, acts of terrorism, acts of God, severe illness, invasion, fire, explosion, floods, and acts of government or governmental agencies or instrumentalities, it shall not be considered a breach of this Agreement or result in any liability for that party.`;

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      forceMejeure: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateForceMejeurePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>,
          { toastId: "updateForceSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateForceFail" }
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.forceMejeure &&
          selectedData.forceMejeure.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ml-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ml-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <ForceMejeureModal
          selectedData={selectedData}
          heading={heading}
          paragraph={paragraph}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default ForceMejeure;
