import React from "react";
import Timer from "./Timer/Timer.jsx";
import WeeklyView from "./WeeklyView/WeeklyView";
import Reports from "./Reports/Reports";

import Styled from "./TimeTractingTimerStyles";
import Colors from "../../../Constants/Colors";
import { useHistory } from "react-router-dom";
import ProtectedRoute from "../../auth/ProtectedRoute";

const TimeTrackingTimer = () => {
  const history = useHistory();
  let urlElements = window.location.href.split("/");

  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        padding: "105px 0 40px 0",
        contain: "content",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center px-2 px-sm-3 px-md-4 px-lg-4"
        style={{ height: "4rem" }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            overflowY: "hidden",
            height: "100%",
          }}
        >
          {" "}
          <Styled.SliderBox
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 5px",
            }}
          >
            <div
              onClick={() => {
                history.push(`/db/timetracking/timers`);
              }}
            >
              {urlElements[5] === "timers" ? (
                <Styled.SelectedItem> Timer</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem> Timer</Styled.NotSelectedItem>
              )}
            </div>

            <div
              onClick={() => {
                history.push(`/db/timetracking/weekview`);
              }}
            >
              {urlElements[5] === "weekview" ? (
                <Styled.SelectedItem> Week view</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem> Week view</Styled.NotSelectedItem>
              )}
            </div>
            <div
              onClick={() => {
                history.push(`/db/timetracking/reports`);
              }}
            >
              {urlElements[5] === "reports" ? (
                <Styled.SelectedItem> Reports</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem> Reports</Styled.NotSelectedItem>
              )}
            </div>
          </Styled.SliderBox>
        </div>
      </div>
      <div className="px-2 px-sm-3 px-md-4 px-lg-4">
        <ProtectedRoute
          path="/db/timetracking/timers"
          exact
          component={Timer}
        />
        <ProtectedRoute
          path="/db/timetracking/weekview"
          exact
          component={WeeklyView}
        />
        <ProtectedRoute
          path="/db/timetracking/reports"
          exact
          component={Reports}
        />
      </div>
    </div>
  );
};

export default React.memo(TimeTrackingTimer);
