import React, { useState } from "react";
import signatureImg from "../../../../../Assets/assetsnew/signature-symbol.svg";
import SignProposal from "./SignProposal.jsx";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./SignatureStyles";

const Signature = ({
  selectedData,
  data,
  businessInfoData,
  primaryContact,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Styled.ReviewBox className="mb-4">
        <div className="d-flex flex-wrap gap-3 justify-content-between align-items-center mb-3">
          <TextStyles.FontSize20px className="d-flex gap-2 align-items-center">
            <img src={signatureImg} alt="signatureImg" />
            <span className="pl-2">Signature</span>
          </TextStyles.FontSize20px>
          <Styled.SignatureBox
            onClick={() => setShowModal(true)}
            className="ml-auto"
          >
            <div className="d-flex justify-content-center">Sign document</div>
          </Styled.SignatureBox>
        </div>
        <div className="d-flex flex-wrap flex-sm-nowrap gap-2justify-content-sm-between align-items-center">
          <div className="d-flex flex-column justify-content-start w-100">
            <div
              style={{
                fontWeight: 400,
                fontSize: 36,
                height: 130,
              }}
              className="d-flex justify-content-start"
            >
              <div className="d-flex align-items-end h-100">
                {selectedData &&
                  selectedData.client_signature &&
                  selectedData.client_signature.text && (
                    <div style={{ fontFamily: "licorice" }}>
                      {selectedData.client_signature.text}
                    </div>
                  )}
                {selectedData &&
                  selectedData.client_signature &&
                  selectedData.client_signature.image &&
                  selectedData.client_signature.image.image_name && (
                    <img
                      src={selectedData.client_signature.image.image_url}
                      alt="imageBtn"
                      style={{ width: "100%", height: "120px" }}
                    />
                  )}
              </div>
            </div>
            <TextStyles.FontSize16px className="d-flex justify-content-start">
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.company_name}
            </TextStyles.FontSize16px>
            <TextStyles.GreyFont14px className="d-flex justify-content-start">
              {primaryContact.contact_name}
            </TextStyles.GreyFont14px>
          </div>
          <div className="d-flex flex-column ml-auto justify-content-end w-100">
            <div
              style={{
                fontWeight: 400,
                fontSize: 36,
                height: 130,
              }}
              className="d-flex justify-content-end"
            >
              <div className="d-flex align-items-end h-100">
                {selectedData &&
                  selectedData.signature &&
                  selectedData.signature.text && (
                    <div style={{ fontFamily: "licorice" }}>
                      {selectedData.signature.text}
                    </div>
                  )}

                {selectedData &&
                  selectedData.signature &&
                  selectedData.signature.image &&
                  selectedData.signature.image.image_name && (
                    <img
                      src={selectedData.signature.image.image_url}
                      alt="imageBtn"
                      style={{ width: "100%", height: "120px", maxWidth: 200 }}
                    />
                  )}
              </div>
            </div>
            <TextStyles.FontSize16px className="d-flex justify-content-end">
              {businessInfoData && businessInfoData.business_name
                ? businessInfoData.business_name
                : data && `${data.firstName} ${data.lastName}`}
            </TextStyles.FontSize16px>
            <TextStyles.GreyFont14px className="d-flex justify-content-end">
              {businessInfoData &&
              businessInfoData.business_name !==
                `${data.firstName} ${data.lastName}`
                ? data && `${data.firstName} ${data.lastName}`
                : ""}
            </TextStyles.GreyFont14px>
          </div>
        </div>
      </Styled.ReviewBox>
      {showModal && (
        <SignProposal
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default Signature;
