import React, { useState } from "react";
import { useSelector } from "react-redux";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

// for page one

import ContractSetting from "./ContactSetting.jsx";
import ProjectPageOne from "./PageOne/Project.jsx";
import ContractDateDetails from "./PageOne/ContractDateDetails.jsx";
import ClientAndContractor from "./PageOne/ClientAndContractor.jsx";
import RestOne from "./PageOne/RestOne.jsx";

// for page two

import ProjectPageTwo from "./PageTwo/Project.jsx";
import Services from "./PageTwo/Services.jsx";
import BillingSchedules from "./PageTwo/BillingSchedules.jsx";

// for page three

import PageThree from "./PageThree/PageThree.jsx";

// for page four

import PageFour from "./PageFour/PageFour.jsx";

// for page five
import PageFive from "./PageFive/PageFive.jsx";

// for page six
import PageSix from "./PageSix/PageSix.jsx";

import TextStyles from "../../../../Constants/TextStyles";
import Styled from "./ReviewStyles";

import SignProposal from "../ProposalReviews/Signatures/SignProposal";
import AddClauseModal from "./PageModals/AddClauseModal";
import AddClause from "./AddClause/AddClause.jsx";
import ListAddClause from "./AddClause/components/ListAddClause";
import Colors from "../../../../Constants/Colors.js";

const Review = ({ page, setPage, backBtn }) => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showSetting, setShowSetting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAddClauseModal, setShowAddClauseModal] = useState(false);

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <div
        className="d-flex justify-content-center w-100"
        style={{ contain: "content" }}
      >
        <div style={{ width: "100%", maxWidth: 800 }}>
          <Styled.ReviewBox className="mb-4">
            <div
              className="d-flex justify-content-end align-items-center gap-1"
              onClick={() => setShowSetting(true)}
              role="button"
            >
              <SettingsOutlinedIcon
                sx={{ color: Colors.font2, fontSize: 24 }}
              />
              <span style={{ color: Colors.font2 }}>Contract settings</span>
            </div>
            <div className="review_content_padding">
              <TextStyles.FontSize27px className="mt-4">
                Service contract
              </TextStyles.FontSize27px>
              <ProjectPageOne />
              <ContractDateDetails />
              <ClientAndContractor />
              <RestOne />
            </div>
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
            <div className="review_content_padding">
              <TextStyles.FontSize27px
                className="d-flex w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots"> Attachment A : Services</span>
              </TextStyles.FontSize27px>
              <ProjectPageTwo />
              <Services />
              <BillingSchedules />
            </div>
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
            <div className="review_content_padding">
              <TextStyles.FontSize27px
                className="d-flex w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">
                  Attachment B : Terms and Conditions
                </span>
              </TextStyles.FontSize27px>
              <PageThree />
            </div>
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
            <div className="review_content_padding">
              <PageFour />
            </div>
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
            <div className="review_content_padding">
              <PageFive />
            </div>
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
            <div className="review_content_padding">
              <PageSix />
            </div>
          </Styled.ReviewBox>

          {selectedData &&
            selectedData.add_clause &&
            selectedData.add_clause.length > 0 && (
              <Styled.ReviewBox className="mb-4">
                <div className="review_content_padding">
                  {selectedData &&
                    selectedData.add_clause &&
                    selectedData.add_clause.map((data, index) => {
                      return (
                        <ListAddClause
                          key={index}
                          data={data}
                          pc_id={selectedData._id}
                        />
                      );
                    })}
                </div>
              </Styled.ReviewBox>
            )}

          <div className="mb-4" onClick={() => setShowAddClauseModal(true)}>
            <AddClause />
          </div>
        </div>
      </div>
      {backBtn}
      <Styled.SubmitButton
        onClick={() => {
          if (
            (selectedData &&
              selectedData.signature &&
              selectedData.signature.text) ||
            (selectedData &&
              selectedData.signature &&
              selectedData.signature.image.image_name &&
              selectedData.signature.image.image_url)
          ) {
            setPage(page + 1);
          } else {
            setShowModal(true);
          }
        }}
      >
        Continue
      </Styled.SubmitButton>
      {showSetting && (
        <ContractSetting
          show={showSetting}
          handleClose={() => setShowSetting(false)}
          selectedData={selectedData}
        />
      )}
      {showModal && (
        <SignProposal
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedData={selectedData}
        />
      )}
      {showAddClauseModal && (
        <AddClauseModal
          selectedData={selectedData}
          show={showAddClauseModal}
          handleClose={() => setShowAddClauseModal(false)}
        />
      )}
    </div>
  );
};

export default Review;
