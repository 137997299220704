import React from "react";
import { Cell, Legend, Pie, PieChart, Sector } from "recharts";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

const COLORS = [Colors.primary, "#dfdfe2"];

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
  } = props;

  return (
    <g>
      <text
        style={{ fontSize: 14, fontFamily: "Poppins", textAlign: "center" }}
        x={cx + 3}
        y={cy + 5}
        textAnchor="middle"
        fill={Colors.primary}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const renderColorfulLegendText = (value) => {
  return (
    <TextStyles.FontSize14px className="d-inline pr-2">
      {value}
    </TextStyles.FontSize14px>
  );
};

const PieChartGraph = ({ invoiced, not_invoiced }) => {
  const activeIndex = 0;
  const data = [
    { name: "Invoiced", value: invoiced },
    { name: "Not invoiced", value: not_invoiced },
  ];

  return (
    <PieChart width={250} height={110}>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx={50}
        cy={50}
        innerRadius={30}
        outerRadius={45}
        dataKey="value"
      >
        {data &&
          data.map((entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
      </Pie>
      <Legend
        fontFamily="Poppins"
        verticalAlign="middle"
        layout="vertical"
        align="right"
        iconType="square"
        iconSize="14"
        formatter={renderColorfulLegendText}
      />
    </PieChart>
  );
};

export default PieChartGraph;
