import React from "react";
import styles from "./Testinomials.module.css";
import Carousel from "./Carousel/Carousel";
import Colors from "../../../Constants/Colors";

const Testinomials = () => {
  return (
    <div className={styles.fouth_section}>
      <div className="container-fluid py-3 py-md-4" style={{ maxWidth: 1200 }}>
        <div style={{ overflow: "hidden" }}>
          <div data-aos={"fade-up"}>
            <div className="text-center mb-4">
              <div className={`${styles.heading}`}>See how our customers</div>
              <div
                className={`${styles.heading}`}
                style={{
                  color: Colors.brandColorFrontend,
                }}
              >
                drive impact
              </div>
            </div>
          </div>
          <div data-aos={"fade-up"}>
            {" "}
            <Carousel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testinomials;
