import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../axios/axios";
import URL from "../../axios/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createTransaction = createAsyncThunk(
  "tr/add_transaction",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_TRANSACTION.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchTransactions = createAsyncThunk(
  "tr/fetch_transactions",
  async (data) => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      postRequest(URL.FTECH_TRANSACTIONS.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchTransactionsForGraph = createAsyncThunk(
  "tr/fetch_transactions_for_graph",
  async (data) => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      postRequest(URL.FTECH_TRANSACTIONS_FOR_GRAPH.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteTransaction = createAsyncThunk(
  "tr/delete_transaction",
  async (data) => {
    let token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      deleteRequest(URL.DELETE_TRANSACTION.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const editTransaction = createAsyncThunk(
  "tr/edit_transaction",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_TRANSACTION.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchTransactionCounts = createAsyncThunk(
  "tr/fetch_transaction_counts",
  async () => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      getRequest(URL.FTECH_TRANSACTION_COUNTS.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
