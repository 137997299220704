import React from "react";
import styles from "./SignupForm.module.css";
import { useForm } from "react-hook-form";
import PasswordToggle from "../auth/PasswordToggle";
import { useHistory } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Colors from "../../Constants/Colors";

const SignupForm = () => {
  const history = useHistory();
  const [InputType, Icons] = PasswordToggle();
  const {
    register,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const validateEmail = (value) => {
    const trimmedValue = value.trim();
    const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i;
    return (
      emailPattern.test(trimmedValue) || "Please enter a valid email address."
    );
  };

  const onSubmit = async (data) => {
    const Obj = {
      email: data.email,
      password: data.password,
    };
    history.push({
      pathname: "/db/signup",
      selectedData: Obj,
    });
    reset();
  };
  return (
    <div className={`${styles.section}`}>
      <div className="container-fluid py-3 py-md-5" style={{ maxWidth: 1200 }}>
        <div className={styles.content_box} data-aos={"fade-up"}>
          <div className={`${styles.heading} text-center text-md-left mb-3`}>
            Sign up for free. No credit card required.
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex gap-3 gap-md-4 flex-wrap flex-md-nowrap justify-content-center justify-content-md-between"
          >
            <div className="w-100">
              <div className="position-relative mb-2">
                <input
                  className={`${styles.input_rectangle} ${
                    errors.email ? `${styles.input_error}` : ""
                  }`}
                  style={{ paddingLeft: "2.75rem" }}
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email address"
                  {...register("email", {
                    validate: validateEmail,
                    onBlur: (e) => setValue("email", e.target.value.trim()),
                  })}
                  // {...register("email", {
                  //   required: "Email can not be empty.",
                  //   pattern: {
                  //     value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                  //     message: "Please enter valid email address.",
                  //   },
                  // })}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "1rem",
                    transform: "translateY(-50%)",
                  }}
                >
                  <MailOutlineIcon
                    sx={{ fontSize: 22, color: Colors.frontendFont2 }}
                  />
                </span>
              </div>

              {errors.email && (
                <div className={`${styles.error_text}`}>
                  {errors.email.message}
                </div>
              )}
            </div>
            <div className="w-100">
              <div className="position-relative mb-2">
                <input
                  className={`${styles.input_rectangle} ${
                    errors.password ? `${styles.input_error}` : ""
                  }`}
                  style={{ paddingLeft: "2.75rem" }}
                  type={InputType}
                  name="password"
                  id="password"
                  placeholder="Password"
                  {...register("password", {
                    required: "Password can not be empty.",
                    pattern: {
                      value:
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/i,
                      message:
                        "Please enter minimum six characters, at least one letter, one number and one special character",
                    },
                  })}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "1rem",
                    transform: "translateY(-50%)",
                  }}
                >
                  <LockOutlinedIcon
                    sx={{ fontSize: 22, color: Colors.font2 }}
                  />
                </span>
                <span
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "1rem",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {Icons}
                </span>
              </div>

              {errors.password && (
                <div className={`${styles.error_text}`}>
                  {errors.password.message}
                </div>
              )}
            </div>

            <div className={`${styles.button_width} w-100`}>
              <button className={styles.button}>Sign up with email</button>
            </div>
          </form>
          <hr className={`${styles.hr}`} />
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-6 col-lg-4 mb-2 mb-lg-0">
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <CheckCircleRoundedIcon className={styles.icons} />
                </div>
                <div className={`${styles.text}`}>
                  Accessibility: Use Zodot on all devices and platforms.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-2 mb-lg-0">
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <CheckCircleRoundedIcon className={styles.icons} />
                </div>
                <div className={`${styles.text}`}>
                  GDPR-Compliant: Your data is protected with us.
                </div>
              </div>
            </div>{" "}
            <div className="col-12 col-md-6 col-lg-4 mb-2 mb-lg-0">
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <CheckCircleRoundedIcon className={styles.icons} />
                </div>
                <div className={`${styles.text}`}>
                  24/7 Support: Enjoy around-the-clock assistance.
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
