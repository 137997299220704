import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import EditInvoiceDates from "../InvoicesModal/EditInvoiceDates";
import EditInvoiceTitle from "../InvoicesModal/EditInvoiceTitle";
import EditClientInfo from "../InvoicesModal/EditClientInfo";
import EditInvoiceService from "../InvoicesModal/EditInvoiceService";
import InvoiceNotes from "../InvoicesModal/InvoiceNotes";
import EditBusinessInfo from "../InvoicesModal/EditBusinessInfo";
import EditLineItemService from "../InvoicesModal/EditLineItemService";

import { ReactTooltip, ReactTooltipWithIcon } from "../../Commons/ReactTooltip";

import {
  deleteLineItemService,
  deleteInvoiceNotes,
  deleteInvoiceDiscount,
  deleteInvoiceTax,
} from "../../../../store/service/invoiceService";

import Styled from "./ReviewInvoicesStyles";
import Colors from "../../../../Constants/Colors";
import TextStyles from "../../../../Constants/TextStyles";
import AddLineItemService from "../InvoicesModal/AddLineItemService";
import InvoiceTaxes from "../InvoicesModal/InvoiceTaxes";
import DiscountInvoices from "../InvoicesModal/DiscountInvoices";
import ChangeCurrency from "../InvoicesModal/changeCurrency";

const ReviewInvoices = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth.data);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);
  const brandAndLogoData = useSelector((state) => state.auth.brandAndLogoData);

  const selectedInvoice = useSelector(
    (state) => state.invoices.selectedInvoice
  );

  // const selectedClient = selectedInvoice.client_id;
  const invoice_services_array = selectedInvoice.invoice_services;
  const line_item_services_array = selectedInvoice.line_item_services;
  const taxes_array = selectedInvoice.taxes;

  // const selectedProject = selectedInvoice.project_id;
  // const depositAmount =
  //   selectedProject &&
  //   selectedProject.billing_scheduled &&
  //   selectedProject.billing_scheduled.deposit &&
  //   selectedProject.billing_scheduled.deposit.require_deposit === true &&
  //   parseFloat(selectedProject.billing_scheduled.deposit.value);

  // const [scheduleInvoice, setScheduleInvoice] = useState(false);
  const [changeCurrency, setChangeCurrency] = useState(false);
  const [editInvoice, setEditInvoice] = useState(false);
  const [editInvoiceDates, setEditInvoiceDates] = useState(false);
  const [editClientInfo, setEditClientInfo] = useState(false);
  const [editInvoiceService, setEditInvoiceService] = useState(false);
  const [editLineItemService, setEditLineItemService] = useState(false);
  const [addLineItemService, setAddLineItemService] = useState(false);
  const [taxes, setTaxes] = useState(false);
  const [discount, setDiscount] = useState(false);

  const [selectedData, setSelectedData] = useState(null);
  const [editNote, setEditNote] = useState(false);
  const [editBusinessInfo, setEditBusinessInfo] = useState(false);

  const totalInvoiceServiceAmount = () => {
    let sum = 0;
    invoice_services_array.forEach((d) => {
      if (d.service_rate !== "") {
        sum += parseFloat(d.service_rate) * parseFloat(d.service_rate_count);
      }
    });
    return sum;
  };

  const totalLineItemServiceAmount = () => {
    let sum = 0;
    line_item_services_array.forEach((d) => {
      if (d.service_rate !== "") {
        sum += parseFloat(d.service_rate) * parseFloat(d.service_rate_count);
      }
    });
    return sum;
  };

  const totalServiceAmount =
    totalInvoiceServiceAmount() + totalLineItemServiceAmount();

  const invoiceDiscount = () => {
    let sum = 0;
    if (selectedInvoice && selectedInvoice.discount) {
      if (selectedInvoice.discount.percentage) {
        sum = parseFloat(
          totalServiceAmount *
            parseFloat(selectedInvoice.discount.percentage) *
            0.01
        );
      } else {
        sum = selectedInvoice.discount.value;
      }
    }
    return sum;
  };

  const totalServiceAmountAfterDiscount =
    parseFloat(totalServiceAmount) - parseFloat(invoiceDiscount());

  const totalTaxesAmount = () => {
    let sum = 0;
    taxes_array.forEach((d) => {
      if (d.percentage !== "") {
        sum +=
          (parseFloat(d.percentage) *
            parseFloat(totalServiceAmountAfterDiscount)) /
          100;
      }
    });
    return sum;
  };

  let totalDueAmount = totalServiceAmountAfterDiscount + totalTaxesAmount();

  const deleteLineItemServiceHandler = (data) => {
    const Obj = {
      invoice_id: selectedInvoice && selectedInvoice._id,
      _id: data._id,
    };
    dispatch(deleteLineItemService(Obj));
  };
  const deleteNotesHandler = (data) => {
    const Obj = {
      invoice_id: data,
      invoice_notes: null,
    };
    dispatch(deleteInvoiceNotes(Obj));
  };

  const deleteTaxHandler = (data) => {
    const Obj = {
      tax_id: data,
      invoice_id: selectedInvoice && selectedInvoice._id,
    };
    dispatch(deleteInvoiceTax(Obj));
  };

  const deleteDiscountHandler = (_id) => {
    const Obj = {
      invoice_id: _id,
      discount: undefined,
    };
    dispatch(deleteInvoiceDiscount(Obj));
  };

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <div
        className="d-flex justify-content-center w-100"
        style={{ contain: "content" }}
      >
        <div style={{ width: "100%", maxWidth: 830 }}>
          <Styled.SetupBox
            className="my-3"
            style={{
              borderTop: `4px solid ${
                brandAndLogoData && brandAndLogoData.brand_color
                  ? brandAndLogoData.brand_color
                  : Colors.primary
              }`,
            }}
          >
            <div className="d-flex justify-content-end mb-3">
              <div
                className="d-flex align-items-center"
                onClick={() => setChangeCurrency(true)}
                style={{ cursor: "pointer" }}
              >
                <CurrencyExchangeIcon
                  sx={{
                    color: Colors.font2,
                    backgroundColor: "white",
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                />
                <TextStyles.GreyFont16px className="ml-2">
                  Change currency
                </TextStyles.GreyFont16px>
              </div>
            </div>
            {brandAndLogoData &&
              brandAndLogoData.brand_logo &&
              brandAndLogoData.brand_logo.url && (
                <div
                  className={
                    brandAndLogoData && brandAndLogoData.logo_alignment
                      ? `text-${brandAndLogoData.logo_alignment} review_box_padding mb-3`
                      : "text-center review_box_padding mb-3"
                  }
                >
                  <img
                    src={brandAndLogoData.brand_logo.url}
                    alt="logoImg"
                    style={{ maxWidth: 512, maxHeight: 512 }}
                  />
                </div>
              )}
            <div className="d-flex flex-wrap flex-sm-nowrap gap-3 review_box_padding mt-4 w-100">
              <div className="w-100">
                <div
                  onClick={() => setEditInvoice(true)}
                  style={{ cursor: "pointer" }}
                  className="d-flex align-items-center"
                >
                  <TextStyles.FontSize27px
                    className="d-flex"
                    id="edit_title"
                    style={{
                      color:
                        brandAndLogoData && brandAndLogoData.brand_color
                          ? brandAndLogoData.brand_color
                          : Colors.primary,
                      fontWeight: 600,
                    }}
                  >
                    {selectedInvoice && selectedInvoice.invoice_title}
                  </TextStyles.FontSize27px>
                  <ReactTooltipWithIcon
                    anchorId={`edit_title`}
                    content="Edit title"
                    place="right"
                    variant="grey"
                  />
                </div>
                <TextStyles.FontSize14px>
                  Invoice number:
                  <span style={{ color: Colors.font2, marginLeft: 5 }}>
                    {selectedInvoice && selectedInvoice.invoice_no}
                  </span>
                </TextStyles.FontSize14px>
                <div
                  className="d-flex"
                  onClick={() => setEditClientInfo(true)}
                  style={{ cursor: "pointer" }}
                >
                  <ReactTooltipWithIcon
                    anchorId={`edit_client_info`}
                    content="Edit client's information"
                    place="right"
                    variant="grey"
                  />
                  <div id="edit_client_info">
                    <TextStyles.FontSize18px className="mt-3">
                      {selectedInvoice &&
                        selectedInvoice.client_id &&
                        selectedInvoice.client_id.company_name}
                    </TextStyles.FontSize18px>
                    <TextStyles.GreyFont14px className="mt-2 ">
                      {selectedInvoice &&
                        selectedInvoice.client_id &&
                        selectedInvoice.client_id.street}
                      {selectedInvoice &&
                      selectedInvoice.client_id &&
                      selectedInvoice.client_id.suite
                        ? ", "
                        : ""}
                      {selectedInvoice &&
                        selectedInvoice.client_id &&
                        selectedInvoice.client_id.suite}
                      {selectedInvoice &&
                      selectedInvoice.client_id &&
                      selectedInvoice.client_id.city
                        ? ", "
                        : ""}
                      {selectedInvoice &&
                        selectedInvoice.client_id &&
                        selectedInvoice.client_id.city}
                      {selectedInvoice &&
                      selectedInvoice.client_id &&
                      selectedInvoice.client_id.state
                        ? ", "
                        : ""}
                      {selectedInvoice &&
                        selectedInvoice.client_id &&
                        selectedInvoice.client_id.state}
                      {selectedInvoice &&
                      selectedInvoice.client_id &&
                      selectedInvoice.client_id.country
                        ? ", "
                        : ""}
                      {selectedInvoice &&
                        selectedInvoice.client_id &&
                        selectedInvoice.client_id.country}
                      {selectedInvoice &&
                      selectedInvoice.client_id &&
                      selectedInvoice.client_id.zip_code
                        ? ", "
                        : ""}
                      {selectedInvoice &&
                        selectedInvoice.client_id &&
                        selectedInvoice.client_id.zip_code}
                      {selectedInvoice &&
                      selectedInvoice.client_id &&
                      selectedInvoice.client_id.tax_id_label
                        ? ", "
                        : ""}
                      {selectedInvoice &&
                        selectedInvoice.client_id &&
                        selectedInvoice.client_id.tax_id_label}
                      {selectedInvoice &&
                      selectedInvoice.client_id &&
                      selectedInvoice.client_id.tax_id_number
                        ? ", "
                        : ""}
                      {selectedInvoice &&
                        selectedInvoice.client_id &&
                        selectedInvoice.client_id.tax_id_number}
                    </TextStyles.GreyFont14px>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end w-100">
                <ReactTooltipWithIcon
                  anchorId={`edit_dates`}
                  content="Edit dates"
                  place="right"
                  variant="grey"
                />
                <div
                  className="d-flex flex-column align-items-end"
                  id={`edit_dates`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setEditInvoiceDates(true)}
                >
                  <TextStyles.FontSize16px
                    style={{
                      color:
                        brandAndLogoData && brandAndLogoData.brand_color
                          ? brandAndLogoData.brand_color
                          : Colors.primary,
                      fontWeight: 600,
                    }}
                  >
                    {selectedInvoice &&
                      selectedInvoice.currency &&
                      selectedInvoice.currency.symbol}
                    {totalDueAmount > 0
                      ? parseFloat(totalDueAmount).toFixed(2)
                      : "00.00"}
                  </TextStyles.FontSize16px>
                  <TextStyles.GreyFont14px className="mt-2">
                    <span style={{ color: Colors.font1 }}>Due: </span>
                    <span>
                      {selectedInvoice &&
                      selectedInvoice.issued &&
                      selectedInvoice.due_date &&
                      moment(selectedInvoice.issued).format("DD MMM YYYY") !==
                        moment(selectedInvoice.due_date).format("DD MMM YYYY")
                        ? moment(selectedInvoice.due_date).format("DD MMM YYYY")
                        : "Upon receipt"}
                    </span>
                  </TextStyles.GreyFont14px>
                  <TextStyles.GreyFont14px className="mt-2">
                    <span style={{ color: Colors.font1 }}>Issued: </span>
                    <span>
                      {selectedInvoice && selectedInvoice.issued
                        ? moment(
                            selectedInvoice && selectedInvoice.issued
                          ).format("DD MMM YYYY")
                        : moment(new Date()).format("DD MMM YYYY")}
                    </span>
                  </TextStyles.GreyFont14px>
                </div>
              </div>
            </div>

            <div className="review_box_padding">
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "1px",
                  border: "0.5px solid #f6f6f6",
                }}
              />
            </div>

            {selectedInvoice.invoice_services.length > 0 && (
              <div className="edit_invoice_service review_box_padding">
                <div className="d-flex align-items-center mb-3">
                  <TextStyles.FontSize20px
                    style={{ fontWeight: 500, fontSize: 24 }}
                  >
                    {selectedInvoice &&
                      selectedInvoice.project_id &&
                      selectedInvoice.project_id.project_name}
                  </TextStyles.FontSize20px>

                  <ReactTooltip
                    anchorId={`edit_invoice_services`}
                    content="Edit services"
                    variant="grey"
                  />
                  <BorderColorOutlinedIcon
                    id={`edit_invoice_services`}
                    onClick={() => setEditInvoiceService(true)}
                    className="edit_invoice_serviceBtn"
                    sx={{
                      width: 32,
                      height: 32,
                      color: Colors.font2,
                      border: "2px solid #ececec",
                      background: "#f6f6f6",
                      borderRadius: 2,
                      padding: "3px",
                      right: 10,
                      cursor: "pointer",
                      position: "absolute",
                    }}
                  />
                </div>
                <div className="d-flex gap-2">
                  <TextStyles.FontSize18px
                    className="d-flex w-100"
                    style={{
                      minWidth: 50,
                    }}
                  >
                    <span className="three-dots">Description</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 60, minWidth: 50 }}
                  >
                    <span className="three-dots">Qty</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 110, minWidth: 50 }}
                  >
                    <span className="three-dots">Price</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 120, minWidth: 50 }}
                  >
                    <span className="three-dots">Amount</span>
                  </TextStyles.FontSize18px>
                </div>
                <div
                  className="my-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    border: "0.5px solid #f6f6f6",
                  }}
                />
                {selectedInvoice &&
                  selectedInvoice.invoice_services &&
                  selectedInvoice.invoice_services.map((data, index) => {
                    return (
                      <div className="d-flex gap-2 mb-2" key={index}>
                        <div
                          className="d-flex flex-column w-100"
                          style={{
                            minWidth: 50,
                          }}
                        >
                          <TextStyles.FontSize14px className="three-dots">
                            <span className="three-dots">
                              {data.service_name}
                            </span>
                          </TextStyles.FontSize14px>
                          <TextStyles.GreyFont14px className="d-none d-md-block">
                            {data.desc}
                          </TextStyles.GreyFont14px>
                        </div>
                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 60, minWidth: 50 }}
                        >
                          <span className="three-dots">
                            {data.service_rate_count}
                          </span>
                        </TextStyles.FontSize14px>

                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 110, minWidth: 50 }}
                        >
                          <span className="three-dots">
                            {" "}
                            {selectedInvoice &&
                              selectedInvoice.currency &&
                              selectedInvoice.currency.symbol}
                            {data.service_rate
                              ? parseFloat(data.service_rate).toFixed(2)
                              : "00.00"}
                          </span>{" "}
                        </TextStyles.FontSize14px>

                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 120, minWidth: 50 }}
                        >
                          <span className="three-dots">
                            {selectedInvoice &&
                              selectedInvoice.currency &&
                              selectedInvoice.currency.symbol}

                            {data.service_rate && data.service_rate_count
                              ? parseFloat(
                                  data.service_rate * data.service_rate_count
                                ).toFixed(2)
                              : "00.00"}
                          </span>
                        </TextStyles.FontSize14px>
                      </div>
                    );
                  })}
                <div
                  className="my-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    border: "0.5px solid #f6f6f6",
                  }}
                />
              </div>
            )}

            {selectedInvoice.line_item_services.length > 0 && (
              <div>
                <div className="d-flex review_box_padding mb-2">
                  <TextStyles.FontSize20px
                    style={{ fontWeight: 500, fontSize: 24 }}
                  >
                    {selectedInvoice &&
                    selectedInvoice.project_id &&
                    selectedInvoice.project_id.project_name
                      ? "Other services"
                      : "Services"}
                  </TextStyles.FontSize20px>
                </div>
                <div className="d-flex gap-2 review_box_padding">
                  <TextStyles.FontSize18px
                    className="d-flex w-100"
                    style={{
                      minWidth: 50,
                    }}
                  >
                    <span className="three-dots">Description</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 60, minWidth: 50 }}
                  >
                    <span className="three-dots">Qty</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 110, minWidth: 50 }}
                  >
                    <span className="three-dots">Price</span>
                  </TextStyles.FontSize18px>
                  <TextStyles.FontSize18px
                    className="d-flex justify-content-end w-100"
                    style={{ maxWidth: 120, minWidth: 50 }}
                  >
                    <span className="three-dots">Amount</span>
                  </TextStyles.FontSize18px>
                </div>

                <div className="review_box_padding">
                  <div
                    className="my-3"
                    style={{
                      width: "100%",
                      height: "1px",
                      border: "0.5px solid #f6f6f6",
                    }}
                  />
                </div>

                {selectedInvoice &&
                  selectedInvoice.line_item_services &&
                  selectedInvoice.line_item_services.map((data, index) => {
                    return (
                      <div
                        className="d-flex gap-2 edit_line_item_service review_box_padding mb-2"
                        key={index}
                      >
                        <div
                          className="d-flex flex-column w-100"
                          style={{
                            minWidth: 50,
                          }}
                        >
                          <TextStyles.FontSize14px className="three-dots">
                            <span className="three-dots">
                              {data.service_name}
                            </span>
                          </TextStyles.FontSize14px>
                          <TextStyles.GreyFont14px className="d-none d-md-block">
                            {data.desc}
                          </TextStyles.GreyFont14px>
                        </div>
                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 60, minWidth: 50 }}
                        >
                          <span className="three-dots">
                            {data.service_rate_count}
                          </span>
                        </TextStyles.FontSize14px>

                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 110, minWidth: 50 }}
                        >
                          <span className="three-dots">
                            {" "}
                            {selectedInvoice &&
                              selectedInvoice.currency &&
                              selectedInvoice.currency.symbol}
                            {data.service_rate
                              ? parseFloat(data.service_rate).toFixed(2)
                              : "00.00"}
                          </span>{" "}
                        </TextStyles.FontSize14px>

                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 120, minWidth: 50 }}
                        >
                          <span className="three-dots">
                            {selectedInvoice &&
                              selectedInvoice.currency &&
                              selectedInvoice.currency.symbol}

                            {data.service_rate && data.service_rate_count
                              ? parseFloat(
                                  data.service_rate * data.service_rate_count
                                ).toFixed(2)
                              : "00.00"}
                          </span>
                        </TextStyles.FontSize14px>
                        <ReactTooltip
                          anchorId={`edit_line_item_services.${index}`}
                          content="Edit service"
                          variant="grey"
                        />

                        <BorderColorOutlinedIcon
                          id={`edit_line_item_services.${index}`}
                          onClick={() => {
                            setSelectedData(data);
                            setEditLineItemService(true);
                          }}
                          className="edit_line_item_serviceBtn"
                          sx={{
                            width: 32,
                            height: 32,
                            color: Colors.font2,
                            border: "2px solid #ececec",
                            background: "#f6f6f6",
                            borderRadius: 2,
                            padding: "3px",
                            right: 10,
                            cursor: "pointer",
                            position: "absolute",
                          }}
                        />
                        <ReactTooltip
                          anchorId={`delete_line_item_services.${index}`}
                          content="Delete service"
                          variant="grey"
                        />
                        <DeleteForeverRoundedIcon
                          id={`delete_line_item_services.${index}`}
                          onClick={() => deleteLineItemServiceHandler(data)}
                          className="delete_line_item_serviceBtn"
                          sx={{
                            width: 32,
                            height: 32,
                            color: Colors.font2,
                            border: "2px solid #ececec",
                            background: "#f6f6f6",
                            borderRadius: 2,
                            padding: "3px",
                            right: 50,
                            cursor: "pointer",
                            position: "absolute",
                          }}
                        />
                      </div>
                    );
                  })}

                <div className="review_box_padding">
                  <div
                    className=" my-3"
                    style={{
                      width: "100%",
                      height: "1px",
                      border: "0.5px solid #f6f6f6",
                    }}
                  />
                </div>
              </div>
            )}

            <div className="review_box_padding mt-3">
              <div className="d-flex gap-2">
                <div
                  className="d-flex w-100"
                  style={{
                    minWidth: 50,
                  }}
                />
                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{ maxWidth: 110, minWidth: 50 }}
                >
                  <span className="three-dots">Subtotal</span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{ maxWidth: 120, minWidth: 50 }}
                >
                  <span className="three-dots">
                    {selectedInvoice &&
                      selectedInvoice.currency &&
                      selectedInvoice.currency.symbol}
                    {totalServiceAmount > 0
                      ? parseFloat(totalServiceAmount).toFixed(2)
                      : "00.00"}
                  </span>
                </TextStyles.FontSize14px>
              </div>
            </div>

            <div className="d-flex review_box_padding">
              <div className="d-flex gap-2 w-100">
                <div
                  className="d-flex w-100"
                  style={{
                    minWidth: 50,
                  }}
                />

                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{ maxWidth: 230, minWidth: 50 }}
                >
                  <div
                    className="my-2"
                    style={{
                      width: "100%",
                      height: "1px",
                      border: "0.5px solid #f6f6f6",
                    }}
                  />
                </TextStyles.FontSize14px>
              </div>
            </div>

            {selectedInvoice && selectedInvoice.taxes.length > 0 ? (
              selectedInvoice.taxes.map((data, index) => {
                return (
                  <div key={index} className="invoice_taxes review_box_padding">
                    <div className="d-flex gap-2 align-items-center">
                      <div
                        className="d-flex w-100"
                        style={{
                          minWidth: 50,
                        }}
                      />

                      <TextStyles.FontSize14px
                        className="d-flex justify-content-end w-100"
                        style={{
                          minWidth: 50,
                          maxWidth: "fit-content",
                        }}
                      >
                        <span className="three-dots">
                          {data.tax_label}
                          {`(${data.percentage}%)`}
                        </span>
                      </TextStyles.FontSize14px>
                      <TextStyles.FontSize14px
                        className="d-flex justify-content-end w-100"
                        style={{ maxWidth: 120, minWidth: 50 }}
                      >
                        <span className="three-dots">
                          {" "}
                          {selectedInvoice &&
                            selectedInvoice.currency &&
                            selectedInvoice.currency.symbol}
                          {totalServiceAmountAfterDiscount > 0
                            ? (
                                parseFloat(data.percentage) *
                                parseFloat(totalServiceAmountAfterDiscount) *
                                0.01
                              ).toFixed(2)
                            : "00.00"}
                        </span>
                      </TextStyles.FontSize14px>
                      <ReactTooltip
                        anchorId={`edit_taxes.${index}`}
                        content="Edit Taxes"
                        variant="grey"
                      />
                      <BorderColorOutlinedIcon
                        id={`edit_taxes.${index}`}
                        className="edit_invoice_taxBtn"
                        onClick={() => setTaxes(true)}
                        sx={{
                          width: 32,
                          height: 32,
                          color: Colors.font2,
                          border: "2px solid #ececec",
                          background: "#f6f6f6",
                          borderRadius: 2,
                          padding: "3px",
                          right: 10,
                          cursor: "pointer",
                          position: "absolute",
                        }}
                      />
                      <ReactTooltip
                        anchorId={`delete_taxes.${index}`}
                        content="Delete tax"
                        variant="grey"
                      />

                      <DeleteForeverRoundedIcon
                        id={`delete_taxes.${index}`}
                        className="delete_invoice_taxBtn"
                        onClick={() => deleteTaxHandler(data._id)}
                        sx={{
                          width: 32,
                          height: 32,
                          color: Colors.font2,
                          border: "2px solid #ececec",
                          background: "#f6f6f6",
                          borderRadius: 2,
                          padding: "3px",
                          right: 50,
                          cursor: "pointer",
                          position: "absolute",
                        }}
                      />
                    </div>
                    <div className="d-flex review_box_padding">
                      <div className="d-flex gap-2 w-100">
                        <div
                          className="d-flex w-100"
                          style={{
                            minWidth: 100,
                          }}
                        />

                        <TextStyles.FontSize14px
                          className="d-flex justify-content-end w-100"
                          style={{ maxWidth: 230, minWidth: 50 }}
                        >
                          <div
                            className="my-2"
                            style={{
                              width: "100%",
                              height: "1px",
                              border: "0.5px solid #f6f6f6",
                            }}
                          />
                        </TextStyles.FontSize14px>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="d-flex review_box_padding">
                  <div className="d-flex gap-2 w-100">
                    <div
                      className="d-flex w-100"
                      style={{
                        minWidth: 100,
                      }}
                    />

                    <TextStyles.GreyFont14px
                      className="d-flex justify-content-end w-100"
                      style={{ maxWidth: 230, minWidth: 50, cursor: "pointer" }}
                      onClick={() => setTaxes(true)}
                    >
                      <ReactTooltip
                        anchorId={`add_taxes`}
                        content="Add taxes"
                        variant="grey"
                      />
                      <span id={`add_taxes`}> + Taxes</span>
                    </TextStyles.GreyFont14px>
                  </div>
                </div>
                <div className="d-flex review_box_padding">
                  <div className="d-flex gap-2 w-100">
                    <div
                      className="d-flex w-100"
                      style={{
                        minWidth: 100,
                      }}
                    />

                    <TextStyles.FontSize14px
                      className="d-flex justify-content-end w-100"
                      style={{ maxWidth: 230, minWidth: 50 }}
                    >
                      <div
                        className="my-2"
                        style={{
                          width: "100%",
                          height: "1px",
                          border: "0.5px solid #f6f6f6",
                        }}
                      />
                    </TextStyles.FontSize14px>
                  </div>
                </div>
              </>
            )}

            {selectedInvoice && selectedInvoice.discount ? (
              <div className="invoice_discount review_box_padding">
                <div className="d-flex gap-2 align-items-center">
                  <div
                    className="d-flex w-100"
                    style={{
                      minWidth: 50,
                    }}
                  />
                  <TextStyles.FontSize14px
                    className="d-flex justify-content-end w-100"
                    style={{
                      minWidth: 50,
                      maxWidth: "fit-content",
                    }}
                  >
                    <span className="three-dots">
                      Discount
                      {selectedInvoice.discount.percentage
                        ? `(${selectedInvoice.discount.percentage}%)`
                        : ""}
                    </span>
                  </TextStyles.FontSize14px>
                  <TextStyles.FontSize14px
                    className="d-flex justify-content-end w-100"
                    style={{
                      minWidth: 50,
                      maxWidth: 120,
                    }}
                  >
                    <span className="three-dots">
                      {selectedInvoice &&
                        selectedInvoice.currency &&
                        selectedInvoice.currency.symbol}
                      {selectedInvoice.discount &&
                      selectedInvoice.discount.percentage
                        ? parseFloat(
                            totalServiceAmount *
                              parseFloat(selectedInvoice.discount.percentage) *
                              0.01
                          ).toFixed(2)
                        : parseFloat(selectedInvoice.discount.value).toFixed(2)}
                    </span>
                  </TextStyles.FontSize14px>

                  <ReactTooltip
                    anchorId={`edit_discount`}
                    content="Edit Discount"
                    variant="grey"
                  />

                  <BorderColorOutlinedIcon
                    id={`edit_discount`}
                    className="edit_invoice_discountBtn"
                    onClick={() => setDiscount(true)}
                    sx={{
                      width: 32,
                      height: 32,
                      color: Colors.font2,
                      border: "2px solid #ececec",
                      background: "#f6f6f6",
                      borderRadius: 2,
                      padding: "3px",
                      right: 10,
                      cursor: "pointer",
                      position: "absolute",
                    }}
                  />

                  <ReactTooltip
                    anchorId={`delete_discount`}
                    content="Delete discount"
                    variant="grey"
                  />

                  <DeleteForeverRoundedIcon
                    id={`delete_discount`}
                    className="delete_invoice_discountBtn"
                    onClick={() => deleteDiscountHandler(selectedInvoice._id)}
                    sx={{
                      width: 32,
                      height: 32,
                      color: Colors.font2,
                      border: "2px solid #ececec",
                      background: "#f6f6f6",
                      borderRadius: 2,
                      padding: "3px",
                      right: 50,
                      cursor: "pointer",
                      position: "absolute",
                    }}
                  />
                </div>

                <div className="d-flex review_box_padding">
                  <div className="d-flex gap-2 w-100">
                    <div
                      className="d-flex w-100"
                      style={{
                        minWidth: 100,
                      }}
                    />

                    <TextStyles.FontSize14px
                      className="d-flex justify-content-end w-100"
                      style={{ maxWidth: 230, minWidth: 50 }}
                    >
                      <div
                        className="my-2"
                        style={{
                          width: "100%",
                          height: "1px",
                          border: "0.5px solid #f6f6f6",
                        }}
                      />
                    </TextStyles.FontSize14px>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex review_box_padding">
                  <div className="d-flex gap-2 w-100">
                    <div
                      className="d-flex w-100"
                      style={{
                        minWidth: 100,
                      }}
                    />

                    <TextStyles.GreyFont14px
                      className="d-flex justify-content-end w-100"
                      style={{ maxWidth: 230, minWidth: 50, cursor: "pointer" }}
                      onClick={() => setDiscount(true)}
                    >
                      <ReactTooltip
                        anchorId={`add_discount`}
                        content="Add discount"
                        variant="grey"
                      />
                      <span id={`add_discount`}> + Discount</span>
                    </TextStyles.GreyFont14px>
                  </div>
                </div>
                <div className="d-flex review_box_padding">
                  <div className="d-flex gap-2 w-100">
                    <div
                      className="d-flex w-100"
                      style={{
                        minWidth: 100,
                      }}
                    />

                    <TextStyles.FontSize14px
                      className="d-flex justify-content-end w-100"
                      style={{ maxWidth: 230, minWidth: 50 }}
                    >
                      <div
                        className="my-2"
                        style={{
                          width: "100%",
                          height: "1px",
                          border: "0.5px solid #f6f6f6",
                        }}
                      />
                    </TextStyles.FontSize14px>
                  </div>
                </div>
              </>
            )}

            <div className="review_box_padding">
              <div className="d-flex gap-2 align-items-center">
                <TextStyles.GreyFont14px
                  className="d-flex w-100"
                  style={{ minWidth: 50, cursor: "pointer" }}
                  onClick={() => setAddLineItemService(true)}
                >
                  <ReactTooltip
                    anchorId={`add_line_item`}
                    content="Add line item"
                    variant="grey"
                  />
                  <span id={`add_line_item`} className="three-dots">
                    + Add line item
                  </span>
                </TextStyles.GreyFont14px>

                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{
                    minWidth: 50,
                    fontWeight: 600,
                    maxWidth: "fit-content",
                  }}
                >
                  <span className="three-dots">Amount due</span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{
                    minWidth: 50,
                    maxWidth: 120,
                    fontWeight: 600,
                  }}
                >
                  <span className="three-dots">
                    {selectedInvoice &&
                      selectedInvoice.currency &&
                      selectedInvoice.currency.symbol}
                    {totalDueAmount > 0
                      ? parseFloat(totalDueAmount).toFixed(2)
                      : "00.00"}
                  </span>
                </TextStyles.FontSize14px>
              </div>
            </div>
            <div className="d-flex review_box_padding">
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "1px",
                  border: "0.5px solid #f6f6f6",
                }}
              />
            </div>

            {selectedInvoice && selectedInvoice.invoice_notes === null && (
              <div className="review_box_padding">
                <TextStyles.GreyFont14px
                  style={{ cursor: "pointer" }}
                  onClick={() => setEditNote(true)}
                >
                  <ReactTooltip
                    anchorId={`add_invoice_note`}
                    content="Add a note"
                    variant="grey"
                  />
                  <span id={`add_invoice_note`}> + Notes</span>
                </TextStyles.GreyFont14px>
              </div>
            )}

            {selectedInvoice && selectedInvoice.invoice_notes !== null && (
              <div className="d-flex flex-column invoice_notes review_box_padding">
                <TextStyles.FontSize16px>Notes</TextStyles.FontSize16px>
                <TextStyles.GreyFont14px className="mt-2">
                  {selectedInvoice && selectedInvoice.invoice_notes}
                </TextStyles.GreyFont14px>

                <ReactTooltip
                  anchorId={`edit_invoice_note`}
                  content="Edit note"
                  variant="grey"
                />
                <BorderColorOutlinedIcon
                  id={`edit_invoice_note`}
                  className="edit_invoice_NoteBtn"
                  onClick={() => setEditNote(true)}
                  sx={{
                    width: 32,
                    height: 32,
                    color: Colors.font2,
                    border: "2px solid #ececec",
                    background: "#f6f6f6",
                    borderRadius: 2,
                    padding: "3px",
                    right: 10,
                    cursor: "pointer",
                    position: "absolute",
                  }}
                />

                <ReactTooltip
                  anchorId={`delete_invoice_note`}
                  content="Delete note"
                  variant="grey"
                />

                <DeleteForeverRoundedIcon
                  id={`delete_invoice_note`}
                  className="delete_invoice_NoteBtn"
                  onClick={() => deleteNotesHandler(selectedInvoice._id)}
                  sx={{
                    width: 32,
                    height: 32,
                    color: Colors.font2,
                    border: "2px solid #ececec",
                    background: "#f6f6f6",
                    borderRadius: 2,
                    padding: "3px",
                    right: 50,
                    cursor: "pointer",
                    position: "absolute",
                  }}
                />
              </div>
            )}

            <div className="d-flex review_box_padding">
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "1px",
                  border: "0.5px solid #f6f6f6",
                }}
              />
            </div>

            <div className="d-flex mb-4 invoice_business review_box_padding">
              <div>
                {businessInfoData && businessInfoData.business_name !== null ? (
                  <TextStyles.FontSize16px>
                    {businessInfoData && businessInfoData.business_name}
                  </TextStyles.FontSize16px>
                ) : (
                  <TextStyles.FontSize16px className="d-flex align-items-center">
                    {data &&
                      `${data.firstName} ${data.lastName} (${data.country.code})`}
                  </TextStyles.FontSize16px>
                )}

                <TextStyles.GreyFont14px className="mt-2">
                  {data && data.email && `Email: ${data.email}`}
                </TextStyles.GreyFont14px>

                {businessInfoData !== null && (
                  <div>
                    <TextStyles.GreyFont14px className="mt-2">
                      {businessInfoData &&
                        businessInfoData.phone_number &&
                        businessInfoData.phone_code &&
                        `Phone: +${businessInfoData.phone_code.country_code}${businessInfoData.phone_number}`}
                    </TextStyles.GreyFont14px>
                    <TextStyles.GreyFont14px className="mt-1">
                      {businessInfoData && businessInfoData.street}
                      {businessInfoData && businessInfoData.suite ? ", " : ""}
                      {businessInfoData && businessInfoData.suite}
                      {businessInfoData && businessInfoData.city ? ", " : ""}
                      {businessInfoData && businessInfoData.city}
                      {businessInfoData && businessInfoData.state ? ", " : ""}
                      {businessInfoData && businessInfoData.state}
                      {businessInfoData && businessInfoData.country ? ", " : ""}
                      {businessInfoData && businessInfoData.country}
                      {businessInfoData && businessInfoData.zip_code
                        ? ", "
                        : ""}
                      {businessInfoData && businessInfoData.zip_code}
                      {businessInfoData &&
                      businessInfoData.tax_id_label &&
                      businessInfoData.tax_id_number
                        ? ", "
                        : ""}
                    </TextStyles.GreyFont14px>
                    <TextStyles.GreyFont14px className="mt-1">
                      {businessInfoData &&
                        businessInfoData.tax_id_label &&
                        businessInfoData.tax_id_number &&
                        `${businessInfoData.tax_id_label}: ${businessInfoData.tax_id_number}`}
                    </TextStyles.GreyFont14px>
                  </div>
                )}
              </div>
              <div>
                <ReactTooltip
                  anchorId={`edit_business_info`}
                  content="Edit business information"
                  variant="grey"
                />

                <BorderColorOutlinedIcon
                  id={`edit_business_info`}
                  className="edit_invoice_businessBtn"
                  onClick={() => setEditBusinessInfo(true)}
                  sx={{
                    width: 32,
                    height: 32,
                    color: Colors.font2,
                    border: "2px solid #ececec",
                    background: "#f6f6f6",
                    borderRadius: 2,
                    padding: "3px",
                    right: 10,
                    cursor: "pointer",
                    position: "absolute",
                  }}
                />
              </div>
            </div>
          </Styled.SetupBox>
        </div>
      </div>
      {props.submitBtn}
      {props.backBtn}
      {changeCurrency && (
        <ChangeCurrency
          show={changeCurrency}
          handleClose={() => setChangeCurrency(false)}
          selectedData={selectedInvoice}
        />
      )}
      {editInvoice && (
        <EditInvoiceTitle
          show={editInvoice}
          handleClose={() => setEditInvoice(false)}
          selectedData={selectedInvoice}
        />
      )}
      {editInvoiceDates && (
        <EditInvoiceDates
          show={editInvoiceDates}
          handleClose={() => setEditInvoiceDates(false)}
          selectedData={selectedInvoice}
        />
      )}
      {editClientInfo && (
        <EditClientInfo
          show={editClientInfo}
          handleClose={() => setEditClientInfo(false)}
          selectedData={selectedInvoice}
        />
      )}

      {editInvoiceService && (
        <EditInvoiceService
          show={editInvoiceService}
          handleClose={() => setEditInvoiceService(false)}
          selectedData={selectedInvoice}
        />
      )}
      {editLineItemService && (
        <EditLineItemService
          show={editLineItemService}
          handleClose={() => setEditLineItemService(false)}
          selectedData={selectedInvoice}
          selectedItem={selectedData}
        />
      )}
      {addLineItemService && (
        <AddLineItemService
          show={addLineItemService}
          handleClose={() => setAddLineItemService(false)}
          selectedData={selectedInvoice}
        />
      )}
      {taxes && (
        <InvoiceTaxes
          show={taxes}
          handleClose={() => setTaxes(false)}
          selectedData={selectedInvoice}
          totalServiceAmount={totalServiceAmountAfterDiscount}
        />
      )}

      {discount && (
        <DiscountInvoices
          show={discount}
          handleClose={() => setDiscount(false)}
          selectedData={selectedInvoice}
          totalServiceAmount={totalServiceAmount}
        />
      )}

      {editNote && (
        <InvoiceNotes
          show={editNote}
          handleClose={() => setEditNote(false)}
          selectedData={selectedInvoice}
        />
      )}
      {editBusinessInfo && (
        <EditBusinessInfo
          show={editBusinessInfo}
          handleClose={() => setEditBusinessInfo(false)}
          businessInfoData={businessInfoData}
          data={data}
        />
      )}
    </div>
  );
};

export default ReviewInvoices;
