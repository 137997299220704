import { createSlice } from "@reduxjs/toolkit";
import {
  fetchInvoices,
  createInvoice,
  editInvoiceTitle,
  editInvoiceDates,
  editClientInfo,
  addLineItemService,
  editInvoiceService,
  editLineItemService,
  deleteLineItemService,
  addAndEditInvoiceTaxes,
  addAndEditInvoiceNotes,
  deleteInvoiceTax,
  addAndEditInvoiceDiscount,
  changeInvoiceCurrency,
  deleteInvoiceDiscount,
  deleteInvoiceNotes,
  createContact,
  emailSendInvoice,
  deleteInvoice,
  paidInvoice,
  fetchInvoiceCounts,
} from "../service/invoiceService";

const invoiceInitialState = {
  invoiceData: null,
  invoiceTotalCount: null,
  isLoading: false,
  error: null,
  selectedInvoice: null,
  contactData: null,
};

export const invoiceSlice = createSlice({
  name: "invoices",
  initialState: invoiceInitialState,
  reducers: {
    editInvoice: (state, action) => {
      state.selectedInvoice = action.payload;
    },
  },

  extraReducers: {
    // create invoices
    [createInvoice.pending]: (state) => {
      state.isLoading = true;
    },
    [createInvoice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selectedInvoice = action.payload.data;
    },
    [createInvoice.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // fetch invoices
    [fetchInvoices.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchInvoices.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoiceData = action.payload.data.data;
      state.invoiceTotalCount = action.payload.data.invoiceTotalCount;
    },
    [fetchInvoices.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit invoice title
    [editInvoiceTitle.pending]: (state) => {
      state.isLoading = true;
    },
    [editInvoiceTitle.fulfilled]: (state, action) => {
      state.selectedInvoice.invoice_title = action.payload.data.invoice_title;
      state.selectedInvoice.status = action.payload.data.status;
      state.isLoading = false;
    },
    [editInvoiceTitle.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit invoice dates
    [editInvoiceDates.pending]: (state) => {
      state.isLoading = true;
    },
    [editInvoiceDates.fulfilled]: (state, action) => {
      state.selectedInvoice.due_date = action.payload.data.due_date;
      state.selectedInvoice.issued = action.payload.data.issued;
      state.selectedInvoice.status = action.payload.data.status;
      state.isLoading = false;
    },
    [editInvoiceDates.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit client info
    [editClientInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [editClientInfo.fulfilled]: (state, action) => {
      state.selectedInvoice.client_id = action.payload.data.client_id;
      state.selectedInvoice.status = action.payload.data.status;
      state.isLoading = false;
    },
    [editClientInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // edit invoice services
    [editInvoiceService.pending]: (state) => {
      state.isLoading = true;
    },
    [editInvoiceService.fulfilled]: (state, action) => {
      state.selectedInvoice.status = action.payload.data.status;
      state.selectedInvoice.invoice_services =
        action.payload.data.invoice_services;
      state.isLoading = false;
    },
    [editInvoiceService.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit line item service
    [editLineItemService.pending]: (state) => {
      state.isLoading = true;
    },
    [editLineItemService.fulfilled]: (state, action) => {
      state.selectedInvoice.status = action.payload.data.status;
      state.selectedInvoice.line_item_services =
        action.payload.data.line_item_services;
      state.isLoading = false;
    },
    [editLineItemService.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete line item service
    [deleteLineItemService.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteLineItemService.fulfilled]: (state, action) => {
      state.selectedInvoice.status = action.payload.data.status;
      state.selectedInvoice.line_item_services =
        action.payload.data.line_item_services;
      state.isLoading = false;
    },
    [deleteLineItemService.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // add line item service
    [addLineItemService.pending]: (state) => {
      state.isLoading = true;
    },
    [addLineItemService.fulfilled]: (state, action) => {
      state.selectedInvoice.status = action.payload.data.status;
      state.selectedInvoice.line_item_services =
        action.payload.data.line_item_services;
      state.isLoading = false;
    },
    [addLineItemService.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // add and edit invoice taxes
    [addAndEditInvoiceTaxes.pending]: (state) => {
      state.isLoading = true;
    },
    [addAndEditInvoiceTaxes.fulfilled]: (state, action) => {
      state.selectedInvoice.status = action.payload.data.status;
      state.selectedInvoice.taxes = action.payload.data.taxes;
      state.isLoading = false;
    },
    [addAndEditInvoiceTaxes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //delete invoice taxes
    [deleteInvoiceTax.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteInvoiceTax.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selectedInvoice.status = action.payload.data.status;
      state.selectedInvoice.taxes = action.payload.data.taxes;
    },
    [deleteInvoiceTax.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // add and edit invoice discount
    [addAndEditInvoiceDiscount.pending]: (state) => {
      state.isLoading = true;
    },
    [addAndEditInvoiceDiscount.fulfilled]: (state, action) => {
      state.selectedInvoice.discount = action.payload.data.discount;
      state.selectedInvoice.status = action.payload.data.status;
      state.isLoading = false;
    },
    [addAndEditInvoiceDiscount.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //delete invoice discount
    [deleteInvoiceDiscount.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteInvoiceDiscount.fulfilled]: (state, action) => {
      state.selectedInvoice.discount = action.payload.data.discount;
      state.selectedInvoice.status = action.payload.data.status;
      state.isLoading = false;
    },
    [deleteInvoiceDiscount.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Change invoice currency
    [changeInvoiceCurrency.pending]: (state) => {
      state.isLoading = true;
    },
    [changeInvoiceCurrency.fulfilled]: (state, action) => {
      state.selectedInvoice.status = action.payload.data.status;
      state.selectedInvoice.currency = action.payload.data.currency;
      state.isLoading = false;
    },
    [changeInvoiceCurrency.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit invoice notes
    [addAndEditInvoiceNotes.pending]: (state) => {
      state.isLoading = true;
    },
    [addAndEditInvoiceNotes.fulfilled]: (state, action) => {
      state.selectedInvoice.status = action.payload.data.status;
      state.selectedInvoice.invoice_notes = action.payload.data.invoice_notes;
      state.isLoading = false;
    },
    [addAndEditInvoiceNotes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete invoice notes
    [deleteInvoiceNotes.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteInvoiceNotes.fulfilled]: (state, action) => {
      state.selectedInvoice.status = action.payload.data.status;
      state.selectedInvoice.invoice_notes = action.payload.data.invoice_notes;
      state.isLoading = false;
    },
    [deleteInvoiceNotes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create a contact for a specific client
    [createContact.pending]: (state) => {
      state.isLoading = true;
    },
    [createContact.fulfilled]: (state, action) => {
      state.selectedInvoice.client_id.contacts = action.payload.data.contacts;
      state.isLoading = false;
    },
    [createContact.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // send invoices to client
    [emailSendInvoice.pending]: (state) => {
      state.isLoading = true;
    },
    [emailSendInvoice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selectedInvoice.send_email = action.payload.data.send_email;
    },
    [emailSendInvoice.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete invoice
    [deleteInvoice.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteInvoice.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deleteInvoice.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // paid invoice
    [paidInvoice.pending]: (state) => {
      state.isLoading = true;
    },
    [paidInvoice.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [paidInvoice.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch invoice count
    [fetchInvoiceCounts.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchInvoiceCounts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoiceTotalCount = action.payload.data.invoiceTotalCount;
    },
    [fetchInvoiceCounts.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export const invoiceActions = invoiceSlice.actions;

export default invoiceSlice.reducer;
