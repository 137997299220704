import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "../../../../../store/service/clientService";

import { useForm, Controller } from "react-hook-form";

import { toast } from "react-toastify";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";

import { Modal } from "react-bootstrap";

import TextStyles from "../../../../../Constants/TextStyles";
import SelectSTDCode from "../../../Commons/SelectSTDCode";
import Colors from "../../../../../Constants/Colors";

const AddContact = ({ handleClose, show }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.clients.isLoading);
  const c_data_position = useSelector((state) => state.clients.c_data_position);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const formsubmit = async (data) => {
    const Obj = {
      client_id: c_data_position._id,
      contact_name: data.contact_name,
      email: data.email,
      role: data.role,
      phone_code: data.phone_code,
      phone: data.phone,
    };

    dispatch(createContact(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "fail" }
        );
      }
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        contentClassName="ToMakeModalBorderRudius12px"
        centered
        scrollable
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Modal.Header className="modal-header">
            <TextStyles.FontSize20px className="d-flex justify-content-start">
              Add contact
            </TextStyles.FontSize20px>
            <span onClick={handleClose} style={{ cursor: "pointer" }}>
              <ClearIcon
                sx={{
                  color: Colors.font2,
                  cursor: "pointer",
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </span>
          </Modal.Header>

          <div className="modal-body">
            <TextStyles.FontSize14px className="mb-2">
              <label htmlFor="contact_name" style={{ display: "flex" }}>
                Contact name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.contact_name}
                id="contact_name"
                type="text"
                placeholder="Contact name"
                name="contact_name"
                {...register("contact_name", {
                  required: "Contact can't be empty.",
                })}
              />
              {errors.contact_name && (
                <TextStyles.InValidFeedback>
                  {errors.contact_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-2">
              <label htmlFor="email" style={{ display: "flex" }}>
                Email address
              </label>
              <TextStyles.InputRectangle
                invalid={errors.email}
                id="email"
                type="text"
                placeholder="Email address"
                name="email"
                {...register("email", {
                  required: "Email can't be empty.",
                  pattern: {
                    value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                    message: "Please enter valid email address.",
                  },
                })}
              />
              {errors.email && (
                <TextStyles.InValidFeedback>
                  {errors.email.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-2">
              <label htmlFor="role" style={{ display: "flex" }}>
                Role
              </label>
              <TextStyles.InputRectangle
                // invalid={errors.role}
                id="role"
                type="text"
                placeholder="Enter role"
                name="role"
                {...register("role", {
                  // required: "Role can't be empty.",
                })}
              />
              {errors.role && (
                <TextStyles.InValidFeedback>
                  {errors.role.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label
                htmlFor="phone"
                style={{ display: "flex" }}
                className="mb-3"
              >
                Select phone code
              </label>
              <Controller
                control={control}
                // defaultValue={businessInfoData && businessInfoData.phone_code}
                name="phone_code"
                render={({ field: { onChange, value } }) => (
                  <SelectSTDCode
                    placeholder="Select code"
                    onChange={onChange}
                    defaultValue={value}
                  />
                )}
              />
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-4">
              <label htmlFor="phone" style={{ display: "flex" }}>
                Phone
              </label>
              <TextStyles.InputRectangle
                // invalid={errors.phone}
                id="phone"
                type="tel"
                placeholder="Phone"
                name="phone"
                {...register("phone", {
                  // required: "Phone can't be empty.",
                  pattern: {
                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                    message: "Please enter valid phone number.",
                  },
                })}
              />
              {errors.phone && (
                <TextStyles.InValidFeedback>
                  {errors.phone.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
          </div>
          <div className="modal-footer" style={{ display: "inline-block" }}>
            <div className="d-flex justify-content-between gap-3">
              <TextStyles.CancelButton onClick={handleClose}>
                <div className="d-flex justify-content-center">Cancel</div>
              </TextStyles.CancelButton>
              <TextStyles.Button
                disabled={isLoading ? true : false}
                style={{
                  opacity: isLoading ? 0.5 : 1,
                }}
              >
                {isLoading ? <LoaderSpin /> : "Save"}
              </TextStyles.Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddContact;
