import React, { useState } from "react";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import LoaderSpin from "../../../Commons/LoaderSpin";

import { createProject } from "../../../../../store/service/projectService";

import { CustomDatePicker } from "../../../Commons/CustomDatePicker";
import { AutoCompleteSelectClient } from "../../../Commons/AutoComplete";

import BillingSchedule from "../../../projects/createProject/BillingSchedule";
import Services from "../../../projects/createProject/Services";

import Styled from "../../../projects/createProject/SetupStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import SwitchButton from "../../../Commons/SwitchButton";
import Colors from "../../../../../Constants/Colors";
import SelectCurrency from "../../../Commons/SelectCurrency";

const NewProject = ({
  enteredProjectName,
  setSelectedCreatedProject,
  backBtn,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.project.isLoading);

  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const userCurrency = useSelector((state) => state.auth.userCurrency);
  const [selectEndDate, setSelectEndDate] = useState(false);
  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      servicesList: [
        {
          service_name: "",
          service_rate: "",
          service_rate_type: "Flat fee",
          desc: "",
        },
      ],
      project_name: enteredProjectName,
      project_start_date: new Date(),
      currency: userCurrency && userCurrency.currency,
    },
  });

  const changedCurrency = watch("currency");
  const currencySymbol = changedCurrency
    ? changedCurrency.symbol
    : userCurrency && userCurrency.currency && userCurrency.currency.symbol;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "servicesList",
  });

  const formsubmit = (data) => {
    const Obj = {
      project_name: data.project_name,
      client_id: data.client && data.client._id,
      project_start_date: data.project_start_date,
      project_end_date:
        selectEndDate || data.project_end_date === undefined
          ? null
          : data.project_end_date && new Date(data.project_end_date),
      currency: data.currency ? data.currency : userCurrency.currency,

      service_list: data.servicesList,
      billing_scheduled: data.billingScheduled,
    };
    dispatch(createProject(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setSelectedCreatedProject(data.payload.data);
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "createProjectSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "createProjectFail" }
        );
      }
    });
  };

  return (
    <div className="d-flex justify-content-center px-2">
      <div style={{ maxWidth: 600, width: "100%" }}>
        <form onSubmit={handleSubmit(formsubmit)}>
          <TextStyles.FontSize27px className="text-center mb-3">
            Create project
          </TextStyles.FontSize27px>
          <Styled.SetupBox className="mb-4">
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="project_name">Project name</label>
              <TextStyles.InputRectangle
                name="project_name"
                invalid={errors.project_name}
                type="text"
                placeholder="Project name"
                {...register("project_name", {
                  required: "Project name is required",
                  minLength: {
                    value: 3,
                    message: "Project name must be at least 3 characters",
                  },
                  maxLength: {
                    value: 30,
                    message: "Project name must be at most 30 characters",
                  },
                })}
              />
              {errors.project_name && (
                <TextStyles.InValidFeedback>
                  {errors.project_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="client">Select client</label>
              <Controller
                control={control}
                name="client"
                rules={{
                  required: {
                    value: true,
                    message: "Client can not be empty",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteSelectClient
                    placeholder="Choose a client"
                    options={
                      clientOptionData &&
                      clientOptionData.map((data) => {
                        return { label: data.company_name, _id: data._id };
                      })
                    }
                    onChange={onChange}
                    invalid={errors.client}
                  />
                )}
              />
              {errors.client && (
                <TextStyles.InValidFeedback className="mt-2">
                  {errors.client.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="client">Select currency</label>
              <Controller
                control={control}
                name="currency"
                render={({ field: { onChange, value } }) => (
                  <SelectCurrency
                    placeholder="Select currency"
                    onChange={onChange}
                    defaultValue={userCurrency && userCurrency.currency}
                  />
                )}
              />
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div htmlFor="project_start_date" className="mb-2">
                Start date
              </div>

              <TextStyles.DateRectangle>
                <Controller
                  name={"project_start_date"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomDatePicker
                        onChange={onChange}
                        selected={value}
                        placeholderText="Select start date"
                        todayButton="Today"
                        dateFormat="dd MMM yyyy"
                      />
                    );
                  }}
                />
              </TextStyles.DateRectangle>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div htmlFor="project_end_date">End date</div>
                <div className="d-flex align-items-center">
                  <TextStyles.FontSize14px
                    style={{ color: Colors.primary, fontWeight: 500 }}
                  >
                    Ongoing
                  </TextStyles.FontSize14px>
                  <SwitchButton
                    defaultChecked={selectEndDate}
                    onChange={(event) => setSelectEndDate(event.target.checked)}
                  />
                </div>
              </div>
              {selectEndDate ? (
                ""
              ) : (
                <TextStyles.DateRectangle>
                  <Controller
                    name={"project_end_date"}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomDatePicker
                          onChange={onChange}
                          selected={value}
                          placeholderText="Select end date"
                          todayButton="Today"
                          dateFormat="dd MMM yyyy"
                          isClearable={true}
                        />
                      );
                    }}
                  />
                </TextStyles.DateRectangle>
              )}
            </TextStyles.FontSize14px>
          </Styled.SetupBox>

          <Services
            control={control}
            Controller={Controller}
            watch={watch}
            register={register}
            fields={fields}
            remove={remove}
            append={append}
            currencySymbol={currencySymbol}
          />
          <BillingSchedule
            currencySymbol={currencySymbol}
            register={register}
            watch={watch}
            setValue={setValue}
            Controller={Controller}
            control={control}
          />

          <div className="my-3">
            <TextStyles.Button disabled={isLoading ? true : false}>
              {isLoading ? <LoaderSpin /> : "Create project"}
            </TextStyles.Button>
          </div>
        </form>
        {backBtn}
      </div>
    </div>
  );
};

export default NewProject;
