import React from "react";
import GoverningLaw from "./Components/GoverningLaw.jsx";
import ForceMejeure from "./Components/ForceMejeure.jsx";
import Notices from "./Components/Notices.jsx";
import AppropriateConduct from "./Components/AppropriateConduct.jsx";
import Miscellaneous from "./Components/Miscellaneous.jsx";
import EntireContract from "./Components/EntireContract.jsx";
import RightToAuthorship from "./Components/RightToAuthorship";
import PrivacyData from "./Components/PrivacyData.jsx";

const PageSix = (props) => {
  const selectedData = props && props.selectedData;

  return (
    <div>
      <RightToAuthorship selectedData={selectedData} />
      <GoverningLaw selectedData={selectedData} />
      <ForceMejeure selectedData={selectedData} />
      <Notices selectedData={selectedData} />
      <AppropriateConduct selectedData={selectedData} />
      <PrivacyData selectedData={selectedData} />
      <Miscellaneous selectedData={selectedData} />
      <EntireContract selectedData={selectedData} />
    </div>
  );
};

export default PageSix;
