import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const SetupBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem 1.25rem;
  border-radius: 0.75rem;
  border: solid 2px #b2b6c5;
  box-shadow: 0px 2px 5px 1px rgb(183 192 205 / 89%);
  background-color: ${Colors.white};
  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

const inputBox = styled.div`
  width: 100%;
  height: 3.15rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  /* padding: 1rem; */
  margin: 6px 0 9px;
  background: ${Colors.white};
  border: 1px solid ${(props) => (props.invalid ? "red" : Colors.borderInput)};

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export default {
  SetupBox,
  inputBox,
};
