import React from "react";
import { Redirect } from "react-router";

export default function LaunchToSetting() {
  return (
    <div>
      <Redirect to="/db/setting/business" />
    </div>
  );
}
