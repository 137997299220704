import React from "react";
import { Modal } from "react-bootstrap";

import TextStyles from "../../../Constants/TextStyles";
import Colors from "../../../Constants/Colors";
const EndModal = (props) => {
  const {
    confirmDialog,
    showEndModal,
    handleCloseEndModal,
    title,
    content,
    isLoading,
  } = props;

  return (
    <>
      <Modal
        show={showEndModal}
        onHide={handleCloseEndModal}
        centered
        contentClassName="ToMakeModalBorderRudius12px"
      >
        <div
          className="modal-content"
          style={{ border: "none", borderRadius: 12, padding: "0 15px" }}
        >
          <div className="container-fluid">
            <TextStyles.FontSize20px className="d-flex justify-content-start my-3">
              End {props.title}
            </TextStyles.FontSize20px>
            <TextStyles.FontSize14px className="d-flex justify-content-start mb-3">
              Are you sure you want to end this {title}?
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-4">
              {content}
            </TextStyles.FontSize14px>

            <div className="row my-4">
              <div className="col">
                <TextStyles.CancelButton
                  onClick={handleCloseEndModal}
                >
                  <div className="d-flex justify-content-center"> Cancel</div>
                </TextStyles.CancelButton>
              </div>
              <div className="col">
                <TextStyles.Button
                  type="submit"
                  style={{ opacity: isLoading ? 0.6 : 1 }}
                  disabled={isLoading ? true : false}
                  color={Colors.white}
                  backgroundColor={Colors.red}
                  borderColor={Colors.red}
                  onClick={() => {
                    confirmDialog.onConfirm();
                  }}
                >
                  <div className="d-flex justify-content-center">End</div>
                </TextStyles.Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EndModal;
