import React, { useState } from "react";
import servicesvg from "../../../../../Assets/assetsnew/service.svg";

import EditServices from "./EditServices.jsx";

import Styled from "./ServicesStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import { ReactTooltipWithIcon } from "../../../Commons/ReactTooltip";

const Services = ({ selectedData }) => {
  const [showModal, setShowModal] = useState(false);
  const service_list = selectedData && selectedData.service_list;
  return (
    <div>
      <Styled.ReviewBox>
        <div className="d-flex gap-2 align-items-center mb-3 w-100">
          <img src={servicesvg} alt="serviceBtn" />
          <TextStyles.FontSize20px className="d-flex" style={{ minWidth: 50 }}>
            <span className="three-dots"> Services summary</span>
          </TextStyles.FontSize20px>
        </div>
        <div
          className="my-3"
          style={{
            width: "100%",
            height: "1px",
            border: "0.5px solid #edeef2",
          }}
        />

        {service_list &&
          service_list.map((item, index) => {
            return (
              <Styled.EditServiceContainer key={index}>
                <ReactTooltipWithIcon
                  anchorId={`edit_services${index}`}
                  content="Edit services"
                  variant="grey"
                  place="right"
                />
                <div
                  onClick={() => setShowModal(true)}
                  className="row"
                  style={{ cursor: "pointer" }}
                  id={`edit_services${index}`}
                >
                  <div className="col-12 col-sm-9">
                    <TextStyles.FontSize16px className="d-flex mb-1">
                      <span className="three-dots"> {item.service_name}</span>
                    </TextStyles.FontSize16px>
                    <TextStyles.GreyFont14px className="mb-1">
                      <span className="three-dots">
                        {selectedData &&
                          selectedData.currency &&
                          selectedData.currency.symbol}
                        {item.service_rate
                          ? parseFloat(item.service_rate).toFixed(2)
                          : "00.00"}
                        {item.service_rate_type !== "Flat fee"
                          ? ` ${item.service_rate_type.toLocaleLowerCase()}`
                          : ` ${item.service_rate_type.toLocaleLowerCase()}`}
                      </span>
                    </TextStyles.GreyFont14px>
                    <TextStyles.GreyFont14px className="d-flex mb-1">
                      {item.desc}
                    </TextStyles.GreyFont14px>
                  </div>
                </div>
                <div
                  className="my-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    border: "0.5px solid #edeef2",
                  }}
                />
              </Styled.EditServiceContainer>
            );
          })}
      </Styled.ReviewBox>
      {showModal && (
        <EditServices
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default Services;
