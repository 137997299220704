import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./LandingPage.module.css";
import { TypeAnimation } from "react-type-animation";
import HomePageHeroSectionImage from "../../../Assets/frontendImages/landingPage_image.avif";
import HomePageHeroSectionImageMobileView from "../../../Assets/frontendImages/landingPage_image_mobile.avif";

function LandingPage() {
  return (
    <div className={styles.hero_section}>
      <div className="container-fluid">
        <div className="d-flex flex-column flex-md-row align-items-center">
          <div
            className="text-center text-md-left w-100 mt-3 mt-md-0"
            data-aos={"fade-right"}
          >
            <div className={`text-white ${styles.heading}`}>
              <span className={styles.italic}>Accelerate</span>
              <span> your business management with Zodot’s </span>
            </div>
            <div
              className="mb-4"
              style={{ height: "auto", width: "100%", lineBreak: "auto" }}
            >
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  "invoices",
                  1000, // wait 1s before replacing "Mice" with "Hamsters"
                  "agreements",
                  1000,
                  "time tracking",
                  1000,
                  "statements and reports",
                  1000,
                ]}
                wrapper="span"
                speed={50}
                className={`${styles.typingAnimation}`}
                repeat={Infinity}
              />
            </div>
            <div className="d-flex justify-content-center justify-content-md-start mb-4">
              <NavLink
                className={styles.btn_begin}
                style={{ textDecoration: "none" }}
                to={"/db/signup"}
              >
                Begin today
              </NavLink>
            </div>

            <p className={`${styles.text}`}>
              Free for all users – no credit card required!
            </p>
          </div>

          <div className="d-flex justify-content-center align-items-center w-100  mt-3 mt-md-0 pb-3">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 700,
                maxHeight: 700,
              }}
            >
              <img
                className="d-none d-sm-inline"
                src={HomePageHeroSectionImage}
                alt="homeHeroSectionImageBtn"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
              <img
                className="d-inline d-sm-none"
                src={HomePageHeroSectionImageMobileView}
                alt="homeHeroSectionImageBtn"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
