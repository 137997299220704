import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import { authActions } from "../../store/storage/authSlice";
import Styled from "./AuthStyles";
import { postRequest } from "../../axios/axios";
import URL from "../../axios/constant";
import logo from "../../Assets/assetsnew/backend_logo_with_name.webp";
import loginBg from "../../Assets/frontendImages/loginBg.svg";
import LoaderSpin from "../../Components/NewDashboard/Commons/LoaderSpin";
import TextStyles from "../../Constants/TextStyles";
import Colors from "../../Constants/Colors";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const {
    register,
    reset,
    setValue,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm();

  const onHandleRecaptcha = (value) => {
    if (value) {
      setIsVerified(true);
    }
  };

  const validateEmail = (value) => {
    const trimmedValue = value.trim();
    const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i;
    return (
      emailPattern.test(trimmedValue) || "Please enter a valid email address."
    );
  };

  const onSubmit = async (data) => {
    const obj = {
      email: data.email,
    };
    setIsLoading(true);
    postRequest(URL.RESET_PASSWORD.url, {}, obj)
      .then((data) => {
        if (data && data.data && data.data.success) {
          // setIsLoading(true);
          history.push("/db/verify-reset-password");

          dispatch(authActions.resetPassword(data.data.data));

          toast.success(
            <TextStyles.FontSize14px>
              {data.data.message}
            </TextStyles.FontSize14px>,
            { toastId: "success" }
          );

          reset();
        }
      })
      .catch((err) => {
        setIsLoading(false);

        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>,
            { toastId: "fail" }
          );
        }

        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>,
            { toastId: "error" }
          );
        }
      });
  };

  return (
    <div style={{ backgroundColor: "#fafbfc" }}>
      <div
        className="fixed-top"
        style={{
          backgroundColor: "#fafbfc",
          width: "100%",
          zIndex: 1000,
          boxShadow: "0 2px 4px 2px rgba(180, 180, 200, 0.2)",
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center px-2 px-sm-3"
          style={{ height: 80, width: "100%" }}
        >
          <TextStyles.FontSize27px
            className="d-flex justify-content-center"
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/")}
          >
            <img src={logo} alt="logo" style={{ maxWidth: 220 }} />
          </TextStyles.FontSize27px>
          <TextStyles.FontSize16px className="d-flex justify-content-end align-items-center w-100">
            <div className="d-none d-sm-flex mr-3">Don’t have an account?</div>
            <Styled.LinkSpan
              className="text-center"
              onClick={() => history.push("/db/signup")}
            >
              Sign up
            </Styled.LinkSpan>
          </TextStyles.FontSize16px>
        </div>
      </div>
      <div style={{ paddingTop: 80 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: `url(${loginBg})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            minHeight: "calc(100vh - 80px)",
            width: "100%",
          }}
        >
          {" "}
          <div className="container-fluid pb-3">
            <div className="d-flex justify-content-center">
              <Styled.Box>
                <div className="d-flex flex-column justify-content-center">
                  <TextStyles.FontSize27px
                    className="text-center mb-3"
                    style={{ fontWeight: 500 }}
                  >
                    Recover your password
                  </TextStyles.FontSize27px>

                  <TextStyles.GreyFont16px className="mb-3">
                    Forgot your password? No worries. Enter your registered
                    email, and we'll send you an OTP to reset it.
                  </TextStyles.GreyFont16px>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <TextStyles.FontSize16px className="my-3">
                      <div className="position-relative">
                        <TextStyles.InputRectangle
                          style={{ paddingLeft: "2.75rem" }}
                          type="text"
                          invalid={errors.email}
                          name="email"
                          id="email"
                          placeholder="Email address"
                          {...register("email", {
                            validate: validateEmail,
                            onBlur: (e) =>
                              setValue("email", e.target.value.trim()),
                          })}
                        />
                        <span
                          style={{
                            position: "absolute",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            top: "1rem",
                            left: "1rem",
                          }}
                        >
                          <MailOutlineIcon
                            sx={{ fontSize: 20, color: Colors.font2 }}
                          />
                        </span>
                      </div>

                      {errors.email && (
                        <TextStyles.InValidFeedback>
                          {errors.email.message}
                        </TextStyles.InValidFeedback>
                      )}
                    </TextStyles.FontSize16px>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={onHandleRecaptcha}
                    />

                    <div className="d-flex justify-content-center my-4">
                      <TextStyles.Button
                        disabled={
                          !isDirty || !isValid || isLoading || !isVerified
                            ? true
                            : false
                        }
                        style={{
                          fontSize: 16,
                        }}
                      >
                        {isLoading ? <LoaderSpin /> : "Send reset OTP"}
                      </TextStyles.Button>
                    </div>
                    <TextStyles.FontSize16px className="d-flex justify-content-center text-center">
                      <span className="mr-1"> or</span>
                      <Styled.Link
                        className="text-center"
                        onClick={() => history.push("/db/login")}
                      >
                        sign in
                      </Styled.Link>
                    </TextStyles.FontSize16px>
                  </form>
                </div>
              </Styled.Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
