import React, { useState, useEffect } from "react";
import styles from "./PrivacyPolicy.module.css";
import Footer from "../Footer/Footer";

const menuData = [
  {
    key: "1",
    value: "Introduction",
  },
  {
    key: "2",
    value: "Privacy policy overview",
  },
  {
    key: "3",
    value: "Information we collect",
  },
  {
    key: "4",
    value: "Our legal basis for processing your personal information",
  },
  {
    key: "5",
    value: "How we utilize the collected information",
  },
  {
    key: "6",
    value: "Retention period for personal information",
  },
  {
    key: "7",
    value: "Children’s privacy",
  },
  {
    key: "8",
    value: "Third-party sharing of personal information",
  },
  {
    key: "9",
    value: "Location of personal information processing",
  },
  {
    key: "10",
    value: "Cookies",
  },
  {
    key: "11",
    value: "Do not track signals",
  },
  {
    key: "12",
    value: "External links",
  },
  {
    key: "13",
    value: "Security measures",
  },
  {
    key: "14",
    value: "Provisions for EU, EEA, and UK users",
  },
  {
    key: "15",
    value: "Provisions for US residents",
  },
  {
    key: "16",
    value: "Updating your personal information",
  },
  {
    key: "17",
    value: "Contact information",
  },
];

const PrivacyPolicy = () => {
  const [selectedContent, setSelectedContent] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSelectedContent(entry.target.id);
          }
        });
      },
      {
        rootMargin: `-${window.innerHeight / 4}px 0px -${
          (3 * window.innerHeight) / 4
        }px 0px`,
      }
    );
    const items = document.querySelectorAll(".content");
    items.forEach((item) => {
      observer.observe(item);
    });
    return () => observer.disconnect();
  }, []);

  const handleMenuItemClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -180; // Adjust this value to set the scroll margin
      const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className={styles.section}>
        <div
          className="container-fluid py-3 py-md-4"
          style={{ maxWidth: 1200 }}
        >
          <div className={`${styles.last_update} d-flex justify-content-end`}>
            Last updated: 22 May 2024
          </div>
          <div className={styles.heading}>Privacy policy</div>
          <div className="d-flex gap-4">
            <div className={styles.menu_box}>
              <div className={`${styles.menu_title} mb-2`}>
                TABLE OF CONTENTS
              </div>
              {menuData &&
                menuData.map((item, index) => {
                  return (
                    <div key={index}>
                      <div
                        className={`${styles.menu_item} ${
                          selectedContent === item.value &&
                          styles.menu_item_selected
                        }`}
                        onClick={(e) => {
                          handleMenuItemClick(item.value);
                        }}
                      >
                        {item.value}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={styles.content_box}>
              <div className="content" id="Introduction">
                <div className={styles.content_title}>Introduction</div>
                <div className={`${styles.content_text} mb-4`}>
                  At Zodot, we are dedicated to safeguard the personal
                  information of our users. Please review this privacy policy
                  ("Policy") thoroughly to understand how we handle personal
                  data. This policy outlines our practices regarding the
                  processing of personal information for users and visitors of
                  our site, as well as our affiliates, influencers, and other
                  partners.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  By visiting or using www.zodot.co, you confirm that you have
                  read and understood this policy. We may update this policy
                  occasionally, and any modifications will be posted on this
                  site. We will also take additional measures, as required by
                  law, to inform you of any significant changes. Updates to this
                  policy will take effect from the "22/05/2024" date indicated.
                  We encourage you to check this policy regularly for any
                  revisions or updates.
                </div>
              </div>
              <div className="content" id="Privacy policy overview">
                <div className={styles.content_title}>
                  Privacy policy overview
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Information we collect –
                  </strong>
                  You directly provide us with most of the information we
                  collect: when you register to the site, use it, complete
                  forms, or register to any of our programs. In addition, we
                  automatically collect information while you access, browse,
                  view, or otherwise use the site and receive information from
                  third-party vendors or other available sources.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Our legal basis for processing your personal information -
                  </strong>
                  Where relevant under applicable laws, all processing of your
                  personal information will be justified by a "lawful ground"
                  for processing as detailed below.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    How we utilize the collected information –
                  </strong>
                  We use personal information to provide you with quality
                  service and security, to operate the site, to perform our
                  obligations to you and to develop, improve, and personalize
                  our service; to ensure marketplace integrity and security; to
                  prevent fraud; to contact you and send you direct marketing
                  communications; to promote and advertise the site, our
                  services, and the Zodot marketplace; to comply with lawful
                  requests by public authorities and to comply with applicable
                  laws and regulations.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Retention period for personal information –
                  </strong>
                  We will keep personal information only for as long as is
                  required to perform the purpose for which it was collected.
                  However, in some cases, we will retain personal information
                  for longer periods.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Children’s privacy -
                  </strong>
                  This site is offered and available to users who are at least
                  18 years of age and legal age to form a binding contract.
                  Minors under 18 and at least 13 years of age are only
                  permitted to use the site through an account owned by a parent
                  or legal guardian with their appropriate permission. Minors
                  under 13 are not permitted to use the site. We do not
                  knowingly collect personal information from children under 13.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Third-party sharing of personal information –
                  </strong>
                  We share personal information with third parties to operate
                  the site, provide our services to you, perform obligations
                  imposed on us by applicable laws and regulations, and prevent
                  fraud, infringements, and illegal activities.
                </div>

                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Location of personal information processing -
                  </strong>
                  Some of the personal information you provide us will be stored
                  or processed on our behalf by third-party suppliers and data
                  processors and may be located in other jurisdictions, such as
                  the United States and Canada.
                </div>

                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Cookies -
                  </strong>
                  We use cookies and similar technologies (such as web beacons,
                  pixels, tags, and scripts) to improve and personalize your
                  experience, analyze website performance, and for marketing
                  purposes.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Do not track (DNT’) signals–
                  </strong>
                  Our site does not respond to ‘Do Not Track (DNT’) signals.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    External links
                  </strong>
                  Zodot contains links to third-party sites. If you choose to
                  visit these external sites, any information you provide to
                  them is governed by their own privacy policies and practices.
                  Zodot does not have control over these third-party sites and
                  is not responsible for how they handle your data. Therefore,
                  this privacy policy does not apply to any data you share with
                  these external sites.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Security measures –
                  </strong>
                  We implement technical and organizational measures to maintain
                  the security of our website, and your personal information and
                  to prevent unauthorized access, loss, misuse, alteration,
                  destruction, or damage to it through industry-standard
                  technologies and internal procedures.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Specific provisions for EU, EEA, and UK users –
                  </strong>
                  If you are an EU, EEA, or UK user, you are entitled to receive
                  further information on our processing and your rights as a
                  data subject, as detailed below.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Specific provisions for US residents –
                  </strong>
                  If you are a US resident, depending on where you live, and
                  subject to certain exceptions, you are entitled to specific
                  privacy rights as detailed below.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Updating your personal information –
                  </strong>
                  We take steps to ensure that the personal information we
                  collect is accurate and up-to-date, and we provide you with
                  the opportunity to update your information through your
                  account profile settings. If you believe your information is
                  in any way inaccurate, please let us know immediately.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Contact information -
                  </strong>
                  You can exercise your rights over your personal information,
                  by contacting us at
                  <a
                    className={`${styles.email} ml-1`}
                    href="mailto:hello@zodot.co"
                  >
                    hello@zodot.co
                  </a>
                  . More details about the rights applicable to you are in the
                  long version of the Policy.
                </div>
              </div>
              <div className="content" id="Information we collect">
                <div className={styles.content_title}>
                  Information we collect
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Information you provide.
                  </strong>
                  When you register to the site, use it, complete forms, sign up
                  for events or newsletters, use the site as a collaborator,
                  and/or register to our affiliate or influencer or similar
                  program, we ask you to provide or otherwise collect personal
                  information provided by you, including a valid email address,
                  login details, name and username, profile details (including
                  picture, profession, and expertise), physical address or
                  billing information, telephone number or other contact
                  details, details about other social networks linked accounts,
                  details about your listed gigs, orders, purchases, and
                  transactions rating and reviews, information included in
                  user-generated content and files uploaded by you to the site.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Information we collect automatically.
                  </strong>
                  We collect information while you access, browse, view, or
                  otherwise use the site including information about
                  transactions and communications with other users, your
                  searches, the pages you visited, browsed, and scrolled as well
                  as your clicks and other actions on the Site. We also collect,
                  use, and process the information relating to such usage,
                  including geo-location information, IP address, device and
                  connection information, operating system, session duration,
                  language, currency and keyboard setting, browser information
                  and web-log information, and the URLs of the web pages you’ve
                  viewed or engaged with before or after using the Site. We also
                  collect and process information relating to the use of cookies
                  and similar technologies, as detailed below.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Information we receive from third parties.
                  </strong>
                  We receive information from third-party vendors and/or other
                  commercially and/or publicly available sources, such as:
                  <ul className="my-3">
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        Third-party vendors who provide us data to supplement
                        the information we collect about you, by applicable
                        laws. For example, we receive fraud and security
                        warnings from service providers for our fraud prevention
                        and risk assessment efforts. We also receive information
                        about you, such as information about your organization
                        and job title, from enrichment service providers to
                        enhance and personalize your experience and offer you
                        services that may be of interest to you as well as from
                        advertising and marketing partners to manage and measure
                        our campaigns.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        If you link, connect, or log in to your account with a
                        third-party service (e.g. Google, LinkedIn), we receive
                        certain information, such as your registration and
                        profile information from that service. This information
                        includes, for example, your email address, name, and
                        profile picture, but may vary and is controlled by that
                        service or as authorized by you via your privacy
                        settings at that service.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        If any other third party submits a report and/or
                        complaint about you or your activity on Zodot, we may
                        receive information relating to the specific report
                        and/or complaint, review, and address it.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        If you integrate into your account information from
                        third parties or otherwise integrate your account with
                        third-party systems, tools or products, we will receive
                        certain information about you according to your
                        permissions at such third parties.
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  We combine your personal information with information we
                  collect automatically or obtain from other companies and use
                  it for the purposes detailed in this Policy.
                </div>
              </div>
              <div
                className="content"
                id="Our legal basis for processing your personal information"
              >
                <div className={styles.content_title}>
                  Our legal basis for processing your personal information
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  Where relevant under applicable laws, all processing of your
                  personal information will be justified by a "lawful ground"
                  for processing. In the majority of cases, processing will be
                  justified on the legal basis that:
                  <ul className="my-3">
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        You have provided your consent for us to use your
                        personal information for a specific purpose;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        Our use of your personal information is necessary to
                        perform a contract or take steps to enter into a
                        contract with you (e.g. to provide you with services
                        that you have purchased);
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        The processing is necessary to comply with a relevant
                        legal obligation or regulatory obligation that we have
                        (e.g. fraud prevention); or
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        The processing is necessary to support our legitimate
                        interests as a business (e.g. to improve our services to
                        you), subject to your overriding interests and
                        fundamental rights, and provided it is proportionately
                        conducted at all times.
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="content"
                id="How we utilize the collected information"
              >
                <div className={styles.content_title}>
                  How we utilize the collected information
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  We will use your personal information for the following
                  purposes:
                  <ul className="my-3">
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        To provide you with quality service and security, to
                        operate the site, to fulfill our obligations to you, and
                        to develop, improve, and personalize our service. For
                        example, we use personal information to verify your
                        identity. We also use this information to establish and
                        set up your account, verify or re-issue a password, log
                        your activity, enable your communications with other
                        members, provide customer support, and contact you from
                        time to time. The information helps us to develop and
                        improve our services, to understand and analyze our
                        performance as well as your preferences and performance,
                        and to customize and personalize our service and enhance
                        your experience (including by making Gig suggestions,
                        ranking search results, etc.).
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        To ensure marketplace integrity, prevent fraud, and
                        maintain a safe and secure marketplace and services. For
                        example, we use personal information to monitor, track,
                        and prevent illegal, fraudulent, and inappropriate
                        activities, monitor content integrity, conduct security
                        investigations and risk assessments, verify or
                        authenticate information provided by you, investigate
                        reports and complaints about users, enforce our terms of
                        service and comply with applicable laws. We conduct
                        certain behavioral analytics to achieve the above
                        objectives and in limited cases, if we detect activity
                        that we think poses a risk to the Zodot marketplace,
                        other users, our community, or third parties, automated
                        processes may restrict or limit your ability to use
                        Zodot. If you would like to challenge any such decision,
                        please contact us at
                        <a
                          className={`${styles.email} mx-1`}
                          href="mailto:hello@zodot.co"
                        >
                          hello@zodot.co
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        To contact you, as requested by you or as otherwise
                        approved by you or permitted according to this policy.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        To promote and advertise the site, and our services. For
                        example, we use the information collected from you to
                        send direct marketing messages (as detailed below), to
                        show you information that may be of interest to you, and
                        to organize and facilitate referral programs, contests,
                        or other promotional activities or events.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        To maintain appropriate business records, to comply with
                        lawful requests by public authorities, and to comply
                        with applicable laws and regulations.We will ask for
                        your consent before using information for a purpose
                        other than those set out in this Policy.
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  Direct marketing:
                  <ul className="my-3">
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        We use your personal information to send you direct
                        marketing communications about our product, services, or
                        promotions from Zodot that may be of interest to you or
                        our related services. This may be via email, post, or
                        targeted online advertisements.
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    This site is also protected by reCAPTCHA.
                  </strong>{" "}
                  Google Privacy Policy and Terms of Service apply concerning
                  data collected by reCAPTCHA. The use of Google reCAPTCHA is
                  required to prevent fraud and misuse of the Site by use of
                  automated machines. To learn more, please see Google's privacy
                  policy.
                </div>
              </div>
              <div
                className="content"
                id="Retention period for personal information"
              >
                <div className={styles.content_title}>
                  Retention period for personal information
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  We apply a general rule of keeping personal information only
                  for as long as is required to perform the purpose for which it
                  was collected. However, in some circumstances, we will retain
                  your personal information for longer periods. We will retain
                  personal information for the following purposes:
                  <ul className="my-3">
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        As long as it is necessary and relevant for our
                        operations, e.g. so that we have an accurate record of
                        your dealings with us in the event of any complaints or
                        challenges;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        About personal information from closed accounts to
                        comply with applicable laws, prevent fraud, resolve
                        disputes, troubleshoot problems, assist with any
                        investigation, enforce our Site terms, and take other
                        actions as permitted by law;
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="content" id="Children’s privacy">
                <div className={styles.content_title}>Children’s privacy</div>
                <div className={`${styles.content_text} mb-4`}>
                  This site is offered and available to users who are at least
                  18 years of age or older and legal age to form a binding
                  contract. Minors under 18 and at least 13 years of age are
                  only permitted to use the site through an account owned by a
                  parent or legal guardian with their appropriate permission.
                  Minors under 13 are not permitted to use the Site or the Zodot
                  services. We do not knowingly collect personal information
                  from children under 13. Parents and guardians should at all
                  times supervise their children's activities. If we learn we
                  have collected or received personal information from a child
                  under 13, we will delete that personal information. If you
                  believe we might have any information from or about a child
                  under 13, please contact us at{" "}
                  <a
                    className={`${styles.email} mx-1`}
                    href="mailto:hello@zodot.co"
                  >
                    hello@zodot.co
                  </a>
                </div>
              </div>
              <div
                className="content"
                id="Third-party sharing of personal information"
              >
                <div className={styles.content_title}>
                  Third-party sharing of personal information
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  We share your personal information with third parties as
                  detailed below:
                  <ul className="my-3">
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        <strong className={styles.content_text_strong}>
                          With other users and site visitors.
                        </strong>
                        Once you register, your username, country, and
                        additional information regarding your activity are made
                        public and visible to all users of the site. This
                        information includes photos you upload, your published
                        portfolio, Gig information, ratings and reviews, and
                        additional information you may choose to add to your
                        profile. We also offer you the opportunity to
                        participate and post content publicly in forums, blogs,
                        and other similar features. Please be aware that certain
                        information, such as your user name and profile,
                        together with the content uploaded by you, will be
                        publicly displayed when you use such features. If you
                        were verified as a business seller (trader) on Zodot,
                        certain EU buyers may see some of the information as
                        required by applicable laws (this includes your name,
                        address, email, phone, and business registration number,
                        as well as your self-certification to comply with
                        Zodot's policies and EU applicable rules). Your
                        information will also be shared with other users when
                        you message or otherwise communicate with them. When you
                        publish your information and/or share your data with
                        other Zodot users, you do so at your own risk. If you
                        choose to share personal information with other users,
                        including sellers providing you a service via Zodot,
                        such personal information is shared with other users as
                        separate controllers. While our Terms of Service require
                        Sellers to keep the information shared with them
                        confidential, Zodot cannot ensure that all users will do
                        so and it is your responsibility to ensure that other
                        users will comply with your standards;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        <strong className={styles.content_text_strong}>
                          With users with whom you interact.
                        </strong>
                        We share personal information to help facilitate
                        interactions between users. For example, if you are a
                        buyer we may share certain information about you (such
                        as the fact it is your first purchase on Zodot) with the
                        seller to ensure the services you receive from the
                        Seller will fit your needs; such as sharing of
                        information is limited in scope, and is intended to
                        improve the services provided by the seller and your
                        user experience;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        <strong className={styles.content_text_strong}>
                          With service providers.
                        </strong>
                        We share personal information for business purposes with
                        service providers that provide us with services to
                        operate the Site, opening and operating your account as
                        well as provide ancillary services and solutions. These
                        include, among others, hosting services, billing and
                        payment processors and vendors, fraud detection and
                        prevention services, CRM systems, forum hosting,
                        community management services, data, and cybersecurity
                        services, web analytics and performance tools,
                        translation tools, IT SaaS services, session recording,
                        communication systems, mailing systems, data
                        optimization and marketing services, data enrichment
                        services, legal and financial advisors and technical
                        consultants. Consistent with applicable legal
                        requirements, we take appropriate technical and
                        organizational measures to require third parties to
                        adequately safeguard personal information and only
                        process it under our instructions;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        When you submit a report or complaint about a user. We
                        may share personal information about you when you submit
                        reports or complaints about other users to allow them to
                        protect their rights or as required by applicable laws.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        <strong className={styles.content_text_strong}>
                          For legal reasons.
                        </strong>
                        We share personal information with law enforcement
                        agencies, public authorities, or other parties to
                        respond to a subpoena or court order, judicial process,
                        or to regulatory authorities if we believe we are
                        required to do so by law, or that doing so is reasonably
                        necessary to comply with legal processes; when we
                        believe it necessary or appropriate to disclose personal
                        information to law enforcement authorities, such as to
                        investigate actual or suspected fraud or violations of
                        law, breaches of security, or breaches of this policy;
                        to respond to claims against us; and to protect the
                        rights, property, or personal safety of Zodot, our
                        customers, or the public;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        <strong className={styles.content_text_strong}>
                          With social media partners.
                        </strong>
                        Our site includes certain social media features,
                        including single sign-on features. We also share limited
                        personal information with such social media platforms
                        and marketing partners, such as Facebook and Google, to
                        promote our services or the Zodot marketplace. Such
                        features and third-party services collect certain
                        information about you and may set a cookie to enable
                        their functionality. Such third parties’ features are
                        governed by their policies;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        <strong className={styles.content_text_strong}>
                          With our corporate affiliates.
                        </strong>
                        We share personal information with Zodot’s corporate
                        affiliates for business and operational purposes,
                        including operating our and our affiliates’ business,
                        providing supplemental services to you, preventing fraud
                        and maintaining safe and secure services, promoting our
                        and our affiliates’ services, and other purposes listed
                        herein; and
                      </div>
                    </li>

                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        <strong className={styles.content_text_strong}>
                          In the context of a corporate transaction.
                        </strong>
                        Personal information will also be disclosed if we go
                        through a business transition such as a merger, sale,
                        transfer of all or a portion of Zodot's assets,
                        acquisition, bankruptcy, or similar events. If we sell
                        any business or assets, we will disclose your data to
                        the prospective buyer. If we or substantially all of our
                        assets are acquired by a third party, information held
                        by us about our users will be one of the transferred
                        assets.
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="content"
                id="Location of personal information processing"
              >
                <div className={styles.content_title}>
                  Location of personal information processing
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  Some of the personal information you provide to us will be
                  stored or processed on our behalf by third-party suppliers and
                  data processors and may be located in other jurisdictions,
                  such as the United States and Canada, whose laws may differ
                  from the jurisdiction in which you live. Whether to third
                  parties or internally, there are also transfers of personal
                  information from the European Economic Area (“EEA”) to
                  countries outside of the EEA not deemed to provide an adequate
                  level of data protection from a European data protection
                  perspective, such as the United States. About data transfers
                  to such recipients outside of the EEA, we provide appropriate
                  safeguards, in particular, by way of entering into European
                  Union (EU) standard contractual clauses as amended from time
                  to time, and/or equivalent data transfer safeguards. We will
                  take all steps reasonably necessary to ensure that your
                  personal information is treated securely and under this
                  Policy.
                </div>
              </div>
              <div className="content" id="Cookies">
                <div className={styles.content_title}>Cookies</div>
                <div className={`${styles.content_text} mb-4`}>
                  We use cookies and similar technologies (such as web beacons,
                  pixels, tags, and scripts) to improve and personalize your
                  experience, provide our services, analyze website performance,
                  and for marketing purposes. Where the use of cookies on our
                  Site involves the processing of your personal information,
                  this Policy applies. The types of cookies used by us and our
                  partners in connection with the Site and our service can be
                  classified into the categories “essential cookies”, “analytics
                  cookies” and “marketing cookies”.
                </div>
              </div>
              <div className="content" id="Do not track signals">
                <div className={styles.content_title}>
                  Do not track (DNT’) signals
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  Do Not Track (DNT) is a privacy preference that users can set
                  in some web browsers, allowing users to opt out of tracking by
                  websites and online services. We do not honor browser requests
                  not to be tracked online (known as “Do Not Track”), but our
                  Cookie Policy describes how you can opt out of receiving
                  cookies.
                </div>
              </div>
              <div className="content" id="External links">
                <div className={styles.content_title}> External links</div>
                <div className={`${styles.content_text} mb-4`}>
                  Please note that Zodot’s website contains links to third-party
                  sites and if you navigate to a third-party site from the Site,
                  any data you provide to that site and any use of that data by
                  the third party are not under the control of Zodot and are not
                  subject to this Policy. You should consult the privacy
                  policies of each site you visit. This policy applies solely to
                  personal information collected by our Site. If you upload
                  content, including personal information, to a social network
                  and then tag the site, your submission will be subject to that
                  social network's terms of use and privacy policy, even where
                  you post on an official Zodot page on the social network. We
                  do not have control over these terms of use and privacy
                  policies and have not reviewed their adequacy. You should
                  therefore review these before submitting any personal
                  information.
                </div>
              </div>
              <div className="content" id="Security measures">
                <div className={styles.content_title}>Security measures</div>
                <div className={`${styles.content_text} mb-4`}>
                  We implement technical and organizational measures to maintain
                  the security of the Site, our services, and personal
                  information and to prevent unauthorized access, loss, misuse,
                  alteration, destruction, or damage to it through
                  industry-standard technologies and internal procedures. Once
                  we have received your data, we will use strict procedures and
                  security features to try to prevent unauthorized access. Users
                  who have registered to the site agree to keep their password
                  in strict confidence and not disclose such password to any
                  third party. <br />
                  <br />
                  Further information about our data security practices can be
                  provided on request.
                </div>
              </div>
              <div
                className="content"
                id="Provisions for EU, EEA, and UK users"
              >
                <div className={styles.content_title}>
                  Provisions for EU, EEA, and UK users
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  This section of the policy applies to you if you are in the
                  EU, the European Economic Area (EEA), or the UK. The
                  controller (within the meaning of the EU and UK General Data
                  Protection Regulation (“GDPR”) for the processing of personal
                  data as described in this privacy policy is the Zodot
                  community. Under applicable EU regulation, you have the
                  following rights in respect of your personal information:
                  <ul className="my-3">
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        to obtain information about how and on what basis your
                        personal information is processed and to obtain a copy;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        to rectify inaccurate personal information;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        to erase your personal information in limited
                        circumstances where (a) you believe that it is no longer
                        necessary for us to hold your personal information; (b)
                        we are processing your personal information based on
                        legitimate interests and you object to such processing,
                        and we cannot demonstrate an overriding legitimate
                        ground for the processing; (c) where you have provided
                        your personal information to us with your consent and
                        you wish to withdraw your consent and there is no other
                        ground under which we can process your personal
                        information; and (d) where you believe the personal
                        information we hold about you is being unlawfully
                        processed by us;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        to restrict processing of your personal information
                        where: (a) the accuracy of the personal information is
                        contested; (b) the processing is unlawful but you object
                        to the erasure of the personal information; (c) we no
                        longer require the personal information for the purposes
                        for which it was collected, but it is required for the
                        establishment, exercise or defense of a legal claim; or
                        (d) you have objected to us processing your personal
                        information based on our legitimate interests and we are
                        considering your objection;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        to object to decisions that are based solely on
                        automated processing or profiling;
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        where you have provided your personal information to us
                        with your consent, to ask us for a copy of this data in
                        a structured, machine-readable format and to ask us to
                        share (port) this data to another data controller; or
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        to obtain a copy of or access to safeguards under which
                        your personal information is transferred outside of the
                        EEA.
                      </div>
                    </li>
                  </ul>
                </div>

                <div className={`${styles.content_text} mb-4`}>
                  <strong className={styles.content_text_strong}>
                    Under certain circumstances, you may have the right to
                    object, on grounds relating to your particular situation, to
                    the processing of your data by us and we may be required to
                    no longer process your data. Moreover, if your data is
                    processed for direct marketing purposes, you have the right
                    to object at any time to the processing of personal data
                    concerning you for such marketing, which includes profiling
                    to the extent that it is related to such direct marketing.
                    In this case, your data will no longer be processed for such
                    purposes by us.
                  </strong>
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  To exercise your rights, please contact us as stated in the
                  “Contact Us” section. In addition to the above, you have the
                  right to complain to a supervisory authority for data
                  protection. Please note that the right of access and the right
                  to erasure do not constitute absolute rights and the interests
                  of other individuals may restrict your right of access or
                  erase under local laws. We will ask you for additional data to
                  confirm your identity and for security purposes before
                  disclosing data you requested. We reserve the right to charge
                  a fee where permitted by law. We will decline to process
                  requests that jeopardize the privacy of others, are extremely
                  impractical, or would cause us to take any action that is not
                  permissible under applicable laws. Additionally, as permitted
                  by applicable laws, we will retain, where necessary, certain
                  personal information for a limited period for record-keeping,
                  accounting, and fraud prevention purposes.
                </div>
              </div>
              <div className="content" id="Provisions for US residents">
                <div className={styles.content_title}>
                  Provisions for US residents
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  This section of the Policy applies to you, if you are a us
                  resident, depending on where you live and subject to certain
                  exceptions.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  During the last twelve (12) months we have collected the
                  following categories of personal information from users:
                  <ul className="my-3">
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        Information that you chose to upload or otherwise
                        provided by you to Zodot, which may include: (i)
                        Identifiers and personal information, such as name,
                        postal addresses, and email addresses; (ii) professional
                        or employment-related information; (iii) commercial
                        information.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        Information we collect when you use Zodot, including (i)
                        Identifiers and personal information, such as online
                        identifiers, internet protocol (IP) addresses, and
                        connection information such as browser type, version,
                        and time zone setting and browser plug-in types and
                        versions; (ii) Internet or other electronic network
                        activity information, including, but not limited to
                        log-in and log-out time, the duration of sessions, the
                        content uploaded and downloaded, viewed web-pages or
                        specific content on web-pages, activity measures; (iii)
                        Location information.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        Information that we collect or receive from third
                        parties, such as service providers, advertisers, and
                        third-party accounts you link with Zodot, including (i)
                        Identifiers and personal information, such as name,
                        online identifiers, email addresses, internet protocol
                        (IP) addresses, access device and connection information
                        such as browser type, version, and time zone setting and
                        browser plug-in types and versions; (ii) Professional or
                        employment-related information; (iii) Internet or other
                        electronic network activity information, including, but
                        not limited to log-in and log-out time, the duration of
                        sessions, the content uploaded and downloaded, viewed
                        web-pages or specific content on web-pages, activity
                        measures; (iv) Commercial information; and (v) Location
                        information.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        Inferences drawn from any of the information identified
                        above to create a profile about you.
                      </div>
                    </li>
                  </ul>
                </div>

                <div className={`${styles.content_text} mb-4`}>
                  We use the personal information that we collect or receive for
                  business purposes as described above under the Section titled
                  “How Do We Use the Information Collected?”. We may disclose
                  the above-listed categories of personal information to third
                  parties for business purposes as described above under the
                  Section titled “Sharing Personal Information with Third
                  Parties” in the Privacy Policy. In the preceding twelve (12)
                  months, we have disclosed all the categories of personal
                  information detailed above for business purposes.
                  <br />
                  While we do not sell or share your personal information for
                  monetary compensation, we may share your personal information
                  with our marketing partners, to tailor and personalize Zodot’s
                  offers, ads, and content to suit your preferences while you're
                  browsing other sites. This process may constitute selling or
                  sharing of personal information under certain US state privacy
                  laws. You can ask us not to use or disclose your personal
                  information for such targeted advertising. You are entitled to
                  the following rights subject to certain exceptions, in
                  relation to personal information related to you:
                  <ul className="my-3">
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        You have a right to request access to the personal
                        information we have collected about you over the past 12
                        months, including (i) the categories of personal
                        information we collect about you; (ii) the categories of
                        sources from which the personal information is
                        collected; (iii) the business or commercial purpose for
                        collecting your personal information; (iv) the
                        categories of third parties with whom we have shared
                        your personal information; (v) the specific pieces of
                        personal information that we have collected about you.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        You have a right to request that we delete personal
                        information related to you that we collected from you
                        under certain circumstances and exceptions.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        You have a right to correct the personal information you
                        provided us with.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        You have a right to opt out of selling your personal
                        information.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        You have a right to opt out of targeted advertisements.
                      </div>
                    </li>
                    <li>
                      <div className={`${styles.content_text} mb-2`}>
                        You also have a right not to be discriminated against
                        for exercising your rights.
                      </div>
                    </li>
                  </ul>
                </div>

                <div className={`${styles.content_text} mb-4`}>
                  To make such requests, Please contact us at{" "}
                  <a
                    className={`${styles.email} ml-1`}
                    href="mailto:hello@zodot.co"
                  >
                    hello@zodot.co
                  </a>
                  .We will verify your request using the information associated
                  with your account, including your email address.
                </div>
              </div>
              <div className="content" id="Updating your personal information">
                <div className={styles.content_title}>
                  Updating your personal information
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  We take steps to ensure that the personal information we
                  collect is accurate and up-to- date, and we provide you with
                  the opportunity to update your information through your
                  account profile settings. If you believe your information is
                  in any way incorrect, please let us know immediately. We will
                  make sure we investigate the matter and correct any
                  inaccuracies as quickly as possible where necessary or give
                  you ways to update it quickly or to delete it - unless we have
                  to keep that information for legitimate business or legal
                  purposes. When updating your personal information, we will ask
                  you to verify your identity before we can act on your request.
                  If for any reason you have a problem with deleting your
                  personal information, please contact Zodot Customer Support
                  and we will make reasonable efforts to delete any such
                  information according to any applicable privacy laws.
                </div>
                <div className={`${styles.content_text} mb-4`}>
                  You can review and change your personal information by logging
                  into the Site and visiting your account profile page.
                </div>
              </div>
              <div className="content" id="Contact information">
                <div className={styles.content_title}>Contact information</div>
                <div className={`${styles.content_text} mb-4`}>
                  To exercise any of your rights in connection with your
                  personal information, You may contact us at
                  <a
                    className={`${styles.email} ml-1`}
                    href="mailto:hello@zodot.co"
                  >
                    hello@zodot.co
                  </a>
                  . If you have any questions (or comments) concerning this
                  Policy, please email our team at
                  <a
                    className={`${styles.email} mx-1`}
                    href="mailto:officialzodot@gmail.com"
                  >
                    officialzodot@gmail.com
                  </a>
                  and we will make an effort to reply within a reasonable
                  timeframe.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
