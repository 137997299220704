import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../axios/axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const start_timer_activity = createAsyncThunk(
  "tt/start_timer_activity",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest("tt/timer_activity", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const stop_timer_activity = createAsyncThunk(
  "tt/stop_timer_activity",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("tt/timer_activity", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const start_timer_change_activity_name = createAsyncThunk(
  "tt/start_timer_change_activity_name",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("tt/start_timer_change_activity_name", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const start_timer_change_project_name = createAsyncThunk(
  "tt/start_timer_change_project_name",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("tt/start_timer_change_project_name", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const cancel_start_activity = createAsyncThunk(
  "tt/cancel_start_activity",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      deleteRequest("tt/cancel_start_activity", token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchActiveActivity = createAsyncThunk(
  "fetch_active_activity",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest("tt/fetch_active_activity", token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createActivityList = createAsyncThunk(
  "tt/create_actvity_list",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest("tt/create_activity_list", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchAllActivityLists = createAsyncThunk(
  "tt/fetch_all_actvity_lists",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest("tt/fetch_all_activity_lists", token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const add_single_timer_activity = createAsyncThunk(
  "tt/add_single_timer_activity",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest("tt/add_single_timer_activity", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchAllTimerActivities = createAsyncThunk(
  "tt/fetch_all_activities",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("tt/fetch_all_timer_activities", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const update_multiple_timer_activities = createAsyncThunk(
  "tt/update_multiple_timer_activities",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("tt/update_multiple_timer_activities", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const update_single_timer_activity = createAsyncThunk(
  "tt/update_single_timer_activity",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("tt/update_single_timer_activity", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const update_multiple_timer_activity_names = createAsyncThunk(
  "tt/update_multiple_timer_activity_names",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("tt/update_multiple_timer_activity_names", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const update_single_timer_activity_name = createAsyncThunk(
  "tt/update_single_timer_activity_name",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("tt/update_single_timer_activity_name", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const update_multiple_timer_project_names = createAsyncThunk(
  "tt/update_multiple_timer_project_names",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("tt/update_multiple_timer_project_names", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const update_single_timer_project_name = createAsyncThunk(
  "tt/update_single_timer_project_name",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("tt/update_single_timer_project_name", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const update_multiple_timer_dates = createAsyncThunk(
  "tt/update_multiple_timer_dates",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("tt/update_multiple_timer_dates", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const update_single_timer_date = createAsyncThunk(
  "tt/update_single_timer_date",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("tt/update_single_timer_date", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const update_multiple_timer_invoices = createAsyncThunk(
  "tt/update_multiple_timer_invoices",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("tt/update_multiple_timer_invoices", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const update_single_timer_invoice = createAsyncThunk(
  "tt/update_single_timer_invoice",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest("tt/update_single_timer_invoice", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const delete_multiple_timer_activities = createAsyncThunk(
  "tt/delete_multiple_timer_activities",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("tt/delete_multiple_timer_activities", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const delete_single_timer_activity = createAsyncThunk(
  "tt/delete_single_timer_activity",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("tt/delete_single_timer_activity", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const merge_timer_activities = createAsyncThunk(
  "tt/merge_timer_activities",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("tt/merge_timer_activities", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchWeekView = createAsyncThunk(
  "tt/fetch_weekview_activity",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest("tt/fetch_weekview_activity", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateWeekviewActivityNames = createAsyncThunk(
  "tt/update_weekview_activity_names",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("tt/update_weekview_activity_names", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createWeekViewSingleActivity = createAsyncThunk(
  "tt/create_weekview_single_activity",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest("tt/create_weekview_single_activity", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateWeekViewSingleActivity = createAsyncThunk(
  "tt/update_weekview_single_activity",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("tt/update_weekview_single_activity", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateWeekViewMultipleActivityTimes = createAsyncThunk(
  "tt/update_weekview_multiple_activity_times",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest("tt/update_weekview_multiple_activity_times", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchActivityReports = createAsyncThunk(
  "tt/fetch_activity_reports",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("tt/fetch_activity_reports", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchActivityReportsForGraph = createAsyncThunk(
  "tt/fetch_activity_reports_for_graph",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest("tt/fetch_activity_reports_for_graph", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchActivityCounts = createAsyncThunk(
  "tt/fetch_activity_counts",
  async () => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      getRequest("tt/fetch_activity_counts", token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
