import React from "react";
import styles from "./Features.module.css";
import { NavLink } from "react-router-dom";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import InvoiceFeature1 from "../../../../Assets/frontendImages/InvoiceFeature1.avif";
import InvoiceFeature2 from "../../../../Assets/frontendImages/InvoiceFeature2.avif";
import InvoiceFeature3 from "../../../../Assets/frontendImages/InvoiceFeature3.avif";
import InvoiceFeature4 from "../../../../Assets/frontendImages/InvoiceFeature4.avif";

const Features = () => {
  return (
    <div className={`${styles.section}`}>
      <div className="container-fluid py-3 py-md-4" style={{ maxWidth: 1100 }}>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100">
            <div
              data-aos={"fade-right"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={InvoiceFeature1}
                alt="InvoiceFeature1"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="text-start mt-3 mt-md-0 w-100">
            <div className="w-100" data-aos={"fade-left"}>
              <div className={`${styles.heading} mb-3`}>
                Effortlessly generate invoices with Zodot
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Rev up your invoicing speed with Zodot’s seamless invoice making
                features. Quickly create invoices online for free, ensuring a
                smooth and trouble-free experience.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Get started with Zodot today
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>
                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="text-start mt-3 mt-md-0 w-100 order-1 order-md-0">
            <div className="w-100" data-aos={"fade-right"}>
              <div className={`${styles.heading} mb-3`}>
                Tailor invoices as per your needs
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Generate client billing invoices instantly with tracked time for
                existing projects or set up details for new ones effortlessly on
                Zodot. Enjoy the flexibility to quickly create invoices without
                project setup, catering to your unique business needs.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Tailor your invoicing - Get started now
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 order-0 order-md-1">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={InvoiceFeature2}
                alt="InvoiceFeature2"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100">
            <div
              data-aos={"fade-right"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={InvoiceFeature3}
                alt="InvoiceFeature3"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="text-start mt-3 mt-md-0 w-100">
            <div className="w-100" data-aos={"fade-left"}>
              <div className={`${styles.heading} mb-3`}>
                Add multiple services simultaneously
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Effortlessly include multiple services in a single invoice for
                clients or diverse projects. Save time, ensure accuracy, and
                streamline your billing process with just a few clicks.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Take control of your invoicing efficiency
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center"
          style={{ overflow: "hidden" }}
        >
          <div className="text-start mt-3 mt-md-0 w-100 order-1 order-md-0">
            <div className="w-100" data-aos={"fade-right"}>
              <div className={`${styles.heading} mb-3`}>
                Secure your financial data
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Safeguard your financial data by utilizing secure cloud-based
                productivity tool. Access your invoices anytime, anywhere, with
                ease.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Safeguard your data - Access securely now
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>
                  <span className={`${styles.farword_arrow}`}>
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 order-0 order-md-1">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={InvoiceFeature4}
                alt="InvoiceFeature4"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
