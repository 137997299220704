import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const ProgressBar = styled.div`
  width: 100%;
  height: 1.687rem;
  margin-bottom: 6px;
  display: flex;
  font-family: Poppins;
  align-items: center;
  font-size: 0.875rem;
  color: ${Colors.font2};
  background: rgb(218, 222, 236);
`;

const SubmitButton = styled.button`
  position: fixed;
  height: 3.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 1rem;
  bottom: 1rem;
  padding: 0 1.25rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 12px;
  background-color: ${(props) => props.backgroundColor || Colors.primary};

  &:hover {
    background-color: ${(props) =>
      props.backgroundColor || Colors.hoverButtonColor};
  }

  &:active {
    background-color: ${(props) => props.backgroundColor || Colors.primary};
  }
`;

export default {
  ProgressBar,
  SubmitButton,
};
