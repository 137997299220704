import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import ClearIcon from "@mui/icons-material/Clear";
import { editInvoiceService } from "../../../../store/service/invoiceService";
import LoaderSpin from "../../Commons/LoaderSpin";
import Styled from "../CreateInvoices/InvoiceSetup/InvoiceSetupStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

const EditInvoiceService = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.invoices.isLoading);
  const { register, control, handleSubmit, watch } = useForm({
    defaultValues: {
      serviceLists:
        selectedData &&
        selectedData.project_id &&
        selectedData.project_id.service_list,
    },
  });
  const { fields } = useFieldArray({
    name: "serviceLists",
    control,
  });

  const formsubmit = (data) => {
    const Obj = {
      invoice_id: selectedData._id,

      invoice_services: data.serviceLists
        .filter((d) => d.checkbox === true)
        .map((d) => {
          return {
            service_name: d.service_name,
            service_rate: d.service_rate,
            service_rate_type: d.service_rate_type,
            service_rate_count: d.num_quantity ? parseFloat(d.num_quantity) : 1,
            desc: d.desc,
          };
        }),
    };

    dispatch(editInvoiceService(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "editInvoiceServiceSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "editInvoiceServiceFail" }
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalOnRight500px"
        fullscreen
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          className="modal-content"
          style={{ height: "100%" }}
        >
          <Modal.Header className="d-flex align-items-center">
            <TextStyles.FontSize20px>
              {selectedData &&
                selectedData.project_id &&
                selectedData.project_id.project_name}
            </TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            {fields.map((item, index) => {
              const fieldName = `serviceLists[${index}]`;
              const ratecount = watch(`${fieldName}.num_quantity`);
              let checkbox = watch(`${fieldName}.checkbox`);
              if (checkbox === undefined) checkbox = true;
              let total = 0;
              if (ratecount === undefined) {
                total += item.service_rate * 1;
              } else {
                total += item.service_rate * ratecount;
              }
              return (
                <div key={index} className="mt-3">
                  <div className="d-flex justify-content-between align-items-center three-dots w-100">
                    <TextStyles.CheckBox
                      className="form-check d-flex align-items-center three-dots"
                      style={{ minWidth: 100 }}
                    >
                      <input
                        style={{ cursor: "pointer" }}
                        className="form-check-input"
                        type="checkbox"
                        id="checkbox"
                        name="checkbox"
                        defaultChecked={true}
                        {...register(`${fieldName}.checkbox`)}
                      />
                      <TextStyles.FontSize14px
                        className="form-check-label three-dots"
                        htmlFor="flexCheckChecked"
                        style={{
                          color: checkbox === false ? "grey" : "",
                        }}
                      >
                        {item.service_name}
                      </TextStyles.FontSize14px>
                    </TextStyles.CheckBox>
                    <TextStyles.GreyFont20px
                      className="d-flex"
                      style={{ minWidth: 100 }}
                    >
                      <span className="three-dots">
                        {selectedData &&
                          selectedData.currency &&
                          selectedData.currency.symbol}
                        {item.service_rate
                          ? parseFloat(item.service_rate).toFixed(2)
                          : "00.00"}{" "}
                        {item.service_rate_type === "Flat fee" && ""}
                        {item.service_rate_type === "Per hour" && "/hour"}
                        {item.service_rate_type === "Per day" && "/day"}
                        {item.service_rate_type === "Per item" && "/item"}
                        {item.service_rate_type === "Per word" && "/word"}
                      </span>
                    </TextStyles.GreyFont20px>
                  </div>

                  {checkbox === true && (
                    <>
                      <div className="mb-2 mt-3">
                        <TextStyles.FontSize14px
                          style={{ color: "rgb(106 106 106)" }}
                        >
                          Quantity
                        </TextStyles.FontSize14px>
                      </div>
                      <div className="d-flex justify-content-start align-items-center">
                        <Styled.inputBox style={{ maxWidth: 120 }}>
                          <input
                            readOnly={item.service_rate_type === "Flat fee"}
                            style={{ width: 80 }}
                            type="number"
                            id="service_rate"
                            min={"0"}
                            step="0.01"
                            defaultValue={1}
                            name="num_quantity"
                            placeholder="00.00"
                            {...register(`${fieldName}.num_quantity`)}
                          />
                        </Styled.inputBox>

                        <TextStyles.FontSize14px
                          style={{
                            marginLeft: "10px",
                          }}
                        >
                          {item.service_rate_type === "Flat fee" && (
                            <TextStyles.FontSize14px>
                              flat fee
                            </TextStyles.FontSize14px>
                          )}
                          {item.service_rate_type === "Per hour" && (
                            <TextStyles.FontSize14px>
                              per hour
                            </TextStyles.FontSize14px>
                          )}
                          {item.service_rate_type === "Per day" && (
                            <TextStyles.FontSize14px>
                              per day
                            </TextStyles.FontSize14px>
                          )}
                          {item.service_rate_type === "Per item" && (
                            <TextStyles.FontSize14px>
                              per item
                            </TextStyles.FontSize14px>
                          )}
                          {item.service_rate_type === "Per word" && (
                            <TextStyles.FontSize14px>
                              per word
                            </TextStyles.FontSize14px>
                          )}
                        </TextStyles.FontSize14px>
                      </div>
                      <TextStyles.FontSize20px className="d-flex justify-content-between align-items-center three-dots w-100 mt-3">
                        <div className="three-dots" style={{ minWidth: 100 }}>
                          Total services
                        </div>
                        <div
                          className="three-dots"
                          style={{ color: Colors.primary, minWidth: 50 }}
                        >
                          {selectedData &&
                            selectedData.currency &&
                            selectedData.currency.symbol}
                          {total > 0 ? parseFloat(total).toFixed(2) : "00.00"}
                        </div>
                      </TextStyles.FontSize20px>
                    </>
                  )}
                  <div
                    className="my-3"
                    style={{
                      width: "100%",
                      height: "1px",
                      border: "0.5px solid #f6f6f6",
                    }}
                  />
                </div>
              );
            })}
          </Modal.Body>

          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button
                disabled={isLoading ? true : false}
                style={{
                  opacity: isLoading ? 0.5 : 1,
                }}
              >
                {isLoading ? <LoaderSpin /> : "Save"}
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditInvoiceService;
