import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";

const Text = ({ data }) => {
  return (
    <Styled.ReviewBox className="mt-4">
      <TextStyles.FontSize14px className="mb-3">
        <div className="mb-2">Text title</div>
        <div className="d-flex gap-1 justify-content-between align-items-center">
          <div
            className="d-flex justify-content-start w-100"
            style={{ minWidth: 100 }}
          >
            <Styled.TextBoader
              className="d-flex w-100"
              style={{ minWidth: 100 }}
            >
              <span className="three-dots">{data.text_title}</span>
            </Styled.TextBoader>
          </div>
        </div>
      </TextStyles.FontSize14px>

      <TextStyles.FontSize14px>
        <div className="mb-2">Text description</div>
        <div className="d-flex gap-1 justify-content-between align-items-center">
          <Styled.TextBoader>{data.textarea}</Styled.TextBoader>
        </div>
      </TextStyles.FontSize14px>
    </Styled.ReviewBox>
  );
};

export default Text;
