import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Modal } from "react-bootstrap";
import { contractSettingsPC } from "../../../../store/service/proposalAndContractService";
import LoaderSpin from "../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";

import SignatureBtn from "../../../../Assets/assetsnew/signature-symbol.svg";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import TextStyles from "../../../../Constants/TextStyles";
import Styled from "./ReviewStyles";

import SelectDueDays from "../../Commons/SelectDueDays";
import SelectProjectTerminationDays from "../../Commons/SelectProjectTerminationDays";

import { CustomDatePicker } from "../../Commons/CustomDatePicker";
import Colors from "../../../../Constants/Colors";

const ContactSettings = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const ownershipText =
    "The work product is granted a global, irrevocable, non-exclusive license that is royalty-free, sub-licensable and non-attribution. This license permits the use, modification, creation of derivative works, or any other form of exploitation of the work product without the need for attribution.";

  const addClauseText =
    "Contractor agrees that throughout the duration of providing Services and for [XX] months after the termination of Services or expiration of this Agreement, within a [XX-mile radius of Client's primary business location], Contractor shall refrain from engaging in any activities directly and substantially competitive with Client's current business, which is narrowly defined as [description of Client's primary current business].";

  const [arrow, setArrow] = useState(false);
  const [arrow2, setArrow2] = useState(false);
  const [arrow3, setArrow3] = useState(false);

  const ArrowHandler = () => {
    setArrow(!arrow);
  };
  const ArrowHandler2 = () => {
    setArrow2(!arrow2);
  };

  const ArrowHandler3 = () => {
    setArrow3(!arrow3);
  };

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      contract_expire_date:
        selectedData &&
        selectedData.contract_expire_date &&
        new Date(selectedData.contract_expire_date),
      payment_due:
        selectedData &&
        selectedData.payment_terms &&
        selectedData.payment_terms.payment_due &&
        selectedData.payment_terms.payment_due === "7 days"
          ? "7 days"
          : selectedData &&
            selectedData.payment_terms &&
            selectedData.payment_terms.payment_due &&
            selectedData.payment_terms.payment_due === "15 days"
          ? "15 days"
          : selectedData &&
            selectedData.payment_terms &&
            selectedData.payment_terms.payment_due &&
            selectedData.payment_terms.payment_due === "30 days"
          ? "30 days"
          : selectedData &&
            selectedData.payment_terms &&
            selectedData.payment_terms.payment_due &&
            selectedData.payment_terms.payment_due === "45 days"
          ? "45 days"
          : selectedData &&
            selectedData.payment_terms &&
            selectedData.payment_terms.payment_due &&
            selectedData.payment_terms.payment_due !== "7 days" &&
            selectedData.payment_terms.payment_due !== "15 days" &&
            selectedData.payment_terms.payment_due !== "30 days" &&
            selectedData.payment_terms.payment_due !== "45 days"
          ? "Custom"
          : "Upon receipt",
      custom_due_days:
        selectedData &&
        selectedData.payment_terms &&
        selectedData.payment_terms.payment_due &&
        selectedData.payment_terms.payment_due !== "7 days" &&
        selectedData.payment_terms.payment_due !== "15 days" &&
        selectedData.payment_terms.payment_due !== "30 days" &&
        selectedData.payment_terms.payment_due !== "45 days"
          ? selectedData.payment_terms.payment_due.split(" ")[0]
          : null,
      project_termination_notice_period:
        selectedData &&
        selectedData.term_and_termination &&
        selectedData.term_and_termination.project_termination_notice_period &&
        selectedData.term_and_termination.project_termination_notice_period ===
          "7 days"
          ? "1 week"
          : selectedData &&
            selectedData.term_and_termination &&
            selectedData.term_and_termination
              .project_termination_notice_period &&
            selectedData.term_and_termination
              .project_termination_notice_period === "14 days"
          ? "2 weeks"
          : selectedData &&
            selectedData.term_and_termination &&
            selectedData.term_and_termination
              .project_termination_notice_period &&
            selectedData.term_and_termination
              .project_termination_notice_period === "21 days"
          ? "3 weeks"
          : selectedData &&
            selectedData.term_and_termination &&
            selectedData.term_and_termination
              .project_termination_notice_period &&
            selectedData.term_and_termination
              .project_termination_notice_period === "30 days"
          ? "1 month"
          : selectedData &&
            selectedData.term_and_termination &&
            selectedData.term_and_termination
              .project_termination_notice_period &&
            selectedData.term_and_termination
              .project_termination_notice_period !== "7 days" &&
            selectedData.term_and_termination
              .project_termination_notice_period !== "14 days" &&
            selectedData.term_and_termination
              .project_termination_notice_period !== "21 days" &&
            selectedData.term_and_termination
              .project_termination_notice_period !== "30 days"
          ? "Custom"
          : "2 week",
      custom_project_termination_notice_period:
        selectedData &&
        selectedData.term_and_termination &&
        selectedData.term_and_termination.project_termination_notice_period &&
        selectedData.term_and_termination.project_termination_notice_period !==
          "7 days" &&
        selectedData.term_and_termination.project_termination_notice_period !==
          "14 days" &&
        selectedData.term_and_termination.project_termination_notice_period !==
          "21 days" &&
        selectedData.term_and_termination.project_termination_notice_period !==
          "30 days"
          ? selectedData.term_and_termination.project_termination_notice_period.split(
              " "
            )[0]
          : null,
      num_of_termination_request_require:
        selectedData &&
        selectedData.number_of_termination_request &&
        selectedData.number_of_termination_request
          .num_of_termination_request_require,
      num_request:
        selectedData &&
        selectedData.number_of_termination_request &&
        selectedData.number_of_termination_request.num_request,
      ownership:
        selectedData &&
        selectedData.ownership_right &&
        selectedData.ownership_right.ownership,
      ownership_text:
        selectedData &&
        selectedData.ownership_right &&
        selectedData.ownership_right.ownership_text
          ? selectedData.ownership_right.ownership_text
          : ownershipText,
      retain_rights:
        selectedData &&
        selectedData.rightToAuthorship &&
        selectedData.rightToAuthorship.retain_rights,
      late_payment_deposit_require:
        selectedData &&
        selectedData.late_payment_fee &&
        selectedData.late_payment_fee.late_payment_deposit_require,
      late_payment:
        selectedData &&
        selectedData.late_payment_fee &&
        selectedData.late_payment_fee.late_payment,
      late_payment_amount:
        selectedData &&
        selectedData.late_payment_fee &&
        selectedData.late_payment_fee.late_payment_amount,
      late_payment_percentage:
        selectedData &&
        selectedData.late_payment_fee &&
        selectedData.late_payment_fee.late_payment_percentage,
      cancellation_fee_deposit_require:
        selectedData &&
        selectedData.cancellation_fee &&
        selectedData.cancellation_fee.cancellation_fee_deposit_require,
      cancellation_fee_amount:
        selectedData &&
        selectedData.cancellation_fee &&
        selectedData.cancellation_fee.cancellation_fee_amount,
      add_non_complete_clause_require:
        selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.add_non_complete_clause_require,
      add_non_complete_clause_text:
        selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.add_non_complete_clause_text
          ? selectedData.add_non_complete.add_non_complete_clause_text
          : addClauseText,
      add_sexual_harassment_clause_require:
        selectedData &&
        selectedData.add_sexual_harassment_clause &&
        selectedData.add_sexual_harassment_clause
          .add_sexual_harassment_clause_require,
    },
  });

  const ownershipRight = watch("ownership");
  const numRequestRequired = watch("num_of_termination_request_require");
  const latePaymentDepositRequire = watch("late_payment_deposit_require");
  const latePayment = watch("late_payment");
  const cancelationFeeDepositRequire = watch(
    "cancellation_fee_deposit_require"
  );

  const addNonCompleteClauseRequire = watch("add_non_complete_clause_require");
  const paymentDue = watch("payment_due");
  const customPaymentDue = watch("custom_due_days");
  const project_termination_notice_period = watch(
    "project_termination_notice_period"
  );

  useEffect(() => {
    if (latePayment === "Amount" && latePaymentDepositRequire) {
      setValue(
        "late_payment_amount",
        selectedData &&
          selectedData.late_payment_fee &&
          selectedData.late_payment_fee.late_payment_amount
      );
      setValue(
        "late_payment_percentage",
        selectedData &&
          selectedData.late_payment_fee &&
          selectedData.late_payment_fee.late_payment_percentage
      );
    }
    if (latePayment === "Percentage" && latePaymentDepositRequire) {
      setValue(
        "late_payment_percentage",
        selectedData &&
          selectedData.late_payment_fee &&
          selectedData.late_payment_fee.late_payment_percentage
      );
      setValue(
        "late_payment_amount",
        selectedData &&
          selectedData.late_payment_fee &&
          selectedData.late_payment_fee.late_payment_amount
      );
    }
  }, [latePayment]);

  useEffect(() => {
    if (cancelationFeeDepositRequire) {
      setValue(
        "cancellation_fee_amount",
        selectedData &&
          selectedData.cancellation_fee &&
          selectedData.cancellation_fee.cancellation_fee_amount
      );
    } else {
      setValue("cancellation_fee_amount", null);
    }
  }, [cancelationFeeDepositRequire]);

  const formsubmit = (item) => {
    // console.log(item);
    const Obj = {
      _id: selectedData && selectedData._id,
      contract_expire_date: item.contract_expire_date,
      payment_due:
        item.payment_due === "Custom" && item.custom_due_days === "1"
          ? `${item.custom_due_days} day`
          : item.payment_due === "Custom"
          ? `${item.custom_due_days} days`
          : item.payment_due !== ("Custom" && "Upon receipt")
          ? item.payment_due
          : "15 days",
      project_termination_notice_period:
        item.project_termination_notice_period === "Custom"
          ? `${item.custom_project_termination_notice_period} days`
          : item.project_termination_notice_period === "1 week"
          ? "7 days"
          : item.project_termination_notice_period === "2 weeks"
          ? "14 days"
          : item.project_termination_notice_period === "3 weeks"
          ? "21 days"
          : item.project_termination_notice_period === "1 month"
          ? "30 days"
          : "14 days",
      num_of_termination_request_require:
        item.num_of_termination_request_require,
      num_request: numRequestRequired === true ? item.num_request : "0",
      ownership: item.ownership,
      ownership_text:
        ownershipRight === "Licensed" ? item.ownership_text : null,
      retain_rights: item.retain_rights,
      late_payment_deposit_require: item.late_payment_deposit_require,
      late_payment: latePaymentDepositRequire
        ? item.late_payment
        : "Percentage",
      late_payment_amount:
        latePayment === "Amount" && latePaymentDepositRequire
          ? item.late_payment_amount
          : "50",
      late_payment_percentage:
        latePayment === "Percentage" && latePaymentDepositRequire
          ? item.late_payment_percentage
          : "1.50",
      cancellation_fee_deposit_require: item.cancellation_fee_deposit_require,
      cancellation_fee_amount: cancelationFeeDepositRequire
        ? item.cancellation_fee_amount
        : null,
      add_non_complete_clause_require: item.add_non_complete_clause_require,
      add_non_complete_clause_text: addNonCompleteClauseRequire
        ? item.add_non_complete_clause_text
        : null,
      add_sexual_harassment_clause_require:
        item.add_sexual_harassment_clause_require,
    };
    dispatch(contractSettingsPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            Contract settings updated successfully!
          </TextStyles.FontSize14px>,
          { toastId: "updateContractSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateContractFail" }
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalOnRight600px"
        fullscreen
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          className="modal-content"
          style={{ height: "100%" }}
        >
          <Modal.Header className="d-flex align-items-center">
            <TextStyles.FontSize20px className="d-flex justify-content-start">
              Contract settings
            </TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex gap-2 align-items-center">
              <img src={SignatureBtn} alt="signatureBtn" />
              <TextStyles.GreyFont14px style={{ maxWidth: 400 }}>
                Set the terms, fees, and clauses to be included in your
                contract.
              </TextStyles.GreyFont14px>
            </div>
            <TextStyles.FontSize14px className="mb-3 mt-4">
              <label htmlFor="contract_expire_date" style={{ display: "flex" }}>
                Contract expires
              </label>

              <TextStyles.DateRectangle style={{ maxWidth: 400 }}>
                <Controller
                  name={"contract_expire_date"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomDatePicker
                        onChange={onChange}
                        selected={value}
                        placeholderText="Setect date"
                        todayButton="Today"
                        dateFormat="dd MMM yyyy"
                        // isClearable={true}
                      />
                    );
                  }}
                />
              </TextStyles.DateRectangle>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="payment_due">Payment due</label>

              <div className="d-flex gap-3 flex-wrap align-items-center">
                <Controller
                  control={control}
                  name="payment_due"
                  render={({ field: { onChange, value } }) => (
                    <SelectDueDays
                      width="180px"
                      defaultValue={
                        selectedData &&
                        selectedData.payment_terms &&
                        selectedData.payment_terms.payment_due &&
                        selectedData.payment_terms.payment_due === "7 days"
                          ? "7 days"
                          : selectedData &&
                            selectedData.payment_terms &&
                            selectedData.payment_terms.payment_due &&
                            selectedData.payment_terms.payment_due === "15 days"
                          ? "15 days"
                          : selectedData &&
                            selectedData.payment_terms &&
                            selectedData.payment_terms.payment_due &&
                            selectedData.payment_terms.payment_due === "30 days"
                          ? "30 days"
                          : selectedData &&
                            selectedData.payment_terms &&
                            selectedData.payment_terms.payment_due &&
                            selectedData.payment_terms.payment_due === "45 days"
                          ? "45 days"
                          : selectedData &&
                            selectedData.payment_terms &&
                            selectedData.payment_terms.payment_due &&
                            selectedData.payment_terms.payment_due !==
                              "7 days" &&
                            selectedData.payment_terms.payment_due !==
                              "15 days" &&
                            selectedData.payment_terms.payment_due !==
                              "30 days" &&
                            selectedData.payment_terms.payment_due !== "45 days"
                          ? "Custom"
                          : "Upon receipt"
                      }
                      onChange={onChange}
                    />
                  )}
                />

                {paymentDue === "Custom" && (
                  <>
                    <TextStyles.FontSize14px>After</TextStyles.FontSize14px>
                    <Styled.inputBox invalid={errors.custom_due_days}>
                      <input
                        type="text"
                        name="custom_due_days"
                        style={{
                          width: 24,
                          textAlign: "center",
                          background: "transparent",
                        }}
                        placeholder="00"
                        {...register("custom_due_days", {
                          required:
                            paymentDue === "Custom"
                              ? "Please enter custom due days."
                              : false,
                          min: {
                            value: 1,
                            message: "Please enter more than 0.",
                          },
                          pattern: {
                            value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                    </Styled.inputBox>
                    <TextStyles.FontSize14px>
                      {customPaymentDue === "1" ? "day" : "days"}
                    </TextStyles.FontSize14px>
                  </>
                )}
              </div>
              {errors.custom_due_days && (
                <TextStyles.InValidFeedback className="mt-2">
                  {errors.custom_due_days.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="project_termination_notice_period">
                Project termination notice period
              </label>

              <div className="d-flex gap-3 flex-wrap align-items-center">
                <Controller
                  control={control}
                  name="project_termination_notice_period"
                  render={({ field: { onChange, value } }) => (
                    <SelectProjectTerminationDays
                      width="180px"
                      defaultValue={
                        selectedData &&
                        selectedData.term_and_termination &&
                        selectedData.term_and_termination
                          .project_termination_notice_period &&
                        selectedData.term_and_termination
                          .project_termination_notice_period === "7 days"
                          ? "1 week"
                          : selectedData &&
                            selectedData.term_and_termination &&
                            selectedData.term_and_termination
                              .project_termination_notice_period &&
                            selectedData.term_and_termination
                              .project_termination_notice_period === "14 days"
                          ? "2 weeks"
                          : selectedData &&
                            selectedData.term_and_termination &&
                            selectedData.term_and_termination
                              .project_termination_notice_period &&
                            selectedData.term_and_termination
                              .project_termination_notice_period === "21 days"
                          ? "3 weeks"
                          : selectedData &&
                            selectedData.term_and_termination &&
                            selectedData.term_and_termination
                              .project_termination_notice_period &&
                            selectedData.term_and_termination
                              .project_termination_notice_period === "30 days"
                          ? "1 month"
                          : selectedData &&
                            selectedData.term_and_termination &&
                            selectedData.term_and_termination
                              .project_termination_notice_period &&
                            selectedData.term_and_termination
                              .project_termination_notice_period !== "7 days" &&
                            selectedData.term_and_termination
                              .project_termination_notice_period !==
                              "14 days" &&
                            selectedData.term_and_termination
                              .project_termination_notice_period !==
                              "21 days" &&
                            selectedData.term_and_termination
                              .project_termination_notice_period !== "30 days"
                          ? "Custom"
                          : "2 week"
                      }
                      onChange={onChange}
                    />
                  )}
                />

                {project_termination_notice_period === "Custom" && (
                  <>
                    <TextStyles.FontSize14px>After</TextStyles.FontSize14px>
                    <Styled.inputBox
                      invalid={errors.custom_project_termination_notice_period}
                    >
                      <input
                        type="text"
                        name="custom_project_termination_notice_period"
                        style={{
                          width: 24,
                          textAlign: "center",
                          background: "transparent",
                        }}
                        placeholder="00"
                        {...register(
                          "custom_project_termination_notice_period",
                          {
                            required:
                              project_termination_notice_period === "Custom"
                                ? "Please enter custom termination notice period."
                                : false,
                            min: {
                              value: 1,
                              message: "Please enter more than 0.",
                            },
                            pattern: {
                              value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                              message: "Please enter a valid number",
                            },
                          }
                        )}
                      />
                    </Styled.inputBox>
                    <TextStyles.FontSize14px>
                      {project_termination_notice_period === "1"
                        ? "day"
                        : "days"}
                    </TextStyles.FontSize14px>
                  </>
                )}
              </div>
              {errors.custom_project_termination_notice_period && (
                <TextStyles.InValidFeedback className="mt-2">
                  {errors.custom_project_termination_notice_period.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <div
              className="d-flex gap-3 flex-wrap align-items-center mb-3"
              style={{ minHeight: 65 }}
            >
              <TextStyles.CheckBox className="form-check d-flex align-items-center">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="num_of_termination_request_require"
                  name="num_of_termination_request_require"
                  {...register("num_of_termination_request_require")}
                />
                <label
                  className="form-check-label"
                  htmlFor="num_of_termination_request_require"
                >
                  <TextStyles.FontSize14px>
                    Limit revision requests?
                  </TextStyles.FontSize14px>
                </label>
              </TextStyles.CheckBox>

              {numRequestRequired && (
                <Styled.inputBox invalid={errors.num_request}>
                  <input
                    style={{
                      width: 24,
                      textAlign: "center",
                      backgroundColor: "transparent",
                    }}
                    placeholder="00"
                    id="num_request"
                    type="text"
                    name="num_request"
                    {...register("num_request", {
                      required: numRequestRequired
                        ? "Number of request can't be empty."
                        : false,
                      min: {
                        value: 1,
                        message: "Please enter minimum requests more than 0.",
                      },
                      pattern: {
                        value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                        message: "Please enter a valid number",
                      },
                    })}
                  />
                </Styled.inputBox>
              )}
            </div>

            {errors.num_request && (
              <TextStyles.InValidFeedback>
                {errors.num_request.message}
              </TextStyles.InValidFeedback>
            )}

            <div className="d-flex justify-content-between">
              <TextStyles.FontSize18px>
                Ownership rights
              </TextStyles.FontSize18px>
              <Styled.ArrowBox onClick={ArrowHandler}>
                {arrow ? (
                  <KeyboardArrowUpOutlinedIcon
                    sx={{
                      color: Colors.font2,
                      fontSize: 24,
                    }}
                  />
                ) : (
                  <ExpandMoreOutlinedIcon
                    sx={{ color: Colors.font2, fontSize: 20 }}
                  />
                )}
              </Styled.ArrowBox>
            </div>

            {arrow && (
              <div>
                <div className="mt-4">
                  <TextStyles.RadioButton className="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="ownership"
                      value="Made for hire"
                      checked={
                        ownershipRight === "Made for hire" ? true : false
                      }
                      name="ownership"
                      {...register("ownership")}
                      style={{ cursor: "pointer" }}
                    />
                    <label className="form-check-label" htmlFor="ownership">
                      <TextStyles.FontSize14px>
                        Made for hire (work I deliver is owned by client)
                      </TextStyles.FontSize14px>
                    </label>
                  </TextStyles.RadioButton>
                  <TextStyles.RadioButton className="form-check d-flex align-items-center mt-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="ownership"
                      value="Licensed"
                      checked={ownershipRight === "Licensed" ? true : false}
                      name="ownership"
                      {...register("ownership")}
                      style={{ cursor: "pointer" }}
                    />
                    <label className="form-check-label" htmlFor="ownership">
                      <TextStyles.FontSize14px>
                        Licensed (I license my work to the client)
                      </TextStyles.FontSize14px>
                    </label>
                  </TextStyles.RadioButton>
                  {ownershipRight === "Licensed" && (
                    <div style={{ paddingLeft: 30, maxWidth: 500 }}>
                      <TextStyles.FontSize14px className="my-2">
                        Which rights to use your work product would you like to
                        grant your client?
                      </TextStyles.FontSize14px>
                      <TextStyles.Textarea
                        invalid={errors.ownership_text}
                        rows={5}
                        placeholder="Add text here..."
                        style={{ padding: 12, fontSize: 14 }}
                        name="ownership_text"
                        {...register("ownership_text", {
                          required: "Please enter ownership text.",
                        })}
                      />
                    </div>
                  )}
                </div>
                {ownershipRight === "Licensed" && errors.ownership_text && (
                  <TextStyles.InValidFeedback>
                    {errors.ownership_text.message}
                  </TextStyles.InValidFeedback>
                )}
                <TextStyles.CheckBox className="form-check mt-3 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="retain_rights"
                    name="retain_rights"
                    {...register("retain_rights")}
                  />
                  <label className="form-check-label" htmlFor="retain_rights">
                    <TextStyles.FontSize14px>
                      Do you wish to retain the right to include work
                      attribution in your portfolio?
                    </TextStyles.FontSize14px>
                  </label>
                </TextStyles.CheckBox>
              </div>
            )}

            <div className="d-flex justify-content-between align-items-center mt-4">
              <TextStyles.FontSize18px>Fees</TextStyles.FontSize18px>
              <Styled.ArrowBox onClick={ArrowHandler2}>
                {arrow2 ? (
                  <KeyboardArrowUpOutlinedIcon
                    sx={{
                      color: Colors.font2,
                      fontSize: 20,
                    }}
                  />
                ) : (
                  <ExpandMoreOutlinedIcon
                    sx={{ color: Colors.font2, fontSize: 20 }}
                  />
                )}
              </Styled.ArrowBox>
            </div>

            {arrow2 && (
              <>
                <div className="mb-3">
                  <TextStyles.CheckBox
                    className="form-check mt-3 d-flex align-items-center"
                    style={{ display: "flex" }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="late_payment_deposit_require"
                      name="late_payment_deposit_require"
                      {...register("late_payment_deposit_require")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="late_payment_deposit_require"
                    >
                      <TextStyles.FontSize14px>
                        Late payment fee
                      </TextStyles.FontSize14px>
                    </label>
                  </TextStyles.CheckBox>
                  {latePaymentDepositRequire === true && (
                    <>
                      <div style={{ padding: "5px 30px" }}>
                        <TextStyles.RadioButton
                          className="form-check my-3 d-flex justify-content-between align-items-center"
                          style={{ maxWidth: 300, width: "100%" }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            id="late_payment"
                            value="Amount"
                            checked={latePayment === "Amount" ? true : false}
                            name="late_payment"
                            {...register("late_payment")}
                            style={{ cursor: "pointer" }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="late_payment"
                          >
                            <div className="d-flex align-items-center">
                              <TextStyles.FontSize14px htmlFor="late_payment_amount">
                                Amount
                              </TextStyles.FontSize14px>
                            </div>
                          </label>
                          <Styled.inputBox invalid={errors.late_payment_amount}>
                            <span>
                              {selectedData &&
                                selectedData.currency &&
                                selectedData.currency.symbol}
                            </span>
                            <input
                              type="text"
                              id="late_payment_amount"
                              placeholder="00.00"
                              style={{ width: 45, background: "transparent" }}
                              name="late_payment_amount"
                              {...register("late_payment_amount", {
                                required: "Please enter amount.",
                                min: {
                                  value: 1,
                                  message: "Please enter positive number.",
                                },
                                pattern: {
                                  value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                                  message: "Please enter a valid number",
                                },
                              })}
                            />
                          </Styled.inputBox>
                        </TextStyles.RadioButton>
                        {errors.late_payment_amount && (
                          <TextStyles.InValidFeedback>
                            {errors.late_payment_amount.message}
                          </TextStyles.InValidFeedback>
                        )}

                        <TextStyles.RadioButton
                          className="form-check my-3 d-flex justify-content-between align-items-center"
                          style={{ maxWidth: 300 }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            value="Percentage"
                            checked={
                              latePayment === "Percentage" ? true : false
                            }
                            id="late_payment"
                            name="late_payment"
                            {...register("late_payment")}
                            style={{ cursor: "pointer" }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="late_payment"
                          >
                            <div className="d-flex">
                              <TextStyles.FontSize14px
                                className="d-inline"
                                htmlFor="late_payment_percentage"
                              >
                                Percentage
                              </TextStyles.FontSize14px>
                            </div>
                          </label>
                          <Styled.inputBox
                            invalid={errors.late_payment_percentage}
                          >
                            <input
                              type="text"
                              id="late_payment_percentage"
                              placeholder="0.00"
                              style={{
                                width: 41,
                                textAlign: "right",
                                background: "transparent",
                              }}
                              name="late_payment_percentage"
                              {...register("late_payment_percentage", {
                                required: "Please enter percentage.",
                                min: {
                                  value: 1,
                                  message: "Please enter positive number.",
                                },
                                pattern: {
                                  value: /^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                                  message: "Please enter a valid number",
                                },
                              })}
                            />
                            <span>%</span>
                          </Styled.inputBox>
                        </TextStyles.RadioButton>
                        {errors.late_payment_percentage && (
                          <TextStyles.InValidFeedback>
                            {errors.late_payment_percentage.message}
                          </TextStyles.InValidFeedback>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div
                  className="mb-3 d-flex align-items-center justify-content-between"
                  style={{ maxWidth: 330 }}
                >
                  <TextStyles.CheckBox className="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="cancellation_fee_deposit_require"
                      name="cancellation_fee_deposit_require"
                      {...register("cancellation_fee_deposit_require")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="cancellation_fee_deposit_require"
                    >
                      <TextStyles.FontSize14px>
                        Cancellation fee
                      </TextStyles.FontSize14px>
                    </label>
                  </TextStyles.CheckBox>
                  {cancelationFeeDepositRequire === true && (
                    <Styled.inputBox invalid={errors.cancellation_fee_amount}>
                      <span>
                        {selectedData &&
                          selectedData.currency &&
                          selectedData.currency.symbol}
                      </span>
                      <input
                        type="text"
                        id="rate"
                        placeholder="00.00"
                        style={{ width: 45, background: "transparent" }}
                        name="cancellation_fee_amount"
                        {...register("cancellation_fee_amount", {
                          required: "Please enter cancellation fee.",
                          min: {
                            value: 1,
                            message: "Please enter more than zero.",
                          },
                          pattern: {
                            value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                    </Styled.inputBox>
                  )}
                </div>
                {cancelationFeeDepositRequire === true &&
                  errors.cancellation_fee_amount && (
                    <TextStyles.InValidFeedback>
                      {errors.cancellation_fee_amount.message}
                    </TextStyles.InValidFeedback>
                  )}
              </>
            )}

            <div className="d-flex justify-content-between align-items-center mt-4">
              <TextStyles.FontSize18px>Clauses</TextStyles.FontSize18px>
              <Styled.ArrowBox onClick={ArrowHandler3}>
                {arrow3 ? (
                  <KeyboardArrowUpOutlinedIcon
                    sx={{
                      color: Colors.font2,
                      fontSize: 20,
                    }}
                  />
                ) : (
                  <ExpandMoreOutlinedIcon
                    sx={{ color: Colors.font2, fontSize: 20 }}
                  />
                )}
              </Styled.ArrowBox>
            </div>
            {arrow3 ? (
              <div>
                <div className="mb-3">
                  <TextStyles.CheckBox className="form-check d-flex align-items-center mt-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="add_non_complete_clause_require"
                      name="add_non_complete_clause_require"
                      {...register("add_non_complete_clause_require")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="add_non_complete_clause_require"
                    >
                      <TextStyles.FontSize14px>
                        Add non-compete clause
                      </TextStyles.FontSize14px>
                    </label>
                  </TextStyles.CheckBox>
                  {addNonCompleteClauseRequire === true && (
                    <div style={{ paddingLeft: 30 }}>
                      <div className="mb-2">Add custom non-compete clause</div>
                      <div>
                        <ErrorOutlineOutlinedIcon
                          sx={{ color: "#ff9900", fontSize: 20 }}
                        />
                        <span className="ml-2">
                          We strongly advise freelancers against signing
                          non-compete agreements that restrict their ability to
                          pursue or access work opportunities during and after
                          the agreement's term. If a hiring party insists on a
                          non-compete, it is recommended to seek legal counsel
                          from an attorney.
                        </span>
                      </div>
                      <div className="my-2">
                        <TextStyles.Textarea
                          invalid={errors.add_non_complete_clause_text}
                          rows={5}
                          placeholder="Add text here..."
                          style={{ padding: 12, fontSize: 14 }}
                          name="add_non_complete_clause_text"
                          {...register("add_non_complete_clause_text", {
                            required: "Please add non complete clouse.",
                          })}
                        />
                      </div>
                    </div>
                  )}
                  {addNonCompleteClauseRequire === true &&
                    errors.add_non_complete_clause_text && (
                      <TextStyles.InValidFeedback>
                        {errors.add_non_complete_clause_text.message}
                      </TextStyles.InValidFeedback>
                    )}
                </div>
                <div className="mb-3">
                  <TextStyles.CheckBox
                    className="form-check mt-3"
                    style={{ display: "flex" }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="add_sexual_harassment_clause_require"
                      name="add_sexual_harassment_clause_require"
                      {...register("add_sexual_harassment_clause_require")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="add_sexual_harassment_clause_require"
                    >
                      <div className="d-flex">
                        <TextStyles.FontSize14px>
                          Add sexual harassment clause
                        </TextStyles.FontSize14px>
                      </div>
                    </label>
                  </TextStyles.CheckBox>
                </div>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button disabled={isLoading ? true : false}>
                {isLoading ? <LoaderSpin /> : "Save"}
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ContactSettings;
