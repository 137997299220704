import React from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import LoaderSpin from "../../../../Commons/LoaderSpin";

import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../../../Constants/TextStyles";

import { createAddSectionTextPC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";

const AddSectionTextModal = ({ show, handleClose, selectedData, position }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  const formsubmit = (data) => {
    const Obj = {
      pc_id: selectedData._id,
      text_title: data.text_title,
      textarea: data.textarea,
      position: position,
    };
    // console.log(Obj);
    dispatch(createAddSectionTextPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "createTextSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "createTextFail" }
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        contentClassName="ToMakeModalBorderRudius12px"
      >
        <div className="modal-header" style={{ borderBottom: "0px" }}>
          <TextStyles.FontSize20px className="d-flex justify-content-start">
            Add text
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(formsubmit)}>
            <TextStyles.FontSize14px className="mb-2">
              <label htmlFor="text_title" style={{ display: "flex" }}>
                Title
              </label>
              <TextStyles.InputRectangle
                id="text_title"
                invalid={errors.text_title}
                type="text"
                placeholder="Section title (e.g, Overview and goals )"
                name="text_title"
                {...register("text_title", {
                  required: "Text title can not be empty.",
                })}
              />
              {errors.text_title && (
                <TextStyles.InValidFeedback>
                  {errors.text_title.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="mb-3">
              <TextStyles.Textarea
                invalid={errors.textarea}
                rows="8"
                id="textarea"
                placeholder="Add text here..."
                name="textarea"
                {...register("textarea", {
                  required: "Textarea can not be empty.",
                })}
              />
              {errors.textarea && (
                <TextStyles.InValidFeedback>
                  {errors.textarea.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <div className="d-flex gap-3 justify-content-between align-items-center">
              <TextStyles.CancelButton onClick={handleClose}>
                <div className="d-flex justify-content-center">Cancel</div>
              </TextStyles.CancelButton>
              <TextStyles.Button disabled={isLoading ? true : false}>
                {isLoading ? <LoaderSpin /> : "Save"}
              </TextStyles.Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddSectionTextModal;
