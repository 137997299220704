import React from "react";
import styles from "./Features.module.css";
import { NavLink } from "react-router-dom";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import AgreementFeature1 from "../../../../Assets/frontendImages/AgreementFeature1.avif";
import AgreementFeature2 from "../../../../Assets/frontendImages/AgreementFeature2.avif";
import AgreementFeature3 from "../../../../Assets/frontendImages/AgreementFeature3.avif";
import AgreementFeature4 from "../../../../Assets/frontendImages/AgreementFeature4.avif";

const Features = () => {
  return (
    <div className={`${styles.section}`}>
      <div className="container-fluid py-3 py-md-4" style={{ maxWidth: 1100 }}>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100">
            <div
              data-aos={"fade-right"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={AgreementFeature1}
                alt="AgreementFeature1"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="text-start mt-3 mt-md-0 w-100">
            <div className="w-100" data-aos={"fade-left"}>
              <div className={`${styles.heading} mb-3`}>
                Top-tier proposals at your fingertips
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Create and send compelling custom proposals effortlessly using
                Zodot’s intuitive online workspace. Impress potential clients
                with personalized, professional proposals that embody your
                brand. Get paid instantly upon acceptance.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Win clients-join Zodot
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="text-start mt-3 mt-md-0 w-100 order-1 order-md-0">
            <div className="w-100" data-aos={"fade-right"}>
              <div className={`${styles.heading} mb-3`}>
                Effortless contract creation
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Seal the deal seamlessly with Zodot’s contract generation
                feature. Create, and tailor contracts to your specific needs and
                ensure clarity in your custom contracts. Simplify the contract
                process for both you and your clients. Edit and add clauses as
                per your choice.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Take control of contract management
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>
                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 order-0 order-md-1">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={AgreementFeature2}
                alt="AgreementFeature2"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100">
            <div
              data-aos={"fade-right"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={AgreementFeature3}
                alt="AgreementFeature3"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="text-start mt-3 mt-md-0 w-100">
            <div className="w-100" data-aos={"fade-left"}>
              <div className={`${styles.heading} mb-3`}>
                Create proposals and contracts together
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Zodot integrates the capabilities of crafting both proposals and
                contracts concurrently, providing a unified solution. Manage
                your entire client engagement lifecycle efficiently within a
                single platform.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Optimize client engagement
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center"
          style={{ overflow: "hidden" }}
        >
          <div className="text-start mt-3 mt-md-0 w-100 order-1 order-md-0">
            <div className="w-100" data-aos={"fade-right"}>
              <div className={`${styles.heading} mb-3`}>
                Unlimited agreements creation
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Generate unlimited proposals, contracts, or a combination of
                both using Zodot. Enjoy the flexibility to scale your business
                without limitations, streamlining your workflow for increased
                productivity.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Experience limitless possibilities
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>
                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 order-0 order-md-1">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={AgreementFeature4}
                alt="AgreementFeature4"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
