import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProjects,
  fetchProjectForOptions,
  fetchProjectsForAClient,
  createProject,
  fetchProjectById,
  changeProjectStatus,
  updateProject,
  uProjectStartDate,
  uProjectEndDate,
  uProjectBillingScheduleStartDate,
  updateProjectNotes,
  fetchProjectCounts,
} from "../service/projectService";

const projectState = {
  projects: null,
  projectOptionsData: null,
  prTotalCount: null,
  projectsForAClient: null,
  selectedProject: null,
  isLoading: false,
  error: null,
};

export const projectSlice = createSlice({
  name: "projects",
  initialState: projectState,
  reducers: {
    setSelectedProject: (state, { payload }) => {
      state.selectedProject = payload;
    },
  },
  extraReducers: {
    // Todo: fetch all projects
    [fetchProjects.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchProjects.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.projects = action.payload.data.data;
      state.prTotalCount = action.payload.data.prTotalCount;
    },
    [fetchProjects.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Todo: fetch all projects for option
    [fetchProjectForOptions.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchProjectForOptions.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.projectOptionsData = action.payload.data;
    },
    [fetchProjectForOptions.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Todo: fetch all projects for  a client
    [fetchProjectsForAClient.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchProjectsForAClient.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.projectsForAClient = action.payload.data;
    },
    [fetchProjectsForAClient.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Todo: create a project
    [createProject.pending]: (state) => {
      state.isLoading = true;
    },
    [createProject.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createProject.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Todo: fetch Project by id
    [fetchProjectById.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchProjectById.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selectedProject = action.payload.data;
    },
    [fetchProjectById.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Todo: delete a project
    [changeProjectStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [changeProjectStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [changeProjectStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Todo: update whole project
    [updateProject.pending]: (state) => {
      state.isLoading = true;
    },
    [updateProject.fulfilled]: (state, action) => {
      state.selectedProject = action.payload.data;
      state.isLoading = false;
    },
    [updateProject.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Todo: update project start date
    [uProjectStartDate.pending]: (state) => {
      state.isLoading = true;
    },
    [uProjectStartDate.fulfilled]: (state, action) => {
      state.selectedProject.project_start_date =
        action.payload.data.project_start_date;
      state.isLoading = false;
    },
    [uProjectStartDate.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Todo: update project end date
    [uProjectEndDate.pending]: (state) => {
      state.isLoading = true;
    },
    [uProjectEndDate.fulfilled]: (state, action) => {
      state.selectedProject.project_end_date =
        action.payload.data.project_end_date;
      state.isLoading = false;
    },
    [uProjectEndDate.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Todo: update project billing schedule start date
    [uProjectBillingScheduleStartDate.pending]: (state) => {
      state.isLoading = true;
    },
    [uProjectBillingScheduleStartDate.fulfilled]: (state, action) => {
      state.selectedProject.billing_scheduled =
        action.payload.data.billing_scheduled;
      state.isLoading = false;
    },
    [uProjectBillingScheduleStartDate.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // Update projects Notes
    [updateProjectNotes.pending]: (state) => {
      state.isLoading = true;
    },
    [updateProjectNotes.fulfilled]: (state, action) => {
      state.selectedProject.notes = action.payload.data.notes;
      state.isLoading = false;
    },
    [updateProjectNotes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch project counts
    [fetchProjectCounts.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchProjectCounts.fulfilled]: (state, action) => {
      state.prTotalCount = action.payload.data.prTotalCount;
      state.isLoading = false;
    },
    [fetchProjectCounts.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});
export const projectsActions = projectSlice.actions;

export default projectSlice.reducer;
