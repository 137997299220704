import React from "react";
import { Modal } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";
import Colors from "../../../Constants/Colors";
import styles from "./SuccessModal.module.css";
import successImage from "../../../Assets/frontendImages/success_icon.svg";

const SuccessModal = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      centered
    >
      <Modal.Body style={{ padding: "24px 20px" }}>
        <div className="d-flex justify-content-end">
          <ClearIcon
            sx={{
              fontSize: 35,
              color: Colors.frontendFont1,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </div>

        <div className="d-flex justify-content-center mb-4">
          <img src={successImage} alt="successImage" />
        </div>

        <div className={`${styles.heading} text-center mb-4`}>
          Message sent!
        </div>

        <div className={`${styles.text} text-center mb-3`}>
          Thank you! Our team will be in touch via email shortly.
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
