import { getRequest, postRequest, putRequest } from "../../axios/axios";
import URL from "../../axios/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchProjects = createAsyncThunk(
  "project/fetchProjects",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.FETCH_PROJECT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const fetchProjectForOptions = createAsyncThunk(
  "project/fetchProjectsForOptions",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_PROJECT.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const fetchProjectsForAClient = createAsyncThunk(
  "project/fetchProjectsForAClient",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_PROJECTS_FOR_A_CLIENT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const createProject = createAsyncThunk(
  "project/createProject",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_PROJECT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const fetchProjectById = createAsyncThunk(
  "project/fetchProjectById",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_PROJECT_BY_ID.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const updateProject = createAsyncThunk(
  "project/update_compelete_project",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_PROJECT_DETAILS.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const changeProjectStatus = createAsyncThunk(
  "project/changeProjectStatus",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.CHANGE_PROJECT_STATUS.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const uProjectStartDate = createAsyncThunk(
  "project/change_start_date",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_PROJECT_START_DATE.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const uProjectEndDate = createAsyncThunk(
  "project/change_end_date",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_PROJECT_END_DATE.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const uProjectBillingScheduleStartDate = createAsyncThunk(
  "project/change_bi_sc_first_date",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_PROJECT_BI_SC_START_DATE.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const updateProjectNotes = createAsyncThunk(
  "projects/update_Project_notes",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("projects/update_project_notes", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

const fetchProjectCounts = createAsyncThunk(
  "project/fetchProjectCount",
  async () => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      getRequest("/projects/project_counts", token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export {
  fetchProjects,
  fetchProjectForOptions,
  fetchProjectsForAClient,
  createProject,
  fetchProjectById,
  changeProjectStatus,
  updateProject,
  uProjectStartDate,
  uProjectEndDate,
  uProjectBillingScheduleStartDate,
  updateProjectNotes,
  fetchProjectCounts,
};
