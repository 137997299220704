import { postRequest, putRequest, deleteRequest } from "../../axios/axios";
import URL from "../../axios/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createQuickTask = createAsyncThunk(
  "home/createQuickTask",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_QUICK_TASK.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) reject(err.response.data.message);
          else reject(err);
        });
    });
  }
);

export const createTask = createAsyncThunk("home/createTask", async (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    postRequest(URL.CREATE_TASK.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) reject(err.response.data.message);
        else reject(err);
      });
  });
});

export const fetchTasks = createAsyncThunk("home/fetchTasks", async (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    postRequest(URL.FETCH_TASKS.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) reject(err.response.data.message);
        else reject(err);
      });
  });
});

export const editTask = createAsyncThunk("home/editTask", async (data) => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    postRequest(URL.EDIT_TASK.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) reject(err.response.data.message);
        else reject(err);
      });
  });
});

export const editTaskDueDate = createAsyncThunk(
  "home/editTaskDueDate",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.EDIT_TASK_DUE_DATE.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) reject(err.response.data.message);
          else reject(err);
        });
    });
  }
);

export const deleteTask = createAsyncThunk("home/deleteTask", async (data) => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    deleteRequest(URL.DELETE_TASK.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) reject(err.response.data.message);
        else reject(err);
      });
  });
});

export const updateTaskStatus = createAsyncThunk(
  "home/updateTaskStatus",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_TASK_STATUS.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) reject(err.response.data.message);
          else reject(err);
        });
    });
  }
);
