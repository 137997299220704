import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const ContractPagesModal = styled.div`
  min-width: 36.25rem;
  max-width: 36.25rem;
  position: fixed;
  margin: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const SliderBox = styled.div`
  border: 2px solid ${Colors.primary};
  background-color: ${Colors.white};
`;

const SelectedItem = styled.div`
  padding: 1rem 2.125rem;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: ${Colors.primary};
  color: ${Colors.white};
`;

const NotSelectedItem = styled(SelectedItem)`
  color: ${Colors.font2} !important;
  background-color: ${Colors.white} !important;
`;

// const inputBox = styled.div`
//   border-radius: 12px;
//   padding: 1rem;
//   margin-right: 3px;
//   background: ${(props) =>
//     props.invalid ? Colors.inputErrorBg : Colors.white};
//   height: 3.437rem;
//   border: 1px solid ${(props) => (props.invalid ? Colors.red : Colors.font2)};
// `;

const inputBox = styled.div`
  border-radius: 0.75rem;
  height: 3.15rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border: 1px solid
    ${(props) => (props.invalid ? Colors.red : Colors.borderInput)};
  background-color: ${(props) =>
    props.invalid ? Colors.inputErrorBg : Colors.white};

  &:hover {
    border: 1px solid ${Colors.primary};
  }
`;

export default {
  ContractPagesModal,
  SliderBox,
  SelectedItem,
  NotSelectedItem,
  inputBox,
};
