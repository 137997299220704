import React, { useState } from "react";
import { useSelector } from "react-redux";

import EditServices from "../../ProposalReviews/Services/EditServices";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "../PageOne/PageOne";
import { ReactTooltipWithIcon } from "../../../Commons/ReactTooltip";

const Services = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <TextStyles.FontSize20px className="mb-3">
        Services summary
      </TextStyles.FontSize20px>
      {selectedData &&
        selectedData.service_list &&
        selectedData.service_list.map((item, index) => {
          return (
            <Styled.EditServiceContainer key={index}>
              <ReactTooltipWithIcon
                anchorId={`edit_services${index}`}
                content="Edit services"
                variant="grey"
                place="right"
              />
              <div
                onClick={() => setShowModal(true)}
                className="d-flex justify-content-between"
                style={{ cursor: "pointer" }}
                id={`edit_services${index}`}
              >
                <div>
                  <TextStyles.FontSize16px
                    className="d-flex mb-2"
                    style={{ minWidth: 100 }}
                  >
                    <span className="three-dots"> {item.service_name}</span>
                  </TextStyles.FontSize16px>
                  <TextStyles.GreyFont14px
                    className="d-flex mb-2"
                    style={{ minWidth: 100 }}
                  >
                    <span className="three-dots">
                      {selectedData &&
                        selectedData.currency &&
                        selectedData.currency.symbol}
                      {item.service_rate
                        ? parseFloat(item.service_rate).toFixed(2)
                        : "00.00"}
                      {item.service_rate_type !== "Flat fee"
                        ? ` ${item.service_rate_type.toLocaleLowerCase()}`
                        : ` ${item.service_rate_type.toLocaleLowerCase()}`}
                    </span>
                  </TextStyles.GreyFont14px>
                  <TextStyles.GreyFont14px
                    className="d-flex mb-2"
                    style={{ maxWidth: 500 }}
                  >
                    {item.desc}
                  </TextStyles.GreyFont14px>
                </div>
              </div>
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "1px",
                  border: "0.5px solid #edeef2",
                }}
              />
            </Styled.EditServiceContainer>
          );
        })}
      {showModal && (
        <EditServices
          show={showModal}
          handleClose={() => setShowModal(false)}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default Services;
