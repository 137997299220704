import React from "react";
import Slider from "react-slick";
import "./Carousel.css";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import testimonialImg1 from "../../../../Assets/frontendImages/HomepageTestimonial1.webp";
import testimonialImg2 from "../../../../Assets/frontendImages/HomepageTestimonial2.webp";
import testimonialImg3 from "../../../../Assets/frontendImages/HomepageTestimonial3.webp";
import testimonialImg4 from "../../../../Assets/frontendImages/HomepageTestimonial4.webp";
import testimonialImg5 from "../../../../Assets/frontendImages/HomepageTestimonial5.webp";
import testimonialImg6 from "../../../../Assets/frontendImages/HomepageTestimonial6.webp";
import quotationMark from "../../../../Assets/frontendImages/quotation.svg";

const Carousel = () => {
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="next_arrow" onClick={onClick}>
        <ArrowCircleRightRoundedIcon className="button" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;

    return (
      <div className="prev_arrow" onClick={onClick}>
        <ArrowCircleLeftRoundedIcon className="button" />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    adaptiveHeight: true,
    className: "homePageTestinomialCarousel",
    dotsClass: "homePageTestinomialCarouselDots",
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: function () {
      return <button aria-label="cuttom-button" className="custom-dot" />;
    },
  };
  return (
    <Slider {...settings}>
      <div className="testi_content_box_one position-relative">
        <div className="d-flex text-start mb-3">
          <img src={quotationMark} alt="quotationImg" />
        </div>

        <div className="d-flex gap-5 justify-content-between w-100">
          <div className="d-flex text-start testinomial-content">
            Zodot software makes managing tasks super easy! Whether it's
            organizing your to-dos, keeping projects on track, or staying on top
            of your time, Zodot has got you covered. Plus, creating custom
            proposals and sending invoices online is a breeze with Zodot by your
            side!
          </div>
          <div className="testi_pics_box">
            <img
              src={testimonialImg1}
              alt="homePage_testinonials_img"
              style={{ width: "100%", height: "auto", objectFit: "fill" }}
            />
          </div>
        </div>
      </div>
      <div className="testi_content_box_one position-relative">
        <div className="d-flex text-start mb-3">
          <img src={quotationMark} alt="quotationImg" />
        </div>

        <div className="d-flex gap-5 justify-content-between w-100">
          <div className="d-flex text-start testinomial-content">
            Zodot’s project management capability is amazing! It's easy to
            organize tasks and timelines for multiple projects. Making changes
            is a snap, and it keeps clients in the loop. Having everything in
            one place is super convenient. For efficient project management,
            Zodot is the way to go!
          </div>
          <div className="testi_pics_box">
            <img
              src={testimonialImg2}
              alt="homePage_testinonials_img"
              style={{ width: "100%", height: "auto", objectFit: "fill" }}
            />
          </div>
        </div>
      </div>
      <div className="testi_content_box_one position-relative">
        <div className="d-flex text-start mb-3">
          <img src={quotationMark} alt="quotationImg" />
        </div>

        <div className="d-flex gap-5 justify-content-between w-100">
          <div className="d-flex text-start testinomial-content">
            Zodot has made invoicing so easy for my small business! I can
            quickly make invoices without stress. I can customize them easily
            and keep my financial info safe. I can send invoices from anywhere
            and get notified when they're seen or paid. If you need help with
            invoicing, definitely try Zodot!
          </div>
          <div className="testi_pics_box">
            <img
              src={testimonialImg3}
              alt="homePage_testinonials_img"
              style={{ width: "100%", height: "auto", objectFit: "fill" }}
            />
          </div>
        </div>
      </div>
      <div className="testi_content_box_one position-relative">
        <div className="d-flex text-start mb-3">
          <img src={quotationMark} alt="quotationImg" />
        </div>

        <div className="d-flex gap-5 justify-content-between w-100">
          <div className="d-flex text-start testinomial-content">
            Zodot has transformed how we operate! From project management to
            team collaboration, it's streamlined everything. The intuitive
            interface saves time, and the personalized support is unbeatable.
            Thanks to Zodot, my entrepreneurial business is thriving! Highly
            recommend it!
          </div>
          <div className="testi_pics_box">
            <img
              src={testimonialImg4}
              alt="homePage_testinonials_img"
              style={{ width: "100%", height: "auto", objectFit: "fill" }}
            />
          </div>
        </div>
      </div>
      <div className="testi_content_box_one position-relative">
        <div className="d-flex text-start mb-3">
          <img src={quotationMark} alt="quotationImg" />
        </div>

        <div className="d-flex gap-5 justify-content-between w-100">
          <div className="d-flex text-start testinomial-content">
            Zodot is like my personal assistant for getting things done. It
            helps me keep track of projects, money, and profits. I can see where
            my money is coming from and going. It also helps me stay connected
            with clients. With Zodot, I can focus on making my business grow as
            a solopreneur.
          </div>
          <div className="testi_pics_box">
            <img
              src={testimonialImg5}
              alt="homePage_testinonials_img"
              style={{ width: "100%", height: "auto", objectFit: "fill" }}
            />
          </div>
        </div>
      </div>

      <div className="testi_content_box_one position-relative">
        <div className="d-flex text-start mb-3">
          <img src={quotationMark} alt="quotationImg" />
        </div>

        <div className="d-flex gap-5 justify-content-between w-100">
          <div className="d-flex text-start testinomial-content">
            Zodot is like a helpful buddy for freelancers! It makes my work
            easier, saves time, and helps me get more done. One of the best
            things about Zodot is its income statement reporting feature,
            showing my business's financial status. I can check earnings before
            taxes with Zodot easily.
          </div>
          <div className="testi_pics_box">
            <img
              src={testimonialImg6}
              alt="homePage_testinonials_img"
              style={{ width: "100%", height: "auto", objectFit: "fill" }}
            />
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default Carousel;
