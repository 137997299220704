import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./LandingPage.module.css";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import Carousel from "./Carousel/Carousel";
import Divider from "@mui/material/Divider";
import Colors from "../../../Constants/Colors";

function LandingPage() {
  return (
    <div className={styles.section}>
      <div className="container-fluid py-4 py-md-5">
        <div
          className={`${styles.heading} text-center mb-4`}
          data-aos={"fade-up"}
        >
          Voice of our clients
        </div>
        <div
          className={`${styles.heading_text} text-center mb-5`}
          data-aos={"fade-up"}
        >
          See what our customers say about Zodot.
        </div>
        <div
          className="d-flex justify-content-center mb-5"
          data-aos={"fade-up"}
        >
          <NavLink
            className={styles.more_button}
            style={{ textDecoration: "none" }}
            to={"/db/signup"}
          >
            <span>Start now</span>
            <span className={`${styles.farword_arrow}`}>
              {" "}
              <EastOutlinedIcon sx={{ fontSize: 24 }} />
            </span>
          </NavLink>
        </div>

        <div
          data-aos={"fade-up"}
          className={`${styles.text_font2}`}
          style={{ maxWidth: 500, marginLeft: "auto", marginRight: "auto" }}
        >
          <Divider
            sx={{
              "&.MuiDivider-root::before": {
                borderTop: `2px solid ${Colors.frontendFont2}`,
              },
              "&.MuiDivider-root::after": {
                borderTop: `2px solid ${Colors.frontendFont2}`,
              },
            }}
          >
            Access all features – 100% free!
          </Divider>
        </div>
        <div style={{ overflow: "hidden", marginTop: 20 }} data-aos={"fade-up"}>
          <Carousel />
        </div>
      </div>
    </div>
  );
}

export default React.memo(LandingPage);
