import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    borderRadius: `${sizes.formInputBorderRadius} !important`,
    height: `${sizes.formInputHeight} !important`,
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",

    "& .MuiOutlinedInput-input": {
      padding: "0.947rem 1rem",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },

    "&.Mui-focused": {
      border: `1px solid ${Colors.primary} !important`,
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
  },

  listMenu: {
    color: "grey",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const SelectServiceTypeOptions = [
  { label: "Flat fee", value: "Flat fee" },
  { label: "Per hour", value: "Per hour" },
  { label: "Per day", value: "Per day" },
  { label: "Per item", value: "Per item" },
  { label: "Per word", value: "Per word" },
];

export default function SelectServiceType(props) {
  const { onChange, defaultValue } = props;
  const value = defaultValue;
  const classes = useStyles();

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        defaultValue={defaultValue ? value : "Flat fee"}
        variant="outlined"
        onChange={onChange}
        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
      >
        {SelectServiceTypeOptions &&
          SelectServiceTypeOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              classes={{
                root: classes.listMenu,
              }}
            >
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
