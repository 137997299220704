import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    fontWeight: "400 !important",
    borderRadius: "6px !important",
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
    padding: "12px 32px 12px 12px !important",
  },

  listMenu: {
    color: Colors.font1,
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

export default function PcDocumentType(props) {
  const classes = useStyles();

  const pcDoctTypeOptions = [
    { label: "All documents", value: "All documents" },
    {
      label: "Proposals",
      value: "Proposal",
    },
    {
      label: "Contracts",
      value: "Contract",
    },
    { label: "Proposal and contracts", value: "Proposal and contract" },
  ];

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        defaultValue={props.defaultValue ? props.defaultValue : "All documents"}
        id="grouped-select"
        label="Grouping"
        onChange={props.onChange}
        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
      >
        {pcDoctTypeOptions &&
          pcDoctTypeOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              classes={{
                root: classes.listMenu,
              }}
            >
              {option.label}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
