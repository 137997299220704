import React from "react";
import PaymentTerms from "./Components/PaymentTerms.jsx";
import LatePayment from "./Components/LatePayment.jsx";
import Deposit from "./Components/Deposit.jsx";
import ExpenseReimbursement from "./Components/ExpenseReimbursement.jsx";
import Feedback from "./Components/Feedback.jsx";
import Changes from "./Components/Changes.jsx";
import LiabilityLimitaion from "./Components/LiabilityLimitation.jsx";
import CancellationFee from "./Components/CancellationFee";

const PageFive = () => {
  return (
    <div>
      <CancellationFee />
      <PaymentTerms />
      <LatePayment />
      <Deposit />
      <ExpenseReimbursement />
      <Feedback />
      <Changes />
      <LiabilityLimitaion />
    </div>
  );
};

export default PageFive;
