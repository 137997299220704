import React from "react";
import { Redirect } from "react-router";

export default function LaunchToStatements() {
  return (
    <div>
      <Redirect to="/db/statements/incomes" />
    </div>
  );
}
