import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

import { updateUserPassword } from "../../../../../store/service/AuthService";

import ClearIcon from "@mui/icons-material/Clear";

import LoaderSpin from "../../../Commons/LoaderSpin";

import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";

const PasswordChange = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const formsubmit = async (res) => {
    const Obj = {
      oldPassword: res.old_password,
      newPassword: res.new_password,
      confirmNewPassword: res.confirm_new_password,
    };
    dispatch(updateUserPassword(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        reset();
        handleClose();

        toast.success(
          <TextStyles.FontSize14px>
            Password updated successfully!
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "fail" }
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      centered
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <div className="modal-header">
          <TextStyles.FontSize20px>Change password</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </div>
        <div className="modal-body">
          <TextStyles.FontSize14px>
            <label
              htmlFor="old_password"
              className="d-flex justify-content-start"
            >
              Current password
            </label>

            <TextStyles.InputRectangle
              invalid={errors.old_password}
              type="password"
              name="old_password"
              id="old_password"
              placeholder="Current password"
              {...register("old_password", {
                required: "Password can not be empty.",
                pattern: {
                  value:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/i,
                  message:
                    "Please enter minimum six characters, at least one letter, one number and one special character",
                },
              })}
            />
            {errors.old_password && (
              <TextStyles.InValidFeedback>
                {errors.old_password.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label
              htmlFor="new_password"
              className="d-flex justify-content-start"
            >
              New password
            </label>

            <TextStyles.InputRectangle
              invalid={errors.new_password}
              type="password"
              name="new_password"
              id="new_password"
              placeholder="New password"
              {...register("new_password", {
                required: "New password can not be empty.",
                pattern: {
                  value:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/i,
                  message:
                    "Please enter minimum six characters, at least one letter, one number and one special character",
                },
              })}
            />
            {errors.new_password && (
              <TextStyles.InValidFeedback>
                {errors.new_password.message}
              </TextStyles.InValidFeedback>
            )}
            {!errors.new_password || !errors.new_password.message ? (
              <div style={{ textAlign: "start" }}>
                Your password must be atleast six characters and should include
                a combination of numbers, letters and special characters (!$@%)
              </div>
            ) : (
              ""
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label
              htmlFor="confirm_new_password"
              className="d-flex justify-content-start"
            >
              Retype new password
            </label>

            <TextStyles.InputRectangle
              invalid={errors.confirm_new_password}
              type="password"
              name="confirm_new_password"
              id="confirm_new_password"
              placeholder="Retype new password"
              {...register("confirm_new_password", {
                required: "Confirm password can not be empty.",
                pattern: {
                  value:
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/i,
                  message:
                    "Please enter minimum six characters, at least one letter, one number and one special character",
                },
              })}
            />
            {errors.confirm_new_password && (
              <TextStyles.InValidFeedback>
                {errors.confirm_new_password.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.Button
            className="mt-2"
            type="submit"
            disabled={isLoading ? true : false}
            style={{
              opacity: isLoading ? 0.5 : 1,
            }}
          >
            {isLoading ? (
              <>
                <LoaderSpin />
              </>
            ) : (
              "Change password"
            )}
          </TextStyles.Button>
        </div>
      </form>
    </Modal>
  );
};

export default PasswordChange;
