import React from "react";
import { makeStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Colors from "../../../Constants/Colors";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.font2,
    },
  },
});

const useStyles = makeStyles({
  root: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 500,
    "& .MuiPagination-ul": {
      "& > li": {
        "& button": {
          border: "2px solid #cbcccf",
          "&:hover": {
            backgroundColor: Colors.backgroundPink,
          },
        },
        "& .Mui-selected": {
          border: `2px solid ${Colors.primary}`,
        },
      },
    },
  },
});

const PaginationUI = ({ handlePageClick, pageCount, page }) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Pagination
        className={classes.root}
        count={pageCount}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageClick}
        page={page}
      />
    </ThemeProvider>
  );
};

export default PaginationUI;
