import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import WarningIcon from "@mui/icons-material/Warning";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import LoaderSpin from "../../../Commons/LoaderSpin";

import TextStyles from "../../../../../Constants/TextStyles";

import { Modal } from "react-bootstrap";

import Colors from "../../../../../Constants/Colors";
import { deleteUserAccount } from "../../../../../store/service/deleteUserAccountService";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { authActions } from "../../../../../store/storage/authSlice";
import { trim } from "validator";

const DeleteAccount = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(true);

  const clientsTotalCount = useSelector(
    (state) => state.clients.clientsTotalCount
  );
  const prTotalCount = useSelector((state) => state.project.prTotalCount);
  const invoiceTotalCount = useSelector(
    (state) => state.invoices.invoiceTotalCount
  );

  const isLoading = useSelector((state) => state.deleteUserAccount.isLoading);

  const totalPcCount = useSelector(
    (state) => state.proposalAndContract.totalPcCount
  );
  const trTotalCount = useSelector((state) => state.transaction.trTotalCount);
  const totalTtReportCount = useSelector(
    (state) => state.timeTracking.totalTtReportCount
  );

  const data = useSelector((state) => state.auth.data);

  const [emailValidMessage, setEmailValidMessage] = useState("");

  const confirmationHandler = (e) => {
    const enteredEmail = trim(e.target.value);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailRegex.test(enteredEmail);
    if (!isValid) {
      setError(true);
      setEmailValidMessage("Please enter valid email address.");
    } else {
      if (enteredEmail === data.email) {
        setError(false);
      } else {
        setError(true);
        setEmailValidMessage("Provided email address does not match.");
      }
    }
  };

  const deleteAccountHandler = () => {
    dispatch(deleteUserAccount()).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        history.push("/");
        handleClose();
        // localStorage.clear();
        // localStorage.setItem("logout", Date.now().toString());
        dispatch(authActions.logout());
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName="ToMakeModalBorderRudius12px"
    >
      <div className="modal-header" style={{ borderBottom: "0px" }}>
        <TextStyles.FontSize20px className="d-flex justify-content-start align-items-center">
          <WarningIcon sx={{ fontSize: 30, color: Colors.red }} />{" "}
          <span
            className="ml-1"
            style={{ color: Colors.red, fontWeight: 500, fontSize: 24 }}
          >
            Warning
          </span>
        </TextStyles.FontSize20px>
        <ClearIcon
          sx={{
            color: Colors.font2,
            cursor: "pointer",
            opacity: 0.7,
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={handleClose}
        />
      </div>
      <div className="modal-body">
        {(clientsTotalCount > 0 ||
          prTotalCount > 0 ||
          invoiceTotalCount > 0 ||
          totalPcCount > 0 ||
          trTotalCount > 0 ||
          totalTtReportCount > 0) && (
          <TextStyles.GreyFont16px className="mb-2">
            You will no longer have access to the following data:
          </TextStyles.GreyFont16px>
        )}

        {clientsTotalCount > 0 && (
          <TextStyles.FontSize14px className="d-flex align-items-center mb-1">
            <DoneOutlinedIcon sx={{ color: Colors.red, fontSize: 20 }} />
            <span className="ml-1">
              {clientsTotalCount > 1
                ? `${clientsTotalCount} Clients`
                : `${clientsTotalCount} Client`}
            </span>
          </TextStyles.FontSize14px>
        )}
        {prTotalCount > 0 && (
          <TextStyles.FontSize14px className="d-flex align-items-center mb-1">
            <DoneOutlinedIcon sx={{ color: Colors.red, fontSize: 20 }} />
            <span className="ml-1">
              {prTotalCount > 1
                ? `${prTotalCount} Projects`
                : `${prTotalCount} Project`}
            </span>
          </TextStyles.FontSize14px>
        )}
        {invoiceTotalCount > 0 && (
          <TextStyles.FontSize14px className="d-flex align-items-center mb-1">
            <DoneOutlinedIcon sx={{ color: Colors.red, fontSize: 20 }} />
            <span className="ml-1">
              {invoiceTotalCount > 1
                ? `${invoiceTotalCount} Invoices`
                : `${invoiceTotalCount} Invoice`}
            </span>
          </TextStyles.FontSize14px>
        )}
        {totalPcCount > 0 && (
          <TextStyles.FontSize14px className="d-flex align-items-center mb-1">
            <DoneOutlinedIcon sx={{ color: Colors.red, fontSize: 20 }} />
            <span className="ml-1">
              {totalPcCount > 1
                ? `${totalPcCount} Agreements`
                : `${totalPcCount} Agreement`}
            </span>
          </TextStyles.FontSize14px>
        )}
        {trTotalCount > 0 && (
          <TextStyles.FontSize14px className="d-flex align-items-center mb-1">
            <DoneOutlinedIcon sx={{ color: Colors.red, fontSize: 20 }} />
            <span className="ml-1">
              {trTotalCount > 1
                ? `${trTotalCount} Transactions`
                : `${trTotalCount} Transaction`}
            </span>
          </TextStyles.FontSize14px>
        )}
        {totalTtReportCount > 0 && (
          <TextStyles.FontSize14px className="d-flex align-items-center mb-1">
            <DoneOutlinedIcon sx={{ color: Colors.red, fontSize: 20 }} />
            <span className="ml-1">
              {totalTtReportCount > 1
                ? `${totalTtReportCount} Time tracking activities`
                : `${totalTtReportCount} Time tracking activity`}
            </span>
          </TextStyles.FontSize14px>
        )}
        <TextStyles.GreyFont16px className="my-2">
          Please confirm you understand:
        </TextStyles.GreyFont16px>
        <TextStyles.CheckBox
          className="form-check"
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            id="flexCheckChecked"
            onClick={() => {
              setConfirm(!confirm);
              setError(true);
            }}
          />
          <label
            className="form-check-label"
            htmlFor="flexCheckChecked"
            style={{ fontSize: 14 }}
          >
            You will lose access to all projects, clients, invoices, etc.
          </label>
        </TextStyles.CheckBox>
        {confirm && (
          <TextStyles.FontSize14px className="mb-3">
            <TextStyles.InputRectangle
              inValid={error}
              type="text"
              placeholder="Type your email"
              name="email"
              onChange={confirmationHandler}
            />
            {error && (
              <TextStyles.InValidFeedback>
                {emailValidMessage}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        )}
        <div className="py-3">
          <div className="row">
            <div className="col">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
            </div>
            <div className="col">
              <TextStyles.Button
                backgroundColor={Colors.red}
                disabled={error ? true : false}
                style={{
                  opacity: error ? 0.5 : 1,
                }}
                onClick={deleteAccountHandler}
              >
                {isLoading ? <LoaderSpin /> : "Delete account"}
              </TextStyles.Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccount;
