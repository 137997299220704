import styled from "styled-components";
import Colors from "../../../../../../Constants/Colors";

const TextBoader = styled.div`
  width: 100%;
  min-height: 3.15rem;
  overflow-wrap: anywhere;
  border-radius: 0.75rem;
  padding: 1rem;
  border: 1px solid ${(props) => (props.invalid ? "red" : "#e4e4e4")};
  background-color: ${(props) => (props.invalid ? "#e7d5d5" : Colors.white)};
  @media (max-width: 576px) {
    padding: 0.6rem;
  }
`;

const ReviewBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: ${Colors.backgroundGrey};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 12.5rem;
  padding: 1.125rem;
  border-radius: 0.75rem;
  border: dashed 3px ${(props) => (props.fileErrors ? "red" : "#a9abb3")};
  background-color: #e5eaf0;
  box-sizing: border-box;
  @media (max-width: 576px) {
    padding: 0.5rem;
  }
`;

const FileDownloadContainer = styled.div`
  cursor: pointer;
  background: #f6f6f6;
  border: 1px solid #e9e9e9;
  border-radius: 0.75rem;
  padding: 1rem 0;
  margin: 0px 0px 6px 0px;
  transition: all 0.3s ease;
  :hover {
    font-weight: 500;
    text-decoration: underline ${Colors.font1} 1.5px;
  }
  :hover i {
    color: ${Colors.font1};
    font-weight: 500;
  }
`;

export default {
  ReviewBox,
  ImageBox,
  TextBoader,
  FileDownloadContainer,
};
