import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import ClearIcon from "@mui/icons-material/Clear";
import receiptImage from "../../../../Assets/assetsnew/receipt.svg";
import { useForm, Controller } from "react-hook-form";
import { AutoCompleteSelectProject } from "../../Commons/AutoComplete";
import LoaderSpin from "../../Commons/LoaderSpin";
import DisabledByDefaultSharpIcon from "@mui/icons-material/DisabledByDefaultSharp";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createTransaction } from "../../../../store/service/transactionService";
import Styled from "../TranscationStyles";
import TextStyles from "../../../../Constants/TextStyles";
import { CustomDatePicker } from "../../Commons/CustomDatePicker";
import SelectCurrency from "../../Commons/SelectCurrency";
import Colors from "../../../../Constants/Colors";

const NewExpense = ({
  show,
  handleClose,
  setUpdateRefresh,
  updateRefresh,
  section,
  selectedProject,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.transaction.isLoading);

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const userCurrency = useSelector((state) => state.auth.userCurrency);

  const [selectedDocs, setSelectedDocs] = useState([]);
  const [fileErrors, setfileErrors] = useState(false);

  const {
    register,
    reset,
    formState: { errors },
    control,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: {
      project: selectedProject && {
        title:
          selectedProject && selectedProject.project_name
            ? selectedProject.project_name
            : null,
        _id:
          selectedProject && selectedProject._id ? selectedProject._id : null,
        client_id:
          selectedProject &&
          selectedProject.client_id &&
          selectedProject.client_id._id
            ? selectedProject.client_id._id
            : null,
        client_name:
          selectedProject &&
          selectedProject.client_id &&
          selectedProject.client_id.company_name
            ? selectedProject.client_id.company_name
            : null,
      },
      paid_date: new Date(),
      currency: userCurrency && userCurrency.currency,
    },
  });

  const changedCurrency = watch("currency");
  const currencySymbol = changedCurrency
    ? changedCurrency.symbol
    : userCurrency && userCurrency.currency && userCurrency.currency.symbol;

  const formsubmit = (data) => {
    let formData = new FormData();
    formData.append("file_url", selectedDocs[0]);
    formData.append("project_id", data.project && data.project._id);
    formData.append("client_id", data.project && data.project.client_id);
    formData.append("total_money", data.total_rate);
    formData.append(
      "currency",
      data.currency
        ? JSON.stringify(data.currency)
        : JSON.stringify(userCurrency.currency)
    );
    formData.append("paid_date", data.paid_date ? data.paid_date : new Date());
    formData.append("purpose", data.purpose);
    formData.append("note", data.note);
    formData.append("transaction_type", "expense");
    dispatch(createTransaction(formData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        reset();
        handleClose();
        if (setUpdateRefresh) {
          setUpdateRefresh(!updateRefresh);
        }
        if (section === "navbar") {
          if (history.location.pathname !== "/db/transactions")
            return history.push("/db/transactions");
        }
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "createTransactionSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "createTransactionFail" }
        );
      }
    });
  };

  const onChangeFileHandler = (el) => {
    setSelectedDocs(Array.from(el.target.files));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files;
    if (file.length > 1) {
      setfileErrors(true);
      toast.error(
        <TextStyles.FontSize14px>
          Please don't drag multiple files.
        </TextStyles.FontSize14px>
      );
    } else {
      setfileErrors(false);
      setSelectedDocs(Array.from(event.dataTransfer.files));
    }
  };

  const removeFileHandler = () => {
    if (selectedDocs.length > 0) {
      setSelectedDocs([]);
      setfileErrors(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen
      dialogClassName="ToMakeFullscreenModal"
    >
      <div className="modal-header d-flex justify-content-center">
        <TextStyles.FontSize14px
          style={{
            position: "absolute",
            left: "1rem",
            top: "1.25rem",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <ClearIcon
            sx={{
              fontSize: 30,
              color: Colors.font2,
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
          />
        </TextStyles.FontSize14px>

        <TextStyles.FontSize20px
          style={{ fontWeight: 500, fontSize: "1.5rem" }}
        >
          New expense
        </TextStyles.FontSize20px>
      </div>
      <div className="modal-body d-flex justify-content-center">
        <div
          className="d-flex justify-content-center w-100 h-100 py-3"
          style={{ maxWidth: 1400 }}
        >
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-start justify-content-md-between gap-3 gap-md-4 gap-lg-4 gap-xl-5 w-100 h-100">
            <div className="w-100 h-100">
              <Styled.ImageBox
                fileErrors={fileErrors}
                style={{
                  cursor: "pointer",
                }}
              >
                <div
                  style={{ position: "absolute", right: 12, top: 6 }}
                  onClick={removeFileHandler}
                >
                  <DisabledByDefaultSharpIcon
                    sx={{
                      color: "grey",
                      background: "tranparent",
                      display: selectedDocs.length > 0 ? "inline" : "none",
                    }}
                  />
                </div>
                <label
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  htmlFor="uploadfiles"
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    id="uploadfiles"
                    type="file"
                    accept="image/*,.pdf,.doc,.docx,.xls,.xlsx, .csv,"
                    // multiple
                    onChange={onChangeFileHandler}
                  />
                  {selectedDocs.length === 0 && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={receiptImage} alt="reciept" />
                      <TextStyles.FontSize18px className="mt-4">
                        Attach a receipt
                      </TextStyles.FontSize18px>
                      <TextStyles.GreyFont14px className="text-center mt-2">
                        Click to upload a file, or just drag & drop.
                      </TextStyles.GreyFont14px>
                    </div>
                  )}

                  {selectedDocs.length > 0 && (
                    <DocViewer
                      style={{
                        width: "100%",
                        height: "100%",
                        maxHeight: "450px",
                      }}
                      // initialActiveDocument={docs[1]}
                      // activeDocument={activeDocument}
                      documents={
                        selectedDocs &&
                        selectedDocs.map((file) => ({
                          uri: window.URL.createObjectURL(file),
                          fileName: file.name,
                        }))
                      }
                      pluginRenderers={DocViewerRenderers}
                      config={{
                        header: {
                          disableHeader: true,
                          disableFileName: true,
                          retainURLParams: false,
                        },
                      }}
                    />
                  )}
                </label>
              </Styled.ImageBox>
            </div>
            <div className="w-100 h-100">
              <form onSubmit={handleSubmit(formsubmit)}>
                <TextStyles.FontSize14px className="mb-3">
                  <div className="row">
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                      <TextStyles.FontSize14px className="mb-2">
                        Assign project
                      </TextStyles.FontSize14px>
                      <Controller
                        control={control}
                        name="project"
                        render={({ field: { onChange, value } }) => (
                          <AutoCompleteSelectProject
                            placeholder="Assign to"
                            options={
                              projectOptionsData &&
                              projectOptionsData.map((d) => {
                                return {
                                  _id: d._id && d._id,
                                  title: d && d.project_name && d.project_name,
                                  client_id:
                                    d &&
                                    d.client_id &&
                                    d.client_id._id &&
                                    d.client_id._id,
                                  client_name:
                                    d &&
                                    d.client_id &&
                                    d.client_id.company_name &&
                                    d.client_id.company_name,
                                };
                              })
                            }
                            onChange={onChange}
                            label="Select project"
                            defaultValue={value}
                          />
                        )}
                      />
                    </div>
                    <div className="col-12 col-lg-6">
                      <label htmlFor="currency" className="mb-2">
                        Select currency
                      </label>
                      <Controller
                        control={control}
                        name="currency"
                        render={({ field: { onChange, value } }) => (
                          <SelectCurrency
                            placeholder="Select currency"
                            onChange={onChange}
                            defaultValue={userCurrency && userCurrency.currency}
                          />
                        )}
                      />
                    </div>
                  </div>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="total_rate">Total</label>
                  <Styled.InputBox>
                    <span>{currencySymbol}</span>
                    <input
                      style={{
                        width: "84%",
                      }}
                      type="text"
                      name="total_rate"
                      id="total_rate"
                      placeholder="00.00"
                      {...register("total_rate", {
                        pattern: {
                          value: /^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                          message: "Please enter a valid number",
                        },
                      })}
                    />
                  </Styled.InputBox>

                  {errors.total_rate && (
                    <TextStyles.InValidFeedback className="mt-2">
                      {errors.total_rate.message}
                    </TextStyles.InValidFeedback>
                  )}
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="date">Date</label>
                  <TextStyles.DateRectangle>
                    <Controller
                      name={"paid_date"}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CustomDatePicker
                            onChange={onChange}
                            selected={value}
                            placeholderText="Set date"
                            todayButton="Today"
                            dateFormat="dd MMM yyyy"
                            isClearable={true}
                            // minDate={new Date()}
                          />
                        );
                      }}
                    />
                  </TextStyles.DateRectangle>
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px className="mb-2">
                  <label htmlFor="purpose"> Purpose</label>
                  <TextStyles.InputRectangle
                    type="text"
                    id="purpose"
                    name="purpose"
                    placeholder="Dinner with client"
                    {...register("purpose")}
                  />
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px>
                  <label htmlFor="addNote">Add note</label>
                  <TextStyles.InputRectangle
                    type="text"
                    id="addNote"
                    name="note"
                    {...register("note")}
                    placeholder="Add a note..."
                  />
                </TextStyles.FontSize14px>

                <div className="row py-3">
                  <div className="col-6">
                    <TextStyles.CancelButton onClick={handleClose}>
                      Cancel
                    </TextStyles.CancelButton>
                  </div>
                  <div className="col-6">
                    <TextStyles.Button
                      disabled={
                        fileErrors.length > 0 || isLoading ? true : false
                      }
                      style={{
                        opacity: isLoading || fileErrors.length > 0 ? 0.5 : 1,
                      }}
                    >
                      {isLoading ? <LoaderSpin /> : "Create"}
                    </TextStyles.Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewExpense;
