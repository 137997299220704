import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";

import Colors from "../../../../../Constants/Colors";
import Styled from "../../../TimeTracking/Timer/TimerStyles";
import TextStyles from "../.././../../../Constants/TextStyles";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import {
  start_timer_activity,
  stop_timer_activity,
  start_timer_change_activity_name,
  cancel_start_activity,
  createActivityList,
  fetchAllActivityLists,
} from "../../../../../store/service/timeTrackingService";
import { timeTrackingActions } from "../../../../../store/storage/timetrackingSlice";

import { formatTime } from "../../../Commons/utils";

import { AutoCompleteCreateActivity } from "../../../Commons/TimeTrackingAutoComplete/Autocomplete";

const TimerBox = () => {
  const dispatch = useDispatch();

  const activityListData = useSelector(
    (state) => state.timeTracking.activityListData
  );
  const isLoading = useSelector((state) => state.timeTracking.isLoading);
  const startActivityData = useSelector(
    (state) => state.timeTracking.startActivityData
  );

  const elapsedTime = useSelector((state) => state.timeTracking.elapsedTime);

  const selectedProject = useSelector((state) => state.project.selectedProject);

  const { reset, handleSubmit, control } = useForm({
    defaultValues: {
      add_activity:
        startActivityData &&
        startActivityData.activity_name &&
        startActivityData.project_id &&
        startActivityData.project_id === selectedProject._id &&
        activityListData
          ? activityListData
              .filter(
                (d) => d.activity_name === startActivityData.activity_name
              )
              .map((data) => {
                return { _id: data._id, title: data.activity_name };
              })[0]
          : null,
    },
  });

  useEffect(() => {
    reset({
      add_activity:
        startActivityData &&
        startActivityData.activity_name &&
        startActivityData.project_id &&
        startActivityData.project_id === selectedProject._id &&
        activityListData
          ? activityListData
              .filter(
                (d) => d.activity_name === startActivityData.activity_name
              )
              .map((data) => {
                return { _id: data._id, title: data.activity_name };
              })[0]
          : null,
    });
  }, [startActivityData]);

  const createActivityHandler = (data) => {
    if (data && !data._id) {
      let Obj = {
        activity_name: data.inputValue,
      };
      dispatch(timeTrackingActions.addNewActivityList(Obj));
      dispatch(createActivityList(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists());
        }
      });
    }
  };

  const startTimerHandler = (data) => {
    const Obj = {
      project_id: selectedProject ? selectedProject._id : null,
      client_id:
        selectedProject && selectedProject.client_id
          ? selectedProject.client_id._id
          : null,
      activity_name:
        data.add_activity && data.add_activity.inputValue
          ? data.add_activity.inputValue
          : data.add_activity && data.add_activity._id
          ? data.add_activity.title
          : null,
      start_time: new Date(),
    };
    dispatch(start_timer_activity(Obj));
  };

  const stopTimerHandler = (data) => {
    const Obj = {
      project_id: selectedProject ? selectedProject._id : null,
      client_id:
        selectedProject && selectedProject.client_id
          ? selectedProject.client_id._id
          : null,
      activity_name:
        data.add_activity && data.add_activity.inputValue
          ? data.add_activity.inputValue
          : data.add_activity && data.add_activity._id
          ? data.add_activity.title
          : null,
      end_time: new Date(),
    };
    dispatch(stop_timer_activity(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(timeTrackingActions.updateRefresh());
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "stopTimerSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}
          </TextStyles.FontSize14px>,
          { toastId: "stopTimerFail" }
        );
      }
    });
  };

  const startTimerChangeActivityNameHandler = (data) => {
    const Obj = {
      activity_name:
        data && data._id
          ? data.title
          : data && data.inputValue
          ? data.inputValue
          : null,
    };

    if (startActivityData && startActivityData.start_time) {
      dispatch(start_timer_change_activity_name(Obj));
    }
  };

  const cancelActivityHandler = () => {
    dispatch(cancel_start_activity()).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchAllActivityLists());
      }
    });
  };

  const resetHandle = () => {
    reset({
      add_activity: null,
      select_project: null,
    });
    dispatch(timeTrackingActions.resetActivityAction());
  };

  return (
    <Styled.TimerBox>
      <div className="d-flex gap-3 flex-wrap flex-md-nowrap align-items-center w-100">
        <div className="d-flex gap-3 flex-wrap flex-sm-nowrap align-items-center w-100">
          <div style={{ width: "100%", maxWidth: 65 }}>
            <button type="submit" disabled={isLoading ? true : false}>
              <PlayCircleFilledWhiteIcon
                onClick={handleSubmit(startTimerHandler)}
                sx={{
                  fontSize: 75,
                  color: Colors.primary,
                  cursor: "pointer",
                  display:
                    startActivityData &&
                    startActivityData.start_time &&
                    startActivityData.project_id &&
                    startActivityData.project_id === selectedProject._id
                      ? "none"
                      : "block",
                }}
              />
              <PauseCircleFilledIcon
                onClick={handleSubmit(stopTimerHandler)}
                sx={{
                  fontSize: 75,
                  color: Colors.red,
                  cursor: "pointer",
                  display:
                    startActivityData.start_time &&
                    startActivityData.project_id &&
                    startActivityData.project_id === selectedProject._id
                      ? "block"
                      : "none",
                }}
              />
            </button>
          </div>
          <div style={{ width: "100%", maxWidth: 115 }}>
            <TextStyles.FontSize18px>
              {startActivityData &&
              startActivityData.project_id &&
              startActivityData.project_id === selectedProject._id
                ? formatTime(elapsedTime)
                : formatTime(0)}
            </TextStyles.FontSize18px>
            {startActivityData &&
            startActivityData.start_time &&
            startActivityData.project_id &&
            startActivityData.project_id === selectedProject._id ? (
              <TextStyles.GreyFont14px
                className="mt-2"
                style={{ cursor: "pointer" }}
                onClick={cancelActivityHandler}
              >
                Cancel
              </TextStyles.GreyFont14px>
            ) : (
              <TextStyles.GreyFont14px
                className="mt-2"
                onClick={resetHandle}
                style={{ cursor: "pointer" }}
              >
                Reset
              </TextStyles.GreyFont14px>
            )}
          </div>
          <div style={{ width: "100%", maxWidth: 600 }}>
            <Controller
              control={control}
              name="add_activity"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteCreateActivity
                  placeholder="Write activity..."
                  options={
                    activityListData &&
                    activityListData.map((d) => {
                      return { _id: d._id, title: d.activity_name };
                    })
                  }
                  onChange={(value) => {
                    onChange(value);
                    createActivityHandler(value);
                    startTimerChangeActivityNameHandler(value);
                  }}
                  defaultValue={value}
                />
              )}
            />
          </div>
        </div>
      </div>
    </Styled.TimerBox>
  );
};

export default React.memo(TimerBox);
