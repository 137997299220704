import React, { useState } from "react";
import Auxillary from "../Auxillary/Auxillary";
import SideDrawer from "../../Components/Navigation/SideDrawer/SideDrawer";
import Toolbar from "../../Components/Navigation/Navbars/ToolBar";

const Layout = ({ children }) => {
  const [sideDrawerVisible, setSideDrawerVisible] = useState(false);
  const sideDrawerHandler = () => setSideDrawerVisible(false);
  const toggleHandler = () => setSideDrawerVisible(!sideDrawerVisible);
  let urlElements = window.location.href.split("/");

  return (
    <Auxillary>
      {urlElements[3] !== "db" ? (
        <Auxillary>
          <Toolbar open={toggleHandler} scroll={300} />
          <SideDrawer close={sideDrawerHandler} open={sideDrawerVisible} />
          <main>{children}</main>
        </Auxillary>
      ) : (
        <Auxillary>
          <main>{children}</main>
        </Auxillary>
      )}
    </Auxillary>
  );
};

export default Layout;
