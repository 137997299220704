import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../Constants/TextStyles";
import { Modal } from "react-bootstrap";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import LoaderSpin from "../../Commons/LoaderSpin";
import { msToTime } from "../../Commons/utils";
import moment from "moment";
import { updateWeekViewMultipleActivityTimes } from "../../../../store/service/timeTrackingService";
import Colors from "../../../../Constants/Colors";

const EditWeekviewMultipleActivityTimes = ({
  show,
  handleClose,
  selectedData,
  setUpdateRefresh,
  updateRefresh,
}) => {
  const isLoading = useSelector((state) => state.timeTracking.isLoading);
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      activityList: selectedData,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "activityList",
  });

  const formsubmit = (data) => {
    const updateSelectedActivities =
      data &&
      data.activityList.map((d) => {
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        if (d.hours !== "00" && d.hours !== "") {
          hours = parseInt(d.hours);
        }
        if (d.minutes !== "00" && d.minutes !== "") {
          minutes = parseInt(d.minutes);
        }
        if (d.seconds !== "00" && d.seconds !== "") {
          seconds = parseInt(d.seconds);
        }
        const sum =
          hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        const start_time = new Date(d.start_time);
        const end_time = new Date(moment(start_time).add(sum));
        const _id = d._id;
        return { _id, end_time };
      });
    const Obj = {
      updateSelectedActivities,
    };

    dispatch(updateWeekViewMultipleActivityTimes(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "updateWeekSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}
          </TextStyles.FontSize14px>,
          { toastId: "updateWeekFail" }
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={selectedData && selectedData.length > 5 ? true : false}
      centered={selectedData && selectedData.length > 5 ? false : true}
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex gap-3 align-items-center">
          <TextStyles.FontSize20px
            style={{ display: "flex", width: "100%", minWidth: "10rem" }}
          >
            <span className="three-dots">
              {selectedData && selectedData.length > 1
                ? `Edit ${selectedData.length} activities for ${moment(
                    selectedData[0].start_time
                  ).format("DD MMM YYYY")}`
                : "Edit activity"}
            </span>
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>

        <Modal.Body className="modal-body">
          {fields &&
            fields.map((data, index) => {
              let diff = new Date(data.end_time) - new Date(data.start_time);
              return (
                <TextStyles.FontSize14px className="mb-3" key={index}>
                  {data && data.client_id && data.project_id ? (
                    <div className="mb-2">
                      <span>
                        {data &&
                          data.project_id &&
                          data.project_id.project_name}
                      </span>
                      <span>
                        {data &&
                          data.client_id &&
                          `(${data.client_id.company_name})`}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="d-flex gap-3 flex-wrap justify-content-start align-items-center">
                    <div className="d-flex justify-content-around align-items-center">
                      <div
                        className="d-flex align-items-center"
                        style={{
                          border: errors.hours
                            ? `1px solid ${Colors.red}`
                            : `1px solid ${Colors.borderInput}`,
                          padding: "0 1rem",
                          borderRadius: "0.75rem",
                          height: "3.15rem",
                        }}
                      >
                        <input
                          type="text"
                          style={{ width: 25 }}
                          name="hours"
                          placeholder="00"
                          defaultValue={msToTime(diff).hours.toString()}
                          {...register(`activityList.${index}.hours`, {
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Please enter valid number for hours.",
                            },
                          })}
                        />
                        <div className="ml-2">hrs</div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around align-items-center">
                      <div
                        className="d-flex align-items-center"
                        style={{
                          border: errors.hours
                            ? `1px solid ${Colors.red}`
                            : `1px solid ${Colors.borderInput}`,
                          padding: "0 1rem",
                          borderRadius: "0.75rem",
                          height: "3.15rem",
                        }}
                      >
                        <input
                          type="text"
                          style={{ width: 25 }}
                          name="minutes"
                          placeholder="00"
                          defaultValue={msToTime(diff).minutes.toString()}
                          {...register(`activityList.${index}.minutes`, {
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Please enter valid number for minutes.",
                            },
                            min: {
                              value: 0,
                              message: "Please don't enter less than 0.",
                            },

                            max: {
                              value: 59,
                              message: "Please don't enter more than 60.",
                            },
                          })}
                        />
                        <div className="ml-2">mins</div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around align-items-center">
                      <div
                        className="d-flex align-items-center"
                        style={{
                          border: errors.hours
                            ? `1px solid ${Colors.red}`
                            : `1px solid ${Colors.borderInput}`,
                          padding: "0 1rem",
                          borderRadius: "0.75rem",
                          height: "3.15rem",
                        }}
                      >
                        <input
                          type="text"
                          style={{ width: 25 }}
                          placeholder="00"
                          name="seconds"
                          defaultValue={msToTime(diff).seconds.toString()}
                          {...register(`activityList.${index}.seconds`, {
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Please enter valid number for seconds.",
                            },
                            mix: {
                              value: 0,
                              message: "Please don't enter less than 0.",
                            },

                            max: {
                              value: 59,
                              message: "Please don't enter more than 60.",
                            },
                          })}
                        />
                        <div className="ml-2">sec</div>
                      </div>
                    </div>
                  </div>

                  {errors.activityList &&
                    errors.activityList[index] &&
                    errors.activityList[index].hours && (
                      <TextStyles.InValidFeedback>
                        {errors.activityList[index].hours.message}
                      </TextStyles.InValidFeedback>
                    )}

                  {errors.activityList &&
                    errors.activityList[index] &&
                    errors.activityList[index].minutes && (
                      <TextStyles.InValidFeedback>
                        {errors.activityList[index].minutes.message}
                      </TextStyles.InValidFeedback>
                    )}
                  {errors.activityList &&
                    errors.activityList[index] &&
                    errors.activityList[index].seconds && (
                      <TextStyles.InValidFeedback>
                        {errors.activityList[index].seconds.message}
                      </TextStyles.InValidFeedback>
                    )}
                </TextStyles.FontSize14px>
              );
            })}
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block", padding: "6px 12px" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.Button disabled={isLoading ? true : false}>
              {isLoading ? (
                <LoaderSpin />
              ) : (
                `${
                  selectedData && selectedData.length > 1
                    ? " Update all"
                    : "Update"
                }`
              )}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditWeekviewMultipleActivityTimes;
