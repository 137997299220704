import React from "react";
import TextStyles from "../../../../../../Constants/TextStyles";

const Services = (props) => {
  const selectedData = props && props.selectedData;

  return (
    <div>
      <TextStyles.FontSize20px className="mb-3">
        Services summary
      </TextStyles.FontSize20px>
      {selectedData &&
        selectedData.service_list &&
        selectedData.service_list.map((item, index) => {
          return (
            <div key={index}>
              <div>
                <TextStyles.FontSize16px
                  className="d-flex mb-2"
                  style={{ minWidth: 100 }}
                >
                  <span className="three-dots"> {item.service_name}</span>
                </TextStyles.FontSize16px>
                <TextStyles.GreyFont14px
                  className="d-flex mb-2"
                  style={{ minWidth: 100 }}
                >
                  <span className="three-dots">
                    {selectedData &&
                      selectedData.currency &&
                      selectedData.currency.symbol}
                    {item.service_rate
                      ? parseFloat(item.service_rate).toFixed(2)
                      : "00.00"}
                    {item.service_rate_type !== "Flat fee"
                      ? ` ${item.service_rate_type.toLocaleLowerCase()}`
                      : ` ${item.service_rate_type.toLocaleLowerCase()}`}
                  </span>
                </TextStyles.GreyFont14px>
                <TextStyles.GreyFont14px
                  className="d-flex mb-2"
                  style={{ maxWidth: 500 }}
                >
                  {item.desc}
                </TextStyles.GreyFont14px>
              </div>
              <div
                className="my-3"
                style={{
                  width: "100%",
                  height: "1px",
                  border: "0.5px solid #edeef2",
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default Services;
