import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import ClearIcon from "@mui/icons-material/Clear";

import { toast } from "react-toastify";

import LoaderSpin from "../../Commons/LoaderSpin";

import { CustomDatePicker } from "../../Commons/CustomDatePicker";
import { AutoCompleteSelectClient } from "../../Commons/AutoComplete";

import TextStyles from "../../../../Constants/TextStyles";

import { Modal } from "react-bootstrap";
import { updateProjectDetailPC } from "../../../../store/service/proposalAndContractService";
import SwitchButton from "../../Commons/SwitchButton";
import Colors from "../../../../Constants/Colors";
import SelectCurrency from "../../Commons/SelectCurrency";

const PCEditProjectInfo = ({ selectedData, show, handleClose }) => {
  const dispatch = useDispatch();
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const [selectEndDate, setSelectEndDate] = useState(
    selectedData && selectedData.project_end_date ? false : true
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      project_name: selectedData && selectedData.project_name,
      client: selectedData && {
        _id: selectedData.client_id._id,
        label: selectedData.client_id.company_name,
      },
      currency: selectedData && selectedData.currency,
      project_start_date:
        selectedData &&
        selectedData.project_start_date &&
        new Date(selectedData.project_start_date),
      project_end_date:
        selectedData &&
        selectedData.project_end_date &&
        new Date(selectedData.project_end_date),
    },
  });

  const formsubmit = (data) => {
    const Obj = {
      pc_id: selectedData && selectedData._id,
      client_id: data.client && data.client._id,
      project_name: data.project_name,
      currency: data.currency ? data.currency : selectedData.currency,
      project_start_date: new Date(data.project_start_date),
      project_end_date:
        selectEndDate || data.project_end_date === undefined
          ? null
          : data.project_end_date && new Date(data.project_end_date),
    };
    dispatch(updateProjectDetailPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "updateProjectSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateProjectFail" }
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="modal-header" style={{ borderBottom: "0px" }}>
          <TextStyles.FontSize20px className="d-flex justify-content-start">
            Project details
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="modal-body">
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="project_name">Project name</label>

            <TextStyles.InputRectangle
              name="project_name"
              id="project_name"
              invalid={errors.project_name}
              type="text"
              placeholder="Project name"
              {...register("project_name", {
                required: "Project name is required",
                minLength: {
                  value: 3,
                  message: "Project name must be at least 3 characters",
                },
                maxLength: {
                  value: 30,
                  message: "Project name must be at most 30 characters",
                },
              })}
            />
            {errors.project_name && (
              <TextStyles.InValidFeedback>
                {errors.project_name.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="client">Client</label>
            <Controller
              control={control}
              name="client"
              rules={{
                required: {
                  value: true,
                  message: "Client can not be empty",
                },
              }}
              render={({ field: { onChange, value } }) => (
                <AutoCompleteSelectClient
                  placeholder="Choose a client"
                  options={
                    clientOptionData &&
                    clientOptionData.map((data) => {
                      return {
                        label: data.company_name,
                        _id: data._id,
                      };
                    })
                  }
                  defaultValue={
                    selectedData && {
                      label: selectedData.client_id.company_name,
                      _id: selectedData.client_id._id,
                    }
                  }
                  onChange={onChange}
                  invalid={errors.client}
                />
              )}
            />
            {errors.client && (
              <TextStyles.InValidFeedback className="mt-2">
                {errors.client.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="client">Select currency</label>
            <Controller
              control={control}
              name="currency"
              render={({ field: { onChange, value } }) => (
                <SelectCurrency
                  placeholder="Select currency"
                  onChange={onChange}
                  defaultValue={selectedData && selectedData.currency}
                />
              )}
            />
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <div htmlFor="project_start_date" className="mb-2">
              Start date
            </div>

            <TextStyles.DateRectangle>
              <Controller
                name={"project_start_date"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <CustomDatePicker
                      onChange={onChange}
                      selected={value}
                      placeholderText="Select start date"
                      todayButton="Today"
                      dateFormat="dd MMM yyyy"
                    />
                  );
                }}
              />
            </TextStyles.DateRectangle>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div htmlFor="project_end_date">End date</div>
              <div className="d-flex align-items-center">
                <TextStyles.FontSize14px
                  style={{ color: Colors.primary, fontWeight: 500 }}
                >
                  Ongoing
                </TextStyles.FontSize14px>
                <SwitchButton
                  defaultChecked={selectEndDate}
                  onChange={(event) => setSelectEndDate(event.target.checked)}
                />
              </div>
            </div>
            {selectEndDate ? (
              ""
            ) : (
              <TextStyles.DateRectangle>
                <Controller
                  name={"project_end_date"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomDatePicker
                        onChange={onChange}
                        selected={value}
                        placeholderText="Select end date"
                        todayButton="Today"
                        dateFormat="dd MMM yyyy"
                        isClearable={true}
                        // minDate={new Date(selectedData.project_start_date)}
                      />
                    );
                  }}
                />
              </TextStyles.DateRectangle>
            )}
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading ? true : false}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PCEditProjectInfo;
