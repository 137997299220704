import React from "react";
import ProfileIcon from "../../../../Assets/assetsnew/profile-image.svg";
import TrophyIcon from "../../../../Assets/assetsnew/trophy.svg";
import CurrencyIcon from "../../../../Assets/assetsnew/setup-currency.svg";
import BusinessInfoIcon from "../../../../Assets/assetsnew/setup-business-info.svg";

import ClearIcon from "@mui/icons-material/Clear";
import styled from "styled-components";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  hideSetupInfo,
  fetchUserSetupData,
} from "../../../../store/service/AuthService";
import { toast } from "react-toastify";

const DashboardItemBox = styled.div`
  padding: 15px 24px;
  border-radius: 12px;
  width: 350px;
  height: auto;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
  margin: 10px 0px;
  cursor: pointer;
`;

export default function secondrow() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userSetupData = useSelector((state) => state.auth.userSetupData);
  const setupHandler = (data) => {
    const Obj = {
      [data]: true,
    };
    dispatch(hideSetupInfo(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchUserSetupData());
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <div className="mx-lg-4 mx-md-4 mx-sm-3 mx-2 mt-3">
      {userSetupData &&
        (!userSetupData.setup_account_info ||
          !userSetupData.setup_currency ||
          !userSetupData.setup_business_info) && (
          <TextStyles.GreyFont20px className="d-flex justify-content-lg-start justify-content-xl-start justify-content-md-start justify-content-center mb-4">
            Let’s get you set up with Zodot
          </TextStyles.GreyFont20px>
        )}

      <div className="d-flex flex-wrap gap-4 justify-content-xl-around justify-content-lg-around justify-content-center">
        {userSetupData && !userSetupData.setup_account_info && (
          <DashboardItemBox
            onClick={() => history.push("/db/setting/account/user_info")}
            className="setup_your_user_info_tour"
          >
            <div className="d-flex justify-content-between">
              <img
                src={ProfileIcon}
                alt="profileBtn"
                style={{ width: 50, height: 50 }}
              />
              <ClearIcon
                sx={{
                  color: Colors.font2,
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setupHandler("setup_account_info");
                }}
              />
            </div>
            <TextStyles.FontSize18px
              className="text-start mt-3"
              style={{ fontWeight: 500 }}
            >
              Complete account setup
            </TextStyles.FontSize18px>
            <TextStyles.GreyFont14px
              className="text-start mt-2"
              style={{ fontWeight: 500 }}
            >
              Configure your profession, experience, profile picture, and other
              details.
            </TextStyles.GreyFont14px>
          </DashboardItemBox>
        )}
        {userSetupData && !userSetupData.setup_currency && (
          <DashboardItemBox
            onClick={() => history.push("/db/setting/business/currency")}
            className="setup_your_currency_tour position-relative"
          >
            <ClearIcon
              sx={{
                position: "absolute",
                top: 10,
                right: 20,
                color: Colors.font2,
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                setupHandler("setup_currency");
              }}
            />
            <div>
              <img src={CurrencyIcon} alt="currencyBtn" />
            </div>

            <TextStyles.FontSize18px
              className="mt-3"
              style={{ fontWeight: 500 }}
            >
              Complete currency setup
            </TextStyles.FontSize18px>
            <TextStyles.GreyFont14px
              className="mt-2"
              style={{ fontWeight: 500 }}
            >
              Set up your currency.
            </TextStyles.GreyFont14px>
          </DashboardItemBox>
        )}

        {userSetupData && !userSetupData.setup_brand_and_logo && (
          <DashboardItemBox
            onClick={() => history.push("/db/setting/business/brand_logo")}
            className="setup_your_brand_logo_tour"
          >
            <div className="d-flex justify-content-between">
              <img src={TrophyIcon} alt="trophyBtn" />
              <ClearIcon
                sx={{
                  color: Colors.font2,
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setupHandler("setup_brand_and_logo");
                }}
              />
            </div>
            <TextStyles.FontSize18px className="mt-3">
              Complete brand setup
            </TextStyles.FontSize18px>
            <TextStyles.GreyFont14px className="mt-2">
              Configure your brand's logo, its color theme and more.
            </TextStyles.GreyFont14px>
          </DashboardItemBox>
        )}

        {userSetupData && !userSetupData.setup_business_info && (
          <DashboardItemBox
            onClick={() => history.push("/db/setting/business/business_info")}
            className="setup_your_business_info_tour"
          >
            <div className="d-flex justify-content-between">
              <img src={BusinessInfoIcon} alt="BusinessInfoBtn" />
              <ClearIcon
                sx={{
                  color: Colors.font2,
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setupHandler("setup_business_info");
                }}
              />
            </div>
            <TextStyles.FontSize18px className="mt-3">
              Get everything ready for your business
            </TextStyles.FontSize18px>
            <TextStyles.GreyFont14px className="mt-2">
              Put in your business's name, where it's located, and other
              important details.
            </TextStyles.GreyFont14px>
          </DashboardItemBox>
        )}
      </div>
    </div>
  );
}
