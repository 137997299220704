import { createSlice } from "@reduxjs/toolkit";
import {
  fetchClients,
  fetchClientsForOption,
  fetchClientForAProject,
  createClient,
  getClientById,
  archiveClient,
  unarchiveClient,
  editClient,
  deleteClient,
  createContact,
  editContact,
  insertNotes,
  deleteContact,
  fetchClientCounts,
} from "../service/clientService";

const clientState = {
  clientData: null,
  clientOptionData: null,
  clientsTotalCount: null,
  isLoading: false,
  error: null,
  c_data_position: null,
  clientForAProject: null,
};

export const clientSlice = createSlice({
  name: "clients",
  initialState: clientState,
  reducers: {
    clientDataAtPosition: (state, action) => {
      state.c_data_position = action.payload;
    },
  },
  extraReducers: {
    // fetch clients
    [fetchClients.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchClients.fulfilled]: (state, action) => {
      state.clientData = action.payload.data.data;
      state.clientsTotalCount = action.payload.data.clientsTotalCount;
      state.isLoading = false;
    },
    [fetchClients.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch clients for option
    [fetchClientsForOption.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchClientsForOption.fulfilled]: (state, action) => {
      state.clientOptionData = action.payload.data;
      state.isLoading = false;
    },
    [fetchClientsForOption.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch clients for option
    [fetchClientForAProject.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchClientForAProject.fulfilled]: (state, action) => {
      state.clientForAProject = action.payload.data;
      state.isLoading = false;
    },
    [fetchClientForAProject.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create client
    [createClient.pending]: (state) => {
      state.isLoading = true;
    },

    [createClient.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createClient.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create client
    [getClientById.pending]: (state) => {
      state.isLoading = true;
    },

    [getClientById.fulfilled]: (state, action) => {
      state.c_data_position = action.payload.data;
      state.isLoading = false;
    },
    [getClientById.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // archive client
    [archiveClient.pending]: (state) => {
      state.isLoading = true;
    },
    [archiveClient.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [archiveClient.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // unarchive client
    [unarchiveClient.pending]: (state) => {
      state.isLoading = true;
    },
    [unarchiveClient.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [unarchiveClient.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //  Delete Client
    [deleteClient.pending]: (state, action) => {
      state.isLoading = true;
    },
    [deleteClient.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deleteClient.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit client
    [editClient.pending]: (state) => {
      state.isLoading = true;
    },

    [editClient.fulfilled]: (state, action) => {
      state.c_data_position = action.payload.data;
      state.isLoading = false;
    },
    [editClient.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create contact
    [createContact.pending]: (state) => {
      state.isLoading = true;
    },
    [createContact.fulfilled]: (state, action) => {
      state.c_data_position.contacts = action.payload.data.contacts;
      state.isLoading = false;
    },
    [createContact.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit contact

    [editContact.pending]: (state) => {
      state.isLoading = true;
    },
    [editContact.fulfilled]: (state, action) => {
      state.c_data_position.contacts = action.payload.data.contacts;
      state.isLoading = false;
    },
    [editContact.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //delete contact
    [deleteContact.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteContact.fulfilled]: (state, action) => {
      state.c_data_position.contacts = action.payload.data.contacts;
      state.isLoading = false;
    },
    [deleteContact.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //insert notes
    [insertNotes.pending]: (state) => {
      state.isLoading = true;
    },
    [insertNotes.fulfilled]: (state, action) => {
      state.c_data_position.notes = action.payload.data.notes;
      state.isLoading = false;
    },
    [insertNotes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //fetch client counts
    [fetchClientCounts.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchClientCounts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.clientsTotalCount = action.payload.data.clientsTotalCount;
    },
    [insertNotes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export const clientActions = clientSlice.actions;

export default clientSlice.reducer;
