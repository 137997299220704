import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateDepositPC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";
import DepositModal from "../../PageModals/DepositModal.jsx";

const Deposit = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading = "Deposit";

  const paragraph =
    selectedData.billing_scheduled &&
    selectedData.billing_scheduled.deposit &&
    selectedData.billing_scheduled.deposit.require_deposit === true
      ? `Upon the acceptance of this Agreement, a deposit of  ${selectedData.currency.symbol}${selectedData.billing_scheduled.deposit.value} is required before work will commence.`
      : "Note: This section shows if only applicable.";

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      deposit: {
        required: false,
      },
    };
    dispatch(updateDepositPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>,
          { toastId: "updateDepositSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateDepositFail" }
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData.deposit && selectedData.deposit.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        {selectedData.billing_scheduled &&
        selectedData.billing_scheduled.deposit &&
        selectedData.billing_scheduled.deposit.require_deposit === true ? (
          <div>
            <Styled.EditButtonToolTip
              className="contract_review_edit_tp"
              style={{ marginLeft: -60 }}
              onClick={() => setShowModal(true)}
            >
              <BorderColorOutlinedIcon
                sx={{ color: Colors.white, fontSize: 20 }}
              />
              <span className="ml-1">Edit</span>
            </Styled.EditButtonToolTip>
            <Styled.EditButtonToolTip
              backgroundColor="#383838"
              className="contract_review_edit_tp"
              style={{ marginLeft: 60 }}
              onClick={() => {
                setShowRemoveModal(true);
                setConfirmDialog({
                  onConfirm: hideClauseHandler,
                });
              }}
            >
              <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
              <span className="ml-1">Hide</span>
            </Styled.EditButtonToolTip>
          </div>
        ) : (
          <Styled.EditButtonToolTip
            className="contract_review_edit_tp"
            backgroundColor={Colors.white}
            color={Colors.font1}
            onClick={() => setShowModal(true)}
          >
            show
          </Styled.EditButtonToolTip>
        )}

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>
          {selectedData.billing_scheduled &&
          selectedData.billing_scheduled.deposit &&
          selectedData.billing_scheduled.deposit.require_deposit === true ? (
            <>
              Upon the acceptance of this Agreement, a deposit of
              <span
                className="mx-2"
                style={{ color: Colors.primary, fontWeight: 500 }}
              >
                {selectedData.currency.symbol}
                {parseFloat(
                  selectedData.billing_scheduled.deposit.value || 0
                ).toFixed(2)}
              </span>
              is required before work will commence.
            </>
          ) : (
            <>Note: This section shows if only applicable.</>
          )}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <DepositModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          paragraph={paragraph}
          selectedData={selectedData}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default Deposit;
