import React, { useState, useEffect, useMemo } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import EventIcon from "@mui/icons-material/Event";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import NoTaskImage from "../../../../Assets/assetsnew/no-task.svg";

import AssignmentIcon from "@mui/icons-material/Assignment";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";

import { Popover } from "antd";

import { toast } from "react-toastify";

import {
  createQuickTask,
  fetchTasks,
  editTaskDueDate,
  deleteTask,
  updateTaskStatus,
} from "../../../../store/service/dashboardService";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import EditTask from "./EditTask";
import DeleteModal from "../../Commons/DeleteModal";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Styled from "./fourthrowStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

import SelectClients from "../../Commons/SelectClients";
import SelectProjects from "../../Commons/SelectProjects";
import SelectTaskStatus from "../../Commons/SelectTaskStatus";
import { ReactTooltip } from "../../Commons/ReactTooltip";
import moment from "moment/moment";
import Pagination from "../../Commons/Pagination";
import {
  createActivityList,
  fetchAllActivityLists,
  start_timer_activity,
} from "../../../../store/service/timeTrackingService";
import { timeTrackingActions } from "../../../../store/storage/timetrackingSlice";
import Reminder from "./Reminder";
import SelectDate from "../../Commons/SelectDate";
import LoaderSpin from "../../Commons/LoaderSpin";

export default function fourthrow() {
  const [selector, setSelector] = useState("task");
  const [showInput, setShowInput] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.dashboard.isLoading);
  const taskData = useSelector((state) => state.dashboard.taskData);
  const taskTotalCount = useSelector((state) => state.dashboard.taskTotalCount);
  const refreshHeaderTask = useSelector(
    (state) => state.dashboard.refreshHeaderTask
  );

  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const [updateRefresh, setUpdateRefresh] = useState(false);
  const [filteredProject, setFilteredProject] = useState(null);
  const [filteredClient, setFilteredClient] = useState(null);
  const [filterdDateRange, setFilteredDateRange] = useState(null);
  const [filteredTaskStatus, setFilteredTaskStatus] = useState("todo");

  const [showFilter, setShowFilter] = useState(false);
  const toggleShowFilter = () => setShowFilter(!showFilter);

  const { register, reset, handleSubmit } = useForm();

  const defaultData = useMemo(() => {
    return (
      taskData &&
      taskData.map((item) => {
        const repeat_task_id = item.repeat_task_id;
        const _id = item._id;
        const due_date = item.due_date ? new Date(item.due_date) : null;
        const hide_until_date = item.hide_until_date
          ? new Date(item.hide_until_date)
          : null;
        const client_id = item.client_id ? item.client_id : null;
        const project_id = item.project_id ? item.project_id : null;
        const status = item.status;
        const task_name = item.task_name ? item.task_name : null;
        const repeat_task = item.repeat_task ? item.repeat_task : null;

        return {
          _id,
          due_date,
          hide_until_date,
          client_id,
          project_id,
          status,
          task_name,
          repeat_task,
          repeat_task_id,
        };
      })
    );
  }, [taskData]);

  const { control: control2, reset: reset2 } = useForm({
    defaultValues: {
      update_task: defaultData && defaultData,
    },
  });

  const { fields: fields2 } = useFieldArray({
    control: control2,
    name: "update_task",
  });

  useEffect(() => {
    reset2({
      update_task: defaultData && defaultData,
    });
  }, [taskData]);

  const RemoveInputHandler = (e) => {
    if (e.keyCode === 8 && e.target.value === "") {
      setShowInput(false);
    }
  };

  // sorting invoice
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const [selectedSortingOne, setSelectedSortingOne] = useState("createdAt");

  const sortingOptionChangeHandler = (event) => {
    setSelectedSortingOne(event.target.value);
  };

  const [selectedSortingTwo, setSelectedSortingTwo] = useState("desc");

  const sortingOrderingChangeHandler = (event) => {
    setSelectedSortingTwo(event.target.value);
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    setPage(1);
  }, [
    filteredClient,
    filteredProject,
    filterdDateRange,
    filteredTaskStatus,
    itemsPerPage,
    refreshHeaderTask,
  ]);

  useEffect(() => {
    if (taskData && taskData.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [taskData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).format("YYYY-MM-DD");
      end_time = moment(filterdDateRange[0]).format("YYYY-MM-DD");
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(6, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(14, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(29, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(44, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(59, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(89, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "This month") {
        const startDateOfThisMonth = moment(new Date())
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDateOfThisMonth = moment(new Date())
          .endOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last month") {
        const endDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        const startDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "This year") {
        const endDateOfThisYear = moment(new Date())
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfThisYear = moment(new Date())
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisYear).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last year") {
        const endDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastYear).format("YYYY-MM-DD");
      }
    }

    const Obj = {
      page: page,
      perPage: itemsPerPage,
      start_time,
      end_time,
      client_id: filteredClient !== 0 ? filteredClient : null,
      project_id: filteredProject !== 0 ? filteredProject : null,
      status: filteredTaskStatus ? filteredTaskStatus : "todo",
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    dispatch(fetchTasks(Obj));
  }, [
    filteredClient,
    filteredProject,
    filterdDateRange,
    filteredTaskStatus,
    updateRefresh,
    page,
    itemsPerPage,
    selectedSortingOne,
    selectedSortingTwo,
    refreshHeaderTask,
  ]);

  const formsubmit = (data) => {
    const Obj = {
      task_name: data.task_name,
    };
    dispatch(createQuickTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        setShowInput(false);
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "taskCreatedSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}
          </TextStyles.FontSize14px>,
          { toastId: "tastCreatedFailed" }
        );
      }
    });
  };

  const doneTaskCallApi = (data) => {
    let Obj = {
      task_id: data._id,
      status: "done",
    };
    dispatch(updateTaskStatus(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            Task completed successfully!
          </TextStyles.FontSize14px>,
          { toastId: "taskDoneSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "taskDoneFail" }
        );
      }
    });
  };

  const setDueDateApiCall = (newDate, data) => {
    const Obj = {
      _id: data._id,
      due_date: new Date(newDate),
    };
    dispatch(editTaskDueDate(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "dueDateSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "dueDateFail" }
        );
      }
    });
  };

  const todoTaskCallApi = (data) => {
    let Obj = {
      task_id: data._id,
      status: "todo",
    };
    dispatch(updateTaskStatus(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            Task undo successfully!
          </TextStyles.FontSize14px>,
          { toastId: "taskStatusSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "taskStatusFail" }
        );
      }
    });
  };

  const deleteTaskHandler = (data) => {
    const Obj = {
      task_id: data && data._id,
    };
    dispatch(deleteTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleCloseDeleteModal();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "deleteTaskSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "deleteTaskFail" }
        );
      }
    });
  };

  const startTimerHandler = (data) => {
    const Obj = {
      project_id: data.project_id ? data.project_id._id : null,
      client_id: data.client_id ? data.client_id._id : null,
      activity_name: data.task_name,
      start_time: new Date(),
    };

    dispatch(
      timeTrackingActions.addNewActivityList({ activity_name: data.task_name })
    );
    dispatch(createActivityList({ activity_name: data.task_name })).then(
      (data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists());
        }
      }
    );
    dispatch(start_timer_activity(Obj));
  };

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <AssignmentIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label className="form-check-label ml-2" htmlFor="task_name">
            Task name
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="task_name"
              type="radio"
              name="sorting"
              value="task_name"
              checked={selectedSortingOne === "task_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <FolderSharedTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label className="form-check-label ml-2" htmlFor="company_name">
            Client name
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="company_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label className="form-check-label ml-2" htmlFor="project_name">
            Project name
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="project_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_name"}
              value="project_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label className="form-check-label ml-2" htmlFor="due_date">
            Due date
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="due_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "due_date"}
              value="due_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label className="form-check-label ml-2" htmlFor="createdAt">
            Created date
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="createdAt"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "createdAt"}
              value="createdAt"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <ArrowUpwardTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
          <label className="form-check-label ml-2" htmlFor="asc">
            Ascending (A-Z)
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <ArrowDownwardTwoToneIcon
            sx={{ color: Colors.font2, fontSize: 21 }}
          />
          <label className="form-check-label ml-2" htmlFor="desc">
            Descending (Z-A)
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2 pt-4">
      <div className="d-flex flex-wrap gap-3 justify-content-sm-between mb-4">
        <div>
          <Styled.SliderBox>
            <div className="d-flex flex-row">
              <div
                onClick={() => {
                  setSelector("task");
                }}
              >
                {selector === "task" ? (
                  <Styled.SelectedItem>Tasks</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Tasks</Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  setSelector("reminder");
                }}
              >
                {selector === "reminder" ? (
                  <Styled.SelectedItem>Reminders</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Reminders</Styled.NotSelectedItem>
                )}
              </div>
            </div>
          </Styled.SliderBox>
        </div>
        {selector === "task" && (
          <div
            className="d-flex flex-wrap gap-3 align-items-center justify-content-center"
            style={{ marginLeft: "auto" }}
          >
            <div className="d-flex align-items-center">
              <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
                Filter
              </TextStyles.HoverEffectSpan>

              <TextStyles.GreyFont20px className="mx-2">
                |
              </TextStyles.GreyFont20px>

              <TextStyles.FontSize16px className="mr-2">
                <Popover
                  placement="bottomRight"
                  // title={"Hello"}
                  content={sortingContent}
                  trigger="click"
                  open={openSorting}
                  onOpenChange={handleOpenSortingChange}
                >
                  <TextStyles.HoverEffectSpan sort={true}>
                    Sort
                    <ArrowDropDownOutlinedIcon
                      sx={{
                        color: Colors.font2,
                        fontSize: 27,
                        transform: openSorting ? "rotate(180deg)" : "",
                      }}
                    />
                  </TextStyles.HoverEffectSpan>
                </Popover>
              </TextStyles.FontSize16px>
            </div>
            <label htmlFor="task_name" className="mb-0">
              <Styled.SelectedItem
                onClick={() => {
                  setShowInput(true);
                  setSelector("task");
                }}
                className="create_dashboard_task_tour"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <AddOutlinedIcon sx={{ fontSize: 20, color: Colors.white }} />
                  <span className="ml-1 pr-2">New task</span>
                </div>
              </Styled.SelectedItem>
            </label>
          </div>
        )}
      </div>
      {selector === "task" && showFilter && (
        <div className="d-flex flex-wrap justify-content-start mb-3">
          <div className="mr-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>
            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                setFilteredDateRange(value);
              }}
            />
          </div>
          <div className="mr-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Project
            </TextStyles.FontSize14px>
            <SelectProjects
              defaultValue={filteredProject && filteredProject}
              options={projectOptionsData}
              onChange={(e) => setFilteredProject(e.target.value)}
            />
          </div>
          <div className="mr-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Client
            </TextStyles.FontSize14px>
            <SelectClients
              options={clientOptionData}
              defaultValue={filteredClient && filteredClient}
              onChange={(e) => setFilteredClient(e.target.value)}
            />
          </div>
          <div className="mr-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Task status
            </TextStyles.FontSize14px>
            <SelectTaskStatus
              defaultValue={filteredTaskStatus && filteredTaskStatus}
              onChange={(e) => setFilteredTaskStatus(e.target.value)}
            />
          </div>
        </div>
      )}
      {selector === "task" && showInput ? (
        <Styled.TodoRectangle
          style={{
            cursor: "auto",
            borderRadius:
              showInput && taskData && taskData.length > 0
                ? "12px 12px 0 0"
                : showInput && taskData && taskData.length === 0
                ? "12px"
                : "",
          }}
        >
          <form
            className="d-flex justify-content-start align-items-center w-100"
            onSubmit={handleSubmit(formsubmit)}
          >
            <RadioButtonUncheckedIcon
              className="radioButton"
              fontSize="medium"
              sx={{
                color: Colors.font2,
                cursor: "pointer",
              }}
            />
            <Styled.Input
              id="task_name"
              type="text"
              style={{ background: "none" }}
              placeholder="Write a to-do and hit enter..."
              name="task_name"
              {...register("task_name", {
                required: "Task name can't be empty.",
              })}
              onKeyDownCapture={RemoveInputHandler}
            />
          </form>
        </Styled.TodoRectangle>
      ) : (
        ""
      )}

      {selector === "task" && fields2 && fields2.length > 0 ? (
        fields2.map((data, index) => {
          return (
            <Styled.TodoRectangle
              key={index}
              style={{
                marginTop: 2,
                borderRadius:
                  taskData && taskData.length === 1 && !showInput
                    ? "12px"
                    : taskData && taskData.length - 1 === index
                    ? "0 0 12px 12px"
                    : taskData &&
                      taskData.length - taskData.length === index &&
                      !showInput
                    ? "12px 12px 0  0"
                    : "",
              }}
              onClick={() => {
                setShow(true);
                setSelectedTaskData(data);
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <div
                  className="d-flex justify-content-between align-items-center three-dots w-100"
                  style={{ maxWidth: 900 }}
                >
                  <TextStyles.FontSize14px className="d-flex align-items-center three-dots w-100">
                    <div
                      className="d-flex align-items-center"
                      style={{ width: 30 }}
                    >
                      {data.status === "done" && (
                        <span>
                          <TaskAltIcon
                            fontSize="medium"
                            sx={{
                              color: Colors.primary,
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      )}
                      {data.status !== "done" && (
                        <span
                          style={{ width: 24, height: 30 }}
                          className="d-flex justify-content-center align-items-center radioButtonWrapper"
                        >
                          <RadioButtonUncheckedIcon
                            className="radioButton"
                            fontSize="medium"
                            sx={{
                              color: Colors.font2,
                              cursor: "pointer",
                            }}
                          />

                          <TaskAltIcon
                            className="radioCheckedButton"
                            fontSize="medium"
                            sx={{
                              color: Colors.font2,
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              doneTaskCallApi(data);
                            }}
                          />
                        </span>
                      )}
                    </div>

                    <div
                      className="d-flex flex-column w-100 ml-2"
                      style={{ minWidth: 50 }}
                    >
                      <TextStyles.FontSize14px className="text-start three-dots w-100">
                        {data.task_name}
                      </TextStyles.FontSize14px>
                      <TextStyles.GreyFont14px className="text-start three-dots w-100">
                        {data.project_id && data.project_id.project_name}
                      </TextStyles.GreyFont14px>
                    </div>
                  </TextStyles.FontSize14px>

                  <div className="d-flex align-items-center justify-content-end w-100">
                    <TextStyles.GreyFont14px
                      className="mr-3 three-dots"
                      style={{ minWidth: 50 }}
                    >
                      {data.client_id && data.client_id.company_name}
                    </TextStyles.GreyFont14px>

                    <TextStyles.FontSize14px
                      style={{ maxWidth: 90 }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Controller
                        control={control2}
                        name={`update_task.${index}.due_date`}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            className="widthofdatepicker three-dots"
                            onChange={(value) => {
                              onChange(value);
                              setDueDateApiCall(value, data);
                            }}
                            selected={value}
                            placeholderText="Set due date"
                            todayButton="Today"
                            dateFormat="dd MMM yyyy"
                            disabled={isLoading ? true : false}
                            minDate={new Date()}
                          />
                        )}
                      />
                    </TextStyles.FontSize14px>
                  </div>
                </div>

                {filteredTaskStatus === "todo" && (
                  <div
                    className="w-100 d-none d-sm-flex"
                    style={{
                      maxWidth: 150,
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div
                        id={`dashboard-startTimer${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          startTimerHandler(data);
                        }}
                      >
                        <PlayCircleIcon
                          sx={{
                            color: Colors.primary,
                            fontSize: 24,
                            background: Colors.white,
                            cursor: "pointer",
                            padding: "2px 6px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>
                      <ReactTooltip
                        anchorId={`dashboard-startTimer${index}`}
                        content="Start timer"
                      />
                      <div
                        id={`dashboard-doneTask${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          doneTaskCallApi(data);
                        }}
                      >
                        <CheckCircleOutlineIcon
                          sx={{
                            fontSize: 24,
                            color: Colors.font2,
                            background: Colors.white,
                            cursor: "pointer",
                            padding: "2px 6px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`dashboard-doneTask${index}`}
                        content="Done task"
                      />
                      <div
                        id={`dashboard-editTask${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShow(true);
                          setSelectedTaskData(data);
                        }}
                      >
                        <BorderColorOutlinedIcon
                          sx={{
                            color: Colors.success,
                            cursor: "pointer",
                            fontSize: 30,
                            background: Colors.white,
                            padding: "2px 6px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`dashboard-editTask${index}`}
                        content="Edit task"
                      />
                      <div
                        id={`dashboard-deteteTask${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteModal(true);
                          setConfirmDialog({
                            onConfirm: () => deleteTaskHandler(data),
                          });
                        }}
                      >
                        <DeleteForeverRoundedIcon
                          sx={{
                            color: Colors.red,
                            cursor: "pointer",
                            fontSize: 30,
                            background: Colors.white,
                            padding: "2px 2px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`dashboard-deteteTask${index}`}
                        content="Delete task"
                      />
                    </div>
                  </div>
                )}

                {filteredTaskStatus === "future" && (
                  <div
                    className="w-100 d-none d-sm-flex"
                    style={{
                      maxWidth: 120,
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div
                        id={`dashboard-doneTask${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          doneTaskCallApi(data);
                        }}
                      >
                        <CheckCircleOutlineIcon
                          sx={{
                            fontSize: 24,
                            color: Colors.font2,
                            background: Colors.white,
                            cursor: "pointer",
                            padding: "2px 6px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`dashboard-doneTask${index}`}
                        content="Done task"
                      />
                      <div
                        id={`dashboard-editTask${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShow(true);
                          setSelectedTaskData(data);
                        }}
                      >
                        <BorderColorOutlinedIcon
                          sx={{
                            color: Colors.success,
                            cursor: "pointer",
                            fontSize: 30,
                            background: Colors.white,
                            padding: "2px 6px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`dashboard-editTask${index}`}
                        content="Edit task"
                      />
                      <div
                        id={`dashboard-deteteTask${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteModal(true);
                          setConfirmDialog({
                            onConfirm: () => deleteTaskHandler(data),
                          });
                        }}
                      >
                        <DeleteForeverRoundedIcon
                          sx={{
                            color: Colors.red,
                            cursor: "pointer",
                            fontSize: 30,
                            background: Colors.white,
                            padding: "2px 2px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`dashboard-deteteTask${index}`}
                        content="Delete task"
                      />
                    </div>
                  </div>
                )}

                {filteredTaskStatus === "done" && (
                  <div
                    className="w-100 d-none d-sm-flex"
                    style={{
                      maxWidth: 50,
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <div
                      className="dropdown"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <button
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <MoreHorizIcon
                          fontSize="large"
                          sx={{
                            background: Colors.white,
                            cursor: "pointer",
                            borderRadius: "6px",
                            padding: "2px 4px",
                            height: "30px",
                            color: "grey",
                          }}
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                todoTaskCallApi(data);
                              }}
                            >
                              Send to To do
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                setConfirmDialog({
                                  onConfirm: () => deleteTaskHandler(data),
                                });
                              }}
                            >
                              Delete
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                <div
                  className="w-100 d-flex d-sm-none"
                  style={{
                    maxWidth: 50,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div
                    className="dropdown"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <button
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <MoreHorizIcon
                        fontSize="large"
                        sx={{
                          background: Colors.white,
                          cursor: "pointer",
                          borderRadius: "6px",
                          padding: "2px 4px",
                          height: "30px",
                          color: "grey",
                        }}
                      />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {filteredTaskStatus === "todo" && (
                        <>
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  startTimerHandler(data);
                                }}
                              >
                                Start timer
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  doneTaskCallApi(data);
                                }}
                              >
                                Done task
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShow(true);
                                  setSelectedTaskData(data);
                                }}
                              >
                                Edit task
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowDeleteModal(true);
                                  setConfirmDialog({
                                    onConfirm: () => deleteTaskHandler(data),
                                  });
                                }}
                              >
                                Delete task
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                        </>
                      )}
                      {filteredTaskStatus === "future" && (
                        <>
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  doneTaskCallApi(data);
                                }}
                              >
                                Done task
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShow(true);
                                  setSelectedTaskData(data);
                                }}
                              >
                                Edit task
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowDeleteModal(true);
                                  setConfirmDialog({
                                    onConfirm: () => deleteTaskHandler(data),
                                  });
                                }}
                              >
                                Delete task
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                        </>
                      )}
                      {filteredTaskStatus === "done" && (
                        <>
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  todoTaskCallApi(data);
                                }}
                              >
                                Send to To do
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowDeleteModal(true);
                                  setConfirmDialog({
                                    onConfirm: () => deleteTaskHandler(data),
                                  });
                                }}
                              >
                                Delete
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Styled.TodoRectangle>
          );
        })
      ) : selector === "task" && isLoading && taskData === null ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        selector === "task" &&
        !isLoading &&
        !showInput && (
          <Styled.ListBox>
            <img src={NoTaskImage} alt="no-task-img" />
            <TextStyles.GreyFont18px className="mt-2">
              There are no tasks created yet.
            </TextStyles.GreyFont18px>
          </Styled.ListBox>
        )
      )}
      {selector === "task" && (
        <div className="d-flex justify-content-center py-4">
          {taskTotalCount > itemsPerPage && (
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={Math.ceil(taskTotalCount / itemsPerPage)}
              page={page}
            />
          )}
        </div>
      )}
      {selector === "reminder" && <Reminder />}
      {show && (
        <EditTask
          setUpdateRefresh={setUpdateRefresh}
          updateRefresh={updateRefresh}
          selectedTaskData={selectedTaskData}
          handleClose={handleClose}
          show={show}
        />
      )}
      <DeleteModal
        title="task"
        confirmDialog={confirmDialog}
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
    </div>
  );
}
