import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";

const Table = ({ data }) => {
  return (
    <Styled.ReviewBox className="mt-4">
      <div>
        <TextStyles.FontSize14px className="mb-3">
          <div className="mb-2">Table title</div>
          <div className="d-flex gap-1 justify-content-between align-items-center">
            <div
              className="d-flex justify-content-start w-100"
              style={{ minWidth: 100 }}
            >
              <Styled.TextBoader
                className="d-flex w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">{data.table_title}</span>
              </Styled.TextBoader>
            </div>
          </div>
        </TextStyles.FontSize14px>

        <TextStyles.FontSize14px>
          <div className="mb-2">Table description</div>
          {data.input_array &&
            data.input_array.map((item, index) => {
              return (
                <TextStyles.FontSize14px
                  className="d-flex gap-1 gap-sm-2 justify-content-start align-items-center mb-2"
                  key={index}
                >
                  <div className="d-flex justify-content-start">
                    <Styled.TextBoader>{item.input_one}</Styled.TextBoader>
                  </div>
                  <div className="d-flex justify-content-start">
                    <Styled.TextBoader>{item.input_two}</Styled.TextBoader>
                  </div>
                </TextStyles.FontSize14px>
              );
            })}
        </TextStyles.FontSize14px>
      </div>
    </Styled.ReviewBox>
  );
};

export default Table;
