import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

import Constant from "../Commons/Constant";
import Sizes from "../../../Constants/sizes";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput}`,
    height: Sizes.formInputHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0.75rem",
    "& .MuiOutlinedInput-root": {
      padding: "0 2rem 0 0.875rem !important",
      marginRight: "0 !important",
    },
    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      padding: "0 !important",
      fontSize: "1rem",
      color: `${Colors.font1}`,
    },
    "&:hover": {
      border: `1px solid ${Colors.primary}`,
    },
    "&.Mui-focused": {
      border: `1px solid ${Colors.primary}`,
    },
  },
  rootError: {
    border: `1px solid ${Colors.red}`,
    height: Sizes.formInputHeight,
    background: Colors.inputErrorBg,
    borderRadius: sizes.formInputBorderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      color: Colors.font1,
    },
  },

  option: {
    fontFamily: "Poppins",
    fontSize: "1rem",
    color: Colors.font1,
    marginBottom: "2px !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    display: "inherit !important",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },

  input: {
    "& input::placeholder": {
      fontFamily: "Poppins",
      fontSize: "1rem",
      color: Colors.font1,
      opacity: "1",
    },
  },
});

export default function SelectCountry(props) {
  var { placeholder, onChange, label, invalid } = props;
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{
        option: classes.option,
      }}
      className={invalid ? classes.rootError : classes.root}
      id="country-select-demo"
      sx={{ width: props.width }}
      options={Constant.countryOptions}
      value={props.defaultValue ? props.defaultValue : null}
      onChange={(event, value) => {
        onChange(value);
      }}
      getOptionLabel={(option) => `${option.name}(${option.code})`}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      autoHighlight
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.name} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          sx={{
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          }}
          {...params}
          label={label}
          classes={{ root: classes.input }}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            autoComplete: "new-password",
            startAdornment: (
              <InputAdornment position="start">
                <FmdGoodOutlinedIcon
                  sx={{ fontSize: 22, color: Colors.font2 }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
