import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteContact } from "../../../../../store/service/clientService";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import EditContact from "./EditContact.jsx";
import AddContact from "./AddContact.jsx";
import DeleteModal from "../../../Commons/DeleteModal.js";
import { toast } from "react-toastify";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "../../clientStyles";
import { ReactTooltip } from "../../../Commons/ReactTooltip";
import Colors from "../../../../../Constants/Colors.js";

const Contact = () => {
  const dispatch = useDispatch();
  const c_data_position = useSelector((state) => state.clients.c_data_position);

  const [selectedContactData, setSelectedContactData] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseModal = () => setShowModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const deleteContactHandler = (data) => {
    const Obj = {
      client_id: c_data_position._id,
      contact_id: data._id,
    };
    dispatch(deleteContact(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseDeleteModal();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "fail" }
        );
      }
    });
  };

  return (
    <div className="pb-4">
      <div className="d-flex justify-content-between align-items-center my-3">
        <TextStyles.FontSize20px className="d-flex justify-content-start mt-1">
          Contact lists
        </TextStyles.FontSize20px>
        <Styled.Oval
          className="d-flex align-items-center justify-content-center"
          onClick={() => setShow(true)}
          id={`add_contact`}
        >
          <AddOutlinedIcon sx={{ fontSize: 20, color: Colors.white }} />
          <ReactTooltip anchorId={`add_contact`} content="Add contact" />
        </Styled.Oval>
      </div>

      <Styled.TableHead>
        <div className="d-flex gap-2 align-items-center">
          <TextStyles.FontSize14px
            className="d-flex w-100"
            style={{ minWidth: 50 }}
          >
            <span className="three-dots">Contact name</span>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px
            className="d-flex w-100"
            style={{ minWidth: 50 }}
          >
            <span className="three-dots">Email</span>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px
            className="d-flex w-100"
            style={{ minWidth: 50 }}
          >
            <span className="three-dots">Phone number</span>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px
            className="d-flex justify-content-end w-100"
            style={{ maxWidth: 80, minWidth: 80 }}
          />
        </div>
      </Styled.TableHead>

      {c_data_position &&
        c_data_position.contacts &&
        c_data_position.contacts.map((data, index) => {
          return (
            <Styled.TableCell
              key={index}
              style={{
                borderRadius:
                  c_data_position.contacts.length - 1 === index
                    ? "0 0 12px 12px"
                    : "",
              }}
              onClick={() => {
                setShowModal(true);
                setSelectedContactData(data);
              }}
            >
              <div className="d-flex gap-2 align-items-center w-100">
                <TextStyles.FontSize14px
                  className="d-flex w-100"
                  style={{ minWidth: 50 }}
                >
                  <span className="three-dots"> {data.contact_name}</span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex w-100"
                  style={{ minWidth: 50 }}
                >
                  <span className="three-dots"> {data.email}</span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex w-100"
                  style={{ minWidth: 50 }}
                >
                  <span className="three-dots">
                    {" "}
                    {data.phone_code ? `+${data.phone_code.country_code}` : ""}
                    {data.phone ? data.phone : ""}
                  </span>
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px
                  className="d-flex justify-content-end w-100"
                  style={{ maxWidth: 80, minWidth: 80 }}
                >
                  <TextStyles.FontSize14px className="d-flex gap-2">
                    <div
                      id={`edit_contact${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(true);
                        setSelectedContactData(data);
                      }}
                    >
                      <BorderColorOutlinedIcon
                        sx={{
                          color: Colors.success,
                          cursor: "pointer",
                          fontSize: 30,
                          background: Colors.white,
                          padding: "2px 6px",
                          borderRadius: "6px",
                          width: 35,
                          height: 30,
                        }}
                      />
                    </div>
                    <ReactTooltip
                      anchorId={`edit_contact${index}`}
                      content="Edit contact"
                    />
                    <div
                      id={`delete_contact${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteModal(true);
                        setConfirmDialog({
                          onConfirm: () => deleteContactHandler(data),
                        });
                      }}
                    >
                      <DeleteForeverRoundedIcon
                        sx={{
                          color: Colors.red,
                          cursor: "pointer",
                          fontSize: 30,
                          background: Colors.white,
                          padding: "2px 2px",
                          borderRadius: "6px",
                          width: 35,
                          height: 30,
                        }}
                      />
                    </div>
                    <ReactTooltip
                      anchorId={`delete_contact${index}`}
                      content="Delete contact"
                    />
                  </TextStyles.FontSize14px>
                </TextStyles.FontSize14px>
              </div>
            </Styled.TableCell>
          );
        })}

      {showDeleteModal && (
        <DeleteModal
          title="Contact"
          confirmDialog={confirmDialog}
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
        />
      )}
      {showModal && (
        <EditContact
          selectedContactData={selectedContactData}
          handleClose={handleCloseModal}
          show={showModal}
        />
      )}
      {show && <AddContact handleClose={handleClose} show={show} />}
    </div>
  );
};

export default Contact;
