import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import TextStyles from "../../../../Constants/TextStyles";
import {
  updateBrandColor,
  updateBrandLogo,
  removeBrandLogo,
  updateLogoAlignment,
} from "../../../../store/service/AuthService";
import CustomColorPicker from "../../Commons/CustomColorPicker";
import Colors from "../../../../Constants/Colors";
import ClearIcon from "@mui/icons-material/Clear";

import { Modal } from "react-bootstrap";

const BrandAndLogoModal = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const brandAndLogoData = useSelector((state) => state.auth.brandAndLogoData);

  const [arrowForColor, setArrowForColor] = useState(false);
  const [arrowForLogo, setArrowForLogo] = useState(false);
  const [arrowForAlign, setArrowForAlign] = useState(false);

  const [image, setImage] = useState(
    brandAndLogoData &&
      brandAndLogoData.brand_logo &&
      brandAndLogoData.brand_logo.file_name
      ? true
      : false
  );

  const timeout = useRef();

  useEffect(() => {
    setImage(
      brandAndLogoData &&
        brandAndLogoData.brand_logo &&
        brandAndLogoData.brand_logo.file_name
        ? true
        : false
    );
  }, [brandAndLogoData]);

  const updateBrandColorHandlerChange = (color) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      let Obj = {
        brand_color: color,
      };
      dispatch(updateBrandColor(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          toast.success(
            <TextStyles.FontSize14px>
              {data.payload.message}
            </TextStyles.FontSize14px>,
            { toastId: "updateBrandColorSuccess" }
          );
        } else {
          toast.error(
            <TextStyles.FontSize14px>
              {data.error.message}!
            </TextStyles.FontSize14px>,
            { toastId: "updateBrandColorFail" }
          );
        }
      });
    }, 1000);
  };

  const uploadLogohandleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2097152) {
        toast.error(
          <TextStyles.FontSize14px>
            The logo's size exceeds the 2MB limit.
          </TextStyles.FontSize14px>
        );
      }

      var img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = function () {
        if (img.width > 512) {
          toast.error(
            <TextStyles.FontSize14px>
              The width of the logo exceeds 512-pixel limit.
            </TextStyles.FontSize14px>
          );
        } else if (img.height > 512) {
          toast.error(
            <TextStyles.FontSize14px>
              The height of the logo exceeds 512-pixel limit.
            </TextStyles.FontSize14px>
          );
        } else {
          const formData = new FormData();
          formData.append("file", file);
          dispatch(updateBrandLogo(formData)).then((data) => {
            if (data.meta.requestStatus === "fulfilled") {
              toast.success(
                <TextStyles.FontSize14px>
                  {data.payload.message}
                </TextStyles.FontSize14px>
              );
            } else {
              toast.error(
                <TextStyles.FontSize14px>
                  {data.error.message}!
                </TextStyles.FontSize14px>
              );
            }
          });
        }
      };
    }
  };

  const removeLogoHandlerChange = () => {
    const Obj = {
      file_name:
        brandAndLogoData &&
        brandAndLogoData.brand_logo &&
        brandAndLogoData.brand_logo.file_name,
    };
    dispatch(removeBrandLogo(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  const updateLogoAlignmentHandler = (data) => {
    const Obj = {
      logo_alignment: data,
    };
    dispatch(updateLogoAlignment(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight350px"
      fullscreen
    >
      <Modal.Header>
        <TextStyles.FontSize20px style={{ fontWeight: 500 }}>
          Branding
        </TextStyles.FontSize20px>
        <ClearIcon
          sx={{
            color: Colors.font2,
            cursor: "pointer",
            opacity: 0.7,
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-wrap">
          <div className="d-flex flex-column">
            <div className="mb-3">
              <TextStyles.GreyFont18px
                className="d-flex align-items-center mb-2"
                onClick={() => setArrowForColor(!arrowForColor)}
                style={{ cursor: "pointer" }}
              >
                <span
                  className="d-flex"
                  onClick={() => setArrowForColor(!arrowForColor)}
                >
                  Brand color
                  <ExpandMoreIcon
                    sx={{ fontSize: 30, rotate: arrowForColor ? "180deg" : "" }}
                  />
                </span>
              </TextStyles.GreyFont18px>
              {arrowForColor && (
                <CustomColorPicker
                  handleChange={updateBrandColorHandlerChange}
                  defaultValues={
                    brandAndLogoData && brandAndLogoData.brand_color
                  }
                />
              )}
            </div>
            <div className="mb-3">
              <TextStyles.GreyFont18px
                className="d-flex align-items-center mb-2"
                onClick={() => setArrowForLogo(!arrowForLogo)}
                style={{ cursor: "pointer" }}
              >
                Brand logo
                <ExpandMoreIcon
                  sx={{ fontSize: 30, rotate: arrowForLogo ? "180deg" : "" }}
                />
              </TextStyles.GreyFont18px>

              {arrowForLogo && !image && (
                <TextStyles.FontSize16px className="d-flex flex-column gap-3">
                  <TextStyles.GreyFont14px
                    className="text-start"
                    style={{ maxWidth: 220 }}
                  >
                    Kindly provide a JPG, JPEG or PNG file for the logo,
                    ensuring that its dimensions are within 512 x 512 pixels,
                    and that the file size does not exceed 2 MB.
                  </TextStyles.GreyFont14px>

                  <div className="d-flex flex-column gap-3">
                    <input
                      type="file"
                      id="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={uploadLogohandleChange}
                      style={{
                        width: "100%",
                        height: 50,
                        borderRadius: "12px 0 0 12px",
                        background: "transparent",
                        display: "none",
                      }}
                    />
                    <label
                      htmlFor="file"
                      style={{
                        width: 220,
                        height: "auto",
                        display: "flex",
                        padding: "12px",
                        justifyContent: "center",
                        alignItems: "center",
                        background: Colors.white,
                        border: "2px solid #d6d6d6",
                        borderRadius: 12,
                        cursor: "pointer",
                      }}
                    >
                      <CloudUploadIcon
                        sx={{ fontSize: 27, color: Colors.primary }}
                      />
                      <TextStyles.GreyFont16px
                        className="ml-2"
                        style={{
                          fontFamily: 500,
                        }}
                      >
                        Upload logo
                      </TextStyles.GreyFont16px>
                    </label>
                  </div>
                </TextStyles.FontSize16px>
              )}

              {arrowForLogo && image && (
                <TextStyles.FontSize16px className="d-flex gap-3">
                  <div
                    onClick={removeLogoHandlerChange}
                    style={{
                      width: 240,
                      height: "auto",
                      display: "flex",
                      padding: "12px",
                      justifyContent: "center",
                      alignItems: "center",
                      background: Colors.white,
                      border: "2px solid #d6d6d6",
                      borderRadius: 12,
                      cursor: "pointer",
                    }}
                  >
                    <TextStyles.GreyFont16px
                      className="ml-2"
                      style={{
                        fontFamily: 500,
                      }}
                    >
                      Remove logo
                    </TextStyles.GreyFont16px>
                  </div>
                </TextStyles.FontSize16px>
              )}
            </div>
            <div className="mb-4">
              <TextStyles.GreyFont18px
                className="d-flex align-items-center mb-2"
                onClick={() => setArrowForAlign(!arrowForAlign)}
                style={{ cursor: "pointer" }}
              >
                Logo alignment
                <ExpandMoreIcon
                  sx={{ fontSize: 30, rotate: arrowForAlign ? "180deg" : "" }}
                />
              </TextStyles.GreyFont18px>
              {arrowForAlign && (
                <TextStyles.FontSize16px className="d-flex gap-3">
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: 220 }}
                  >
                    <div className="d-flex flex-column gap-2 w-90">
                      <TextStyles.FontSize16px className="d-flex">
                        Left
                      </TextStyles.FontSize16px>
                      <TextStyles.FontSize16px className="d-flex">
                        <FormatAlignLeftIcon
                          onClick={() => updateLogoAlignmentHandler("left")}
                          sx={{
                            fontSize: 27,
                            color:
                              brandAndLogoData &&
                              brandAndLogoData.logo_alignment === "left"
                                ? brandAndLogoData &&
                                  brandAndLogoData.brand_color
                                : Colors.font2,
                            cursor: "pointer",
                          }}
                        />
                      </TextStyles.FontSize16px>
                    </div>
                    <div className="d-flex flex-column gap-2 w-100">
                      <TextStyles.FontSize16px className="d-flex justify-content-center">
                        Center
                      </TextStyles.FontSize16px>
                      <TextStyles.FontSize16px className="d-flex justify-content-center">
                        <FormatAlignCenterIcon
                          onClick={() => updateLogoAlignmentHandler("center")}
                          sx={{
                            fontSize: 27,
                            color:
                              brandAndLogoData &&
                              brandAndLogoData.logo_alignment === "center"
                                ? brandAndLogoData &&
                                  brandAndLogoData.brand_color
                                : Colors.font2,
                            cursor: "pointer",
                          }}
                        />
                      </TextStyles.FontSize16px>
                    </div>
                    <div className="d-flex flex-column gap-2 w-110">
                      <TextStyles.FontSize16px className="d-flex justify-content-end">
                        Right
                      </TextStyles.FontSize16px>
                      <TextStyles.FontSize16px className="d-flex justify-content-end">
                        <FormatAlignRightIcon
                          onClick={() => updateLogoAlignmentHandler("right")}
                          sx={{
                            fontSize: 24,
                            color:
                              brandAndLogoData &&
                              brandAndLogoData.logo_alignment === "right"
                                ? brandAndLogoData &&
                                  brandAndLogoData.brand_color
                                : Colors.font2,
                            cursor: "pointer",
                          }}
                        />
                      </TextStyles.FontSize16px>
                    </div>
                  </div>
                </TextStyles.FontSize16px>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BrandAndLogoModal;
