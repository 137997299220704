import React from "react";
import styles from "./BusinessModal.module.css";
import Colors from "../../../Constants/Colors";

const BusinessModal = () => {
  return (
    <div className={styles.section}>
      <div className="container-fluid py-4 py-md-5">
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center w-100 h-100"
          style={{ overflow: "hidden" }}
        >
          <div className="w-100 mt-3 mt-md-0">
            <div
              className={`${styles.heading} text-center`}
              data-aos={"fade-up"}
            >
              Our unique business model
            </div>
            <div
              className={`${styles.heading_text} text-center mb-3`}
              data-aos={"fade-up"}
            >
              Easily manage your ideal workflow with
              <span
                className="mx-2"
                style={{ fontWeight: 600, color: Colors.brandColorFrontend }}
              >
                Zodot's
              </span>
              building blocks
            </div>
            <div className="d-flex justify-content-center mb-5">
              <div
                data-aos={"fade-up"}
                className={`${styles.text_fontend_brand} text-center`}
                style={{ maxWidth: 1000 }}
              >
                At Zodot, our clear and flexible model prioritizes your success.
                No hidden fees, just straightforward plans tailored to you, with
                ongoing updates included at no extra cost.
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div
            className="row d-flex justify-content-between align-items-center"
            data-aos={"fade-up"}
            style={{ maxWidth: 1100 }}
          >
            <div className="col-12 col-sm-6 col-md-6" data-aos={"zoom-in"}>
              <div
                data-color="primary"
                className={`${styles.feature_container}`}
              >
                <div className={`${styles.feature_container_heading} mb-4`}>
                  User-centric design
                </div>

                <div className={`${styles.container_text} text-start`}>
                  Our software is built with you in mind. Enjoy an intuitive
                  interface that minimizes the learning curve, ensuring you get
                  the most out of our features from day one.
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6" data-aos={"zoom-in"}>
              <div
                data-color="secondary"
                className={`${styles.feature_container}`}
              >
                <div className={`${styles.feature_container_heading} mb-4`}>
                  Scalability
                </div>

                <div className={`${styles.container_text} text-start`}>
                  Whether you're a freelancer or a growing SMB, our software
                  grows with you. Scale your usage effortlessly as your business
                  expands.
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-6 col-md-6  order-4 order-md-3"
              data-aos={"zoom-in"}
            >
              <div
                data-color="secondary"
                className={`${styles.feature_container}`}
              >
                <div className={`${styles.feature_container_heading} mb-4`}>
                  Reliability
                </div>

                <div className={`${styles.container_text} text-start`}>
                  A solid business relationship is established on trust. Zodot
                  is structured with reliability at its core, securing your data
                  and ensuring the seamless execution of your tasks.
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-6 col-md-6 order-3 order-md-4"
              data-aos={"zoom-in"}
            >
              <div
                data-color="primary"
                className={`${styles.feature_container}`}
              >
                <div className={`${styles.feature_container_heading} mb-4`}>
                  Customer-centric support
                </div>

                <div className={`${styles.container_text} text-start`}>
                  From the initial onboarding process to troubleshooting
                  hurdles, our dedicated support team is by your side, committed
                  to ensuring your success.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessModal;
