import React, { useState } from "react";
import styles from "./Features.module.css";
import TimeTracking from "./TimeTracking/TimeTracking";
import Invoices from "./Invoices/Invoices";
import Statements from "./Statements/Statements";
import Agreements from "./Agreements/Agreements";

const SecondHeader = () => {
  const [selected, setSelected] = useState("Invoices");
  return (
    <div className={styles.second_section}>
      <div className="container-fluid py-4 py-md-5">
        <div
          className={`${styles.heading} text-center mb-2`}
          data-aos={"fade-up"}
        >
          A SaaS productivity suite for all your business needs
        </div>
        <div
          className={`${styles.heading_text} text-center mb-3 mb-sm-4`}
          data-aos={"fade-up"}
        >
          Easily manage your ideal workflow with{" "}
          <span className={styles.heading_text_brand}>Zodot's</span> building
          blocks
        </div>
        <div
          className="d-flex justify-content-center mb-5"
          data-aos={"fade-up"}
        >
          <div
            className="d-flex"
            style={{
              overflow: "auto",
            }}
          >
            <div className="d-flex gap-3 pb-3">
              <div
                className={`${styles.section_button} ${
                  selected === "Invoices" ? `${styles.is_active}` : ""
                }`}
                onClick={() => setSelected("Invoices")}
              >
                Invoices
              </div>
              <div
                className={`${styles.section_button} ${
                  selected === "Agreements" ? `${styles.is_active}` : ""
                }`}
                onClick={() => setSelected("Agreements")}
              >
                Agreements
              </div>
              <div
                className={`${styles.section_button} ${
                  selected === "Time tracking" ? `${styles.is_active}` : ""
                }`}
                onClick={() => setSelected("Time tracking")}
              >
                Time tracking
              </div>
              <div
                className={`${styles.section_button} ${
                  selected === "Statements" ? `${styles.is_active}` : ""
                }`}
                onClick={() => setSelected("Statements")}
              >
                Statements
              </div>
            </div>
          </div>
        </div>
        {selected === "Invoices" && <Invoices />}
        {selected === "Agreements" && <Agreements />}
        {selected === "Time tracking" && <TimeTracking />}
        {selected === "Statements" && <Statements />}
      </div>
    </div>
  );
};

export default SecondHeader;
