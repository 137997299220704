import React from "react";
import { NavLink } from "react-router-dom";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";

import styles from "./AdditionalFeatures.module.css";

const AdditionalFeatures = () => {
  return (
    <div className={styles.section}>
      <div className="container-fluid py-3 py-md-4">
        <div
          className={`${styles.heading} text-center mb-1`}
          data-aos={"fade-up"}
        >
          Additional features
        </div>

        <div
          className={`${styles.heading_text} text-center mb-3 mb-sm-4`}
          data-aos={"fade-up"}
        >
          Easily manage your ideal workflow with
          <span className={`${styles.heading_text_brand} mx-2`}>Zodot's</span>
          building blocks
        </div>

        <div
          className="row d-flex justify-content-between align-items-center"
          data-aos={"fade-up"}
        >
          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Manage payments in real-time
              </div>

              <div className={`${styles.container_text} text-start mb-2`}>
                Take charge of your finances with seamless tracking, billing,
                and precise workflow automation. Zodot ensures you stay in
                control, helping you manage payments in real-time.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Instantly view payment alerts
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                No more wondering about your invoices' status. Zodot keeps you
                informed by, notifying you instantly whenever your invoice is
                viewed or settled, ensuring you're always in the loop.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Create invoices in preferred currency
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                Specify your currency of choice and generate invoices
                effortlessly with Zodot’s easy invoicing feature. Bill in your
                preferred currency to cater to your global clientele.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Navigate intuitively
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                Effortlessly navigate Zodot’s user-friendly interface, ensuring
                a smooth invoicing experience without any learning curves. Enjoy
                seamless operations and increased productivity.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Apply discounts easily
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                Implement discounts seamlessly on your invoices. Choose a
                percentage or a fixed amount to create a mutually beneficial
                situation for both you and your clients, enhancing satisfaction.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Make edits for a distinct touch
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                Whether updating your business info or tweaking invoice details,
                Zodot’s intuitive editing features make customization a breeze,
                ensuring everything meets your exact needs.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Invoice globally like a pro
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                Stay on top of invoicing with Zodot. Create and send invoices on
                the go, access your data from anywhere, and enjoy seamless
                syncing for uninterrupted cash flow.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Effortless email invoicing
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                Craft and send invoices effortlessly with Zodot. Preview before
                sending and customize emails using templates or create new ones.
                Elevate your invoicing game with ease and flexibility.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Simplify tax compliance
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                Navigate tax season stress-free by effortlessly maintaining
                accurate records, generating tax-ready invoices, and ensuring
                your finances are always in order with ease.
              </div>
            </div>
          </div>
        </div>

        <NavLink
          data-aos={"fade-up"}
          className={`d-block text-decoration-none mx-auto ${styles.more_button}`}
          to="/db/signup"
        >
          Explore now
          <span className={`${styles.forward_arrow}`}>
            <EastOutlinedIcon sx={{ fontSize: 24 }} />
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default AdditionalFeatures;
