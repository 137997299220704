import React, { useState, useEffect } from "react";
import "./ToolBar.css";
import NavButton from "../SideDrawer/NavButton/NavButton";
import NavBar from "./NavBar/NavBar";

const ToolBar = (props) => {
  const [isTop, setIsTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isOnTop = window.scrollY > props.scroll;
      setIsTop(isOnTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={isTop ? "Toolbar Toolbar--sticky" : "Toolbar"}>
      <NavButton open={props.open} />
      <nav className="DesktopOnly">
        <NavBar scroll={300} />
      </nav>
    </nav>
  );
};

export default ToolBar;
