import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

import Colors from "../../../../../../../Constants/Colors";

const Feedback = (props) => {
  const selectedData = props && props.selectedData;

  const heading = "Feedback";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.number_of_termination_request &&
          selectedData.number_of_termination_request.required === true
            ? "inline"
            : "none",
      }}
    >
      {selectedData &&
        selectedData.number_of_termination_request &&
        selectedData.number_of_termination_request
          .num_of_termination_request_require === true && (
          <Styled.ReviewContentBox className="mt-4">
            <TextStyles.FontSize20px className="mb-2">
              {heading}
            </TextStyles.FontSize20px>
            <TextStyles.FontSize14px>
              {selectedData &&
              selectedData.number_of_termination_request &&
              selectedData.number_of_termination_request
                .num_of_termination_request_require === true &&
              selectedData.number_of_termination_request.num_request ? (
                <div>
                  <div>
                    Client will have the opportunity to provide feedback up to
                    <span
                      className="mx-1"
                      style={{ color: Colors.primary, fontWeight: 500 }}
                    >
                      {selectedData.number_of_termination_request.num_request}
                    </span>{" "}
                    times and Contractor will make revisions up to
                    <span
                      className="mx-1"
                      style={{ color: Colors.primary, fontWeight: 500 }}
                    >
                      {selectedData.number_of_termination_request.num_request}
                    </span>{" "}
                    times based on the provided feedback.
                  </div>
                  <div>
                    <div className="mt-3">
                      a.Clients as well as contractors must establish a process
                      for providing feedback, such as scheduled review meetings
                      or periodic feedback sessions.
                    </div>
                    <div className="mt-3">
                      b. Both the parties define the preferred method of
                      feedback communication, whether it's in person, email, or
                      through the project management system.
                    </div>
                    <div className="mt-3">
                      c. Client is expected to provide feedback on deliverables
                      or milestones within a specific time frame.
                    </div>
                  </div>
                </div>
              ) : (
                "Note: This section shows if only applicable."
              )}
            </TextStyles.FontSize14px>
          </Styled.ReviewContentBox>
        )}
    </div>
  );
};

export default Feedback;
