import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";
import { getFileFromUrl } from "../../../../../Commons/utils";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

const File = ({ data }) => {
  const DownloadForOfflineOutlined = (item) => {
    let filename = item.file_name;
    let indexofUnderscore = filename.indexOf("_");
    let file_name = filename.substring(indexofUnderscore + 1);
    getFileFromUrl(item.file_url, file_name).then((res) => {
      const href = URL.createObjectURL(res);
      const aElement = document.createElement("a");
      aElement.href = href;
      aElement.setAttribute("download", file_name);
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    });
  };

  return (
    <Styled.ReviewBox className="mt-4">
      <TextStyles.FontSize14px className="mb-3">
        <TextStyles.FontSize18px className="mb-2">
          File title
        </TextStyles.FontSize18px>
        <div className="d-flex gap-1 justify-content-between align-items-center">
          <div
            className="d-flex justify-content-start w-100"
            style={{ minWidth: 100 }}
          >
            <Styled.TextBoader
              className="d-flex w-100"
              style={{ minWidth: 100 }}
            >
              <span className="three-dots">{data.file_title}</span>
            </Styled.TextBoader>
          </div>
        </div>
      </TextStyles.FontSize14px>
      <TextStyles.FontSize14px className="mb-3">
        <TextStyles.FontSize18px className="mb-2">
          Files
        </TextStyles.FontSize18px>
        {data &&
          data.files &&
          data.files.map((item, index) => {
            let filename = item.file_name;
            let indexofUnderscore = filename.indexOf("_");
            let file_name = filename.substring(indexofUnderscore + 1);
            return (
              <Styled.FileDownloadContainer
                className="d-flex align-items-center"
                key={index}
                onClick={() => DownloadForOfflineOutlined(item)}
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">
                  <InsertDriveFileOutlinedIcon className="pr-2" />
                  {file_name} - {item.size / 1000000} MB
                </span>
              </Styled.FileDownloadContainer>
            );
          })}
      </TextStyles.FontSize14px>
    </Styled.ReviewBox>
  );
};

export default File;
