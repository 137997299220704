import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useDispatch } from "react-redux";
import {
  fetchBusinessInfo,
  getUserCurrency,
  getUserInfo,
  fetchBrandAndLogo,
} from "../../../store/service/AuthService";

import Business from "./Business/Business.jsx";
import AccountAndSecurity from "./AccountAndSecurity/AccountAndSecurity.jsx";

import Styled from "./SettingStyles";
import Colors from "../../../Constants/Colors";
import ProtectedRoute from "../../auth/ProtectedRoute";
import BusinessInfo from "./Business/components/BusinessInfo";
import Currency from "./Business/components/Currency";
import BrandAndLogo from "./Business/components/BrandAndLogo";

import { BackButton } from "../Commons/BackAndForwardButton";
import UserInfo from "./AccountAndSecurity/components/UserInfo";
import Templates from "./Templates/Templates.jsx";
import { fetchClientCounts } from "../../../store/service/clientService.js";
import { fetchProjectCounts } from "../../../store/service/projectService.js";
import { fetchInvoiceCounts } from "../../../store/service/invoiceService.js";
import { fetchPCCounts } from "../../../store/service/proposalAndContractService.js";
import { fetchTransactionCounts } from "../../../store/service/transactionService.js";
import { fetchActivityCounts } from "../../../store/service/timeTrackingService.js";

const Setting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let urlElements = window.location.href.split("/");
  useEffect(() => {
    dispatch(fetchBusinessInfo());
    dispatch(getUserCurrency());
    dispatch(getUserInfo());
    dispatch(fetchBrandAndLogo());
    dispatch(fetchClientCounts());
    dispatch(fetchProjectCounts());
    dispatch(fetchInvoiceCounts());
    dispatch(fetchPCCounts());
    dispatch(fetchTransactionCounts());
    dispatch(fetchActivityCounts());
  }, []);

  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        padding: "105px 0 60px 0",
        contain: "content",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center px-2 px-sm-3 px-md-4 px-lg-4"
        style={{ height: "4rem" }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            overflowY: "hidden",
            height: "100%",
          }}
        >
          <Styled.SliderBox
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 5px",
            }}
          >
            <div
              onClick={() => {
                history.push("/db/setting/business");
              }}
            >
              {urlElements[5] === "business" ? (
                <Styled.SelectedItem>Business</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem>Business</Styled.NotSelectedItem>
              )}
            </div>

            <div
              onClick={() => {
                history.push("/db/setting/templates");
              }}
            >
              {urlElements[5] === "templates" ? (
                <Styled.SelectedItem>Templates</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem>Templates</Styled.NotSelectedItem>
              )}
            </div>

            <div
              onClick={() => {
                history.push("/db/setting/account");
              }}
            >
              {urlElements[5] === "account" ? (
                <Styled.SelectedItem>Account & Security</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem>
                  Account & Security
                </Styled.NotSelectedItem>
              )}
            </div>
          </Styled.SliderBox>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 25,
          }}
          onClick={() => history.goBack()}
        >
          <BackButton />
        </div>
      </div>
      <div className="px-2 px-sm-3 px-md-4 px-lg-4">
        <ProtectedRoute
          path="/db/setting/business"
          exact
          component={Business}
        />
        <ProtectedRoute
          path="/db/setting/account"
          exact
          component={AccountAndSecurity}
        />
        <ProtectedRoute
          path="/db/setting/templates"
          exact
          component={Templates}
        />

        <ProtectedRoute
          path="/db/setting/business/business_info"
          exact
          component={BusinessInfo}
        />
        <ProtectedRoute
          path="/db/setting/account/user_info"
          exact
          component={UserInfo}
        />
        <ProtectedRoute
          path="/db/setting/business/currency"
          exact
          component={Currency}
        />
        <ProtectedRoute
          path="/db/setting/business/brand_logo"
          exact
          component={BrandAndLogo}
        />
      </div>
    </div>
  );
};

export default Setting;
