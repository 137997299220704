import React, { useState, useEffect, useRef } from "react";
import Header from "./header/header";
import ProtectedRoute from "../auth/ProtectedRoute";
import ClientsDb from "./clients/clients";
import EditClient from "./clients/editClient/editClient";
import ProjectsDb from "./projects/projects";
import EditProject from "./projects/editProject/editProject";
import Transaction from "./Transaction/Transaction";
import TimeTrackingTimer from "./TimeTracking/TimeTrackingTimer";
import ProposalsDB from "./Proposals/Proposals";

import Settings from "./Settings/Setting";
import Invocies from "./invoices/Invocies";
import ViewInvoices from "./invoices/ViewInvoices/ViewInvoices";

import Statement from "./statement/Statement";

// import "./DashboardDrawer.css";
import Styled from "./DashBoardDrawerStyles";
import SideNavContent from "./SideNavContent";
import Backdrop from "./Backdrop";
import Dashboard from "./dashboard/dashboard";
import { useWindowSize } from "./Commons/utils";
import { useDispatch, useSelector } from "react-redux";
import { timeTrackingActions } from "../../store/storage/timetrackingSlice";
import Colors from "../../Constants/Colors";

import GlobalTimerBox from "./TimeTracking/GlobalTimer/GlobalTimerBox";

import {
  fetchBusinessInfo,
  getUserCurrency,
  getUserInfo,
  getProfileInfoPic,
  fetchBrandAndLogo,
  fetchTemplates,
  fetchUserTourSetupData,
} from "../../store/service/AuthService";

import {
  fetchActiveActivity,
  fetchAllActivityLists,
} from "../../store/service/timeTrackingService";

import {
  fetchProjectForOptions,
  fetchProjectsForAClient,
} from "../../store/service/projectService";
import {
  fetchClientForAProject,
  fetchClientsForOption,
} from "../../store/service/clientService";

const DashboardDrawer = () => {
  const dispatch = useDispatch();
  const startActivityData = useSelector(
    (state) => state.timeTracking.startActivityData
  );
  const location = window.location.pathname;
  const locationArray = location.split("/");

  useEffect(() => {
    dispatch(fetchUserTourSetupData());
    dispatch(fetchProjectForOptions());
    dispatch(fetchAllActivityLists());
    dispatch(fetchActiveActivity());
    dispatch(getProfileInfoPic());
    dispatch(fetchBusinessInfo());
    dispatch(getUserInfo());
    dispatch(getUserCurrency());
    dispatch(fetchBrandAndLogo());
    dispatch(fetchClientsForOption());
    dispatch(fetchTemplates());
    dispatch(fetchProjectsForAClient());
    dispatch(fetchClientForAProject());
  }, []);

  const [toggle, setToggle] = useState(false);
  const [width] = useWindowSize();

  const [showGlobalTimer, setShowGlobalTimer] = useState(false);
  const scrolledHeight = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      scrolledHeight.current =
        window.scrollY || document.documentElement.scrollTop;
      if (scrolledHeight.current > 300) {
        setShowGlobalTimer(true);
      } else {
        setShowGlobalTimer(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (width > 992) {
      setToggle(false);
    }
  }, [width]);

  const toggleHandler = () => {
    setToggle(!toggle);
  };

  const closeHandler = () => {
    setToggle(false);
  };

  useEffect(() => {
    if (startActivityData && startActivityData.start_time) {
      const interval = setInterval(() => {
        dispatch(timeTrackingActions.updateTimer());
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [startActivityData.start_time]);

  // useEffect(() => {
  //   if (startActivityData && startActivityData.start_time) {
  //     document.title = "Dynamic Title 1";
  //   }
  //   return () => {
  //     document.title = "Original Title";
  //   };
  // }, [startActivityData.start_time]);

  return (
    <div>
      <Styled.DashboardDrawer className="d-flex">
        <SideNavContent show={toggle} closeHandler={closeHandler} />
        <div className="dashboard-content">
          <Backdrop closeHandler={closeHandler} show={toggle} />
          <Header
            title="My Desk"
            SideDrawerHandler={toggleHandler}
            show={toggle}
          />
          {startActivityData && startActivityData.start_time && (
            <div
              style={{
                position: "fixed",
                boxSizing: "border-box",
                left: width > 992 ? 265 : 15,
                right: width > 992 ? 25 : 15,
                bottom:
                  locationArray[3] === "timers" &&
                  showGlobalTimer &&
                  startActivityData &&
                  startActivityData.start_time
                    ? 3
                    : locationArray[3] === "timers" &&
                      showGlobalTimer === false &&
                      startActivityData &&
                      startActivityData.start_time
                    ? -100
                    : locationArray[4] === "timers" &&
                      showGlobalTimer &&
                      startActivityData &&
                      startActivityData.start_time
                    ? 3
                    : locationArray[4] === "timers" &&
                      showGlobalTimer === false &&
                      startActivityData &&
                      startActivityData.start_time
                    ? -100
                    : startActivityData && startActivityData.start_time
                    ? 3
                    : -100,
                padding: "6px 12px",
                background: Colors.red,
                zIndex: 300,
                borderRadius: "12px 12px 12px 12px",
                transition: "all 1s ease-in-out",
              }}
            >
              <GlobalTimerBox />
            </div>
          )}

          {/* my desk related routes */}
          <ProtectedRoute path="/db/home" exact component={Dashboard} />

          {/* clients related routes */}
          <ProtectedRoute path="/db/clients" exact component={ClientsDb} />

          <ProtectedRoute
            path="/db/clients/:id/tasks"
            exact
            component={EditClient}
          />
          <ProtectedRoute
            path="/db/clients/:id/address"
            exact
            component={EditClient}
          />
          <ProtectedRoute
            path="/db/clients/:id/projects"
            exact
            component={EditClient}
          />
          <ProtectedRoute
            path="/db/clients/:id/invoices"
            exact
            component={EditClient}
          />
          <ProtectedRoute
            path="/db/clients/:id/transactions"
            exact
            component={EditClient}
          />
          <ProtectedRoute
            path="/db/clients/:id/notes"
            exact
            component={EditClient}
          />
          {/* projects related routes */}
          <ProtectedRoute path="/db/projects" exact component={ProjectsDb} />
          <ProtectedRoute
            path="/db/projects/:id/terms"
            exact
            component={EditProject}
          />
          <ProtectedRoute
            path="/db/projects/:id/tasks"
            exact
            component={EditProject}
          />
          <ProtectedRoute
            path="/db/projects/:id/timers"
            exact
            component={EditProject}
          />
          <ProtectedRoute
            path="/db/projects/:id/weekviews"
            exact
            component={EditProject}
          />
          <ProtectedRoute
            path="/db/projects/:id/reports"
            exact
            component={EditProject}
          />
          <ProtectedRoute
            path="/db/projects/:id/invoices"
            exact
            component={EditProject}
          />
          <ProtectedRoute
            path="/db/projects/:id/transactions"
            exact
            component={EditProject}
          />
          <ProtectedRoute
            path="/db/projects/:id/notes"
            exact
            component={EditProject}
          />
          {/* transaction related routes */}
          <ProtectedRoute
            path="/db/transactions"
            exact
            component={Transaction}
          />
          {/* time tracting related routes */}
          <ProtectedRoute
            path="/db/timetracking/timers"
            exact
            component={TimeTrackingTimer}
          />
          <ProtectedRoute
            path="/db/timetracking/weekview"
            exact
            component={TimeTrackingTimer}
          />
          <ProtectedRoute
            path="/db/timetracking/reports"
            exact
            component={TimeTrackingTimer}
          />
          {/* Aggreements related routes */}
          <ProtectedRoute path="/db/agreements" exact component={ProposalsDB} />

          {/* setting related routes */}
          <ProtectedRoute
            path="/db/setting/business"
            exact
            component={Settings}
          />

          <ProtectedRoute
            path="/db/setting/business/business_info"
            exact
            component={Settings}
          />
          <ProtectedRoute
            path="/db/setting/business/currency"
            exact
            component={Settings}
          />
          <ProtectedRoute
            path="/db/setting/business/brand_logo"
            exact
            component={Settings}
          />

          <ProtectedRoute
            path="/db/setting/templates"
            exact
            component={Settings}
          />

          <ProtectedRoute
            path="/db/setting/account"
            exact
            component={Settings}
          />
          <ProtectedRoute
            path="/db/setting/account/user_info"
            exact
            component={Settings}
          />

          {/* invoices related routes */}

          <ProtectedRoute path="/db/invoices" exact component={Invocies} />
          <ProtectedRoute
            path="/db/invoices/view/:id"
            exact
            component={ViewInvoices}
          />
          <ProtectedRoute
            path="/db/invoices/view/:id/details"
            exact
            component={ViewInvoices}
          />
          <ProtectedRoute
            path="/db/invoices/view/:id/notes"
            exact
            component={ViewInvoices}
          />

          {/* statements related routes */}
          <ProtectedRoute
            path="/db/statements/incomes"
            exact
            component={Statement}
          />
          <ProtectedRoute
            path="/db/statements/expenses"
            exact
            component={Statement}
          />
          <ProtectedRoute
            path="/db/statements/taxes"
            exact
            component={Statement}
          />
        </div>
      </Styled.DashboardDrawer>
    </div>
  );
};

export default React.memo(DashboardDrawer);
