import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const ProgressBar = styled.div`
  width: 100%;
  height: 1.687rem;
  margin-bottom: 6px;
  display: flex;
  font-family: Poppins;
  align-items: center;
  font-size: 0.875rem;
  color: ${Colors.font2};
  background: rgb(218, 222, 236);
`;

const SubmitButton = styled.button`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
  min-width: fit-content;
  min-width: 6rem;
  padding: 0 1.5rem;
  height: 3.15rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};

  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? `${props.backgroundColor}`
        : `${Colors.hoverButtonColor}`};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${Colors.primary}`};
  }

  &:disabled {
    opacity: 0.5 !important;
  }
`;

export default {
  ProgressBar,
  SubmitButton,
};
