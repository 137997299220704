import React from "react";
import moment from "moment";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "./PageOne";

const ContractDateDetails = (props) => {
  const selectedData = props && props.selectedData;

  return (
    <Styled.ReviewContentBox>
      <TextStyles.FontSize14px>
        This Agreement (the "Contract") is made and entered into by the parties
        listed below (the "Parties"). This offer is valid until the close of
        business on
        <span className="mx-2">
          {selectedData &&
            selectedData.contract_expire_date &&
            moment(selectedData.contract_expire_date).format("DD MMM YYYY")}
        </span>
        and it must be accepted in writing by counter-signing this Agreement by
        the specified date to be considered valid.
      </TextStyles.FontSize14px>
    </Styled.ReviewContentBox>
  );
};

export default ContractDateDetails;
