import { createSlice } from "@reduxjs/toolkit";

import {
  fetchPC,
  createPC,
  updateProjectDetailPC,
  updateClientDetailPC,
  updateServiceListPC,
  updateBillingSchedulePC,
  createAddSectionTextPC,
  deleteAddSectionTextPC,
  updateAddSectionTextPC,
  createAddSectionImagePC,
  deleteAddSectionImagePC,
  updateAddSectionImagePC,
  createAddSectionTablePC,
  deleteAddSectionTablePC,
  updateAddSectionTablePC,
  createAddSectionColumnPC,
  deleteAddSectionColumnPC,
  updateAddSectionColumnPC,
  createAddSectionFilePC,
  updateAddSectionFilePC,
  deleteAddSectionFilePC,
  createContact,
  updateSignaturePC,
  emailSendPC,
  contractSettingsPC,
  contractExpireDatePC,
  updateAcceptancePC,
  updateResponsibilityPC,
  updateCompensationPC,
  updateWarrantyPC,
  updateConfidentialPC,
  updateOwnershipRightPC,
  updateNonSolicitPC,
  updateAddNonCompletePC,
  updateRelationshipOfPartiesPC,
  updateProjectTerminationNoticePeriodPC,
  updateCancellationFeePC,
  updatePaymentTermsPC,
  updateContractorTaxIdPC,
  updateHealthInsurancePC,
  updateLatePaymentPC,
  updateDepositPC,
  updateReimbursementPC,
  updateTerminationRequestPC,
  updateChangesPC,
  updateLiabilityLimitationPC,
  updateRetainRightsPC,
  updateGoverningLawPC,
  updateForceMejeurePC,
  updateNoticesPC,
  updateAddSexualHarassmentCluasePC,
  updatePrivacyDataPC,
  updateMiscellaneousPC,
  updateEntireContractPC,
  createAddClausePC,
  updateAddClausePC,
  deletePC,
  dismissedPcByUser,
  approvedPcByUser,
  fetchPCCounts,
} from "../service/proposalAndContractService";

const proposalInitialState = {
  pcData: null,
  totalPcCount: null,
  isLoading: false,
  error: null,
  selectedData: null,
};

export const proposalSlice = createSlice({
  name: "proposals",
  initialState: proposalInitialState,
  reducers: {
    editPositionPcData: (state, action) => {
      state.selectedData = action.payload;
    },
  },

  extraReducers: {
    // fetch proposal and contract
    [fetchPC.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPC.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.pcData = action.payload.data.resData;
      state.totalPcCount = action.payload.data.totalPcCount;
    },
    [fetchPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create proposal contract
    [createPC.pending]: (state) => {
      state.isLoading = true;
    },
    [createPC.fulfilled]: (state, action) => {
      state.selectedData = action.payload.data;
      state.isLoading = false;
    },
    [createPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update Project details in PC

    [updateProjectDetailPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateProjectDetailPC.fulfilled]: (state, action) => {
      state.selectedData.client_id = action.payload.data.client_id;
      state.selectedData.project_name = action.payload.data.project_name;
      state.selectedData.currency = action.payload.data.currency;
      state.selectedData.project_start_date =
        action.payload.data.project_start_date;
      state.selectedData.project_end_date =
        action.payload.data.project_end_date;
      state.selectedData.client_email = action.payload.data.client_email;
      state.isLoading = false;
    },
    [updateProjectDetailPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update client details in PC
    [updateClientDetailPC.pending]: (state) => {
      state.isLoading = true;
    },

    [updateClientDetailPC.fulfilled]: (state, action) => {
      state.selectedData.client_id = action.payload.data.client_id;
      state.selectedData.client_email = action.payload.data.client_email;
      state.isLoading = false;
    },

    [updateClientDetailPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit service_list proposal contract

    [updateServiceListPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateServiceListPC.fulfilled]: (state, action) => {
      state.selectedData.service_list = action.payload.data.service_list;
      state.isLoading = false;
    },
    [updateServiceListPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit service_list proposal contract

    [updateBillingSchedulePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateBillingSchedulePC.fulfilled]: (state, action) => {
      state.selectedData.billing_scheduled =
        action.payload.data.billing_scheduled;
      state.isLoading = false;
    },
    [updateBillingSchedulePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create add section text

    [createAddSectionTextPC.pending]: (state) => {
      state.isLoading = true;
    },
    [createAddSectionTextPC.fulfilled]: (state, action) => {
      state.selectedData.add_section_text_one =
        action.payload.data.add_section_text_one;
      state.selectedData.add_section_text_two =
        action.payload.data.add_section_text_two;
      state.isLoading = false;
    },
    [createAddSectionTextPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete add section text

    [deleteAddSectionTextPC.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteAddSectionTextPC.fulfilled]: (state, action) => {
      state.selectedData.add_section_text_one =
        action.payload.data.add_section_text_one;
      state.selectedData.add_section_text_two =
        action.payload.data.add_section_text_two;
      state.isLoading = false;
    },
    [deleteAddSectionTextPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update add section text

    [updateAddSectionTextPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAddSectionTextPC.fulfilled]: (state, action) => {
      state.selectedData.add_section_text_one =
        action.payload.data.add_section_text_one;
      state.selectedData.add_section_text_two =
        action.payload.data.add_section_text_two;
      state.isLoading = false;
    },
    [updateAddSectionTextPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create add section image

    [createAddSectionImagePC.pending]: (state) => {
      state.isLoading = true;
    },
    [createAddSectionImagePC.fulfilled]: (state, action) => {
      state.selectedData.add_section_image_one =
        action.payload.data.add_section_image_one;
      state.selectedData.add_section_image_two =
        action.payload.data.add_section_image_two;
      state.isLoading = false;
    },
    [createAddSectionImagePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete add section image
    [deleteAddSectionImagePC.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteAddSectionImagePC.fulfilled]: (state, action) => {
      state.selectedData.add_section_image_one =
        action.payload.data.add_section_image_one;
      state.selectedData.add_section_image_two =
        action.payload.data.add_section_image_two;
      state.isLoading = false;
    },
    [deleteAddSectionImagePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update add section image

    [updateAddSectionImagePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAddSectionImagePC.fulfilled]: (state, action) => {
      state.selectedData.add_section_image_one =
        action.payload.data.add_section_image_one;
      state.selectedData.add_section_image_two =
        action.payload.data.add_section_image_two;
      state.isLoading = false;
    },
    [updateAddSectionImagePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // create add section table

    [createAddSectionTablePC.pending]: (state) => {
      state.isLoading = true;
    },
    [createAddSectionTablePC.fulfilled]: (state, action) => {
      state.selectedData.add_section_table_one =
        action.payload.data.add_section_table_one;
      state.selectedData.add_section_table_two =
        action.payload.data.add_section_table_two;
      state.isLoading = false;
    },
    [createAddSectionTablePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete add section table
    [deleteAddSectionTablePC.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteAddSectionTablePC.fulfilled]: (state, action) => {
      state.selectedData.add_section_table_one =
        action.payload.data.add_section_table_one;
      state.selectedData.add_section_table_two =
        action.payload.data.add_section_table_two;
      state.isLoading = false;
    },
    [deleteAddSectionTablePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update add section table

    [updateAddSectionTablePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAddSectionTablePC.fulfilled]: (state, action) => {
      state.selectedData.add_section_table_one =
        action.payload.data.add_section_table_one;
      state.selectedData.add_section_table_two =
        action.payload.data.add_section_table_two;
      state.isLoading = false;
    },
    [updateAddSectionTablePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create add section column
    [createAddSectionColumnPC.pending]: (state) => {
      state.isLoading = true;
    },
    [createAddSectionColumnPC.fulfilled]: (state, action) => {
      state.selectedData.add_section_column_one =
        action.payload.data.add_section_column_one;
      state.selectedData.add_section_column_two =
        action.payload.data.add_section_column_two;
      state.isLoading = false;
    },
    [createAddSectionColumnPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // delete add section column
    [deleteAddSectionColumnPC.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteAddSectionColumnPC.fulfilled]: (state, action) => {
      state.selectedData.add_section_column_one =
        action.payload.data.add_section_column_one;
      state.selectedData.add_section_column_two =
        action.payload.data.add_section_column_two;
      state.isLoading = false;
    },
    [deleteAddSectionColumnPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // update add section column

    [updateAddSectionColumnPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAddSectionColumnPC.fulfilled]: (state, action) => {
      state.selectedData.add_section_column_one =
        action.payload.data.add_section_column_one;
      state.selectedData.add_section_column_two =
        action.payload.data.add_section_column_two;
      state.isLoading = false;
    },
    [updateAddSectionColumnPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create add section file

    [createAddSectionFilePC.pending]: (state) => {
      state.isLoading = true;
    },
    [createAddSectionFilePC.fulfilled]: (state, action) => {
      state.selectedData.add_section_file_one =
        action.payload.data.add_section_file_one;
      state.selectedData.add_section_file_two =
        action.payload.data.add_section_file_two;
      state.isLoading = false;
    },
    [createAddSectionFilePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update add section file

    [updateAddSectionFilePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAddSectionFilePC.fulfilled]: (state, action) => {
      state.selectedData.add_section_file_one =
        action.payload.data.add_section_file_one;
      state.selectedData.add_section_file_two =
        action.payload.data.add_section_file_two;
      state.isLoading = false;
    },
    [updateAddSectionFilePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete add section File
    [deleteAddSectionFilePC.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteAddSectionFilePC.fulfilled]: (state, action) => {
      state.selectedData.add_section_file_one =
        action.payload.data.add_section_file_one;
      state.selectedData.add_section_file_two =
        action.payload.data.add_section_file_two;
      state.isLoading = false;
    },
    [deleteAddSectionFilePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create a contact for a specific client
    [createContact.pending]: (state) => {
      state.isLoading = true;
    },
    [createContact.fulfilled]: (state, action) => {
      state.selectedData.client_id.contacts = action.payload.data.contacts;
      state.isLoading = false;
    },
    [createContact.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update signature

    [updateSignaturePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateSignaturePC.fulfilled]: (state, action) => {
      state.selectedData.signature = action.payload.data;
      state.isLoading = false;
    },
    [updateSignaturePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // send email pc

    [emailSendPC.pending]: (state) => {
      state.isLoading = true;
    },
    [emailSendPC.fulfilled]: (state, action) => {
      state.selectedData.send_email = action.payload.data.send_email;
      state.selectedData.status = action.payload.data.status;

      state.isLoading = false;
    },
    [emailSendPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update contract settings

    [contractSettingsPC.pending]: (state) => {
      state.isLoading = true;
    },
    [contractSettingsPC.fulfilled]: (state, action) => {
      state.selectedData.contract_expire_date =
        action.payload.data.contract_expire_date;
      state.selectedData.payment_terms = action.payload.data.payment_terms;
      state.selectedData.term_and_termination =
        action.payload.data.term_and_termination;
      state.selectedData.number_of_termination_request =
        action.payload.data.number_of_termination_request;
      state.selectedData.ownership_right = action.payload.data.ownership_right;
      state.selectedData.rightToAuthorship =
        action.payload.data.rightToAuthorship;
      state.selectedData.late_payment_fee =
        action.payload.data.late_payment_fee;
      state.selectedData.cancellation_fee =
        action.payload.data.cancellation_fee;
      state.selectedData.add_non_complete =
        action.payload.data.add_non_complete;
      state.selectedData.add_sexual_harassment_clause =
        action.payload.data.add_sexual_harassment_clause;
      state.isLoading = false;
    },
    [contractSettingsPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update client contract
    [contractExpireDatePC.pending]: (state) => {
      state.isLoading = true;
    },
    [contractExpireDatePC.fulfilled]: (state, action) => {
      state.selectedData.contract_expire_date =
        action.payload.data.contract_expire_date;
      state.isLoading = false;
    },
    [contractExpireDatePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update responsibility
    [updateResponsibilityPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateResponsibilityPC.fulfilled]: (state, action) => {
      state.selectedData.responsibility = action.payload.data.responsibility;
      state.isLoading = false;
    },
    [updateResponsibilityPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update compensation
    [updateCompensationPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCompensationPC.fulfilled]: (state, action) => {
      state.selectedData.compensation = action.payload.data.compensation;
      state.isLoading = false;
    },
    [updateCompensationPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update acceptance
    [updateAcceptancePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAcceptancePC.fulfilled]: (state, action) => {
      state.selectedData.acceptance = action.payload.data.acceptance;
      state.isLoading = false;
    },
    [updateAcceptancePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update warranty
    [updateWarrantyPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateWarrantyPC.fulfilled]: (state, action) => {
      state.selectedData.warranty = action.payload.data.warranty;
      state.isLoading = false;
    },
    [updateWarrantyPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update confidential
    [updateConfidentialPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateConfidentialPC.fulfilled]: (state, action) => {
      state.selectedData.confidential = action.payload.data.confidential;
      state.isLoading = false;
    },
    [updateConfidentialPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update ownership right
    [updateOwnershipRightPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateOwnershipRightPC.fulfilled]: (state, action) => {
      state.selectedData.ownership_right = action.payload.data.ownership_right;
      state.isLoading = false;
    },
    [updateOwnershipRightPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update non solicit
    [updateNonSolicitPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateNonSolicitPC.fulfilled]: (state, action) => {
      state.selectedData.non_solicit = action.payload.data.non_solicit;
      state.isLoading = false;
    },
    [updateNonSolicitPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update add non complete clause
    [updateAddNonCompletePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAddNonCompletePC.fulfilled]: (state, action) => {
      state.selectedData.add_non_complete =
        action.payload.data.add_non_complete;
      state.isLoading = false;
    },
    [updateAddNonCompletePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update add non complete clause
    [updateRelationshipOfPartiesPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateRelationshipOfPartiesPC.fulfilled]: (state, action) => {
      state.selectedData.relationship_of_parties =
        action.payload.data.relationship_of_parties;
      state.isLoading = false;
    },
    [updateRelationshipOfPartiesPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update project termination_notice_period
    [updateProjectTerminationNoticePeriodPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateProjectTerminationNoticePeriodPC.fulfilled]: (state, action) => {
      state.selectedData.term_and_termination =
        action.payload.data.term_and_termination;
      state.isLoading = false;
    },
    [updateProjectTerminationNoticePeriodPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update contractor tax payer id
    [updateContractorTaxIdPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateContractorTaxIdPC.fulfilled]: (state, action) => {
      state.selectedData.contractor_tax_id =
        action.payload.data.contractor_tax_id;
      state.isLoading = false;
    },
    [updateContractorTaxIdPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update health and medical insurance
    [updateHealthInsurancePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateHealthInsurancePC.fulfilled]: (state, action) => {
      state.selectedData.health_insurance =
        action.payload.data.health_insurance;
      state.isLoading = false;
    },
    [updateHealthInsurancePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update project termination_notice_period
    [updateCancellationFeePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCancellationFeePC.fulfilled]: (state, action) => {
      state.selectedData.cancellation_fee =
        action.payload.data.cancellation_fee;
      state.isLoading = false;
    },
    [updateCancellationFeePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update payment terms
    [updatePaymentTermsPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updatePaymentTermsPC.fulfilled]: (state, action) => {
      state.selectedData.payment_terms = action.payload.data.payment_terms;
      state.isLoading = false;
    },
    [updatePaymentTermsPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update late payment
    [updateLatePaymentPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateLatePaymentPC.fulfilled]: (state, action) => {
      state.selectedData.late_payment_fee =
        action.payload.data.late_payment_fee;
      state.isLoading = false;
    },
    [updateLatePaymentPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update deposit
    [updateDepositPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateDepositPC.fulfilled]: (state, action) => {
      state.selectedData.deposit = action.payload.data.deposit;
      state.isLoading = false;
    },
    [updateDepositPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update reimbursement
    [updateReimbursementPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateReimbursementPC.fulfilled]: (state, action) => {
      state.selectedData.reimbursement = action.payload.data.reimbursement;
      state.isLoading = false;
    },
    [updateReimbursementPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update termination request
    [updateTerminationRequestPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateTerminationRequestPC.fulfilled]: (state, action) => {
      state.selectedData.number_of_termination_request =
        action.payload.data.number_of_termination_request;
      state.isLoading = false;
    },
    [updateTerminationRequestPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update changes in contracts
    [updateChangesPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateChangesPC.fulfilled]: (state, action) => {
      state.selectedData.changes = action.data.payload.changes;
      state.isLoading = false;
    },
    [updateChangesPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update liability and limitation
    [updateLiabilityLimitationPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateLiabilityLimitationPC.fulfilled]: (state, action) => {
      state.selectedData.liabilityLimitation =
        action.payload.data.liabilityLimitation;
      state.isLoading = false;
    },
    [updateLiabilityLimitationPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update retain right
    [updateRetainRightsPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateRetainRightsPC.fulfilled]: (state, action) => {
      state.selectedData.rightToAuthorship =
        action.payload.data.rightToAuthorship;
      state.isLoading = false;
    },
    [updateRetainRightsPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update Governing law
    [updateGoverningLawPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateGoverningLawPC.fulfilled]: (state, action) => {
      state.selectedData.governingLaw = action.payload.data.governingLaw;
      state.isLoading = false;
    },
    [updateGoverningLawPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update force mejure
    [updateForceMejeurePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateForceMejeurePC.fulfilled]: (state, action) => {
      state.selectedData.forceMejeure = action.payload.data.forceMejeure;
      state.isLoading = false;
    },
    [updateForceMejeurePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update force mejure
    [updateNoticesPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateNoticesPC.fulfilled]: (state, action) => {
      state.selectedData.notices = action.payload.data.notices;
      state.isLoading = false;
    },
    [updateNoticesPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update add sexual harassment clause
    [updateAddSexualHarassmentCluasePC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAddSexualHarassmentCluasePC.fulfilled]: (state, action) => {
      state.selectedData.add_sexual_harassment_clause =
        action.payload.data.add_sexual_harassment_clause;
      state.isLoading = false;
    },
    [updateAddSexualHarassmentCluasePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update privacy data

    [updatePrivacyDataPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updatePrivacyDataPC.fulfilled]: (state, action) => {
      state.selectedData.privacy_data = action.payload.data.privacy_data;
      state.isLoading = false;
    },
    [updatePrivacyDataPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update miscellaneous

    [updateMiscellaneousPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateMiscellaneousPC.fulfilled]: (state, action) => {
      state.selectedData.miscellaneous = action.payload.data.miscellaneous;
      state.isLoading = false;
    },
    [updateMiscellaneousPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update entire contract
    [updateEntireContractPC.pending]: (state) => {
      state.isLoading = true;
    },
    [updateEntireContractPC.fulfilled]: (state, action) => {
      state.selectedData.entire_contract = action.payload.data.entire_contract;
      state.isLoading = false;
    },
    [updateEntireContractPC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //  add clause
    [createAddClausePC.pending]: (state) => {
      state.isLoading = true;
    },
    [createAddClausePC.fulfilled]: (state, action) => {
      state.selectedData.add_clause = action.payload.data.add_clause;
      state.isLoading = false;
    },
    [createAddClausePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update add clause
    [updateAddClausePC.pending]: (state) => {
      state.isLoading = true;
    },

    [updateAddClausePC.fulfilled]: (state, action) => {
      state.selectedData.add_clause = action.payload.data.add_clause;
      state.isLoading = false;
    },
    [updateAddClausePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete PC
    [deletePC.pending]: (state) => {
      state.isLoading = true;
    },
    [deletePC.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deletePC.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // approved pc by user
    [approvedPcByUser.pending]: (state) => {
      state.isLoading = true;
    },
    [approvedPcByUser.fulfilled]: (state, action) => {
      const index = state.pcData.findIndex(
        (pc) => pc._id === action.payload.data._id
      );
      state.pcData[index].status = action.payload.data.status;
      state.isLoading = false;
    },
    [approvedPcByUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // dismissed PC
    [dismissedPcByUser.pending]: (state) => {
      state.isLoading = true;
    },
    [dismissedPcByUser.fulfilled]: (state, action) => {
      const index = state.pcData.findIndex(
        (pc) => pc._id === action.payload.data._id
      );
      state.pcData[index].status = action.payload.data.status;
      state.isLoading = false;
    },
    [dismissedPcByUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch pc counts
    [fetchPCCounts.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchPCCounts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.totalPcCount = action.payload.data.totalPcCount;
    },
    [fetchPCCounts.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export const pcActions = proposalSlice.actions;
export default proposalSlice.reducer;
