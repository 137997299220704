import React, { useRef } from "react";
import TextStyles from "../../../../../Constants/TextStyles";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectNotes } from "../../../../../store/service/projectService";

export default function notes() {
  const timeout = useRef();
  const dispatch = useDispatch();
  const selectedProject = useSelector((state) => state.project.selectedProject);

  const updateNotes = (data) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      let obj = {
        project_id: selectedProject._id,
        notes: data,
      };
      dispatch(updateProjectNotes(obj));
    }, 1000);
  };

  return (
    <div>
      <TextStyles.FontSize20px className="d-flex my-4">
        Notes
      </TextStyles.FontSize20px>
      <TextStyles.FontSize14px>
        <TextStyles.Textarea
          rows={15}
          placeholder="Add a note..."
          defaultValue={selectedProject.notes || ""}
          onChange={(e) => {
            updateNotes(e.target.value);
          }}
        />
      </TextStyles.FontSize14px>
    </div>
  );
}
