import React from "react";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

import styles from "./SuccessStory.module.css";
import SignupForm2 from "../../UIs/SignupForm2";

import capterraImg from "../../../Assets/frontendImages/AboutUsSuccessStory2.avif";
import g2Img from "../../../Assets/frontendImages/AboutUsSuccessStory3.webp";
import trustImg from "../../../Assets/frontendImages/AboutUsSuccessStory1.avif";

const SuccessStory = () => {
  return (
    <div className={styles.section}>
      <div
        className="container-fluid py-4 py-md-5"
        style={{ overflow: "hidden" }}
      >
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          data-aos={"fade-up"}
        >
          <div style={{ maxWidth: 1150 }}>
            <div className={`${styles.heading} text-center mb-4 mb-md-5`}>
              Your success, our priority
            </div>

            <div className="d-flex gap-4 gap-md-5 flex-wrap flex-md-nowrap justify-content-between">
              <div className="w-100">
                <div className={`${styles.heading_text} mb-4 mb-md-5 mt-3`}>
                  At Zodot, we believe your success is our success. Join
                  freelancers, SMBs, and entrepreneurs who trust Zodot as their
                  business companion. Start your success story now with
                  efficiency, innovation, and growth. Welcome to the future of
                  end-to-end Saas business management.
                </div>

                <div className={`${styles.heading_text} mb-4 mb-md-5`}>
                  Take the leap towards efficiency, innovation, and growth with
                  Zodot. Your business deserves the best, and we're here to
                  deliver. Welcome to the future of business management.
                </div>
                <div className={`${styles.heading_text} mb-4 mb-md-5`}>
                  Experience unparalleled productivity and streamlined
                  operations with Zodot. Our comprehensive suite of tools
                  ensures that managing tasks, tracking time, and handling
                  projects are seamless and intuitive.
                </div>
                <div className="d-flex justify-content-between mt">
                  <div
                    className="d-flex flex-column align-items-center gap-2"
                    style={{ maxWidth: "4.5rem" }}
                  >
                    <Rating
                      value={4.5}
                      precision={0.5}
                      readOnly
                      emptyIcon={<StarIcon fontSize="inherit" />}
                      sx={{ fontSize: "1.1rem" }}
                    />
                    <img className="w-100" src={trustImg} alt="trust-logo" />
                  </div>

                  <div
                    className="d-flex flex-column align-items-center gap-2"
                    style={{ maxWidth: "5.8rem" }}
                  >
                    <Rating
                      value={4.5}
                      precision={0.5}
                      readOnly
                      emptyIcon={<StarIcon fontSize="inherit" />}
                      sx={{ fontSize: "1.1rem" }}
                    />
                    <img
                      className="w-100"
                      src={capterraImg}
                      alt="capterra-logo"
                    />
                  </div>

                  <div
                    className="d-flex flex-column justify-content-between align-items-center gap-2"
                    style={{ maxWidth: "4.5rem" }}
                  >
                    <Rating
                      value={4.5}
                      precision={0.5}
                      readOnly
                      emptyIcon={<StarIcon fontSize="inherit" />}
                      sx={{ fontSize: "1.1rem" }}
                    />
                    <img className="w-100" src={g2Img} alt="g2-logo" />
                  </div>
                </div>
              </div>

              <div className="w-100">
                <SignupForm2 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStory;
