import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const Changes = (props) => {
  const selectedData = props && props.selectedData;
  const heading =
    selectedData && selectedData.changes && selectedData.changes.heading
      ? selectedData.changes.heading
      : "Changes";
  const paragraph =
    selectedData && selectedData.changes && selectedData.changes.paragraph
      ? selectedData && selectedData.changes && selectedData.changes.paragraph
      : "Any material changes to the Services, including work to be performed and related fees must be approved by the prior written consent of both parties.";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.changes &&
          selectedData.changes.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default Changes;
