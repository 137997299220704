import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const ForceMejeure = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.forceMejeure &&
    selectedData.forceMejeure.heading
      ? selectedData.forceMejeure.heading
      : "Force Majeure";

  const paragraph =
    selectedData &&
    selectedData.forceMejeure &&
    selectedData.forceMejeure.paragraph
      ? selectedData.forceMejeure.paragraph
      : `If a party's failure or omission in fulfilling any obligation under this Agreement is due to causes beyond their reasonable control, and such causes cannot be overcome through reasonable diligence, including but not limited to strikes, riots, war, acts of terrorism, acts of God, severe illness, invasion, fire, explosion, floods, and acts of government or governmental agencies or instrumentalities, it shall not be considered a breach of this Agreement or result in any liability for that party.`;

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.forceMejeure &&
          selectedData.forceMejeure.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default ForceMejeure;
