import React from "react";
import { Modal } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";

import moment from "moment";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

const EmailPreview = ({
  show,
  handleClose,
  text,
  subject,
  selectedData,
  businessInfoData,
  data,
  brandAndLogoData,
}) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalOnRight600px"
        fullscreen
      >
        <Modal.Header className="d-flex align-items-center">
          <TextStyles.FontSize20px style={{ fontWeight: 500, fontSize: 24 }}>
            Email preview
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              fontSize: 30,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex mb-2">
            <TextStyles.GreyFont14px className="pr-2">
              From:
            </TextStyles.GreyFont14px>
            <TextStyles.FontSize14px
              className="d-flex w-100"
              style={{ minWidth: 100 }}
            >
              <span className="three-dots">
                {businessInfoData && businessInfoData.business_name
                  ? `${businessInfoData.business_name} via Zodot.co`
                  : `${data.firstName} ${data.lastName} via Zodot.co`}
              </span>
            </TextStyles.FontSize14px>
          </div>

          <div className="d-flex mb-2">
            <TextStyles.GreyFont14px className="pr-2">
              Subject:
            </TextStyles.GreyFont14px>
            <TextStyles.FontSize14px
              className="d-flex w-100"
              style={{ minWidth: 100 }}
            >
              <span className="three-dots">{subject}</span>
            </TextStyles.FontSize14px>
          </div>

          <div
            className="p-3 p-sm-4 mt-4"
            style={{
              background: Colors.white,
              borderTop: `4px solid ${
                brandAndLogoData && brandAndLogoData.brand_color
                  ? brandAndLogoData.brand_color
                  : Colors.primary
              }`,
              boxShadow: "0px 0px 9px 0px lightgrey",
            }}
          >
            {brandAndLogoData &&
              brandAndLogoData.brand_logo &&
              brandAndLogoData.brand_logo.url && (
                <div
                  className={
                    brandAndLogoData && brandAndLogoData.logo_alignment
                      ? `text-${brandAndLogoData.logo_alignment} mb-3`
                      : "text-center mb-3"
                  }
                >
                  <img
                    src={brandAndLogoData.brand_logo.url}
                    alt="logoImg"
                    style={{ maxWidth: 512, maxHeight: 512 }}
                  />
                </div>
              )}

            <div className="d-flex justify-content-center">
              <TextStyles.FontSize27px style={{ fontWeight: 500 }}>{`Review ${
                selectedData &&
                selectedData.section.charAt(0).toLowerCase() +
                  selectedData.section.slice(1)
              }`}</TextStyles.FontSize27px>
            </div>
            <TextStyles.FontSize14px
              className="mt-3 pr-0 pr-sm-5"
              style={{ whiteSpace: "pre-line" }}
            >
              {text}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize18px
              className="mt-3"
              style={{ fontWeight: 500 }}
            >
              Details
            </TextStyles.FontSize18px>
            {selectedData && selectedData.client_id && (
              <div className="d-flex mt-1">
                <TextStyles.FontSize14px className="mr-1">
                  Client:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {selectedData &&
                    selectedData.client_id &&
                    selectedData.client_id.company_name}
                </TextStyles.GreyFont14px>
              </div>
            )}

            {selectedData && selectedData.project_name && (
              <div className="d-flex mt-1">
                <TextStyles.FontSize14px className="mr-1">
                  Project:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {selectedData && selectedData.project_name}
                </TextStyles.GreyFont14px>
              </div>
            )}

            {selectedData.project_start_date && (
              <div className="d-flex mt-1">
                <TextStyles.FontSize14px className="mr-1">
                  Start date:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {moment(new Date(selectedData.project_start_date)).format(
                    "DD MMM YYYY"
                  )}
                </TextStyles.GreyFont14px>
              </div>
            )}

            {selectedData.project_end_date ? (
              <div className="d-flex mt-1">
                <TextStyles.FontSize14px className="mr-1">
                  End date:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>
                  {moment(new Date(selectedData.project_end_date)).format(
                    "DD MMM YYYY"
                  )}
                </TextStyles.GreyFont14px>
              </div>
            ) : (
              <div className="d-flex mt-1">
                <TextStyles.FontSize14px className="mr-1">
                  End date:
                </TextStyles.FontSize14px>
                <TextStyles.GreyFont14px>On going</TextStyles.GreyFont14px>
              </div>
            )}

            <div className="d-flex mt-1">
              <TextStyles.FontSize14px className="mr-1">
                Received:
              </TextStyles.FontSize14px>
              <TextStyles.GreyFont14px>
                {moment(new Date()).format("DD MMM YYYY")}
              </TextStyles.GreyFont14px>
            </div>

            <TextStyles.FontSize18px
              className="mt-3"
              style={{ fontWeight: 500 }}
            >
              From
            </TextStyles.FontSize18px>
            <div>
              {businessInfoData && businessInfoData.business_name !== null ? (
                <TextStyles.GreyFont16px style={{ fontWeight: 500 }}>
                  {businessInfoData && businessInfoData.business_name}
                </TextStyles.GreyFont16px>
              ) : (
                <TextStyles.GreyFont16px
                  className="d-flex align-items-center"
                  style={{ fontWeight: 500 }}
                >
                  {data &&
                    `${data.firstName} ${data.lastName} (${data.country.code})`}
                </TextStyles.GreyFont16px>
              )}

              <TextStyles.GreyFont14px className="mt-1">
                {data && data.email && `Email: ${data.email}`}
              </TextStyles.GreyFont14px>

              {businessInfoData !== null && (
                <div>
                  <TextStyles.GreyFont14px className="mt-1">
                    {businessInfoData &&
                      businessInfoData.phone_number &&
                      businessInfoData.phone_code &&
                      `Phone: +${businessInfoData.phone_code.country_code}${businessInfoData.phone_number}`}
                  </TextStyles.GreyFont14px>
                  <TextStyles.GreyFont14px className="mt-1">
                    {businessInfoData && businessInfoData.street}
                    {businessInfoData && businessInfoData.suite ? ", " : ""}
                    {businessInfoData && businessInfoData.suite}
                    {businessInfoData && businessInfoData.city ? ", " : ""}
                    {businessInfoData && businessInfoData.city}
                    {businessInfoData && businessInfoData.state ? ", " : ""}
                    {businessInfoData && businessInfoData.state}
                    {businessInfoData && businessInfoData.country ? ", " : ""}
                    {businessInfoData && businessInfoData.country}
                    {businessInfoData && businessInfoData.zip_code ? ", " : ""}
                    {businessInfoData && businessInfoData.zip_code}
                    {businessInfoData &&
                    businessInfoData.tax_id_label &&
                    businessInfoData.tax_id_number
                      ? ", "
                      : ""}
                  </TextStyles.GreyFont14px>
                  <TextStyles.GreyFont14px className="mt-1">
                    {businessInfoData &&
                      businessInfoData.tax_id_label &&
                      businessInfoData.tax_id_number &&
                      `${businessInfoData.tax_id_label}: ${businessInfoData.tax_id_number}`}
                  </TextStyles.GreyFont14px>
                </div>
              )}
            </div>
            <div className="row mt-4">
              <div className="d-flex justify-content-center">
                <div className="col-12">
                  <TextStyles.Button
                    style={{
                      fontWeight: 500,
                      fontSize: 18,
                      background:
                        brandAndLogoData && brandAndLogoData.brand_color
                          ? brandAndLogoData.brand_color
                          : Colors.primary,
                    }}
                  >
                    Review documents
                  </TextStyles.Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EmailPreview;
