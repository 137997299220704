import styled from "styled-components";
import Colors from "../../../Constants/Colors";

const Boxes = styled.div`
  height: 3.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.312rem 0 1.125rem;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: ${Colors.primary};
  color: ${Colors.white};

  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const TableHead = styled.div`
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  .proposal_actions {
    position: relative;
    max-width: 7rem;
    min-width: 7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .visible-project,
  .visible-company,
  .visible-start-date,
  .visible-end-date,
  .visible-status {
    visibility: hidden;
  }

  .hover-project:hover .visible-project,
  .hover-company:hover .visible-company,
  .hover-start-date:hover .visible-start-date,
  .hover-end-date:hover .visible-end-date,
  .hover-status:hover .visible-status {
    visibility: visible;
  }

  @media (max-width: 768px) {
    .proposal_actions {
      max-width: 2.5rem;
      min-width: 2.5rem;
      justify-content: end;
    }
  }
`;

const TableCell = styled.div`
  width: 100%;
  height: 4.5rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  cursor: pointer;
  margin: 0 0 2px 0;
  background-color: ${Colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  &:hover {
    background: ${Colors.backgroundPink};
  }

  .dropdown-menu {
    border: 2px solid #f9f9f9;
    padding: 6px 6px;
    border-radius: 6px;
    box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.2);
  }

  .dropdown-item {
    padding: 7px 12px;
    border-radius: 4px;
  }
  .dropdown-item:hover {
    background: ${Colors.backgroundPink};
  }
  .dropdown-item a:hover {
    text-decoration: none !important;
  }

  .proposal_actions {
    position: relative;
    max-width: 7rem;
    min-width: 7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 768px) {
    .proposal_actions {
      max-width: 2.5rem;
      min-width: 2.5rem;
      justify-content: end;
    }
  }
`;

const ListBox = styled.div`
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 0 0 0.75rem 0.75rem;
  padding: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
`;

const Sorting = styled.div`
  display: flex;
  min-width: 13.75rem;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
  border-radius: 6px;

  label {
    display: flex;
    width: 100%;
    cursor: pointer;
  }
  .form-check-input {
    width: 1rem;
    height: 1rem;
    box-shadow: none !important;
    border: 2px solid ${Colors.font2};
    cursor: pointer;
  }
  .form-check-input:checked {
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }

  &:hover {
    background: ${Colors.backgroundPink};
  }
  &:hover svg {
    color: ${Colors.primary};
  }
`;

export default {
  Boxes,
  TableHead,
  TableCell,
  ListBox,
  Sorting,
};
