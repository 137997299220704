import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateCancellationFeePC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import CancellationFeeModal from "../../PageModals/CancellationFeeModal";
import Colors from "../../../../../../Constants/Colors";

const CancellationFee = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading = "Cancellation Fee";

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      cancellation_fee: {
        required: false,
        cancellation_fee_deposit_require: false,
        cancellation_fee_amount: "",
      },
    };
    dispatch(updateCancellationFeePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>,
          { toastId: "updateCancelFeeSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateCancelFeeFail" }
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.cancellation_fee &&
          selectedData.cancellation_fee.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        {selectedData &&
        selectedData.cancellation_fee &&
        selectedData.cancellation_fee.cancellation_fee_deposit_require ===
          true ? (
          <div>
            <Styled.EditButtonToolTip
              className="contract_review_edit_tp"
              style={{ marginLeft: -60 }}
              onClick={() => setShowModal(true)}
            >
              <BorderColorOutlinedIcon
                sx={{ color: Colors.white, fontSize: 20 }}
              />
              <span className="ml-1">Edit</span>
            </Styled.EditButtonToolTip>
            <Styled.EditButtonToolTip
              backgroundColor="#383838"
              className="contract_review_edit_tp"
              style={{ marginLeft: 60 }}
              onClick={() => {
                setShowRemoveModal(true);
                setConfirmDialog({
                  onConfirm: hideClauseHandler,
                });
              }}
            >
              <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
              <span className="ml-1">Hide</span>
            </Styled.EditButtonToolTip>
          </div>
        ) : (
          <Styled.EditButtonToolTip
            className="contract_review_edit_tp"
            backgroundColor={Colors.white}
            color={Colors.font1}
            onClick={() => setShowModal(true)}
          >
            show
          </Styled.EditButtonToolTip>
        )}

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>
          {selectedData &&
          selectedData.cancellation_fee &&
          selectedData.cancellation_fee.cancellation_fee_deposit_require ===
            true ? (
            <>
              If the Client terminates this Agreement earlier without cause,
              Contractor will charge a cancellation fee of
              <span
                className="ml-2"
                style={{ color: Colors.primary, fontWeight: 500 }}
              >
                {selectedData.currency.symbol}
                {parseFloat(
                  selectedData.cancellation_fee.cancellation_fee_amount || 0
                ).toFixed(2)}
              </span>
              .
            </>
          ) : (
            <>Note: This section shows if only applicable.</>
          )}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <CancellationFeeModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          selectedData={selectedData}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default CancellationFee;
