import React, { useState, useEffect } from "react";
import AddTemplateModal from "./AddTemplateModal.jsx";
import { toast } from "react-toastify";

import TextStyles from "../../../../Constants/TextStyles";
import Styled from "./TemplatesStyles.js";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import NoTemplateImg from "../../../../Assets/assetsnew/no-template.svg";

import { ReactTooltip } from "../../Commons/ReactTooltip.js";
import {
  deleteTemplate,
  fetchTemplates,
} from "../../../../store/service/AuthService.js";
import { useDispatch, useSelector } from "react-redux";
import EditTemplateModal from "./EditTemplateModal.jsx";
import DeleteModal from "../../Commons/DeleteModal.js";
import Colors from "../../../../Constants/Colors.js";
import LoaderSpin from "../../Commons/LoaderSpin.js";

const Templates = () => {
  const dispatch = useDispatch();

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [updateRefresh, setUpdateRefresh] = useState(false);
  const [addTemplateModal, setAddTemplateModal] = useState(false);
  const [editTemplateModal, setEditTemplateModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const templateData = useSelector((state) => state.auth.templateData);
  const isLoading = useSelector((state) => state.auth.isLoading);

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [updateRefresh]);

  const deleteTemplateHandler = (_id) => {
    const Obj = {
      _id: _id,
    };
    dispatch(deleteTemplate(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "fail" }
        );
      }
    });
  };

  return (
    <div>
      <TextStyles.GreyFont14px className="d-flex text-start my-3">
        With email templates you can create custom email messages and access
        them from the email composer.
      </TextStyles.GreyFont14px>
      <Styled.ButtonBox
        className="d-flex justify-content-center align-items-center"
        onClick={() => setAddTemplateModal(true)}
      >
        <AddOutlinedIcon sx={{ fontSize: 22, color: Colors.white }} />
        New template
      </Styled.ButtonBox>

      <Styled.TableHead className="mt-3">
        <div className="d-flex justify-content-between gap-3 w-100">
          <TextStyles.GreyFont14px
            className="d-flex justify-content-start text-start w-100"
            style={{ minWidth: 50 }}
          >
            <span className="three-dots">Template name</span>
          </TextStyles.GreyFont14px>
          <TextStyles.GreyFont14px
            className="d-flexd-flex justify-content-end three-dots w-100"
            style={{
              maxWidth: 80,
            }}
          />
        </div>
      </Styled.TableHead>
      <div className="pb-5">
        {templateData && templateData.length > 0 ? (
          templateData.map((item, index) => {
            return (
              <Styled.TableCell
                key={index}
                style={{
                  borderRadius:
                    templateData.length - 1 === index ? "0 0 12px 12px" : "",
                }}
                onClick={() => {
                  setSelectedData(item);
                  setEditTemplateModal(true);
                }}
              >
                <div className="d-flex justify-content-between align-items-center gap-3 w-100">
                  <TextStyles.FontSize14px
                    className="d-flex justify-content-start text-start w-100"
                    style={{ minWidth: 50 }}
                  >
                    <span className="three-dots">{item.template_name}</span>
                  </TextStyles.FontSize14px>

                  <div
                    className="d-flexd-flex justify-content-end three-dots w-100"
                    style={{
                      maxWidth: 80,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        id={`edit_template${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedData(item);
                          setEditTemplateModal(true);
                        }}
                      >
                        <BorderColorOutlinedIcon
                          sx={{
                            color: Colors.success,
                            cursor: "pointer",
                            fontSize: 30,
                            background: Colors.white,
                            padding: "2px 6px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`edit_template${index}`}
                        content="Edit template"
                      />
                      <div
                        id={`delete_template${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteModal(true);
                          setConfirmDialog({
                            onConfirm: () => deleteTemplateHandler(item._id),
                          });
                        }}
                      >
                        <DeleteForeverRoundedIcon
                          sx={{
                            color: Colors.red,
                            cursor: "pointer",
                            fontSize: 30,
                            background: Colors.white,
                            padding: "2px 2px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`delete_template${index}`}
                        content="Delete template"
                      />
                    </div>
                  </div>
                </div>
              </Styled.TableCell>
            );
          })
        ) : isLoading && templateData === null ? (
          <Styled.ListBox>
            <LoaderSpin color={Colors.primary} />
          </Styled.ListBox>
        ) : (
          !isLoading && (
            <Styled.ListBox>
              <img src={NoTemplateImg} alt="no-template-img" />
              <TextStyles.GreyFont18px className="mt-2">
                There are no templates yet.
              </TextStyles.GreyFont18px>
            </Styled.ListBox>
          )
        )}
      </div>

      {addTemplateModal && (
        <AddTemplateModal
          show={addTemplateModal}
          handleClose={() => setAddTemplateModal(false)}
          updateRefresh={updateRefresh}
          setUpdateRefresh={setUpdateRefresh}
        />
      )}
      {editTemplateModal && (
        <EditTemplateModal
          show={editTemplateModal}
          handleClose={() => setEditTemplateModal(false)}
          updateRefresh={updateRefresh}
          setUpdateRefresh={setUpdateRefresh}
          selectedData={selectedData}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          title="template"
          confirmDialog={confirmDialog}
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default Templates;
