import React from "react";
import styles from "./Services.module.css";
import Carousel from "./Carousel/Carousel";

const Services = () => {
  return (
    <div className={styles.third_section}>
      <div className="container-fluid py-3 py-md-5">
        <div
          className={`${styles.heading_brand} text-center mb-2`}
          data-aos={"fade-up"}
        >
          VERSATILE WORKSPACE HUB
        </div>
        <div
          className={`${styles.heading_font1} text-center`}
          data-aos={"fade-up"}
        >
          End-to-end SaaS business suite
        </div>
        <div
          className={`${styles.heading_font2} text-center mb-3 mt-2 mb-sm-4`}
          data-aos={"fade-up"}
        >
          The non-intrusive workflow automation for self-driven individuals.
        </div>
        <div className="py-4" data-aos={"fade-up"}>
          <Carousel />
        </div>
      </div>
    </div>
  );
};

export default Services;
