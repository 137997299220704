import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { fetchTaxes } from "../../../../../store/service/statementService";

import Colors from "../../../../../Constants/Colors";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "../../StatementStyles";
import SelectProjects from "../../../Commons/SelectProjects";
import SelectClients from "../../../Commons/SelectClients";

import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import NatoSansRegular from "../../../../../Assets/fonts/NotoSans-Regular.ttf";
import NatoSansMedium from "../../../../../Assets/fonts/NotoSans-Medium.ttf";
import NatoSansSemiBold from "../../../../../Assets/fonts/NotoSans-SemiBold.ttf";
import NatoSansBold from "../../../../../Assets/fonts/NotoSans-Bold.ttf";

import PoppinsItalic from "../../../../../Assets/fonts/Poppins-Italic.ttf";
import PoppinsRegular from "../../../../../Assets/fonts/Poppins-Regular.ttf";
import PoppinsMedium from "../../../../../Assets/fonts/Poppins-Medium.ttf";
import PoppinsSemiBold from "../../../../../Assets/fonts/Poppins-SemiBold.ttf";
import PoppinsBold from "../../../../../Assets/fonts/Poppins-Bold.ttf";
import SelectDate from "../../../Commons/SelectDate";

Font.register({
  family: "NatoSans",
  fonts: [
    { src: NatoSansRegular, fontWeight: 400 },
    { src: NatoSansMedium, fontWeight: 500 },
    { src: NatoSansSemiBold, fontWeight: 600 },
    { src: NatoSansBold, fontWeight: 700 },
  ],
});

Font.register({
  family: "Poppins",
  fonts: [
    { src: PoppinsRegular, fontWeight: 400 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsSemiBold, fontWeight: 600 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsItalic, fontStyle: "italic" },
  ],
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: Colors.font1,
    width: "100%",
    fontWeight: 400,
    fontSize: 10,
    padding: 30,
    lineHeight: 1,
  },
  heading: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
  },
  name: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: 14,
    marginTop: 6,
  },
  range: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontSize: 11,
    marginTop: 9,
    color: Colors.font2,
  },

  notes: {
    fontFamily: "Poppins",
    fontSize: 10,
    marginTop: 9,
    color: Colors.font2,
    fontStyle: "italic",
  },

  horizontaline: {
    border: `1px solid ${Colors.tableHeadColor}`,
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  desc_heading: {
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "row",
    fontSize: 16,
    marginTop: 15,
  },

  desc: {
    fontFamily: "Poppins",
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
  },

  left: {
    fontFamily: "Poppins",
    flex: 1,
    textAlign: "left",
  },
  right: {
    fontFamily: "NatoSans",
    flex: 1,
    textAlign: "right",
  },
  desc_subHeading: {
    fontFamily: "Poppins",
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
  },
});

const Taxes = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth.data);
  const taxesData = useSelector((state) => state.statements.taxesData);
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const userCurrency = useSelector((state) => state.auth.userCurrency);
  const currencySymbol =
    userCurrency && userCurrency.currency && userCurrency.currency.symbol;

  const incomesMappingData = useMemo(() => {
    return (
      taxesData &&
      taxesData
        .filter((d) => d.transaction_type === "income")
        .filter((d) => d.total_tax_in_user_currency > 0)
    );
  }, [taxesData]);

  const totalIncomesArray = useMemo(() => {
    return (
      incomesMappingData &&
      incomesMappingData.map((d) => d.total_tax_in_user_currency)
    );
  }, [incomesMappingData]);

  const totalTaxesFromIncome = useMemo(() => {
    return (
      totalIncomesArray &&
      totalIncomesArray.reduce(
        (preValue, curValue) =>
          parseFloat(preValue) + parseFloat(curValue ? curValue : 0),
        0
      )
    );
  }, [totalIncomesArray]);

  const expenseMappingData = useMemo(() => {
    return (
      taxesData &&
      taxesData
        .filter((d) => d.transaction_type === "expense")
        .filter((d) => d.total_tax_in_user_currency > 0)
    );
  }, [taxesData]);

  const totalExpensesArray = useMemo(() => {
    return (
      expenseMappingData &&
      expenseMappingData.map((d) => d.total_tax_in_user_currency)
    );
  }, [expenseMappingData]);

  const totalTaxesFromExpense = useMemo(() => {
    return (
      totalExpensesArray &&
      totalExpensesArray.reduce(
        (preValue, curValue) =>
          parseFloat(preValue) + parseFloat(curValue ? curValue : 0),
        0
      )
    );
  }, [totalExpensesArray]);

  const NetTaxes = useMemo(() => {
    return totalTaxesFromIncome + totalTaxesFromExpense;
  }, [totalTaxesFromIncome, totalTaxesFromExpense]);

  // filtered state
  const [filteredClient, setFilteredClient] = useState(null);
  const [filteredProject, setFilteredProject] = useState(null);
  const [filterdDateRange, setFilteredDateRange] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const toggleShowFilter = () => setShowFilter(!showFilter);

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).format("YYYY-MM-DD");
      end_time = moment(filterdDateRange[0]).format("YYYY-MM-DD");
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(6, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(14, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(29, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(44, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(59, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(89, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "This month") {
        const startDateOfThisMonth = moment(new Date())
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDateOfThisMonth = moment(new Date())
          .endOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last month") {
        const endDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        const startDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "This year") {
        const endDateOfThisYear = moment(new Date())
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfThisYear = moment(new Date())
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisYear).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last year") {
        const endDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastYear).format("YYYY-MM-DD");
      }
    }

    const Obj = {
      start_time,
      end_time,
      project_id: filteredProject !== 0 ? filteredProject : null,
      client_id: filteredClient !== 0 ? filteredClient : null,
    };

    dispatch(fetchTaxes(Obj));
  }, [filteredClient, filteredProject, filterdDateRange]);

  const IncomesPdf = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.heading}>
            <Text> Tax report</Text>
          </View>
          <View style={styles.name}>
            <Text> {`${data.firstName} ${data.lastName}`}</Text>
          </View>
          <View style={styles.range}>
            <Text>
              {filterdDateRange && typeof filterdDateRange === "object"
                ? `From ${moment(filterdDateRange[0]).format("DD MMM YYYY")}`
                : filterdDateRange === "Last 7 days"
                ? `From ${moment(new Date())
                    .subtract(6, "d")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "Last 15 days"
                ? `From ${moment(new Date())
                    .subtract(14, "d")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "Last 30 days"
                ? `From ${moment(new Date())
                    .subtract(29, "d")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "Last 45 days"
                ? `From ${moment(new Date())
                    .subtract(44, "d")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "Last 60 days"
                ? `From ${moment(new Date())
                    .subtract(59, "d")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "Last 90 days"
                ? `From ${moment(new Date())
                    .subtract(89, "d")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "This month"
                ? `From ${moment(new Date())
                    .startOf("month")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "Last month"
                ? `From ${moment(new Date())
                    .startOf("month")
                    .subtract(1, "months")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "This year"
                ? `From ${moment(new Date())
                    .startOf("year")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "Last year"
                ? `From ${moment(new Date())
                    .startOf("year")
                    .subtract(1, "years")
                    .format("DD MMM YYYY")}`
                : `From 01 Jan 2021`}

              {filterdDateRange && typeof filterdDateRange === "object"
                ? ` to ${moment(filterdDateRange[1]).format("DD MMM YYYY")}`
                : filterdDateRange ===
                  ("Last 7 days" ||
                    "Last 15 days" ||
                    "Last 30 days" ||
                    "Last 45 days" ||
                    "Last 60 days" ||
                    "Last 90 days")
                ? ` to ${moment(new Date()).format("DD MMM YYYY")}`
                : filterdDateRange === "This month"
                ? ` to ${moment(new Date())
                    .endOf("month")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "Last month"
                ? ` to ${moment(new Date())
                    .subtract(1, "months")
                    .endOf("month")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "This year"
                ? ` to ${moment(new Date())
                    .endOf("year")
                    .format("DD MMM YYYY")}`
                : filterdDateRange === "Last year"
                ? ` to ${moment(new Date())
                    .subtract(1, "years")
                    .endOf("year")
                    .format("DD MMM YYYY")}`
                : ` to ${moment(new Date()).format("DD MMM YYYY")}`}
            </Text>
          </View>

          <View style={styles.notes}>
            <Text>
              Note: The currency of the statement is in
              {` ${
                userCurrency &&
                userCurrency.currency &&
                userCurrency.currency.name
              }. `}
              Don't mind if currency's symbol does not exist.
            </Text>
          </View>

          {incomesMappingData && incomesMappingData.length > 0 && (
            <View style={[styles.desc_heading, { marginTop: 30 }]}>
              <Text>Taxes from incomes</Text>
            </View>
          )}
          {incomesMappingData && incomesMappingData.length > 0 && (
            <Text style={styles.horizontaline} />
          )}
          {incomesMappingData &&
            incomesMappingData.map((item, index) => {
              return (
                <View key={index}>
                  <View style={styles.desc}>
                    <Text style={styles.left}>
                      {item.client_id && item.client_id.company_name}
                      {item.project_id && item.project_id.project_name
                        ? `(${item.project_id.project_name})`
                        : ""}
                    </Text>
                    <Text style={styles.right}>
                      {`${currencySymbol}${parseFloat(
                        item.total_tax_in_user_currency
                      ).toFixed(2)}`}
                    </Text>
                  </View>
                  <Text style={styles.horizontaline} />
                </View>
              );
            })}
          {incomesMappingData && incomesMappingData.length > 0 && (
            <View style={styles.desc_subHeading}>
              <Text style={styles.left}>Total taxes collected from income</Text>
              <Text style={styles.right}>
                {`${currencySymbol}${parseFloat(totalTaxesFromIncome).toFixed(
                  2
                )}`}
              </Text>
            </View>
          )}

          {expenseMappingData && expenseMappingData.length > 0 && (
            <View style={[styles.desc_heading, { marginTop: 30 }]}>
              <Text>Taxes paid on expenses</Text>
            </View>
          )}
          {expenseMappingData && expenseMappingData.length > 0 && (
            <Text style={styles.horizontaline} />
          )}
          {expenseMappingData &&
            expenseMappingData.map((item, index) => {
              return (
                <View key={index}>
                  <View style={styles.desc}>
                    <Text style={styles.left}>
                      {item.client_id && item.client_id.company_name}
                      {item.project_id && item.project_id.project_name
                        ? `(${item.project_id.project_name})`
                        : ""}
                    </Text>
                    <Text style={styles.right}>
                      {`${currencySymbol}${parseFloat(
                        item.total_tax_in_user_currency
                      ).toFixed(2)}`}
                    </Text>
                  </View>
                  <Text style={styles.horizontaline} />
                </View>
              );
            })}
          {expenseMappingData && expenseMappingData.length > 0 && (
            <View style={styles.desc_subHeading}>
              <Text style={styles.left}> Total taxes paid on expenses</Text>
              <Text style={styles.right}>
                {`${currencySymbol}${parseFloat(totalTaxesFromExpense).toFixed(
                  2
                )}`}
              </Text>
            </View>
          )}

          {((incomesMappingData && incomesMappingData.length > 0) ||
            (expenseMappingData && expenseMappingData.length > 0)) && (
            <Text style={styles.horizontaline} />
          )}
          {((incomesMappingData && incomesMappingData.length > 0) ||
            (expenseMappingData && expenseMappingData.length > 0)) && (
            <View style={styles.desc_heading}>
              <Text style={styles.left}> Net taxes</Text>
              <Text style={styles.right}>
                {NetTaxes < 0
                  ? `-${currencySymbol}${parseFloat(Math.abs(NetTaxes)).toFixed(
                      2
                    )}`
                  : NetTaxes > 0
                  ? `${currencySymbol}${parseFloat(Math.abs(NetTaxes)).toFixed(
                      2
                    )}`
                  : NetTaxes === 0
                  ? `${currencySymbol}00.00`
                  : ""}
              </Text>
            </View>
          )}
        </Page>
      </Document>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center my-3">
        <TextStyles.HoverEffectSpan className="mr-2" onClick={toggleShowFilter}>
          Filter
        </TextStyles.HoverEffectSpan>
        <span
          style={{
            width: 40,
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: Colors.primary,
            borderRadius: "50%",
            cursor: "pointer",
            padding: 0,
          }}
        >
          <PDFDownloadLink
            document={<IncomesPdf />}
            fileName="taxesstatement.pdf"
          >
            <SaveAltIcon sx={{ color: Colors.white, fontSize: 27 }} />
          </PDFDownloadLink>
        </span>
      </div>

      {showFilter && (
        <div className="d-flex flex-wrap justify-content-start mb-3">
          <div className="mr-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>

            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                setFilteredDateRange(value);
              }}
            />
          </div>
          <div className="mr-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Client
            </TextStyles.FontSize14px>
            <SelectClients
              options={clientOptionData}
              defaultValue={filteredClient && filteredClient}
              onChange={(e) => setFilteredClient(e.target.value)}
            />
          </div>
          <div className="mr-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Project
            </TextStyles.FontSize14px>
            <SelectProjects
              defaultValue={filteredProject && filteredProject}
              options={projectOptionsData}
              onChange={(e) => setFilteredProject(e.target.value)}
            />
          </div>
        </div>
      )}

      <div className="d-flex justify-content-center">
        <Styled.EditIncomesBox>
          <TextStyles.FontSize27px className="mb-1">
            Tax report
          </TextStyles.FontSize27px>
          <TextStyles.FontSize20px className="mb-1">
            {`${data.firstName} ${data.lastName}`}
          </TextStyles.FontSize20px>
          <TextStyles.GreyFont14px className="">
            {filterdDateRange && typeof filterdDateRange === "object"
              ? `From ${moment(filterdDateRange[0]).format("DD MMM YYYY")}`
              : filterdDateRange === "Last 7 days"
              ? `From ${moment(new Date())
                  .subtract(6, "d")
                  .format("DD MMM YYYY")}`
              : filterdDateRange === "Last 15 days"
              ? `From ${moment(new Date())
                  .subtract(14, "d")
                  .format("DD MMM YYYY")}`
              : filterdDateRange === "Last 30 days"
              ? `From ${moment(new Date())
                  .subtract(29, "d")
                  .format("DD MMM YYYY")}`
              : filterdDateRange === "Last 45 days"
              ? `From ${moment(new Date())
                  .subtract(44, "d")
                  .format("DD MMM YYYY")}`
              : filterdDateRange === "Last 60 days"
              ? `From ${moment(new Date())
                  .subtract(59, "d")
                  .format("DD MMM YYYY")}`
              : filterdDateRange === "Last 90 days"
              ? `From ${moment(new Date())
                  .subtract(89, "d")
                  .format("DD MMM YYYY")}`
              : filterdDateRange === "This month"
              ? `From ${moment(new Date())
                  .startOf("month")
                  .format("DD MMM YYYY")}`
              : filterdDateRange === "Last month"
              ? `From ${moment(new Date())
                  .startOf("month")
                  .subtract(1, "months")
                  .format("DD MMM YYYY")}`
              : filterdDateRange === "This year"
              ? `From ${moment(new Date())
                  .startOf("year")
                  .format("DD MMM YYYY")}`
              : filterdDateRange === "Last year"
              ? `From ${moment(new Date())
                  .startOf("year")
                  .subtract(1, "years")
                  .format("DD MMM YYYY")}`
              : `From 01 Jan 2021`}

            {filterdDateRange && typeof filterdDateRange === "object"
              ? ` to ${moment(filterdDateRange[1]).format("DD MMM YYYY")}`
              : filterdDateRange ===
                ("Last 7 days" ||
                  "Last 15 days" ||
                  "Last 30 days" ||
                  "Last 45 days" ||
                  "Last 60 days" ||
                  "Last 90 days")
              ? ` to ${moment(new Date()).format("DD MMM YYYY")}`
              : filterdDateRange === "This month"
              ? ` to ${moment(new Date()).endOf("month").format("DD MMM YYYY")}`
              : filterdDateRange === "Last month"
              ? ` to ${moment(new Date())
                  .subtract(1, "months")
                  .endOf("month")
                  .format("DD MMM YYYY")}`
              : filterdDateRange === "This year"
              ? ` to ${moment(new Date()).endOf("year").format("DD MMM YYYY")}`
              : filterdDateRange === "Last year"
              ? ` to ${moment(new Date())
                  .subtract(1, "years")
                  .endOf("year")
                  .format("DD MMM YYYY")}`
              : ` to ${moment(new Date()).format("DD MMM YYYY")}`}
          </TextStyles.GreyFont14px>

          {incomesMappingData && incomesMappingData.length > 0 && (
            <TextStyles.FontSize20px className="d-flex jsutify-content-start mt-5">
              Taxes from incomes
            </TextStyles.FontSize20px>
          )}

          {incomesMappingData && incomesMappingData.length > 0 && (
            <hr style={{ height: 0.1, opacity: 0.13 }} />
          )}

          {incomesMappingData &&
            incomesMappingData.map((item, index) => {
              return (
                <div key={index}>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <TextStyles.FontSize14px
                      className="d-flex justify-content-start w-100"
                      style={{ minWidth: 100 }}
                    >
                      <span className="three-dots">
                        {item.client_id && item.client_id.company_name}{" "}
                        {item.project_id && item.project_id.project_name
                          ? `(${item.project_id.project_name})`
                          : ""}
                      </span>
                    </TextStyles.FontSize14px>
                    <TextStyles.FontSize14px
                      className="d-flex justify-content-end w-100"
                      style={{ minWidth: 100 }}
                    >
                      <span className="three-dots">
                        {`${currencySymbol}${parseFloat(
                          item.total_tax_in_user_currency
                        ).toFixed(2)}`}
                      </span>
                    </TextStyles.FontSize14px>
                  </div>

                  <hr style={{ height: 0.1, opacity: 0.05 }} />
                </div>
              );
            })}

          {incomesMappingData && incomesMappingData.length > 0 && (
            <div className="d-flex justify-content-between align-items-center w-100">
              <TextStyles.FontSize16px
                className="d-flex justify-content-start w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">
                  {" "}
                  Total taxes collected from income
                </span>
              </TextStyles.FontSize16px>
              <TextStyles.FontSize16px
                className="d-flex justify-content-end w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">
                  {`${currencySymbol}${parseFloat(totalTaxesFromIncome).toFixed(
                    2
                  )}`}
                </span>
              </TextStyles.FontSize16px>
            </div>
          )}

          {expenseMappingData && expenseMappingData.length > 0 && (
            <TextStyles.FontSize20px className="d-flex jsutify-content-start mt-3">
              Taxes paid on expenses
            </TextStyles.FontSize20px>
          )}

          {expenseMappingData && expenseMappingData.length > 0 && (
            <hr style={{ height: 0.1, opacity: 0.13 }} />
          )}

          {expenseMappingData &&
            expenseMappingData.map((item, index) => {
              return (
                <div key={index}>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <TextStyles.FontSize14px
                      className="d-flex justify-content-start w-100"
                      style={{ minWidth: 100 }}
                    >
                      <span className="three-dots">
                        {item.client_id && item.client_id.company_name}{" "}
                        {item.project_id && item.project_id.project_name
                          ? `(${item.project_id.project_name})`
                          : ""}
                      </span>
                    </TextStyles.FontSize14px>
                    <TextStyles.FontSize14px
                      className="d-flex justify-content-end w-100"
                      style={{ minWidth: 100 }}
                    >
                      <span className="three-dots">
                        {`${currencySymbol}${parseFloat(
                          item.total_tax_in_user_currency
                        ).toFixed(2)}`}
                      </span>
                    </TextStyles.FontSize14px>
                  </div>
                  <hr style={{ height: 0.1, opacity: 0.05 }} />
                </div>
              );
            })}

          {expenseMappingData && expenseMappingData.length > 0 && (
            <div className="d-flex justify-content-between align-items-center w-100">
              <TextStyles.FontSize16px
                className="d-flex justify-content-start w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">
                  {" "}
                  Total taxes paid on expenses
                </span>
              </TextStyles.FontSize16px>
              <TextStyles.FontSize16px
                className="d-flex justify-content-end w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">
                  {`${currencySymbol}${parseFloat(
                    totalTaxesFromExpense
                  ).toFixed(2)}`}
                </span>
              </TextStyles.FontSize16px>
            </div>
          )}

          {((incomesMappingData && incomesMappingData.length > 0) ||
            (expenseMappingData && expenseMappingData.length > 0)) && (
            <hr style={{ height: 0.3, opacity: 0.1 }} />
          )}

          {((incomesMappingData && incomesMappingData.length > 0) ||
            (expenseMappingData && expenseMappingData.length > 0)) && (
            <div className="d-flex justify-content-between align-items-center w-100">
              <TextStyles.FontSize20px
                className="d-flex justify-content-start w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots"> Net taxes</span>
              </TextStyles.FontSize20px>
              <TextStyles.FontSize20px
                className="d-flex justify-content-end w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">
                  {NetTaxes < 0
                    ? `-${currencySymbol}${parseFloat(
                        Math.abs(NetTaxes)
                      ).toFixed(2)}`
                    : NetTaxes > 0
                    ? `${currencySymbol}${parseFloat(
                        Math.abs(NetTaxes)
                      ).toFixed(2)}`
                    : NetTaxes === 0
                    ? `${currencySymbol}00.00`
                    : ""}
                </span>
              </TextStyles.FontSize20px>
            </div>
          )}
        </Styled.EditIncomesBox>
      </div>
    </div>
  );
};

export default Taxes;
