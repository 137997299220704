import { deleteRequest } from "../../axios/axios";
import URL from "../../axios/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const deleteUserAccount = createAsyncThunk(
  "auth/delete-user-account",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      deleteRequest(URL.DELETE_USER_ACCOUNT.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
