import React from "react";
import { Redirect } from "react-router";

export default function LaunchToTimers() {
  return (
    <div>
      <Redirect to="/db/timetracking/timers" />
    </div>
  );
}
