import React from "react";
import styles from "./ClientReviews.module.css";
import Masonry from "react-masonry-css";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

const reviewData = [
  {
    id: "1",
    name: "Lisa Chen",
    designation: "Small Business Owner",
    rating: 5,
    text: "Zodot’s integrated proposal and contract management features have been a game-changer for me. As a small business owner, I appreciate how everything I need is centralized in one user-friendly platform. It has not only saved me countless hours but also simplified my processes significantly. Thanks to Zodot, I can now dedicate more time to my clients and business growth.",
  },
  {
    id: "2",
    name: "Alex Morgan",
    rating: 5,
    designation: "Freelance Graphic Designer",
    text: "The ability to send agreements via email and get instant notifications when they’re viewed or signed is fantastic. Zodot’s seamless integration into my daily operations has eliminated the hassle of manual follow-ups. Now, I can manage contracts effortlessly and keep track of all my agreements with ease. Zodot has greatly enhanced my productivity!",
  },
  {
    id: "3",
    name: "Karen W.",
    designation: "Freelance Writer",
    rating: 4.5,
    text: "As a freelance writer juggling numerous projects, managing tasks and payments used to be a daunting chore. Since discovering Zodot, my workflow has transformed. Now, everything is organized and effortless to manage, allowing me to focus on my writing rather than administrative hassles. Zodot has truly my freelance business more manageable, making my life significantly easier.",
  },
  {
    id: "4",
    name: "Emily J.",
    designation: "Owner, Creative Minds Studio",
    rating: 5,
    text: "Zodot simplifies my invoice creation process, allowing me to produce professional, personalized documents effortlessly. As a small design studio owner, this customization feature is invaluable, enabling me to impress clients with branded invoices that reflect our unique style. By streamlining invoicing tasks, Zodot grants me more time to focus on creative projects. It ensures my business maintains a high standard of professionalism, fostering client trust and loyalty while enhancing our brand identity.",
  },
  {
    id: "5",
    name: "Michael B.",
    designation: "Owner, MB Consultancy",
    rating: 4,
    text: "Managing invoicing has never been smoother thanks to Zodot's seamless integration of multiple services into a single invoice. This time-saving gem has become my go-to, significantly streamlining my workflow and freeing up precious hours for other tasks. Zodot understands the modern professional's needs, offering a solution that not only simplifies but enhances business operations, making it an indispensable tool.",
  },
  {
    id: "6",
    name: "Alex White",
    rating: 5,
    designation: "Owner of White & Co.",
    text: "Zodot has really made managing my business finances a breeze. I can easily see how much money I'm making before taxes, which helps me understand how well my business is doing. This clarity has been super helpful in making smart choices about where to invest or save. Zodot isn't just software to me; it's like having a financial advisor right at my fingertips.",
  },
  {
    id: "7",
    name: "Jessica T.",
    rating: 5,
    designation: "Solopreneur",
    text: "Zodot is a lifesaver for me as a solo entrepreneur. It helps me handle billing and manage my time effectively. The best part? The cool graphs show how productive I am! With Zodot, I feel more organized and in control of my work. I can't imagine running my business without it now!",
  },
  {
    id: "8",
    name: "Olivia K.",
    rating: 5,
    designation: "Entrepreneur",
    text: "Zodot's weekly view, facilitating effortless editing of logged hours, is a true lifesaver! Our team's time tracking has become a breeze, thanks to Zodot's precision and convenience. No more wrestling with inaccurate records or cumbersome adjustments. With Zodot, productivity thrives, and our workflow has never been smoother. It's an indispensable asset for any efficient operation!",
  },
  {
    id: "9",
    name: "Alex P.",
    rating: 4,
    designation: "Founder of CodeWave",
    text: "Zodot makes tracking money super easy. I love how it shows my finances in simple pictures that even I understand. It helps me keep an eye on where our project's money is going. Thanks to Zodot, I feel more in control of our finances and can make smarter decisions. It's like having a financial guru right in my pocket!",
  },
  {
    id: "10",
    name: "Emily Davis",
    designation: "Entrepreneur at Startup Genius",
    rating: 5,
    text: "Since adopting Zodot, managing profits has become a breeze. It's like having a financial wizard at my fingertips. With just a few clicks, I gain profound insights into my revenue streams, empowering me to optimize every aspect of my business. Zodot doesn't just crunch numbers; it illuminates pathways to success, making every decision smarter and more profitable.",
  },
  {
    id: "11",
    name: "Daniel Wilson",
    rating: 5,
    designation: "Solopreneur",
    text: "Zodot makes it super easy for me to find all my invoices and keep track of my money. It's like having a helpful friend who organizes everything for me. I just click a few buttons, and boom! I can see all my records neatly sorted. With Zodot, keeping track of my money is a piece of cake!",
  },
  {
    id: "12",
    name: "Rachel",
    designation: "Freelance full-stack developer",
    rating: 5,
    text: "Zodot's reporting software has truly revolutionized how I manage my projects. It seamlessly handles everything from creating invoices, proposals, and contracts to meticulously tracking expenses and tasks. This platform has become my go-to for ensuring smooth project management. Thanks to Zodot, I navigate essential tasks effortlessly, resulting in heightened productivity and impeccable organization across all my projects.",
  },
  {
    id: "13",
    name: "Max",
    designation: "Python Developer",
    rating: 4,
    text: "Zodot's progress tracker is not just a tool; it's a source of motivation that turns my to-do list into a roadmap of accomplishments. The satisfaction of ticking off tasks is unparalleled, and Zodot amplifies that feeling, reminding me that every small victory counts towards the bigger picture of success.",
  },
  {
    id: "14",
    name: "Michael Thompson",
    designation: "Freelance Designer",
    rating: 4.5,
    text: "Zodot has transformed how I manage multiple projects. Its intuitive interface makes it so easy to create, modify, and track tasks. The client-centric monitoring and full project visibility features are game-changers, keeping me informed about every aspect of my projects. Zodot has streamlined my workflow, making project management efficient and precise. I can't imagine handling my projects without it!",
  },
  {
    id: "15",
    name: "Sophia Bennett",
    designation: "Digital Marketing Freelancer",
    rating: 5,
    text: "Zodot has revolutionized how I track my work hours! The automatic task time tracking and the 'week view' feature help me stay organized and efficient. No more fumbling with spreadsheets. I particularly love the detailed reports and project-specific breakdowns, which give me clear insights into my productivity. Highly recommended for freelancers!",
  },
  {
    id: "16",
    name: "Jane Thompson",
    designation: "Content Writer",
    rating: 5,
    text: "Zodot’s time tracking has made my freelance writing business more efficient. I can effortlessly log my hours, and the graphs showing weekly productivity help me identify patterns in my work habits. The feature to edit logged hours in week view is a lifesaver when I forget to track. Plus, client billing has never been this smooth and accurate. Zodot is a fantastic tool for any content creator!",
  },
  {
    id: "17",
    name: "Mark Stevens",
    designation: "Small Business Owner",
    rating: 5,
    text: "Managing my business finances used to be overwhelming, but Zodot makes it simple. The income statement reports are comprehensive and easy to generate. I can quickly see where my money is going and make informed decisions. Tracking profit before taxes and managing total expenses has helped improve my business's profitability. Calculating net taxes is straightforward, making compliance much easier.",
  },
  {
    id: "18",
    name: "Emily Carter",
    designation: "Freelance Consultant",
    rating: 5,
    text: "Zodot is a game-changer for freelancers! The income statement feature lets me see my financial status. I love how I can track profit before taxes to understand my revenue streams better. Managing expenses by client and project helps me optimize spending, and the net tax calculation feature ensures I’m always compliant. Highly recommend it to other consultants!",
  },
];

const ClientReviews = () => {
  const breakpointColumnsObj = {
    default: 3,
    1150: 3,
    768: 2,
    576: 1,
  };

  const firstLettersFromName = (name) => {
    const words = name.split(" ");
    if (words.length < 2) {
      return words[0][0].toUpperCase();
    } else {
      const firstLetters = words.map((word) => word[0].toUpperCase());
      return firstLetters.join("");
    }
  };

  return (
    <div className={styles.section}>
      <div className="container-fluid py-4 py-md-5" style={{ maxWidth: 1150 }}>
        <div
          className="d-flex justify-content-center w-100"
          data-aos={"fade-up"}
        >
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={styles.my_masonry_grid}
            columnClassName={styles.my_masonry_grid_column}
          >
            {reviewData.map((review, index) => (
              <div key={index}>
                <div className="d-flex gap-3 mb-3">
                  <div className={styles.review_pic_box}>
                    <div className={styles.review_pic_text}>
                      {firstLettersFromName(review.name)}
                    </div>
                  </div>
                  <div>
                    <div className={styles.reviewer_name}>{review.name}</div>
                    <div className={styles.reviewer_designation}>
                      {review.designation}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <Rating
                    name="rating"
                    value={review.rating}
                    precision={0.1}
                    readOnly
                    emptyIcon={<StarIcon fontSize="inherit" />}
                  />
                </div>
                <div className={`${styles.review_text} text-start`}>
                  {review.text}
                </div>
              </div>
            ))}
          </Masonry>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ClientReviews);
