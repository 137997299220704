import React from "react";
import styles from "./Features.module.css";
const Features = () => {
  return (
    <div className={styles.section}>
      <div className="container-fluid py-4 py-md-5">
        <div
          className={`${styles.heading} text-center mb-3`}
          data-aos={"fade-up"}
        >
          Revolutionize your workflow automation
        </div>
        <div className="d-flex justify-content-center">
          <div
            className={`${styles.heading_text} text-center mb-3 mb-sm-5`}
            data-aos={"fade-up"}
            style={{ maxWidth: 1050 }}
          >
            Our productivity software isn't merely a tool – it's a collaborator
            in shaping your success narrative. Explore the influence we generate
            through a variety of features crafted to meet the evolving needs of
            professionals such as yourself.
          </div>
        </div>

        <div
          className="row d-flex justify-content-between align-items-center"
          data-aos={"fade-up"}
        >
          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                A holistic approach
              </div>

              <div className={`${styles.container_text} text-start`}>
                Our SaaS productivity suite provides a complete solution for
                project management. From crafting invoices, proposals, and
                contracts to tracking expenses and tasks, we cover all aspects
                of your business.
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Time is money
              </div>

              <div className={`${styles.container_text} text-start`}>
                Milestone tracking is central to our work. Manage projects
                efficiently by tracking time for each task, ensuring accurate
                billing and streamlined workflow. Maximize billable hours and
                gain new insights into productivity.
              </div>
            </div>
          </div>{" "}
          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Simplified financials
              </div>

              <div className={`${styles.container_text} text-start`}>
                Ditch payment hassles with Zodot. Easily create, send, and track
                invoices. Manage recurring expenses effortlessly and gain
                financial clarity with detailed reports.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
