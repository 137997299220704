import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AutoCompleteSelectClient } from "../../../Commons/AutoComplete";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./ChooseClientStyle";
import Colors from "../../../../../Constants/Colors";
import { useForm, Controller } from "react-hook-form";

const ChooseClient = ({
  setChooseQuickClient,
  setCreateQuickClient,
  backBtn,
  selectedQuickInvoice,
  setSelectedQuickInvoice,
}) => {
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );
  const [selectExistingClient, setSelectExistingClient] = useState(null);

  const { handleSubmit, control } = useForm();

  const formsubmit = () => {
    if (selectedQuickInvoice === "create_new_client") {
      setCreateQuickClient(true);
    } else {
      setChooseQuickClient(selectExistingClient);
    }
  };

  return (
    <div className="d-flex justify-content-center px-2">
      <div style={{ overflow: "hidden" }}>
        <TextStyles.FontSize27px className=" text-center mb-4">
          Who's your client ?
        </TextStyles.FontSize27px>
        <form onSubmit={handleSubmit(formsubmit)}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center">
              <Styled.DashBoardContainer
                className="d-flex flex-column justify-content-center align-items-center"
                onClick={() =>
                  setSelectedQuickInvoice("select_existing_client")
                }
                style={{
                  border:
                    selectedQuickInvoice === "select_existing_client"
                      ? `3px solid ${Colors.primary}`
                      : "",
                }}
              >
                <TextStyles.FontSize16px className="mb-3">
                  Select from existing clients
                </TextStyles.FontSize16px>

                <Controller
                  control={control}
                  name="choose_client"
                  render={({ field: { onChange, value } }) => (
                    <AutoCompleteSelectClient
                      placeholder="Choose a client"
                      options={
                        clientOptionData &&
                        clientOptionData.map((data) => {
                          return { label: data.company_name, _id: data._id };
                        })
                      }
                      onChange={(value) => {
                        onChange(value);
                        setSelectExistingClient(value);
                      }}
                      defaultValue={value}
                    />
                  )}
                />
              </Styled.DashBoardContainer>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-center">
              <Styled.DashBoardContainer
                className="d-flex flex-column justify-content-center align-items-center"
                onClick={() => setSelectedQuickInvoice("create_new_client")}
                style={{
                  border:
                    selectedQuickInvoice === "create_new_client"
                      ? `3px solid ${Colors.primary}`
                      : "",
                }}
              >
                <TextStyles.FontSize16px>
                  Create new client
                </TextStyles.FontSize16px>
              </Styled.DashBoardContainer>
            </div>
          </div>

          <Styled.SubmitButton
            disabled={
              selectedQuickInvoice === "select_existing_client" &&
              selectExistingClient
                ? false
                : selectedQuickInvoice === "create_new_client"
                ? false
                : true
            }
            style={{
              minWidth: 120,
            }}
          >
            Next
          </Styled.SubmitButton>
        </form>
      </div>
      {backBtn}
    </div>
  );
};

export default ChooseClient;
