import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../axios/axios";
import URL from "../../axios/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchUserSetupData = createAsyncThunk(
  "Auth/fetchUserSetupData",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_USER_SETUP_DATA.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const hideSetupInfo = createAsyncThunk(
  "Auth/hideSetupinfo",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.HIDE_USER_SETUP_DATA.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateUserInfo = createAsyncThunk(
  "Auth/updateUserInfo",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_USER_INFO.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const getUserInfo = createAsyncThunk(
  "Auth/getUserInfo",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.GET_USER_INFO.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateUserPassword = createAsyncThunk(
  "Auth/updateUserPassword",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_USER_PASSWORD.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateBusinessInfo = createAsyncThunk(
  "Auth/updateBusinessInfo",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_BUSINESS_INFO.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchBusinessInfo = createAsyncThunk(
  "Auth/fetchBusinessInfo",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_BUSINESS_INFO.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateProfileInfoPic = createAsyncThunk(
  "Auth/profile/account-security/update_image",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest("auth/profile/account_security/image", token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const getProfileInfoPic = createAsyncThunk(
  "Auth/profile/account-security/get_image",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest("auth/profile/account_security/image", token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteProfileInfoPic = createAsyncThunk(
  "Auth/profile/account-security/delete_image",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      deleteRequest("auth/profile/account_security/image", token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const setUserCurrency = createAsyncThunk(
  "Auth/setUserCurrency",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.SET_USER_CURRENCY.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const getUserCurrency = createAsyncThunk(
  "Auth/getUserCurrency",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.GET_USER_CURRENCY.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateBrandColor = createAsyncThunk(
  "Auth/updateBrandColor",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_BRAND_COLOR.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateBrandLogo = createAsyncThunk(
  "Auth/updateBrandLogo",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_BRAND_LOGO.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const removeBrandLogo = createAsyncThunk(
  "Auth/removeBrandLogo",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      deleteRequest(URL.UPDATE_BRAND_LOGO.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateLogoAlignment = createAsyncThunk(
  "Auth/updateLogoAlignment",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_BRAND_LOGO_ALIGNMENT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchBrandAndLogo = createAsyncThunk(
  "Auth/fetchBrandAndLogo",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_BRAND_AND_LOGO.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchTemplates = createAsyncThunk(
  "Auth/fetch_templates",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_TEMPLATES.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createTemplate = createAsyncThunk(
  "Auth/create_template",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_TEMPLATE.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateTemplate = createAsyncThunk(
  "Auth/update_template",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_TEMPLATE.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteTemplate = createAsyncThunk(
  "Auth/delete_template",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      deleteRequest(URL.DELETE_TEMPLATE.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchUserTourSetupData = createAsyncThunk(
  "Auth/fetchUserTourSetupData",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_USER_TOUR_SETUP_DATA.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateUserTourSetupData = createAsyncThunk(
  "Auth/updateUserTourSetupData",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.UPDATE_USER_TOUR_SETUP_DATA.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
