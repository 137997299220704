import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateLatePaymentPC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import LatePaymentModal from "../../PageModals/LatePaymentModal";
import Colors from "../../../../../../Constants/Colors";

const LatePayment = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData &&
    selectedData.late_payment_fee &&
    selectedData.late_payment_fee.heading
      ? selectedData.late_payment_fee.heading
      : "Late Payment";

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      late_payment_fee: {
        required: false,
        late_payment_deposit_require: false,
        late_payment: "Percentage",
        late_payment_amount: "50",
        late_payment_percentage: "1.50",
      },
    };
    dispatch(updateLatePaymentPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>,
          { toastId: "updateLatePaymentSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateLatePaymentFail" }
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.late_payment_fee &&
          selectedData.late_payment_fee.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        {selectedData &&
        selectedData.late_payment_fee &&
        selectedData.late_payment_fee.late_payment_deposit_require === true ? (
          <>
            {" "}
            <Styled.EditButtonToolTip
              className="contract_review_edit_tp"
              style={{ marginLeft: -60 }}
              onClick={() => setShowModal(true)}
            >
              <BorderColorOutlinedIcon
                sx={{ color: Colors.white, fontSize: 20 }}
              />
              <span className="ml-1">Edit</span>
            </Styled.EditButtonToolTip>
            <Styled.EditButtonToolTip
              backgroundColor="#383838"
              className="contract_review_edit_tp"
              style={{ marginLeft: 60 }}
              onClick={() => {
                setShowRemoveModal(true);
                setConfirmDialog({
                  onConfirm: hideClauseHandler,
                });
              }}
            >
              <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
              <span className="ml-1">Hide</span>
            </Styled.EditButtonToolTip>
          </>
        ) : (
          <Styled.EditButtonToolTip
            className="contract_review_edit_tp"
            backgroundColor={Colors.white}
            color={Colors.font1}
            onClick={() => setShowModal(true)}
          >
            show
          </Styled.EditButtonToolTip>
        )}

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        {selectedData &&
        selectedData.late_payment_fee &&
        selectedData.late_payment_fee.late_payment_deposit_require === true ? (
          <TextStyles.FontSize14px>
            In case of delayed payment of an invoice, the Contractor reserves
            the right to charge a late payment fee of
            {selectedData &&
              selectedData.late_payment_fee &&
              selectedData.late_payment_fee.late_payment === "Percentage" && (
                <span
                  className="mx-1"
                  style={{ color: Colors.primary, fontWeight: 500 }}
                >
                  {`${selectedData.late_payment_fee.late_payment_percentage}% per month`}
                </span>
              )}
            {selectedData &&
              selectedData.late_payment_fee &&
              selectedData.late_payment_fee.late_payment === "Amount" && (
                <span
                  className="mx-1"
                  style={{ color: Colors.primary, fontWeight: 500 }}
                >
                  {`${selectedData.currency.symbol}${selectedData.late_payment_fee.late_payment_amount} as late payment fee`}
                </span>
              )}
            . Acceptance of these service charges by Contractor does not waive
            any rights or remedies in the event of Client's breach of this
            Agreement. All payment obligations are non-cancellable, and fees
            paid are non-refundable.
          </TextStyles.FontSize14px>
        ) : (
          <>Note: This section shows if only applicable.</>
        )}
      </Styled.ReviewContentBox>
      {showModal && (
        <LatePaymentModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          selectedData={selectedData}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default LatePayment;
