import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./TimeTracting.module.css";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import HomePageTimeTrackingSectionImage from "../../../../Assets/frontendImages/time_tracking_homepage.avif";

const TimeTracking = () => {
  return (
    <div
      className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center"
      style={{ overflow: "hidden" }}
    >
      <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100">
        <div
          data-aos={"fade-right"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 550,
            maxHeight: 550,
          }}
        >
          <img
            src={HomePageTimeTrackingSectionImage}
            alt="HomePageTimeTrackingSectionImage"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
      <div className="text-start mt-3 mt-md-0 w-100">
        <div data-aos={"fade-left"}>
          <div className={`${styles.heading} mb-4`}>
            Avoid missed deadlines & losses.
          </div>
          <div className={`${styles.text_font1} mb-4`}>
            Maximize earnings with Zodot's freelance time-tracking software.
            Efficiently manage projects, track hours, and compare estimates with
            actual time and costs. Stay organized and boost productivity with
            our intuitive tool. Monetize your time effectively!
          </div>
          <div className="d-flex mb-4">
            <NavLink
              className={styles.more_button}
              style={{ textDecoration: "none" }}
              to="/features/time-tracking"
            >
              More about easy time-tracking
              <span className={`${styles.cta_icons}`}>
                <ArrowForwardIosRoundedIcon sx={{ fontSize: 17 }} />
              </span>
            </NavLink>
          </div>

          <div
            className={`${styles.text_font1} ${styles.border_left_side_of_content}`}
          >
            "Zodot's time-tracking has revolutionized my work routine! It's like
            having a personal time assistant."
            <span className={`${styles.text_font2}`}> -Jack S.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTracking;
