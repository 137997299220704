import { postRequest, putRequest } from "../../axios/axios";
import URL from "../../axios/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchNotifications = createAsyncThunk(
  "notification/fetch-notifications",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.FETCH_NOTIFICATIONS.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const ViewedNotifications = createAsyncThunk(
  "notification/viewed-notifications",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.VIEWED_NOTIFICATIONS.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
