import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Invoices.module.css";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import HomePageInvoiceSectionImage from "../../../../Assets/frontendImages/invoice_homepage.avif";

const Invoices = () => {
  return (
    <div
      className="d-flex gap-3 gam-md-5 flex-column flex-md-row align-items-center"
      style={{ overflow: "hidden" }}
    >
      <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 w-100">
        <div
          data-aos={"fade-right"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 550,
            maxHeight: 550,
          }}
        >
          <img
            src={HomePageInvoiceSectionImage}
            alt="homePageInvoiceImageBtn"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
      <div className="text-start mt-3 mt-md-0 w-100">
        <div className="w-100" data-aos={"fade-left"}>
          <div className={`${styles.heading} mb-4`}>
            Tired of tangled billing processes?
          </div>
          <div className={`${styles.text_font1} mb-4`}>
            Say goodbye to manual invoicing! With Zodot, easily manage, and send
            invoices online with a click. Create invoices online for free, and
            simplify tracking payments. Try Zodot and experience convenience.
          </div>
          <div className="d-flex mb-4">
            <NavLink
              className={styles.more_button}
              style={{ textDecoration: "none" }}
              to="/features/invoicing"
            >
              More about easy invoicing
              <span className={`${styles.cta_icons}`}>
                <ArrowForwardIosRoundedIcon sx={{ fontSize: 17 }} />
              </span>
            </NavLink>
          </div>
          <div
            className={`${styles.text_font1} ${styles.border_left_side_of_content} mb-4`}
          >
            “Finally, a game-changer! Generating invoices has never been
            smoother. Highly recommend!”{" "}
            <span className={`${styles.text_font2}`}>-John D.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoices;
