import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { postRequest } from "../../../../axios/axios";
import TextStyles from "../../../../Constants/TextStyles";
import LoaderSpin from "../../Commons/LoaderSpin";
import Colors from "../../../../Constants/Colors";

const Details = () => {
  const { id } = useParams();
  const [file, setFile] = useState(null);

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    viewInvoiceDetailsHandler();
  }, [id]);

  const viewInvoiceDetailsHandler = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    postRequest(`invoices/view_invoice/details`, token, {
      _id: id,
    })
      .then((data) => {
        if (data.data.success === true) {
          setIsLoading(false);
          setError(false);
          setFile(data.data.data);
        }
      })
      .catch((err) => {
        setError(true);
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>,
            { toastId: "fail" }
          );
        }
        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>,
            { toastId: "error" }
          );
        }
      });
  };

  return (
    <div className="d-flex justify-content-center align-items-center px-2 px-sm-3 px-md-4 px-lg-4">
      <div
        style={{
          width: "100%",
          maxWidth: "850px",
          paddingBottom: 80,
        }}
      >
        {isLoading ? (
          <TextStyles.FontSize14px
            style={{
              width: "100%",
              height: "100%",
              minHeight: 460,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoaderSpin color={Colors.primary} />
          </TextStyles.FontSize14px>
        ) : error ? (
          <TextStyles.FontSize14px
            style={{
              width: "100%",
              height: "100%",
              minHeight: 460,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextStyles.FontSize16px
              style={{
                padding: "12px 20px",
                borderRadius: 9,
                border: `2px solid ${Colors.red}`,
                color: Colors.red,
              }}
            >
              Sorry, we couldn't find your invoice.
            </TextStyles.FontSize16px>
          </TextStyles.FontSize14px>
        ) : (
          <iframe
            src={file}
            style={{
              height: "1000px",
              verticalAlign: "middle",
              width: "100%",
            }}
            title="Invoice pdf"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default Details;
