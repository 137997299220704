import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const SliderBox = styled.div`
  width: 51.32rem;
  border-radius: 0.75rem;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
`;

const SelectedItem = styled.div`
  padding: 1rem 1.5rem;
  height: 3.15rem;
  border-radius: 0.75rem;
  cursor: pointer;
  font-family: Poppins;
  font-size: 0.875rem;
  background-color: ${Colors.primary};
  color: ${Colors.white};

  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }
`;

const NotSelectedItem = styled(SelectedItem)`
  color: ${Colors.font2} !important;
  background-color: ${Colors.white} !important;

  &:hover {
    background-color: ${Colors.backgroundPink} !important;
    color: ${Colors.primary} !important;
  }
`;

export default {
  SliderBox,
  SelectedItem,
  NotSelectedItem,
};
