import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const AppropriateConduct = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.add_sexual_harassment_clause &&
    selectedData.add_sexual_harassment_clause.heading
      ? selectedData.add_sexual_harassment_clause.heading
      : "Appropriate Conduct";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.add_sexual_harassment_clause &&
          selectedData.add_sexual_harassment_clause.required === true
            ? "inline"
            : "none",
      }}
    >
      {selectedData &&
        selectedData.add_sexual_harassment_clause &&
        selectedData.add_sexual_harassment_clause
          .add_sexual_harassment_clause_require === true && (
          <Styled.ReviewContentBox className="mt-4">
            <TextStyles.FontSize20px className="mb-2">
              {heading}
            </TextStyles.FontSize20px>
            {selectedData &&
            selectedData.add_sexual_harassment_clause &&
            selectedData.add_sexual_harassment_clause
              .add_sexual_harassment_clause_require === true &&
            selectedData.add_sexual_harassment_clause.paragraph ? (
              <div style={{ whiteSpace: "break-spaces" }}>
                {selectedData.add_sexual_harassment_clause.paragraph}
              </div>
            ) : selectedData &&
              selectedData.add_sexual_harassment_clause &&
              selectedData.add_sexual_harassment_clause
                .add_sexual_harassment_clause_require === true ? (
              <TextStyles.FontSize14px>
                Client and Contractor are committed to maintaining a
                professional relationship free from any form of harassment or
                offensive conduct. If Contractor experiences any harassing
                behavior from Client or Client's staff during the term of this
                Agreement, Contractor will promptly notify Client and request
                appropriate corrective action. If the reported behavior persists
                after Contractor's second notice, it will be considered a breach
                of this Agreement, allowing Contractor to terminate the
                Agreement in accordance with the Term and Termination Clause.
                Contractor will be entitled to receive full payment for the
                ordered project or planned monthly service hours, without
                prejudice to any other rights or remedies available to
                Contractor under the law.
              </TextStyles.FontSize14px>
            ) : (
              <>Note: This section shows if only applicable.</>
            )}
          </Styled.ReviewContentBox>
        )}
    </div>
  );
};
export default AppropriateConduct;
