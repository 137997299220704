import React from "react";
import Acceptance from "./Components/Acceptance.jsx";
import Warranty from "./Components/Warranty";
import Confidential from "./Components/Confidential";

import Responsibility from "./Components/Responsiblity.jsx";
import Compensation from "./Components/Compensation.jsx";

const PageThree = () => {
  return (
    <div>
      <Acceptance />
      <Responsibility />
      <Compensation />
      <Warranty />
      <Confidential />
    </div>
  );
};

export default PageThree;
