import React from "react";
import { NavLink } from "react-router-dom";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";

import styles from "./AdditionalFeatures.module.css";

const AdditionalFeatures = () => {
  return (
    <div className={styles.section}>
      <div className="container-fluid py-3 py-md-4">
        <div
          className={`${styles.heading} text-center mb-1`}
          data-aos={"fade-up"}
        >
          Additional features
        </div>

        <div
          className={`${styles.heading_text} text-center mb-3 mb-sm-4`}
          data-aos={"fade-up"}
        >
          Easily manage your ideal workflow with
          <span className={`${styles.heading_text_brand} mx-2`}>Zodot's</span>
          building blocks
        </div>

        <div
          className="row d-flex justify-content-between align-items-center"
          data-aos={"fade-up"}
        >
          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Customize branding for lasting impressions
              </div>

              <div className={`${styles.container_text} text-start mb-2`}>
                Give agreements a distinct identity with Zodot’s customizable
                branding. Ensure a refined presentation that mirrors your
                business, leaving a lasting impact on clients.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Secure e-signatures for faster deals
              </div>

              <div className={`${styles.container_text} text-start mb-2`}>
                Zodot’s secure e-signature expedites signing, eliminates
                paperwork, and ensures legal validity, instilling confidence in
                you and your clients throughout every transaction.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Send agreements via email easily
              </div>

              <div className={`${styles.container_text} text-start mb-2`}>
                With Zodot, effortlessly create and send agreements via email.
                Preview before sending and customize emails using templates or
                create your own for a personalized touch.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Get notified instantly
              </div>

              <div className={`${styles.container_text} text-start mb-2`}>
                You won't have to worry about whether your agreements have been
                seen or signed anymore. Zodot will let you know right away when
                someone views or signs your agreements.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Track status and stay in charge
              </div>

              <div className={`${styles.container_text} text-start mb-2`}>
                Gain control over your deals with Zodot’s real-time
                collaboration. Use our advanced agreements tool to track your
                contracts – whether approved, dismissed, or pending. Stay ahead
                and in charge.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Convert agreements to projects manually
              </div>

              <div className={`${styles.container_text} text-start mb-2`}>
                To make sure everything is in order, you can even manually give
                your approval to the agreements. Once you've given the green
                light, We Zodot automatically converts them into projects.
              </div>
            </div>
          </div>
        </div>

        <NavLink
          data-aos={"fade-up"}
          className={`d-block text-decoration-none mx-auto ${styles.more_button}`}
          to="/db/signup"
        >
          Explore now
          <span className={`${styles.forward_arrow}`}>
            <EastOutlinedIcon sx={{ fontSize: 24 }} />
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default AdditionalFeatures;
