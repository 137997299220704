import React from "react";
import Footer from "../Footer/Footer";
import LandingPage from "./LandingPage/LandingPage";
import ClientReviews from "./CustomerReviews/ClientReviews";
import Workload from "../HomePage/Workload/Workload";

const Reviews = () => {
  return (
    <div>
      <LandingPage />
      <ClientReviews />
      <Workload />
      <Footer />
    </div>
  );
};

export default React.memo(Reviews);
