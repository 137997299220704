import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "../../../TimeTracking/WeeklyView/WeeklyViewStyles";
import Colors from "../../../../../Constants/Colors";
import NoTimeTrackingImage from "../../../../../Assets/assetsnew/no-time-tracking.svg";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import CustomWeekPicker from "../../../Commons/CustomWeekPicker";
import SelectActivity from "../../../Commons/SelectActivity";
import { toast } from "react-toastify";

import moment from "moment/moment";
import {
  fetchWeekView,
  updateWeekviewActivityNames,
  createWeekViewSingleActivity,
  updateWeekViewSingleActivity,
  fetchAllActivityLists,
} from "../../../../../store/service/timeTrackingService";
import EditWeekviewMultipleActivityTimes from "../../../TimeTracking/TimerModals/EditWeekviewMultipleActivityTimes";
import dayjs from "dayjs";
import { weekviewNumberFormat } from "../../../Commons/utils";
import LoaderSpin from "../../../Commons/LoaderSpin";

const WeeklyView = () => {
  const dispatch = useDispatch();
  const weekViewData = useSelector((state) => state.timeTracking.weekViewData);
  const isLoading = useSelector((state) => state.timeTracking.isLoading);

  const selectedProject = useSelector((state) => state.project.selectedProject);
  const activityListData = useSelector(
    (state) => state.timeTracking.activityListData
  );

  let groupByProjectId =
    weekViewData &&
    weekViewData.reduce((acc, value) => {
      acc[value.project_id && value.project_id._id] = [
        ...(acc[value.project_id && value.project_id._id] || []),
        value,
      ];
      return acc;
    }, {});

  const mappingWeekViewDataByProject =
    groupByProjectId && Object.values(groupByProjectId);

  let groupByActivityName =
    mappingWeekViewDataByProject &&
    mappingWeekViewDataByProject.map((d) => {
      const data = d.reduce((acc, value) => {
        acc[value.activity_name && value.activity_name] = [
          ...(acc[value.activity_name && value.activity_name] || []),
          value,
        ];
        return acc;
      }, {});
      const mappingWeekViewDataActivityName = data && Object.values(data);
      return mappingWeekViewDataActivityName;
    });

  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [filteredWeek, setFilteredWeek] = useState(null);
  const [filteredWeekData, setFilteredWeekData] = useState(dayjs());

  const [startFilterDate, setStartFilterDate] = useState(null);

  const [filteredActivity, setFilteredActivity] = useState(null);

  const [showFilter, setShowFilter] = useState(false);
  const toggleShowFilter = () => setShowFilter(!showFilter);
  const [updateRefresh, setUpdateRefresh] = useState(false);

  useEffect(() => {
    setSelectedData(null);
  }, [updateRefresh]);

  const [collapse, setCollapse] = useState(null);
  const collapsableHandleChange = (index) => {
    if (collapse === index) {
      return setCollapse(null);
    }
    setCollapse(index);
  };

  useEffect(() => {
    setCollapse(null);
  }, [showModal]);

  const timeout = useRef(null);

  useEffect(() => {
    const startDateOfWeek = dayjs().startOf("week").format("YYYY-MM-DD");
    const endDateOfWeek = dayjs().endOf("week").format("YYYY-MM-DD");
    const firstDateOfWeek = filteredWeek
      ? dayjs(filteredWeek).startOf("week").format("YYYY-MM-DD")
      : startDateOfWeek;
    const lastDateOfWeek = filteredWeek
      ? dayjs(filteredWeek).endOf("week").format("YYYY-MM-DD")
      : endDateOfWeek;
    setStartFilterDate(firstDateOfWeek);
    const Obj = {
      firstDateOfWeek,
      lastDateOfWeek,
      client_id:
        selectedProject && selectedProject.client_id
          ? selectedProject.client_id._id
          : null,
      project_id: selectedProject ? selectedProject._id : null,
      activity_name: filteredActivity !== 0 ? filteredActivity : null,
    };
    dispatch(fetchWeekView(Obj));
  }, [filteredWeek, filteredActivity, updateRefresh]);

  const createAndUpdateActivity = (value, data, element, date) => {
    let startDate = new Date(date);
    startDate.setHours(new Date().getHours());
    startDate.setMinutes(new Date().getMinutes());
    startDate.setSeconds(new Date().getSeconds());

    if (data.length > 0) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        const start_time = data && data[0] && new Date(data[0].start_time);
        const end_time = new Date(moment(start_time).add(value, "hours"));
        const Obj = {
          _id: data && data[0]._id,
          end_time,
        };
        dispatch(updateWeekViewSingleActivity(Obj)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            setUpdateRefresh(!updateRefresh);
          } else {
            toast.error(
              <TextStyles.FontSize14px>
                {data.error.message}
              </TextStyles.FontSize14px>,
              { toastId: "updateWeekActivityFail" }
            );
          }
        });
      }, 500);
    } else {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        const start_time = new Date(startDate);
        const end_time = new Date(moment(start_time).add(value, "hours"));
        const Obj = {
          activity_name: element && element[0] && element[0].activity_name,
          client_id: element && element[0] && element[0].client_id,
          project_id: element && element[0] && element[0].project_id,
          start_time,
          end_time,
        };
        dispatch(createWeekViewSingleActivity(Obj)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            setUpdateRefresh(!updateRefresh);
          } else {
            toast.error(
              <TextStyles.FontSize14px>
                {data.error.message}
              </TextStyles.FontSize14px>,
              { toastId: "createWeekActivityFail" }
            );
          }
        });
      }, 500);
    }
  };

  const activityWiseTotalTimeEachDay = (data, element, date) => {
    let sum = 0;
    data &&
      data.forEach((d) => {
        sum += new Date(d.end_time) - new Date(d.start_time);
      });
    const sumInSeconds = Math.floor(sum / 1000);
    const sumInHours = Math.abs(sumInSeconds / 3600);
    const totalHours = weekviewNumberFormat(sumInHours);

    if (totalHours > 0.0) {
      return (
        <div
          onClick={() => {
            if (data.length >= 2) {
              setShowModal(true);
              setSelectedData(data);
            }
          }}
        >
          <Styled.DaysInputRectangle
            onChange={(e) => {
              createAndUpdateActivity(e.target.value, data, element, date);
            }}
            style={{ maxWidth: "3rem" }}
            defaultValue={totalHours}
          />
        </div>
      );
    } else {
      return (
        <div
          onClick={() => {
            if (data.length >= 2) {
              setShowModal(true);
              setSelectedData(data);
            }
          }}
        >
          <Styled.DaysInputRectangle
            style={{ maxWidth: "3rem" }}
            onChange={(e) => {
              createAndUpdateActivity(e.target.value, data, element, date);
            }}
            defaultValue={0}
          />
        </div>
      );
    }
  };

  const projectWiseTotalTimeEachDay = (data) => {
    let sum = 0;
    data &&
      data.forEach((d) => {
        sum += new Date(d.end_time) - new Date(d.start_time);
      });
    const sumInSeconds = Math.floor(sum / 1000);
    const sumInHours = Math.abs(sumInSeconds / 3600);
    const totalHours = weekviewNumberFormat(sumInHours);

    if (totalHours > 0) {
      return totalHours;
    } else {
      return 0;
    }
  };

  const totalTimeEachDay = (data) => {
    let sum = 0;
    data &&
      data.forEach((d) => {
        sum += new Date(d.end_time) - new Date(d.start_time);
      });
    const sumInSeconds = Math.floor(sum / 1000);
    const sumInHours = Math.abs(sumInSeconds / 3600);
    const totalHours = weekviewNumberFormat(sumInHours);

    if (totalHours > 0) {
      return totalHours;
    } else {
      return 0;
    }
  };

  const updateActivityNames = (activity_name, element) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      const Obj = {
        activity_name: activity_name,
        updateSelectedActivities: element.map((d) => d._id),
      };
      dispatch(updateWeekviewActivityNames(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists()).then((data) => {
            if (data.meta.requestStatus === "fulfilled") {
              setUpdateRefresh(!updateRefresh);
            }
          });
        } else {
          toast.error(
            <TextStyles.FontSize14px>
              {data.error.message}
            </TextStyles.FontSize14px>,
            { toastId: "weekViewActivityFail" }
          );
        }
      });
    }, 1000);
  };

  const weeklyTotalTimes = useMemo(() => {
    let sum = 0;
    weekViewData &&
      weekViewData.forEach((item) => {
        sum += new Date(item.end_time) - new Date(item.start_time);
      });
    const timesInHours = Math.abs(sum / (1000 * 60 * 60));
    const totalHours = weekviewNumberFormat(timesInHours);
    return totalHours;
  }, [weekViewData]);

  return (
    <div className="my-3">
      <div className="d-flex justify-content-end mb-3">
        <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
          Filter
        </TextStyles.HoverEffectSpan>
      </div>

      {showFilter && (
        <div className="d-flex flex-wrap gap-3 justify-content-start mb-3">
          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Week
            </TextStyles.FontSize14px>
            <CustomWeekPicker
              defaultValue={filteredWeekData && filteredWeekData}
              onChange={(date, value) => {
                setFilteredWeekData(date);
                setFilteredWeek(date);
              }}
            />
          </div>

          <div>
            <TextStyles.FontSize14px className="d-flex mb-2">
              Activity
            </TextStyles.FontSize14px>
            <SelectActivity
              defaultValue={filteredActivity && filteredActivity}
              options={activityListData}
              onChange={(e) => setFilteredActivity(e.target.value)}
            />
          </div>
        </div>
      )}
      <TextStyles.GreyFont16px
        className="d-flex justify-content-end mb-3"
        style={{ fontWeight: 500 }}
      >
        Total weekly time:{` ${weeklyTotalTimes} hrs`}
      </TextStyles.GreyFont16px>

      <Styled.WeeklyViewBox
        style={{
          background: Colors.tableHeadColor,
          borderRadius: "12px 12px 0 0",
        }}
      >
        <div className="d-flex gap-1 justify-content-between">
          <TextStyles.FontSize14px
            className="d-flex"
            style={{ width: "100%", minWidth: "2rem" }}
          />
          <TextStyles.GreyFont14px
            className="d-flex gap-1 justify-content-end w-100"
            style={{ maxWidth: 500, minWidth: 250 }}
          >
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate)).format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate)).format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {" "}
                {moment(new Date(startFilterDate)).format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
                  ? "Today"
                  : "Sun"}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(1, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(1, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {" "}
                {moment(new Date(startFilterDate))
                  .add(1, "d")
                  .format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
                  ? "Today"
                  : "Mon"}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(2, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(2, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {" "}
                {moment(new Date(startFilterDate))
                  .add(2, "d")
                  .format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
                  ? "Today"
                  : "Tue"}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(3, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(3, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {" "}
                {moment(new Date(startFilterDate))
                  .add(3, "d")
                  .format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
                  ? "Today"
                  : "Wed"}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(4, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(4, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {moment(new Date(startFilterDate))
                  .add(4, "d")
                  .format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
                  ? "Today"
                  : "Thu"}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(5, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(5, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {moment(new Date(startFilterDate))
                  .add(5, "d")
                  .format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
                  ? "Today"
                  : "Fri"}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(6, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(6, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {" "}
                {moment(new Date(startFilterDate))
                  .add(6, "d")
                  .format("YYYY-MM-DD") ===
                moment(new Date()).format("YYYY-MM-DD")
                  ? "Today"
                  : "Sat"}
              </span>
            </Styled.WeeklyViewDays>
          </TextStyles.GreyFont14px>
        </div>
      </Styled.WeeklyViewBox>

      <Styled.WeeklyViewBox
        style={{
          marginBottom: 2,
        }}
      >
        <div className="d-flex gap-1 justify-content-between">
          <TextStyles.FontSize14px
            className="d-flex justify-content-start align-items-center"
            style={{ width: "100%", minWidth: "2rem" }}
          >
            <span className="three-dots"> Total time (Hours)</span>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px
            className="d-flex gap-1 justify-content-end w-100"
            style={{ maxWidth: 500, minWidth: 250 }}
          >
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate)).format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate)).format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {" "}
                {totalTimeEachDay(
                  weekViewData &&
                    weekViewData.filter(
                      (d) => moment(d.start_time).format("ddd") === "Sun"
                    )
                )}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(1, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(1, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {" "}
                {totalTimeEachDay(
                  weekViewData &&
                    weekViewData.filter(
                      (d) => moment(d.start_time).format("ddd") === "Mon"
                    )
                )}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(2, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(2, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {" "}
                {totalTimeEachDay(
                  weekViewData &&
                    weekViewData.filter(
                      (d) => moment(d.start_time).format("ddd") === "Tue"
                    )
                )}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(3, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(3, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {" "}
                {totalTimeEachDay(
                  weekViewData &&
                    weekViewData.filter(
                      (d) => moment(d.start_time).format("ddd") === "Wed"
                    )
                )}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(4, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(4, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {totalTimeEachDay(
                  weekViewData &&
                    weekViewData.filter(
                      (d) => moment(d.start_time).format("ddd") === "Thu"
                    )
                )}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(5, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(5, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {" "}
                {totalTimeEachDay(
                  weekViewData &&
                    weekViewData.filter(
                      (d) => moment(d.start_time).format("ddd") === "Fri"
                    )
                )}
              </span>
            </Styled.WeeklyViewDays>
            <Styled.WeeklyViewDays
              className="w-100"
              style={{
                minWidth: "2rem",
                background:
                  moment(new Date(startFilterDate))
                    .add(6, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.backgroundPink
                    : "",
                color:
                  moment(new Date(startFilterDate))
                    .add(6, "d")
                    .format("YYYY-MM-DD") ===
                  moment(new Date()).format("YYYY-MM-DD")
                    ? Colors.primary
                    : "",
              }}
            >
              <span className="three-dots">
                {totalTimeEachDay(
                  weekViewData &&
                    weekViewData.filter(
                      (d) => moment(d.start_time).format("ddd") === "Sat"
                    )
                )}
              </span>
            </Styled.WeeklyViewDays>
          </TextStyles.FontSize14px>
        </div>
      </Styled.WeeklyViewBox>

      {weekViewData && weekViewData.length > 0 ? (
        <div>
          {groupByActivityName &&
            groupByActivityName.map((item, index) => {
              return (
                <Styled.WeeklyViewBox
                  style={{
                    marginBottom:
                      groupByActivityName.length - 1 !== index ? 2 : "",
                    borderRadius:
                      groupByActivityName.length - 1 === index
                        ? "0 0 12px 12px"
                        : "",
                  }}
                  key={index}
                >
                  <div
                    className="d-flex gap-1 justify-content-between"
                    onClick={() => collapsableHandleChange(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <TextStyles.FontSize14px
                      className="d-flex justify-content-start align-items-center"
                      style={{
                        width: "100%",
                        minWidth: "2rem",
                      }}
                    >
                      <span className="three-dots">
                        {item[0][0] &&
                        item[0][0].project_id &&
                        item[0][0].project_id.project_name
                          ? item[0][0].project_id.project_name
                          : "Unassigned"}{" "}
                        {item[0][0] &&
                          item[0][0].client_id &&
                          item[0][0].client_id.company_name &&
                          `(${item[0][0].client_id.company_name})`}
                        {collapse === index ? (
                          <ArrowDropUpIcon
                            sx={{ color: "#5B5B5B", fontSize: 30 }}
                          />
                        ) : (
                          <ArrowDropDownIcon
                            sx={{ color: "#5B5B5B", fontSize: 30 }}
                          />
                        )}
                      </span>
                    </TextStyles.FontSize14px>
                    <TextStyles.FontSize14px
                      className="d-flex gap-1 justify-content-end w-100"
                      style={{ maxWidth: 500, minWidth: 250 }}
                    >
                      <Styled.WeeklyViewDays
                        className="w-100"
                        style={{
                          minWidth: "2rem",
                          background:
                            moment(new Date(startFilterDate)).format(
                              "YYYY-MM-DD"
                            ) === moment(new Date()).format("YYYY-MM-DD")
                              ? Colors.backgroundPink
                              : "",
                        }}
                      >
                        <span className="three-dots">
                          {projectWiseTotalTimeEachDay(
                            item
                              .flat()
                              .filter(
                                (d) =>
                                  moment(d.start_time).format("ddd") === "Sun"
                              )
                          )}
                        </span>
                      </Styled.WeeklyViewDays>
                      <Styled.WeeklyViewDays
                        className="w-100"
                        style={{
                          minWidth: "2rem",
                          background:
                            moment(new Date(startFilterDate))
                              .add(1, "d")
                              .format("YYYY-MM-DD") ===
                            moment(new Date()).format("YYYY-MM-DD")
                              ? Colors.backgroundPink
                              : "",
                        }}
                      >
                        <span className="three-dots">
                          {" "}
                          {projectWiseTotalTimeEachDay(
                            item
                              .flat()
                              .filter(
                                (d) =>
                                  moment(d.start_time).format("ddd") === "Mon"
                              )
                          )}
                        </span>
                      </Styled.WeeklyViewDays>
                      <Styled.WeeklyViewDays
                        className="w-100"
                        style={{
                          minWidth: "2rem",
                          background:
                            moment(new Date(startFilterDate))
                              .add(2, "d")
                              .format("YYYY-MM-DD") ===
                            moment(new Date()).format("YYYY-MM-DD")
                              ? Colors.backgroundPink
                              : "",
                        }}
                      >
                        <span className="three-dots">
                          {" "}
                          {projectWiseTotalTimeEachDay(
                            item
                              .flat()
                              .filter(
                                (d) =>
                                  moment(d.start_time).format("ddd") === "Tue"
                              )
                          )}
                        </span>
                      </Styled.WeeklyViewDays>
                      <Styled.WeeklyViewDays
                        className="w-100"
                        style={{
                          minWidth: "2rem",
                          background:
                            moment(new Date(startFilterDate))
                              .add(3, "d")
                              .format("YYYY-MM-DD") ===
                            moment(new Date()).format("YYYY-MM-DD")
                              ? Colors.backgroundPink
                              : "",
                        }}
                      >
                        <span className="three-dots">
                          {" "}
                          {projectWiseTotalTimeEachDay(
                            item
                              .flat()
                              .filter(
                                (d) =>
                                  moment(d.start_time).format("ddd") === "Wed"
                              )
                          )}
                        </span>
                      </Styled.WeeklyViewDays>
                      <Styled.WeeklyViewDays
                        className="w-100"
                        style={{
                          minWidth: "2rem",
                          background:
                            moment(new Date(startFilterDate))
                              .add(4, "d")
                              .format("YYYY-MM-DD") ===
                            moment(new Date()).format("YYYY-MM-DD")
                              ? Colors.backgroundPink
                              : "",
                        }}
                      >
                        <span className="three-dots">
                          {" "}
                          {projectWiseTotalTimeEachDay(
                            item
                              .flat()
                              .filter(
                                (d) =>
                                  moment(d.start_time).format("ddd") === "Thu"
                              )
                          )}
                        </span>
                      </Styled.WeeklyViewDays>
                      <Styled.WeeklyViewDays
                        className="w-100"
                        style={{
                          minWidth: "2rem",
                          background:
                            moment(new Date(startFilterDate))
                              .add(5, "d")
                              .format("YYYY-MM-DD") ===
                            moment(new Date()).format("YYYY-MM-DD")
                              ? Colors.backgroundPink
                              : "",
                        }}
                      >
                        <span className="three-dots">
                          {projectWiseTotalTimeEachDay(
                            item
                              .flat()
                              .filter(
                                (d) =>
                                  moment(d.start_time).format("ddd") === "Fri"
                              )
                          )}
                        </span>
                      </Styled.WeeklyViewDays>
                      <Styled.WeeklyViewDays
                        className="w-100"
                        style={{
                          minWidth: "2rem",
                          background:
                            moment(new Date(startFilterDate))
                              .add(6, "d")
                              .format("YYYY-MM-DD") ===
                            moment(new Date()).format("YYYY-MM-DD")
                              ? Colors.backgroundPink
                              : "",
                        }}
                      >
                        <span className="three-dots">
                          {projectWiseTotalTimeEachDay(
                            item
                              .flat()
                              .filter(
                                (d) =>
                                  moment(d.start_time).format("ddd") === "Sat"
                              )
                          )}
                        </span>
                      </Styled.WeeklyViewDays>
                    </TextStyles.FontSize14px>
                  </div>

                  {collapse === index &&
                    item &&
                    item.map((element, i) => {
                      return (
                        <div
                          className="d-flex gap-1 justify-content-between"
                          key={i}
                        >
                          <TextStyles.GreyFont14px
                            className="d-flex justify-content-start align-items-center"
                            style={{
                              width: "100%",
                              minWidth: "2rem",
                            }}
                          >
                            <Styled.DaysInputRectangle
                              style={{
                                maxWidth: "20rem",
                                paddingLeft: "0.5rem",
                                textAlign: "start",
                              }}
                              placeholder="Write Activity"
                              defaultValue={element && element[0].activity_name}
                              onChange={(e) =>
                                updateActivityNames(e.target.value, element)
                              }
                            />
                          </TextStyles.GreyFont14px>
                          <TextStyles.GreyFont14px
                            className="d-flex gap-1 justify-content-end w-100"
                            style={{ maxWidth: 500, minWidth: 250 }}
                          >
                            <Styled.WeeklyViewDays
                              className="w-100"
                              style={{
                                minWidth: "2rem",
                                background:
                                  moment(new Date(startFilterDate)).format(
                                    "YYYY-MM-DD"
                                  ) === moment(new Date()).format("YYYY-MM-DD")
                                    ? Colors.backgroundPink
                                    : "",
                              }}
                            >
                              {activityWiseTotalTimeEachDay(
                                element.filter(
                                  (d) =>
                                    moment(d.start_time).format("ddd") === "Sun"
                                ),
                                element,
                                moment(new Date(startFilterDate)).format(
                                  "YYYY-MM-DD"
                                )
                              )}
                            </Styled.WeeklyViewDays>
                            <Styled.WeeklyViewDays
                              className="w-100"
                              style={{
                                minWidth: "2rem",
                                background:
                                  moment(new Date(startFilterDate))
                                    .add(1, "d")
                                    .format("YYYY-MM-DD") ===
                                  moment(new Date()).format("YYYY-MM-DD")
                                    ? Colors.backgroundPink
                                    : "",
                              }}
                            >
                              {activityWiseTotalTimeEachDay(
                                element.filter(
                                  (d) =>
                                    moment(d.start_time).format("ddd") === "Mon"
                                ),
                                element,
                                moment(new Date(startFilterDate))
                                  .add(1, "d")
                                  .format("YYYY-MM-DD")
                              )}
                            </Styled.WeeklyViewDays>
                            <Styled.WeeklyViewDays
                              className="w-100"
                              style={{
                                minWidth: "2rem",
                                background:
                                  moment(new Date(startFilterDate))
                                    .add(2, "d")
                                    .format("YYYY-MM-DD") ===
                                  moment(new Date()).format("YYYY-MM-DD")
                                    ? Colors.backgroundPink
                                    : "",
                              }}
                            >
                              {activityWiseTotalTimeEachDay(
                                element.filter(
                                  (d) =>
                                    moment(d.start_time).format("ddd") === "Tue"
                                ),
                                element,
                                moment(new Date(startFilterDate))
                                  .add(2, "d")
                                  .format("YYYY-MM-DD")
                              )}
                            </Styled.WeeklyViewDays>
                            <Styled.WeeklyViewDays
                              className="w-100"
                              style={{
                                minWidth: "2rem",
                                background:
                                  moment(new Date(startFilterDate))
                                    .add(3, "d")
                                    .format("YYYY-MM-DD") ===
                                  moment(new Date()).format("YYYY-MM-DD")
                                    ? Colors.backgroundPink
                                    : "",
                              }}
                            >
                              {activityWiseTotalTimeEachDay(
                                element.filter(
                                  (d) =>
                                    moment(d.start_time).format("ddd") === "Wed"
                                ),
                                element,
                                moment(new Date(startFilterDate))
                                  .add(3, "d")
                                  .format("YYYY-MM-DD")
                              )}
                            </Styled.WeeklyViewDays>
                            <Styled.WeeklyViewDays
                              className="w-100"
                              style={{
                                minWidth: "2rem",
                                background:
                                  moment(new Date(startFilterDate))
                                    .add(4, "d")
                                    .format("YYYY-MM-DD") ===
                                  moment(new Date()).format("YYYY-MM-DD")
                                    ? Colors.backgroundPink
                                    : "",
                              }}
                            >
                              {activityWiseTotalTimeEachDay(
                                element.filter(
                                  (d) =>
                                    moment(d.start_time).format("ddd") === "Thu"
                                ),
                                element,
                                moment(new Date(startFilterDate))
                                  .add(4, "d")
                                  .format("YYYY-MM-DD")
                              )}
                            </Styled.WeeklyViewDays>
                            <Styled.WeeklyViewDays
                              className="w-100"
                              style={{
                                minWidth: "2rem",
                                background:
                                  moment(new Date(startFilterDate))
                                    .add(5, "d")
                                    .format("YYYY-MM-DD") ===
                                  moment(new Date()).format("YYYY-MM-DD")
                                    ? Colors.backgroundPink
                                    : "",
                              }}
                            >
                              {activityWiseTotalTimeEachDay(
                                element.filter(
                                  (d) =>
                                    moment(d.start_time).format("ddd") === "Fri"
                                ),
                                element,
                                moment(new Date(startFilterDate))
                                  .add(5, "d")
                                  .format("YYYY-MM-DD")
                              )}
                            </Styled.WeeklyViewDays>
                            <Styled.WeeklyViewDays
                              className="w-100"
                              style={{
                                minWidth: "2rem",
                                background:
                                  moment(new Date(startFilterDate))
                                    .add(6, "d")
                                    .format("YYYY-MM-DD") ===
                                  moment(new Date()).format("YYYY-MM-DD")
                                    ? Colors.backgroundPink
                                    : "",
                              }}
                            >
                              {activityWiseTotalTimeEachDay(
                                element.filter(
                                  (d) =>
                                    moment(d.start_time).format("ddd") === "Sat"
                                ),
                                element,
                                moment(new Date(startFilterDate))
                                  .add(6, "d")
                                  .format("YYYY-MM-DD")
                              )}
                            </Styled.WeeklyViewDays>
                          </TextStyles.GreyFont14px>
                        </div>
                      );
                    })}
                </Styled.WeeklyViewBox>
              );
            })}
        </div>
      ) : isLoading && (weekViewData === null || weekViewData.length === 0) ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        !isLoading &&
        (weekViewData === null || weekViewData.length === 0) && (
          <Styled.ListBox>
            <img src={NoTimeTrackingImage} alt="no-time-tracting-img" />
            <TextStyles.GreyFont18px className="mt-2">
              There is no activity this week.
            </TextStyles.GreyFont18px>
          </Styled.ListBox>
        )
      )}

      {selectedData && (
        <EditWeekviewMultipleActivityTimes
          show={showModal}
          handleClose={handleClose}
          selectedData={selectedData}
          updateRefresh={updateRefresh}
          setUpdateRefresh={setUpdateRefresh}
        />
      )}
    </div>
  );
};

export default React.memo(WeeklyView);
