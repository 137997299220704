import React from "react";
import { useHistory } from "react-router-dom";
import userProfileImg from "../../../../Assets/assetsnew/userinfo.png";

import Styled from "../SettingStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

const AccountAndSecurity = () => {
  const history = useHistory();

  return (
    <div
      className="pt-4"
      style={{ backgroundColor: Colors.backgroundGrey, minHeight: "100vh" }}
    >
      <div className="d-flex flex-wrap justify-content-center gap-5">
        <Styled.SettingRectangle
          onClick={() => {
            history.push("/db/setting/account/user_info");
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <img src={userProfileImg} alt="userBtn" style={{ width: 65 }} />
          </div>
          <TextStyles.FontSize20px className="my-3">
            Set up your user info
          </TextStyles.FontSize20px>
          <TextStyles.GreyFont16px>
            Modify name, email, profession, experience, and profile pic etc.
          </TextStyles.GreyFont16px>
        </Styled.SettingRectangle>
      </div>
    </div>
  );
};

export default AccountAndSecurity;
