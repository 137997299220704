import React, { useState } from "react";
import imageBtn from "../../../../../Assets/assetsnew/image.svg";
import fileBtn from "../../../../../Assets/assetsnew/file-add-symbol.svg";
import textBtn from "../../../../../Assets/assetsnew/text-image.svg";
import tableBtn from "../../../../../Assets/assetsnew/table-image.svg";
import columnBtn from "../../../../../Assets/assetsnew/column-image.svg";

import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";

import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import Styled from "./AddSectionStyles";

import AddSectionTextModal from "./Text/AddSectionTextModal";
import AddSectionImageModal from "./Image/AddSectionImageModal";
import AddSectionTableModal from "./Table/AddSectionTableModal";
import AddSectionColumnModal from "./Column/AddSectionColumnModal";
import AddSectionFileModal from "./File/AddSectionFileModal";

const AddSection = ({ selectedData, position }) => {
  const [textShowModal, setTextShowModal] = useState(false);
  const [imageShowModal, setImageShowModal] = useState(false);
  const [tableShowModal, setTableShowModal] = useState(false);
  const [columnShowModal, setColumnShowModal] = useState(false);
  const [fileShowModal, setFileShowModal] = useState(false);

  return (
    <>
      <Styled.AddSectionContainer className="py-4">
        <Styled.AddSectionBox>
          <div className="add_section_show_feature w-100">
            <div className="d-flex gap-1 gap-sm-2 gap-xl-3 justify-content-between w-100">
              <Styled.IconsBox onClick={() => setTextShowModal(true)}>
                <img src={textBtn} alt="textImageBtn" />
                <TextStyles.FontSize14px className="d-flex justify-content-center mt-1">
                  Text
                </TextStyles.FontSize14px>
              </Styled.IconsBox>
              <Styled.IconsBox onClick={() => setImageShowModal(true)}>
                <img src={imageBtn} alt="imageBtn" />
                <TextStyles.FontSize14px className="d-flex justify-content-center mt-1">
                  Image
                </TextStyles.FontSize14px>
              </Styled.IconsBox>
              <Styled.IconsBox
                onClick={() => setTableShowModal(true)}
                className="d-none d-sm-flex"
              >
                <img src={tableBtn} alt="tableImageBtn" />
                <TextStyles.FontSize14px className="d-flex justify-content-center mt-1">
                  Table
                </TextStyles.FontSize14px>
              </Styled.IconsBox>
              <Styled.IconsBox
                onClick={() => setColumnShowModal(true)}
                className="d-none d-sm-flex"
              >
                <img src={columnBtn} alt="columnBtn" />
                <TextStyles.FontSize14px className="d-flex justify-content-center mt-1">
                  Columns
                </TextStyles.FontSize14px>
              </Styled.IconsBox>
              <Styled.IconsBox onClick={() => setFileShowModal(true)}>
                <img src={fileBtn} alt="fileBtn" />
                <TextStyles.FontSize14px className="d-flex justify-content-center mt-1">
                  File
                </TextStyles.FontSize14px>
              </Styled.IconsBox>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <ControlPointRoundedIcon sx={{ color: Colors.primary }} />
            <TextStyles.FontSize16px
              style={{
                fontWeight: 500,
                color: Colors.primary,
                display: "inline-block",
                marginLeft: 3,
              }}
            >
              Add a section
            </TextStyles.FontSize16px>
          </div>
        </Styled.AddSectionBox>
      </Styled.AddSectionContainer>
      {textShowModal && (
        <AddSectionTextModal
          position={position}
          selectedData={selectedData}
          show={textShowModal}
          handleClose={() => setTextShowModal(false)}
        />
      )}
      {imageShowModal && (
        <AddSectionImageModal
          position={position}
          selectedData={selectedData}
          show={imageShowModal}
          handleClose={() => setImageShowModal(false)}
        />
      )}

      {tableShowModal && (
        <AddSectionTableModal
          position={position}
          selectedData={selectedData}
          show={tableShowModal}
          handleClose={() => setTableShowModal(false)}
        />
      )}

      {columnShowModal && (
        <AddSectionColumnModal
          position={position}
          selectedData={selectedData}
          show={columnShowModal}
          handleClose={() => setColumnShowModal(false)}
        />
      )}
      {fileShowModal && (
        <AddSectionFileModal
          position={position}
          selectedData={selectedData}
          show={fileShowModal}
          handleClose={() => setFileShowModal(false)}
        />
      )}
    </>
  );
};

export default AddSection;
