import React from "react";
import styles from "./Features.module.css";
import { NavLink } from "react-router-dom";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import StatementFeature1 from "../../../../Assets/frontendImages/StatementFeature1.avif";
import StatementFeature2 from "../../../../Assets/frontendImages/StatementFeature2.avif";
import StatementFeature3 from "../../../../Assets/frontendImages/StatementFeature3.avif";
import StatementFeature4 from "../../../../Assets/frontendImages/StatementFeature4.avif";

const Features = () => {
  return (
    <div className={`${styles.section}`}>
      <div className="container-fluid py-3 py-md-4" style={{ maxWidth: 1100 }}>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100">
            <div
              data-aos={"fade-right"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={StatementFeature1}
                alt="StatementFeature1"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="text-start mt-3 mt-md-0 w-100">
            <div className="w-100" data-aos={"fade-left"}>
              <div className={`${styles.heading} mb-3`}>
                Generate comprehensive income statements
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Access detailed income statements effortlessly with Zodot. Our
                platform simplifies the process of creating income reports,
                providing you with a clear understanding of your financial
                management.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Start generating income statements
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="text-start mt-3 mt-md-0 w-100 order-1 order-md-0">
            <div className="w-100" data-aos={"fade-right"}>
              <div className={`${styles.heading} mb-3`}>
                Track profit before taxes
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Efficiently monitor your profit before taxes with our income
                statement reports. Easily identify your revenue streams and
                gauge your business’ profitability to make informed financial
                decisions.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">Monitor profit now</span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 order-0 order-md-1">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={StatementFeature2}
                alt="StatementFeature2"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100">
            <div
              data-aos={"fade-right"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={StatementFeature3}
                alt="StatementFeature3"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="text-start mt-3 mt-md-0 w-100">
            <div className="w-100" data-aos={"fade-left"}>
              <div className={`${styles.heading} mb-3`}>
                Manage total expenses
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Stay on top of your expenses with Zodot's total expense
                reporting feature. Track all your expenditures according to
                clients and projects accurately to understand where your money
                is going and optimize your spending for improved financial
                health.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Regulate your expenses effectively
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center"
          style={{ overflow: "hidden" }}
        >
          <div className="text-start mt-3 mt-md-0 w-100 order-1 order-md-0">
            <div className="w-100" data-aos={"fade-right"}>
              <div className={`${styles.heading} mb-3`}>
                Calculate net taxes
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Simplify tax calculations with our income reports. Understand
                your net tax liability and ensure compliance with tax
                regulations, helping you avoid penalties and maximize your tax
                savings.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Enable easy tax estimation
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 order-0 order-md-1">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={StatementFeature4}
                alt="StatementFeature4"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
