import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { authActions } from "../../store/storage/authSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      const tokenExpiration = jwtDecode(token).exp;
      const dateNow = new Date().getTime() / 1000;
      if (tokenExpiration > dateNow) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        dispatch(authActions.logout());
      }
    } else {
      setIsAuthenticated(false);
      dispatch(authActions.logout());
    }
  }, [pathname]);

  if (isAuthenticated === null) {
    return <></>;
  }

  if (!isAuthenticated) {
    const data = { ...rest };
    const pathname =
      data && data.location && data.location.pathname && data.location.pathname;
    if (data.path === "/db/agreements/view/:id") {
      localStorage.setItem("pathname", pathname);
    } else if (data.path === "/db/invoices/view/:id/details") {
      localStorage.setItem("pathname", pathname);
    } else {
      localStorage.removeItem("pathname");
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) return <Component {...props} />;
        if (!isAuthenticated) return <Redirect to="/db/login" />;
      }}
    />
  );
};

export default ProtectedRoute;
