import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";

import ClearIcon from "@mui/icons-material/Clear";
import { editInvoiceDates } from "../../../../store/service/invoiceService";
import LoaderSpin from "../../Commons/LoaderSpin";
import { CustomDatePicker } from "../../Commons/CustomDatePicker";
import SelectDueDates from "../../Commons/SelectDueDates";

import TextStyles from "../../../../Constants/TextStyles";
import Styled from "./Taxes";
import moment from "moment";
import Colors from "../../../../Constants/Colors";

const EditInvoiceDates = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.invoices.isLoading);

  const {
    reset,
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      issued: selectedData ? new Date(selectedData.issued) : new Date(),
      due_days:
        selectedData &&
        moment(selectedData.due_date).diff(moment(selectedData.issued), "d") ===
          0
          ? "upon receipt"
          : selectedData &&
            moment(selectedData.due_date).diff(
              moment(selectedData.issued),
              "d"
            ) === 7
          ? "7 days"
          : selectedData &&
            moment(selectedData.due_date).diff(
              moment(selectedData.issued),
              "d"
            ) === 15
          ? "15 days"
          : selectedData &&
            moment(selectedData.due_date).diff(
              moment(selectedData.issued),
              "d"
            ) === 30
          ? "30 days"
          : selectedData &&
            moment(selectedData.due_date).diff(
              moment(selectedData.issued),
              "d"
            ) === 45
          ? "45 days"
          : "Custom",

      custom_due_days:
        (selectedData &&
          moment(selectedData.due_date).diff(
            moment(selectedData.issued),
            "d"
          ) !== 0) ||
        (selectedData &&
          moment(selectedData.due_date).diff(
            moment(selectedData.issued),
            "d"
          ) !== 7) ||
        (selectedData &&
          moment(selectedData.due_date).diff(
            moment(selectedData.issued),
            "d"
          ) !== 15) ||
        (selectedData &&
          moment(selectedData.due_date).diff(
            moment(selectedData.issued),
            "d"
          ) !== 30) ||
        (selectedData &&
          moment(selectedData.due_date).diff(
            moment(selectedData.issued),
            "d"
          ) !== 45)
          ? selectedData &&
            moment(selectedData.due_date).diff(moment(selectedData.issued), "d")
          : null,
    },
  });

  const dueDays = watch("due_days");
  const customDueDays = watch("custom_due_days");

  const formsubmit = async (data) => {
    const Obj = {
      invoice_id: selectedData && selectedData._id,
      due_date:
        dueDays === "Custom" && data.custom_due_days
          ? new Date(moment(data.issued).add(data.custom_due_days, "d"))
          : data.due_days === "7 days"
          ? new Date(moment(data.issued).add("7", "d"))
          : data.due_days === "15 days"
          ? new Date(moment(data.issued).add("15", "d"))
          : data.due_days === "30 days"
          ? new Date(moment(data.issued).add("30", "d"))
          : data.due_days === "45 days"
          ? new Date(moment(data.issued).add("45", "d"))
          : data.issued,
      issued: data.issued,
    };

    dispatch(editInvoiceDates(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "editInvoiceSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "editInvoiceFail" }
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalOnRight500px"
        fullscreen
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          className="modal-content"
          style={{ height: "100%" }}
        >
          <Modal.Header className="d-flex align-items-center">
            <TextStyles.FontSize20px>Invoice dates</TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body className="mr-0 mr-sm-2 mr-md-3 mr-lg-5">
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="due_days">Due</label>

              <div className="d-flex align-items-center gap-3">
                <Controller
                  control={control}
                  name="due_days"
                  render={({ field: { onChange, value } }) => (
                    <SelectDueDates
                      width="180px"
                      defaultValue={
                        selectedData &&
                        moment(selectedData.due_date).diff(
                          moment(selectedData.issued),
                          "d"
                        ) === 0
                          ? "upon receipt"
                          : selectedData &&
                            moment(selectedData.due_date).diff(
                              moment(selectedData.issued),
                              "d"
                            ) === 7
                          ? "7 days"
                          : selectedData &&
                            moment(selectedData.due_date).diff(
                              moment(selectedData.issued),
                              "d"
                            ) === 15
                          ? "15 days"
                          : selectedData &&
                            moment(selectedData.due_date).diff(
                              moment(selectedData.issued),
                              "d"
                            ) === 30
                          ? "30 days"
                          : selectedData &&
                            moment(selectedData.due_date).diff(
                              moment(selectedData.issued),
                              "d"
                            ) === 45
                          ? "45 days"
                          : "Custom"
                      }
                      onChange={onChange}
                    />
                  )}
                />
                {dueDays === "Custom" && (
                  <>
                    <TextStyles.FontSize14px className="mx-2">
                      After
                    </TextStyles.FontSize14px>
                    <Styled.inputBox
                      invalid={errors.custom_due_days}
                      style={{ maxWidth: 60 }}
                    >
                      <input
                        type="text"
                        name="custom_due_days"
                        style={{
                          width: 24,
                          textAlign: "center",
                          background: "transparent",
                        }}
                        placeholder="00"
                        {...register("custom_due_days", {
                          required:
                            dueDays === "Custom"
                              ? "Please enter custom due days."
                              : false,
                          min: {
                            value: 1,
                            message: "Please enter more than 0.",
                          },
                          pattern: {
                            value: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/i,
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                    </Styled.inputBox>
                    <TextStyles.FontSize14px className="ml-2">
                      {customDueDays === "1" ? "day" : "days"}
                    </TextStyles.FontSize14px>
                  </>
                )}
              </div>
            </TextStyles.FontSize14px>
            {errors.custom_due_days && (
              <TextStyles.InValidFeedback className="mt-2">
                {errors.custom_due_days.message}
              </TextStyles.InValidFeedback>
            )}
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="issued_date">Issued</label>
              <TextStyles.DateRectangle>
                <Controller
                  name={"issued"}
                  control={control}
                  //   defaultValue={new Date()}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomDatePicker
                        onChange={onChange}
                        selected={value}
                        placeholderText="Set issued date"
                        todayButton="Today"
                        dateFormat="dd MMM yyyy"
                      />
                    );
                  }}
                />
              </TextStyles.DateRectangle>
            </TextStyles.FontSize14px>
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button
                disabled={isLoading ? true : false}
                style={{
                  opacity: isLoading ? 0.5 : 1,
                }}
              >
                {isLoading ? <LoaderSpin /> : "Save"}
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditInvoiceDates;
