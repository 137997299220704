import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const ReviewBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: ${Colors.backgroundGrey};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
`;

const SignatureBox = styled.div`
  width: 100%;
  max-width: 10.625rem;
  height: 3.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || Colors.white};
  border-radius: 0.75rem;
  background-color: ${(props) => props.backgroundColor || Colors.primary};

  &:hover {
    color: ${(props) => props.hoverColor};
  }
`;

const SignatureImageBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 11.875rem;
  padding: 1rem;
  border-radius: 0.75rem;
  // border: solid 1px #b2b6c5;
  border: solid 1px ${(props) => (props.fileErrors ? "red" : "#b2b6c5")} !important;
  background-color: ${Colors.white} !important;
  box-sizing: border-box;
`;

export default {
  ReviewBox,
  SignatureBox,
  SignatureImageBox,
};
