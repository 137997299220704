import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import TextStyles from "../../../../Constants/TextStyles";
import { postRequest, putRequest } from "../../../../axios/axios";
import Colors from "../../../../Constants/Colors";
import Loader from '../../Commons/LoaderSpin';

const Notes = () => {
  const { id } = useParams();
  const [notes, setNotes] = useState("");
  const timeout = useRef();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    viewInvoiceNotesHandler();
  }, [id]);

  const viewInvoiceNotesHandler = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    postRequest(`invoices/view_invoice/notes`, token, {
      _id: id,
    })
      .then((data) => {
        if (data.data.success === true) {
          setIsLoading(false);
          setError(false);
          setNotes(data.data.data.notes);
        } else {
          setIsLoading(false);
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>,
            { toastId: "fail" }
          );
        }
        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>,
            { toastId: "error" }
          );
        }
      });
  };

  const updateNotes = (data) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      let Obj = {
        _id: id,
        notes: data,
      };
      const token = localStorage.getItem("token");
      putRequest(`invoices/view_invoice/notes`, token, Obj)
        .then((data) => {
          if (data.data.success) {
            setIsLoading(false);
            setNotes(data.data.data.notes);
            // toast.success(
            //   <TextStyles.FontSize14px>
            //     {data.data.message}
            //   </TextStyles.FontSize14px>,
            //   { toastId: "updateNotesSuccess" }
            // );
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>,
            { toastId: "updateNotesFail" }
          );
        });
    }, 1000);
  };

  return (
    <div className="px-2 px-sm-3 px-md-4 px-lg-4">
      <TextStyles.FontSize20px className="d-flex mb-4">
        Notes
      </TextStyles.FontSize20px>

      {error ? (
        <TextStyles.FontSize14px
          style={{
            width: "100%",
            height: "100%",
            minHeight: 460,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextStyles.FontSize16px
            style={{
              padding: "12px 20px",
              borderRadius: 9,
              border: `2px solid ${Colors.red}`,
              color: Colors.red,
            }}
          >
            Invalid credential
          </TextStyles.FontSize16px>
        </TextStyles.FontSize14px>
      ) : (
        <TextStyles.FontSize14px>
          {isLoading ?
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                borderRadius: "0.75rem",
                border: "1px solid #d1d1d1",
                minHeight: "21.8rem",
                backgroundColor: "#fff",
              }}
            >
              <Loader color={Colors.primary} />
            </div>
            :
              <TextStyles.Textarea
                disabled={isLoading}
                rows={15}
                placeholder="Add a note..."
                defaultValue={notes}
                onChange={(e) => {
                  updateNotes(e.target.value);
                }}
              /> }
        </TextStyles.FontSize14px>
      )}
    </div>
  );
};

export default Notes;
