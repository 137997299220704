import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./LandingPage.module.css";
import AboutUs from "../../../Assets/frontendImages/aboutUs.avif";

function LandingPage() {
  return (
    <div className={styles.hero_section}>
      <div className="container-fluid">
        <div className="d-flex flex-column flex-md-row align-items-center">
          <div
            className="text-center text-md-left w-100 mt-3 mt-md-0"
            data-aos={"fade-right"}
          >
            <div className={`text-white ${styles.heading}`}>
              Step into the world of Zodot!
            </div>
            <div className={`${styles.heading_text} my-3 my-md-4`}>
              Where innovation meets efficiency in managing your freelance,
              solopreneur, SMB, or entrepreneurial endeavors.
            </div>
            <div className="d-flex justify-content-center justify-content-md-start mb-3">
              <NavLink
                className={styles.btn_begin}
                style={{ textDecoration: "none" }}
                to="db/signup"
              >
                Begin today
              </NavLink>
            </div>

            <p className={`${styles.text}`}>
              No credit card necessary for registration.
            </p>
          </div>

          <div className="d-flex justify-content-center align-items-center w-100  mt-3 mt-md-0 pb-3">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 600,
                maxHeight: 600,
              }}
            >
              <img
                src={AboutUs}
                alt="homeImageBtn"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
