import React from "react";
import Slider from "react-slick";
import "./Carousel.css";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";

import CTA1 from "../../../../Assets/frontendImages/CTA1.avif";
import CTA2 from "../../../../Assets/frontendImages/CTA2.avif";
import CTA3 from "../../../../Assets/frontendImages/CTA3.avif";
import CTA4 from "../../../../Assets/frontendImages/CTA4.avif";
import CTA5 from "../../../../Assets/frontendImages/CTA5.avif";
import CTA6 from "../../../../Assets/frontendImages/CTA6.avif";

const Carousel = () => {
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="next_arrow" onClick={onClick}>
        <ArrowCircleRightRoundedIcon className="button" />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;

    return (
      <div className="prev_arrow" onClick={onClick}>
        <ArrowCircleLeftRoundedIcon className="button" />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    adaptiveHeight: true,
    className: "homePageCTACarousel",
    dotsClass: "homePageCTACarouselDots",
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    customPaging: function () {
      return <button aria-label="cuttom-button" className="custom-dot" />;
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="feature_container" data-color="primary">
        <div className="container_heading text-start">Freelancers</div>
        <div className="container_text text-start">
          Our software is built with you in mind. Enjoy an intuitive interface
          that minimizes the learning curve, ensuring you get the most out of
          our features.
        </div>
        <div className="hover_image" data-color="pink">
          <h4 className="hover_content">Enhance your freelance journey</h4>
        </div>
        <div className="container_image_wrapper">
          <picture>
            <source media="(min-width:650px)" srcSet={CTA1} />
            <source media="(min-width:465px)" srcSet={CTA1} />
            <img className="container_image" src={CTA1} alt="CTA1" />
          </picture>
        </div>
      </div>

      <div className="feature_container" data-color="secondary">
        <div className="container_heading text-start">Software teams</div>
        <div className="container_text text-start">
          Tailored for software teams, it offers intuitive tools for streamlined
          project management and collaboration, meeting their unique needs
          effectively.
        </div>
        <div className="hover_image" data-color="yellow">
          <h4 className="hover_content">Accelerate your team's productivity</h4>
        </div>
        <div className="container_image_wrapper">
          <picture>
            <source media="(min-width:650px)" srcSet={CTA6} />
            <source media="(min-width:465px)" srcSet={CTA6} />
            <img className="container_image" src={CTA6} alt="CTA6" />
          </picture>
        </div>
      </div>

      <div className="feature_container" data-color="primary">
        <div className="container_heading text-start">Attorney and lawyers</div>
        <div className="container_text text-start">
          Designed for attorneys and lawyers, it addresses specific needs with
          tools for case management, document preparation, and client
          communication.
        </div>
        <div className="hover_image" data-color="pink">
          <h4 className="hover_content">Simplify your legal workflow</h4>
        </div>
        <div className="container_image_wrapper">
          <picture>
            <source media="(min-width:650px)" srcSet={CTA5} />
            <source media="(min-width:465px)" srcSet={CTA5} />
            <img className="container_image" src={CTA5} alt="CTA5" />
          </picture>
        </div>
      </div>

      <div className="feature_container" data-color="secondary">
        <div className="container_heading text-start">Solopreneurs</div>
        <div className="container_text text-start">
          Whether you're a freelancer or a growing SMB, our software grows with
          you. Scale your usage effortlessly as your business expands.
        </div>
        <div className="hover_image" data-color="yellow">
          <h4 className="hover_content">Empower your solo venture</h4>
        </div>
        <div className="container_image_wrapper">
          <picture>
            <source media="(min-width:650px)" srcSet={CTA2} />
            <source media="(min-width:465px)" srcSet={CTA2} />
            <img className="container_image" src={CTA2} alt="CTA2" />
          </picture>
        </div>
      </div>

      <div className="feature_container" data-color="primary">
        <div className="container_heading text-start">Small businesses</div>
        <div className="container_text text-start">
          Zodot is built on trust. With reliability at its core, it secures your
          data, ensuring the seamless execution of your tasks.
        </div>
        <div className="hover_image" data-color="pink">
          <h4 className="hover_content">Propel your business forward</h4>
        </div>
        <div className="container_image_wrapper">
          <picture>
            <source media="(min-width:650px)" srcSet={CTA3} />
            <source media="(min-width:465px)" srcSet={CTA3} />
            <img className="container_image" src={CTA3} alt="CTA3" />
          </picture>
        </div>
      </div>

      <div className="feature_container" data-color="secondary">
        <div className="container_heading text-start">Entrepreneurs</div>
        <div className="container_text text-start">
          From the initial onboarding process to troubleshooting hurdles, our
          dedicated support team is by your side, committed to ensuring your
          success.
        </div>
        <div className="hover_image" data-color="yellow">
          <h4 className="hover_content">Drive your startup's growth</h4>
        </div>
        <div className="container_image_wrapper">
          <picture>
            <source media="(min-width:650px)" srcSet={CTA4} />
            <source media="(min-width:465px)" srcSet={CTA4} />
            <img className="container_image" src={CTA4} alt="CTA4" />
          </picture>
        </div>
      </div>
    </Slider>
  );
};

export default Carousel;
