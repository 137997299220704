import React from "react";

import "./Backdrop.css";

const Backdrop = (props) => {
  let classes = "backdrop";
  if (props.show) {
    classes = "backdrop show";
  }

  return <div className={classes} onClick={props.closeHandler} />;
};

export default Backdrop;
