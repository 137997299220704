import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

import LoaderSpin from "../../../../Commons/LoaderSpin";

import ClearIcon from "@mui/icons-material/Clear";
import imageBtn from "../../../../../../Assets/assetsnew/image-add-line.svg";

import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";

import { updateAddSectionImagePC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";

const EditAddSectionImageModal = ({
  show,
  handleClose,
  editData,
  pc_id,
  position,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const image = editData && editData.image_name && editData.image_url;
  const [files, setFiles] = useState([]);
  const [fileErrors, setfileErrors] = useState([]);
  const MAX_SIZE = 2000000;

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      image_title: editData && editData.image_title,
      image_text: editData && editData.image_text,
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxSize: MAX_SIZE,
    multiple: false,
    onDrop: (acceptedFiles, rejectedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setfileErrors(rejectedFiles.length > 0 ? rejectedFiles[0].errors : []);
      // showText = files.length === 0;
    },
    onDragEnter: () => {
      setFiles([]);
      // setfileErrors([]);
    },
  });

  const imageErrors = {
    FILESIZE: "More than 2MB in size",
    FILETYPE: "Not an image file",
  };

  const getErrorMessage = () => {
    switch (fileErrors[0].code) {
      case "file-invalid-type":
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            {imageErrors.FILETYPE}
          </p>
        );
      case "file-too-large":
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            {imageErrors.FILESIZE}
          </p>
        );
      default:
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              color: "red",
            }}
          >
            File error
          </p>
        );
    }
  };

  const formsubmit = (data) => {
    let formData = new FormData();
    formData.append("image", files[0]);
    formData.append("_id", editData._id);
    formData.append("pc_id", pc_id);
    formData.append("image_title", data.image_title);
    formData.append("image_name", editData && editData.image_name);
    formData.append("image_text", data.image_text);
    formData.append("position", position);

    dispatch(updateAddSectionImagePC(formData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        setFiles([]);
        reset();

        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "updateImageSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateImageFail" }
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        scrollable={true}
        fullscreen
        dialogClassName="ToMakeModalWidth600px"
        contentClassName="ToMakeModalBorderRudius12px"
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Modal.Header
            className="d-flex align-items-center"
            style={{ borderBottom: "0px" }}
          >
            <TextStyles.FontSize20px className="d-flex justify-content-start">
              Edit image
            </TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>

          <Modal.Body>
            <TextStyles.FontSize14px className="mb-2">
              <label htmlFor="image_title" style={{ display: "flex" }}>
                Image title
              </label>
              <TextStyles.InputRectangle
                id="image_title"
                invalid={errors.image_title}
                type="text"
                placeholder="Section title (e.g, Overview and goals )"
                name="image_title"
                {...register("image_title", {
                  required: "Image title can not be empty.",
                })}
              />
              {errors.image_title && (
                <TextStyles.InValidFeedback>
                  {errors.image_title.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px>
              <TextStyles.Textarea
                invalid={errors.image_text}
                rows="7"
                id="image_text"
                placeholder="Add text here..."
                name="image_text"
                {...register("image_text", {
                  required: "Image text can not be empty.",
                })}
              />
              {errors.image_text && (
                <TextStyles.InValidFeedback>
                  {errors.image_text.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <Styled.ImageBox
              className="my-2"
              {...getRootProps()}
              fileErrors={fileErrors.length > 0}
            >
              <input {...getInputProps()} />
              {files.length === 0 && !image && (
                <>
                  <div className="d-flex justify-content-center">
                    <img
                      src={imageBtn}
                      alt="imageBtn"
                      style={{ width: 56, height: 56 }}
                    />
                  </div>
                  <TextStyles.GreyFont18px className="mt-2">
                    Upload Image
                  </TextStyles.GreyFont18px>
                  <TextStyles.GreyFont14px className="text-center mt-2">
                    Drag an image here or
                    <span
                      style={{
                        textDecoration: "2px solid underline",
                        margin: "0 5px",
                        cursor: "pointer",
                      }}
                    >
                      select a file
                    </span>
                    from your computer.
                  </TextStyles.GreyFont14px>
                </>
              )}
              {files.length === 0 && fileErrors.length > 0 && getErrorMessage()}
              {files.map((file) => (
                <img
                  alt="Preview"
                  key={file.preview}
                  src={file.preview}
                  style={{ maxWidth: "100%" }}
                />
              ))}
              {files.length === 0 && image && (
                <img
                  src={image}
                  style={{ width: "100%", height: "auto" }}
                  alt="imageBtn"
                />
              )}
            </Styled.ImageBox>
          </Modal.Body>

          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-around align-items-center">
              <TextStyles.CancelButton onClick={handleClose}>
                <div className="d-flex justify-content-center">Cancel</div>
              </TextStyles.CancelButton>
              <TextStyles.Button
                disabled={fileErrors.length > 0 || isLoading ? true : false}
              >
                <div className="d-flex justify-content-center">
                  {isLoading ? <LoaderSpin /> : "Save"}
                </div>
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditAddSectionImageModal;
