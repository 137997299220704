import React, { useEffect, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import profileIcon from "../../../Assets/assetsnew/profile-image.svg";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import logo from "../../../Assets/assetsnew/backend_logo_with_name.webp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/storage/authSlice";
import { toast } from "react-toastify";
import Colors from "../../../Constants/Colors";
import Styles from "./headerStyles";
import TextStyles from "../../../Constants/TextStyles";
import CreateProject from "../projects/createProject/createProject";
import NewExpense from "../Transaction/TrModals/NewExpense";
import CreateTask from "../dashboard/fourthrow/CreateTask";

import CustomBadge from "../Commons/CustomBadge";
import { Popover } from "antd";

import Notifications from "./Notifications";
import {
  fetchNotifications,
  ViewedNotifications,
} from "../../../store/service/notificationService";
import { start_timer_activity } from "../../../store/service/timeTrackingService";

export default function header(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [showNewExpenseModal, setShowNewExpenseModal] = useState(false);
  const [showTask, setShowTask] = useState(false);

  const data = useSelector((state) => state.auth.data);
  const isNotViewed = useSelector((state) => state.notifications.isNotViewed);
  const startActivityData = useSelector(
    (state) => state.timeTracking.startActivityData
  );
  const [openPopupNotification, setOpenPopupNotification] = useState(false);

  const hideNotificationPopupHandler = () => {
    setOpenPopupNotification(false);
    if (isNotViewed) {
      dispatch(ViewedNotifications());
    }
  };
  const changeNotificationPopupHandler = (newOpen) => {
    setOpenPopupNotification(newOpen);
    if (!newOpen && isNotViewed) {
      dispatch(ViewedNotifications());
    }
  };

  useEffect(() => {
    dispatch(
      fetchNotifications({
        page: 1,
        perPage: 10,
      })
    );
  }, []);

  const clickHandlerSettingBtn = () => {
    history.push("/db/setting");
  };

  const logoutHandler = () => {
    // localStorage.clear();
    // localStorage.setItem("logout", Date.now().toString());
    dispatch(authActions.logout());
    history.push("/");
    toast.success(
      <TextStyles.FontSize14px>
        You have successfully logged out!
      </TextStyles.FontSize14px>
    );
  };

  const onProfileClickHandler = () => {
    const settingBoxEl = document.querySelector(".settingBox");
    if (settingBoxEl) settingBoxEl.style.display = "block";
  };

  const hoverOnCreateButtonHandler = () => {
    const settingBoxEl = document.querySelector(".settingBox");
    if (settingBoxEl) settingBoxEl.style.display = "none";
  };

  useEffect(() => {
    document.querySelector("body").addEventListener("click", () => {
      const settingBoxEl = document.querySelector(".settingBox");
      if (settingBoxEl) settingBoxEl.style.display = "none";
    });
  }, []);

  const AddAgreementHandler = (data) => {
    history.push({
      pathname: "/db/agreements/new",
      state: { selectedAgreement: data },
    });
  };

  const startTimerHandler = () => {
    dispatch(start_timer_activity());
  };

  return (
    <div>
      <div
        className="fixed-top"
        style={{
          backgroundColor: Colors.white,
          width: "100%",
          zIndex: 300,
        }}
      >
        <div
          style={{
            backgroundColor: Colors.white,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingRight: 12,
            paddingLeft: 12,
            borderBottom: "3px solid #f0f0f0",
            boxShadow: `0px 1px 1px 0 ${Colors.tableHeadColor}`,
            boxSizing: "border-box",
          }}
        >
          <div
            className="nav-item"
            style={{
              marginTop: 15,
              marginBottom: 15,
              width: 220,
            }}
          >
            <NavLink to="/" className="d-xl-block d-lg-block d-none">
              <img src={logo} alt="Logo" style={{ maxWidth: 200 }} />
            </NavLink>

            <button
              className="nav-item d-flex w-100 d-lg-none d-xl-none"
              id="navbarToggle"
              onClick={props.SideDrawerHandler}
              type="button"
              style={{
                backgroundColor: "#f9f9f9",
                alignItems: "center",
              }}
              aria-label="toggle"
            >
              <CloseIcon
                sx={{
                  fontSize: 35,
                  color: Colors.primary,
                  display: props.show ? "inline-block" : "none",
                }}
              />
              <MenuOutlinedIcon
                sx={{
                  fontSize: 35,
                  color: Colors.primary,
                  display: !props.show ? "inline-block" : "none",
                }}
              />
            </button>
          </div>

          <ul className="navbar-nav  d-flex flex-start flex-row align-items-center">
            <li
              className="nav-item mx-1 mx-xs-2 mx-sm-3"
              style={{ textAlign: "center" }}
            >
              <Popover
                // overlayClassName="notification-popover"
                overlayInnerStyle={{
                  width: "100%",
                  height: "100%",
                  padding: "0px",
                  maxWidth: 450,
                  maxHeight: 480,
                }}
                placement="bottom"
                content={
                  <Notifications
                    openPopupNotification={openPopupNotification}
                    hideNotificationPopupHandler={hideNotificationPopupHandler}
                  />
                }
                trigger="click"
                open={openPopupNotification}
                onOpenChange={changeNotificationPopupHandler}
              >
                <TextStyles.FontSize14px>
                  <CustomBadge badgeContent={isNotViewed && isNotViewed}>
                    <NotificationsNoneIcon
                      sx={{
                        fontSize: 30,
                        color: Colors.font1,
                        cursor: "pointer",
                        "&:hover": {
                          color: Colors.primary,
                        },
                      }}
                    />
                  </CustomBadge>
                </TextStyles.FontSize14px>
              </Popover>
            </li>

            <li className="nav-item mx-1 mx-xs-2 mx-sm-3">
              <div
                className="nav-link"
                style={{ color: Colors.white }}
                onMouseEnter={hoverOnCreateButtonHandler}
              >
                <Styles.CreateBox
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <AddOutlinedIcon sx={{ color: Colors.white }} />
                  <span className="ml-1">Create</span>
                  <Styles.CreateRectangle className="createBox">
                    <Styles.DropdownList>
                      <li onClick={() => history.push("/db/invoices/new")}>
                        <span>
                          <DescriptionOutlinedIcon
                            sx={{ fontSize: 23, color: Colors.font1 }}
                          />
                        </span>
                        <span className="mx-3">Invoice</span>
                      </li>
                      <li
                        onClick={() => AddAgreementHandler("Create proposal")}
                      >
                        <span>
                          <ReceiptLongOutlinedIcon
                            sx={{ fontSize: 23, color: Colors.font1 }}
                          />
                        </span>
                        <span className="mx-3">Proposal</span>
                      </li>
                      <li
                        onClick={() => AddAgreementHandler("Create contract")}
                      >
                        <span>
                          <ContactPageOutlinedIcon
                            sx={{ fontSize: 23, color: Colors.font1 }}
                          />
                        </span>
                        <span className="mx-3">Contract</span>
                      </li>
                      <li onClick={() => setShowTask(true)}>
                        <span>
                          <AssignmentOutlinedIcon
                            sx={{ fontSize: 23, color: Colors.font1 }}
                          />
                        </span>
                        <span className="mx-3">Task</span>
                      </li>
                      <li onClick={() => setShow(true)}>
                        <span>
                          <FolderSharedOutlinedIcon
                            sx={{ fontSize: 23, color: Colors.font1 }}
                          />
                        </span>
                        <span className="mx-3">Project</span>
                      </li>
                      <li onClick={() => setShowNewExpenseModal(true)}>
                        <span>
                          <PaidOutlinedIcon
                            sx={{ fontSize: 23, color: Colors.font1 }}
                          />
                        </span>
                        <span className="mx-3">Expense</span>
                      </li>
                    </Styles.DropdownList>
                  </Styles.CreateRectangle>
                </Styles.CreateBox>
              </div>
            </li>

            <li className="nav-item mx-1 mx-xs-2 mx-sm-3">
              <button
                disabled={
                  startActivityData && startActivityData.start_time
                    ? true
                    : false
                }
                style={{
                  opacity:
                    startActivityData && startActivityData.start_time ? 0.5 : 1,
                }}
                onClick={startTimerHandler}
                aria-label="timer"
              >
                <PlayCircleFilledWhiteIcon
                  sx={{
                    fontSize: 55,
                    color: Colors.primary,
                    cursor: "pointer",
                    "&:hover": {
                      color: Colors.hoverButtonColor,
                    },
                  }}
                />
              </button>
            </li>

            <li
              className="nav-item mx-1 mx-xs-2 mx-sm-3"
              onClick={onProfileClickHandler}
            >
              <Styles.DropdownSpan>
                <img
                  src={data && data.profilePic ? data.imageUrl : profileIcon}
                  alt="profileIcon"
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                />
                <Styles.SettingRectangle className="settingBox">
                  <div className="d-flex flex-column">
                    <TextStyles.FontSize16px className="d-flex justify-content-start mb-1">
                      {data && `${data.firstName} ${data.lastName}`}
                    </TextStyles.FontSize16px>
                    <TextStyles.GreyFont14px
                      style={{
                        textAlign: "start",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data && data.email}
                    </TextStyles.GreyFont14px>
                  </div>
                  <hr />
                  <Styles.DropdownList>
                    <li className="d-flex justify-content-start mt-2 mb-3">
                      <div
                        onClick={clickHandlerSettingBtn}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        User settings
                      </div>
                    </li>

                    <li className="d-flex justify-content-start mt-2">
                      <TextStyles.Button
                        onClick={logoutHandler}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Logout
                      </TextStyles.Button>
                    </li>
                  </Styles.DropdownList>
                </Styles.SettingRectangle>
              </Styles.DropdownSpan>
            </li>
          </ul>
        </div>
      </div>
      {showTask && (
        <CreateTask
          show={showTask}
          handleClose={() => setShowTask(false)}
          section="navbar"
        />
      )}

      {show && (
        <CreateProject
          show={show}
          handleClose={() => setShow(false)}
          section="navbar"
        />
      )}
      {showNewExpenseModal && (
        <NewExpense
          show={showNewExpenseModal}
          handleClose={() => setShowNewExpenseModal(false)}
          section="navbar"
        />
      )}
    </div>
  );
}
