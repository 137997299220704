import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Statements.module.css";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import HomePageStatementsSectionImage from "../../../../Assets/frontendImages/statement_homepage.avif";

const Statements = () => {
  return (
    <div
      className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center"
      style={{ overflow: "hidden" }}
    >
      <div className="d-flex justify-content-center justify-content-md-start w-100 mt-3 mt-md-0 pb-3">
        <div
          data-aos={"fade-right"}
          style={{
            width: "100%",
            height: "100%",
            maxWidth: 550,
            maxHeight: 550,
          }}
        >
          <img
            src={HomePageStatementsSectionImage}
            alt="HomePageStatementsSectionImage"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </div>
      <div className="text-start w-100 mt-3 mt-md-0 pb-3">
        <div data-aos={"fade-left"}>
          {" "}
          <div className={`${styles.heading} mb-4`}>
            Are tax statements overwhelming?
          </div>
          <div className={`${styles.text_font1} mb-4`}>
            Simplify tax time with Zodot's income statement reporting. Easily
            generate detailed reports, track profit, manage expenses, and
            calculate net taxes. Stay organized and compliant while maximizing
            savings. Simplify tax management with Zodot today.
          </div>
          <div className="d-flex mb-4">
            <NavLink
              className={styles.more_button}
              style={{ textDecoration: "none" }}
              to="/features/statements"
            >
              More about easy statements
              <span className={`${styles.cta_icons}`}>
                <ArrowForwardIosRoundedIcon sx={{ fontSize: 17 }} />
              </span>
            </NavLink>
          </div>
          <div
            className={`${styles.text_font1} ${styles.border_left_side_of_content}`}
          >
            "Zodot's tax reporting simplifies tax forms like a personal
            financial wizard. Tax time is empowering, not daunting, with Zodot!"
            <span className={`${styles.text_font2}`}> -David R. </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statements;
