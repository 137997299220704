import React from "react";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "./PageOne";

const Project = (props) => {
  const selectedData = props && props.selectedData;
  return (
    <Styled.ReviewContentBox className="my-3">
      <TextStyles.FontSize16px style={{ fontWeight: 500 }}>
        {selectedData && selectedData.project_name}
      </TextStyles.FontSize16px>
    </Styled.ReviewContentBox>
  );
};

export default Project;
