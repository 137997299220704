import React from "react";
import PaymentTerms from "./Components/PaymentTerms.jsx";
import LatePayment from "./Components/LatePayment.jsx";
import Deposit from "./Components/Deposit.jsx";
import ExpenseReimbursement from "./Components/ExpenseReimbursement.jsx";
import Feedback from "./Components/Feedback.jsx";
import Changes from "./Components/Changes.jsx";
import LiabilityLimitaion from "./Components/LiabilityLimitation.jsx";
import CancellationFee from "./Components/CancellationFee";

const PageFive = (props) => {
  const selectedData = props && props.selectedData;

  return (
    <div>
      <CancellationFee selectedData={selectedData} />
      <PaymentTerms selectedData={selectedData} />
      <LatePayment selectedData={selectedData} />
      <Deposit selectedData={selectedData} />
      <ExpenseReimbursement selectedData={selectedData} />
      <Feedback selectedData={selectedData} />
      <Changes selectedData={selectedData} />
      <LiabilityLimitaion selectedData={selectedData} />
    </div>
  );
};

export default PageFive;
