import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../AddSectionStyles";

const Image = ({ data }) => {
  return (
    <Styled.ReviewBox className="mt-4">
      <TextStyles.FontSize14px className="mb-3">
        <div className="mb-2">Image title</div>
        <div className="d-flex gap-1 justify-content-between align-items-center">
          <div
            className="d-flex justify-content-start w-100"
            style={{ minWidth: 100 }}
          >
            <Styled.TextBoader
              className="d-flex w-100"
              style={{ minWidth: 100 }}
            >
              <span className="three-dots">{data.image_title}</span>
            </Styled.TextBoader>
          </div>
        </div>
      </TextStyles.FontSize14px>
      <TextStyles.FontSize14px className="mb-3">
        <div className="mb-2">Image description</div>
        <div className="d-flex gap-1 justify-content-between align-items-center">
          <Styled.TextBoader>{data.image_text}</Styled.TextBoader>
        </div>
      </TextStyles.FontSize14px>
      <Styled.ImageBox>
        <div className="d-flex justify-content-center align-items-center text-center">
          {data && data.image_name && data.image_url ? (
            <img
              src={data && data.image_name && data.image_url}
              alt="imageBtn"
              style={{ maxWidth: "100%" }}
            />
          ) : (
            <TextStyles.GreyFont16px>
              There is no image upladed!
            </TextStyles.GreyFont16px>
          )}
        </div>
      </Styled.ImageBox>
    </Styled.ReviewBox>
  );
};

export default Image;
