import { createSlice } from "@reduxjs/toolkit";
import {
  fetchExpenses,
  fetchIncomes,
  fetchTaxes,
} from "../service/statementService";

const statementsInitialState = {
  isLoading: false,
  error: null,
  incomesData: null,
  expensesData: null,
  taxesData: null,
};

export const statementSlice = createSlice({
  name: "statements",
  initialState: statementsInitialState,
  reducers: {},

  extraReducers: {
    // fetch incomes
    [fetchIncomes.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchIncomes.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.incomesData = action.payload.data;
    },
    [fetchIncomes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch expenses
    [fetchExpenses.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchExpenses.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.expensesData = action.payload.data;
    },
    [fetchExpenses.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // fetch taxes
    [fetchTaxes.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTaxes.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.taxesData = action.payload.data;
    },
    [fetchTaxes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export const statementsActions = statementSlice.actions;

export default statementSlice.reducer;
