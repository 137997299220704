import React from "react";
import Colors from "../../../Constants/Colors";
import FirsthRow from "./firstrow/firstrow";
import SecondRow from "./secondrow/secondrow";
import ThirdRow from "./thirdrow/thirdrow";
import FourthRow from "./fourthrow/fourthrow";

export default function Dashboard() {
  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        padding: "70px 0 60px 0",
        minHeight: "100vh",
        contain: "content",
      }}
    >
      <FirsthRow />
      <SecondRow />
      <ThirdRow />
      <FourthRow />
    </div>
  );
}
