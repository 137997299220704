import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import NoTaskImage from "../../../../../Assets/assetsnew/no-task.svg";

import DeleteModal from "../../../Commons/DeleteModal";

import { ReactTooltip } from "../../../Commons/ReactTooltip";
import Pagination from "../../../Commons/Pagination";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import Styled from "../../../clients/editClient/task/TodoStyles";
import TextStyles from "../../../../../Constants/TextStyles";

import {
  deleteTask,
  fetchTasks,
  updateTaskStatus,
  editTaskDueDate,
} from "../../../../../store/service/dashboardService";
import EditTask from "../../../dashboard/fourthrow/EditTask";
import Colors from "../../../../../Constants/Colors";
import LoaderSpin from "../../../Commons/LoaderSpin";

const Future = () => {
  const dispatch = useDispatch();
  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const selectedProject = useSelector((state) => state.project.selectedProject);

  const isLoading = useSelector((state) => state.dashboard.isLoading);
  const taskData = useSelector((state) => state.dashboard.taskData);
  const taskTotalCount = useSelector((state) => state.dashboard.taskTotalCount);
  const defaultData = useMemo(() => {
    return (
      taskData &&
      taskData.map((item) => {
        const repeat_task_id = item.repeat_task_id;
        const _id = item._id;
        const due_date = item.due_date ? new Date(item.due_date) : null;
        const hide_until_date = item.hide_until_date
          ? new Date(item.hide_until_date)
          : null;
        const client_id = item.client_id ? item.client_id : null;
        const project_id = item.project_id ? item.project_id : null;
        const status = item.status;
        const task_name = item.task_name ? item.task_name : null;
        const repeat_task = item.repeat_task ? item.repeat_task : null;

        return {
          _id,
          due_date,
          hide_until_date,
          client_id,
          project_id,
          status,
          task_name,
          repeat_task,
          repeat_task_id,
        };
      })
    );
  }, [taskData]);

  const { control: control2, reset: reset2 } = useForm({
    defaultValues: {
      update_task: defaultData && defaultData,
    },
  });

  const { fields: fields2 } = useFieldArray({
    control: control2,
    name: "update_task",
  });

  useEffect(() => {
    reset2({
      update_task: defaultData && defaultData,
    });
  }, [taskData]);

  const [updateRefresh, setUpdateRefresh] = useState(false);

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 1;

  useEffect(() => {
    setPage(1);
  }, [updateRefresh, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const Obj = {
      project_id: selectedProject._id,
      status: "future",
      page: page,
      perPage: itemsPerPage,
    };
    dispatch(fetchTasks(Obj));
  }, [updateRefresh, itemsPerPage, page]);

  const setDueDateApiCall = (newDate, data) => {
    const Obj = {
      _id: data._id,
      due_date: new Date(newDate),
    };
    dispatch(editTaskDueDate(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "dueDateSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "dueDateFail" }
        );
      }
    });
  };

  const doneTaskCallApi = (data) => {
    let Obj = {
      task_id: data._id,
      status: "done",
    };
    dispatch(updateTaskStatus(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            Task completed successfully!
          </TextStyles.FontSize14px>,
          { toastId: "taskDoneSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "taskDoneFail" }
        );
      }
    });
  };

  const deleteTaskHandler = (data) => {
    const Obj = {
      task_id: data && data._id,
    };
    dispatch(deleteTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleCloseDeleteModal();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "deleteTaskSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "deleteTaskFail" }
        );
      }
    });
  };

  return (
    <div>
      <div>
        {fields2 && fields2.length > 0 ? (
          fields2.map((data, index) => {
            return (
              <Styled.TodoRectangle
                key={index}
                style={{
                  marginTop: 2,
                  borderRadius:
                    taskData && taskData.length === 1
                      ? "12px"
                      : taskData && taskData.length - 1 === index
                      ? "0 0 12px 12px"
                      : taskData && taskData.length - taskData.length === index
                      ? "12px 12px 0  0"
                      : "",
                }}
                onClick={() => {
                  setShow(true);
                  setSelectedTaskData(data);
                }}
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div
                    className="d-flex justify-content-between align-items-center three-dots w-100"
                    style={{ maxWidth: 900 }}
                  >
                    <TextStyles.FontSize14px className="d-flex align-items-center three-dots w-100">
                      <div
                        className="d-flex align-items-center radioButtonWrapper"
                        style={{ width: 30 }}
                      >
                        <RadioButtonUncheckedIcon
                          className="radioButton"
                          fontSize="medium"
                          sx={{
                            color: Colors.font2,
                            cursor: "pointer",
                          }}
                        />
                        <TaskAltIcon
                          className="radioCheckedButton"
                          fontSize="medium"
                          sx={{
                            color: Colors.font2,
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            doneTaskCallApi(data);
                          }}
                        />
                      </div>
                      <div
                        className="d-flex flex-column w-100 ml-2"
                        style={{ minWidth: 50 }}
                      >
                        <TextStyles.FontSize14px className="text-start three-dots w-100">
                          {data.task_name}
                        </TextStyles.FontSize14px>
                        <TextStyles.GreyFont14px className="text-start three-dots w-100">
                          {data.project_id && data.project_id.project_name}
                        </TextStyles.GreyFont14px>
                      </div>
                    </TextStyles.FontSize14px>

                    <div className="d-flex align-items-center justify-content-end w-100">
                      <TextStyles.GreyFont14px
                        className="mr-3 three-dots"
                        style={{ minWidth: 50 }}
                      >
                        {data.client_id && data.client_id.company_name}
                      </TextStyles.GreyFont14px>

                      <TextStyles.FontSize14px
                        style={{ maxWidth: 90 }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Controller
                          control={control2}
                          name={`update_task.${index}.due_date`}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              className="widthofdatepicker three-dots"
                              onChange={(value) => {
                                onChange(value);
                                setDueDateApiCall(value, data);
                              }}
                              selected={value}
                              placeholderText="Set due date"
                              todayButton="Today"
                              dateFormat="dd MMM yyyy"
                              disabled={isLoading ? true : false}
                              minDate={new Date()}
                            />
                          )}
                        />
                      </TextStyles.FontSize14px>
                    </div>
                  </div>

                  <div
                    className="w-100 d-none d-sm-flex"
                    style={{
                      maxWidth: 120,
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div
                        id={`dashboard-doneTask${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          doneTaskCallApi(data);
                        }}
                      >
                        <CheckCircleOutlineIcon
                          sx={{
                            fontSize: 24,
                            color: Colors.font2,
                            background: Colors.white,
                            cursor: "pointer",
                            padding: "2px 6px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`dashboard-doneTask${index}`}
                        content="Done task"
                      />
                      <div
                        id={`dashboard-editTask${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShow(true);
                          setSelectedTaskData(data);
                        }}
                      >
                        <BorderColorOutlinedIcon
                          sx={{
                            color: Colors.success,
                            cursor: "pointer",
                            fontSize: 30,
                            background: Colors.white,
                            padding: "2px 6px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`dashboard-editTask${index}`}
                        content="Edit task"
                      />
                      <div
                        id={`dashboard-deteteTask${index}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDeleteModal(true);
                          setConfirmDialog({
                            onConfirm: () => deleteTaskHandler(data),
                          });
                        }}
                      >
                        <DeleteForeverRoundedIcon
                          sx={{
                            color: Colors.red,
                            cursor: "pointer",
                            fontSize: 30,
                            background: Colors.white,
                            padding: "2px 2px",
                            borderRadius: "6px",
                            width: 35,
                            height: 30,
                          }}
                        />
                      </div>

                      <ReactTooltip
                        anchorId={`dashboard-deteteTask${index}`}
                        content="Delete task"
                      />
                    </div>
                  </div>
                  <div
                    className="w-100 d-flex d-sm-none"
                    style={{
                      maxWidth: 50,
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <div
                      className="dropdown"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <button
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <MoreHorizIcon
                          fontSize="large"
                          sx={{
                            background: Colors.white,
                            cursor: "pointer",
                            borderRadius: "6px",
                            padding: "2px 4px",
                            height: "30px",
                            color: "grey",
                          }}
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                doneTaskCallApi(data);
                              }}
                            >
                              Done task
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                setShow(true);
                                setSelectedTaskData(data);
                              }}
                            >
                              Edit task
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowDeleteModal(true);
                                setConfirmDialog({
                                  onConfirm: () => deleteTaskHandler(data),
                                });
                              }}
                            >
                              Delete task
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Styled.TodoRectangle>
            );
          })
        ) : isLoading && taskData === null ? (
          <Styled.ListBox>
            <LoaderSpin color={Colors.primary} />
          </Styled.ListBox>
        ) : (
          !isLoading && (
            <Styled.ListBox>
              <img src={NoTaskImage} alt="no-task-img" />
              <TextStyles.GreyFont18px className="mt-2">
                There are no tasks created yet.
              </TextStyles.GreyFont18px>
            </Styled.ListBox>
          )
        )}
        <div className="d-flex justify-content-center pt-4">
          {taskTotalCount > itemsPerPage && (
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={Math.ceil(taskTotalCount / itemsPerPage)}
              page={page}
            />
          )}
        </div>
        {show && (
          <EditTask
            setUpdateRefresh={setUpdateRefresh}
            updateRefresh={updateRefresh}
            selectedTaskData={selectedTaskData}
            handleClose={handleClose}
            show={show}
          />
        )}

        <DeleteModal
          title="task"
          confirmDialog={confirmDialog}
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
        />
      </div>
    </div>
  );
};

export default Future;
