import React from "react";
import styles from "./Features.module.css";
import { NavLink } from "react-router-dom";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import TTFeature1 from "../../../../Assets/frontendImages/TTFeature1.avif";
import TTFeature2 from "../../../../Assets/frontendImages/TTFeature2.avif";
import TTFeature3 from "../../../../Assets/frontendImages/TTFeature3.avif";
import TTFeature4 from "../../../../Assets/frontendImages/TTFeature4.avif";

const Features = () => {
  return (
    <div className={`${styles.section}`}>
      <div className="container-fluid py-3 py-md-4" style={{ maxWidth: 1100 }}>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100">
            <div
              data-aos={"fade-right"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={TTFeature1}
                alt="TTFeature1"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="text-start mt-3 mt-md-0 w-100">
            <div className="w-100" data-aos={"fade-left"}>
              <div className={`${styles.heading} mb-3`}>
                Simplify time tracking
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Effortlessly log work hours using Zodot’s workflow automation.
                Streamline the time-tracking process and eliminate the need for
                complex spreadsheets. Whether you prefer automatic task time
                tracking or manual entry, choose the method that best suits your
                workflow.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Track time with ease
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="text-start mt-3 mt-md-0 w-100 order-1 order-md-0">
            <div className="w-100" data-aos={"fade-right"}>
              <div className={`${styles.heading} mb-3`}>
                Seize real-time control
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Maintain control over project timelines with Zodot’s milestone
                time tracking, providing instant insights into project progress,
                empowering informed decision-making for effective task
                management.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Take charge of your projects
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 order-0 order-md-1">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={TTFeature2}
                alt="TTFeature2"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100">
            <div
              data-aos={"fade-right"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={TTFeature3}
                alt="TTFeature3"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="text-start mt-3 mt-md-0 w-100">
            <div className="w-100" data-aos={"fade-left"}>
              <div className={`${styles.heading} mb-3`}>
                Optimize project-wise breakdown
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Organize time data by projects to evaluate productivity on a
                project-specific level, gaining valuable insights into impactful
                efforts and identifying areas for improvement.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Elevate project efficiency now
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center"
          style={{ overflow: "hidden" }}
        >
          <div className="text-start mt-3 mt-md-0 w-100 order-1 order-md-0">
            <div className="w-100" data-aos={"fade-right"}>
              <div className={`${styles.heading} mb-3`}>
                Consolidate tasks with Zodot
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Merge similar tasks seamlessly with Zodot. Organize your
                workload efficiently and enhance productivity instantly.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Enhance workflow with online time tracking
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 order-0 order-md-1">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={TTFeature4}
                alt="TTFeature4"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
