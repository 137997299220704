import React from "react";

import Colors from "../../../../Constants/Colors";
import Styled from "./ViewInvoiceStyles";
import Details from "./Details";
import Notes from "./Notes";

import ProtectedRoute from "../../../auth/ProtectedRoute";
import { useHistory, useParams } from "react-router-dom";
import { BackButton } from "../../Commons/BackAndForwardButton";

const ViewInvoices = () => {
  const { id } = useParams();
  const history = useHistory();
  let urlElements = window.location.href.split("/");

  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        paddingTop: 105,
      }}
    >
      <div className="d-flex justify-content-between align-items-center pb-3 mx-lg-4 mx-md-4 mx-sm-3 mx-2">
        <div
          style={{
            width: "100%",
            position: "relative",
            overflowY: "hidden",
            height: 80,
          }}
        >
          <Styled.SliderBox
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 5px",
            }}
          >
            <div
              onClick={() => {
                history.push(`/db/invoices/view/${id}/details`);
              }}
            >
              {urlElements[7] === "details" ? (
                <Styled.SelectedItem>View</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem>View</Styled.NotSelectedItem>
              )}
            </div>

            <div
              onClick={() => {
                history.push(`/db/invoices/view/${id}/notes`);
              }}
            >
              {urlElements[7] === "notes" ? (
                <Styled.SelectedItem>Notes</Styled.SelectedItem>
              ) : (
                <Styled.NotSelectedItem>Notes</Styled.NotSelectedItem>
              )}
            </div>
          </Styled.SliderBox>
        </div>

        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 25,
          }}
          onClick={() => history.goBack()}
        >
          <BackButton />
        </div>
      </div>

      <ProtectedRoute
        path="/db/invoices/view/:id/details"
        exact
        component={Details}
      />
      <ProtectedRoute
        path="/db/invoices/view/:id/notes"
        exact
        component={Notes}
      />
    </div>
  );
};

export default ViewInvoices;
