import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import TextStyles from "../../../../Constants/TextStyles";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AutoCompleteCreateActivity,
  AutoCompleteSelectProject,
} from "../../Commons/AutoComplete";
import {
  CustomDatePicker,
  CustomTimePicker,
} from "../../Commons/CustomDatePicker";
import LoaderSpin from "../../Commons/LoaderSpin";
import { msToTime } from "../../Commons/utils";
import moment from "moment";
import {
  createActivityList,
  fetchAllActivityLists,
  update_multiple_timer_activities,
} from "../../../../store/service/timeTrackingService";
import Colors from "../../../../Constants/Colors";
import { timeTrackingActions } from "../../../../store/storage/timetrackingSlice";

const MultipleActivityEditModal = ({
  show,
  handleClose,
  selectedData,
  setUpdateRefresh,
  updateRefresh,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.timeTracking.isLoading);

  const activityListData = useSelector(
    (state) => state.timeTracking.activityListData
  );

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      select_activity:
        selectedData && selectedData.activity_name
          ? selectedData.activity_name
          : null,
      select_project:
        selectedData && selectedData.project_id
          ? selectedData.project_id
          : null,
      invoiced: selectedData && selectedData.is_invoiced,
      start_date:
        selectedData &&
        selectedData.start_time &&
        new Date(selectedData.start_time),
      start_time:
        selectedData &&
        selectedData.sameDateProjectActivityArray &&
        selectedData.sameDateProjectActivityArray.length === 1 &&
        selectedData.start_time
          ? new Date(selectedData.start_time)
          : null,
      hours:
        selectedData &&
        selectedData.sameDateProjectActivityArray &&
        selectedData.sameDateProjectActivityArray.length === 1 &&
        selectedData.sameDateProjectActivityArray[0].start_time &&
        selectedData.sameDateProjectActivityArray[0].end_time
          ? msToTime(
              new Date(selectedData.sameDateProjectActivityArray[0].end_time) -
                new Date(
                  selectedData.sameDateProjectActivityArray[0].start_time
                )
            ).hours.toString()
          : "",
      minutes:
        selectedData &&
        selectedData.sameDateProjectActivityArray &&
        selectedData.sameDateProjectActivityArray.length === 1 &&
        selectedData.sameDateProjectActivityArray[0].start_time &&
        selectedData.sameDateProjectActivityArray[0].end_time
          ? msToTime(
              new Date(selectedData.sameDateProjectActivityArray[0].end_time) -
                new Date(
                  selectedData.sameDateProjectActivityArray[0].start_time
                )
            ).minutes.toString()
          : "",
      seconds:
        selectedData &&
        selectedData.sameDateProjectActivityArray &&
        selectedData.sameDateProjectActivityArray.length === 1 &&
        selectedData.sameDateProjectActivityArray[0].start_time &&
        selectedData.sameDateProjectActivityArray[0].end_time
          ? msToTime(
              new Date(selectedData.sameDateProjectActivityArray[0].end_time) -
                new Date(
                  selectedData.sameDateProjectActivityArray[0].start_time
                )
            ).seconds.toString()
          : "",
    },
  });

  const createActivityHandler = (data) => {
    if (data && !data._id) {
      let Obj = {
        activity_name: data.inputValue,
      };
      dispatch(timeTrackingActions.addNewActivityList(Obj));
      dispatch(createActivityList(Obj)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists());
        }
      });
    }
  };

  const formsubmit = (data) => {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    if (data.hours !== "") {
      hours = parseInt(data.hours);
    }
    if (data.minutes !== "") {
      minutes = parseInt(data.minutes);
    }
    if (data.seconds !== "") {
      seconds = parseInt(data.seconds);
    }
    const sum = hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
    const updateTimerActivityArray =
      selectedData &&
      selectedData.sameDateProjectActivityArray &&
      selectedData.sameDateProjectActivityArray.map((d) => {
        const diff = new Date(d.end_time) - new Date(d.start_time);
        const _id = d._id;
        const client_id = data.select_project && data.select_project.client_id;
        const project_id = data.select_project && data.select_project._id;
        const activity_name =
          data.select_activity && data.select_activity.inputValue
            ? data.select_activity.inputValue
            : data.select_activity && data.select_activity._id
            ? data.select_activity.title
            : null;
        let start_time;
        if (data.start_time !== undefined) {
          start_time = new Date(
            moment(d.start_time)
              .set({
                year: moment(data.start_date).get("year"),
                month: moment(data.start_date).get("month"),
                date: moment(data.start_date).get("date"),
              })
              .set({
                hour: moment(data.start_time).get("hour"),
                minute: moment(data.start_time).get("minute"),
                second: moment(data.start_time).get("second"),
              })
          );
        } else {
          start_time = new Date(
            moment(d.start_time).set({
              year: moment(data.start_date).get("year"),
              month: moment(data.start_date).get("month"),
              date: moment(data.start_date).get("date"),
            })
          );
        }
        let end_time;

        if (sum > 0) {
          end_time = new Date(moment(start_time).add(sum));
        } else {
          end_time = new Date(moment(start_time).add(diff));
        }

        let is_invoiced = data.invoiced;
        return {
          _id,
          client_id,
          project_id,
          activity_name,
          start_time,
          end_time,
          is_invoiced,
        };
      });
    const Obj = {
      updateSelectedActivities: updateTimerActivityArray,
    };
    dispatch(update_multiple_timer_activities(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleClose();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "updateSelectedActivitySuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}
          </TextStyles.FontSize14px>,
          { toastId: "updateSelectedActivityFail" }
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex align-items-center">
          <TextStyles.FontSize20px>
            {selectedData &&
            selectedData.sameDateProjectActivityArray &&
            selectedData.sameDateProjectActivityArray.length > 1
              ? `Edit ${selectedData.sameDateProjectActivityArray.length} activities`
              : "Edit activity"}
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="modal-body">
          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-3">Time</div>
            <div className="d-flex gap-3 flex-wrap justify-content-start align-items-center">
              <div className="d-flex justify-content-around align-items-center">
                <div
                  className="d-flex align-items-center"
                  style={{
                    border: errors.hours
                      ? `1px solid ${Colors.red}`
                      : `1px solid ${Colors.borderInput}`,
                    padding: "0 1rem",
                    borderRadius: "0.75rem",
                    height: "3.15rem",
                  }}
                >
                  <input
                    type="text"
                    style={{ width: 25 }}
                    name="hours"
                    placeholder="00"
                    {...register("hours", {
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: "Please enter valid number for hours.",
                      },
                    })}
                  />
                  <div className="ml-2">hrs</div>
                </div>
              </div>
              <div className="d-flex justify-content-around align-items-center">
                <div
                  className="d-flex align-items-center"
                  style={{
                    border: errors.minutes
                      ? `1px solid ${Colors.red}`
                      : `1px solid ${Colors.borderInput}`,
                    padding: "0 1rem",
                    borderRadius: "0.75rem",
                    height: "3.15rem",
                  }}
                >
                  <input
                    type="text"
                    style={{ width: 25 }}
                    name="minutes"
                    placeholder="00"
                    {...register("minutes", {
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: "Please enter valid number for minutes.",
                      },
                    })}
                  />
                  <div className="ml-2">mins</div>
                </div>
              </div>
              <div className="d-flex justify-content-around align-items-center">
                <div
                  className="d-flex align-items-center"
                  style={{
                    border: errors.seconds
                      ? `1px solid ${Colors.red}`
                      : `1px solid ${Colors.borderInput}`,
                    padding: "0 1rem",
                    borderRadius: "0.75rem",
                    height: "3.15rem",
                  }}
                >
                  <input
                    type="text"
                    style={{ width: 25 }}
                    placeholder="00"
                    name="seconds"
                    {...register("seconds", {
                      pattern: {
                        value: /^[0-9]+$/i,
                        message: "Please enter valid number for seconds.",
                      },
                    })}
                  />
                  <div className="ml-2">sec</div>
                </div>
              </div>
            </div>
            {errors.hours && (
              <TextStyles.InValidFeedback>
                {errors.hours.message}
              </TextStyles.InValidFeedback>
            )}
            {errors.minutes && (
              <TextStyles.InValidFeedback>
                {errors.minutes.message}
              </TextStyles.InValidFeedback>
            )}
            {errors.seconds && (
              <TextStyles.InValidFeedback>
                {errors.seconds.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-2">Activity</div>
            <Controller
              control={control}
              name="select_activity"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteCreateActivity
                  placeholder="Write activity..."
                  options={
                    activityListData &&
                    activityListData.map((d) => {
                      return { _id: d._id, title: d.activity_name };
                    })
                  }
                  onChange={(value) => {
                    onChange(value);
                    createActivityHandler(value);
                  }}
                  defaultValue={value}
                />
              )}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-2">Project</div>
            <Controller
              control={control}
              name="select_project"
              render={({ field: { onChange, value } }) => (
                <AutoCompleteSelectProject
                  placeholder="Assign to project"
                  options={
                    projectOptionsData &&
                    projectOptionsData.map((d) => {
                      return {
                        _id: d._id ? d._id : null,
                        title: d && d.project_name ? d.project_name : null,
                        client_id:
                          d && d.client_id && d.client_id._id
                            ? d.client_id._id
                            : null,
                        client_name:
                          d && d.client_id && d.client_id.company_name
                            ? d.client_id.company_name
                            : null,
                      };
                    })
                  }
                  onChange={onChange}
                  defaultValue={value}
                />
              )}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-2">Date</div>
            <TextStyles.DateRectangle>
              <Controller
                name={"start_date"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <CustomDatePicker
                      onChange={onChange}
                      selected={value}
                      placeholderText="Setect date"
                      dateFormat="dd MMM yyyy"
                    />
                  );
                }}
              />
            </TextStyles.DateRectangle>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <div className="mb-2">Start time</div>
            <TextStyles.DateRectangle className="d-flex">
              <div className="mr-2">
                <WatchLaterIcon
                  sx={{ color: Colors.primary }}
                  fontSize="medium"
                />
              </div>
              <Controller
                name={"start_time"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <CustomTimePicker
                      className="widthoftimepicker"
                      onChange={onChange}
                      selected={value}
                      placeholderText="Start Time"
                      timeIntervals={1}
                    />
                  );
                }}
              />
            </TextStyles.DateRectangle>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px>
            <TextStyles.CheckBox className="form-check d-flex align-items-center">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                name="invoiced"
                {...register("invoiced")}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                <div>Marked as invoiced</div>
              </label>
            </TextStyles.CheckBox>
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block", padding: "6px 12px" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.Button disabled={isLoading ? true : false}>
              {isLoading ? (
                <LoaderSpin />
              ) : (
                `${
                  selectedData &&
                  selectedData.sameDateProjectActivityArray.length > 1
                    ? " Update All"
                    : "Update"
                }`
              )}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default MultipleActivityEditModal;
