import { createSlice } from "@reduxjs/toolkit";
import {
  start_timer_activity,
  stop_timer_activity,
  start_timer_change_activity_name,
  start_timer_change_project_name,
  cancel_start_activity,
  createActivityList,
  fetchAllActivityLists,
  add_single_timer_activity,
  fetchActiveActivity,
  fetchAllTimerActivities,
  update_multiple_timer_activities,
  update_single_timer_activity,
  update_multiple_timer_activity_names,
  update_single_timer_activity_name,
  update_multiple_timer_project_names,
  update_single_timer_project_name,
  update_multiple_timer_invoices,
  update_single_timer_invoice,
  delete_multiple_timer_activities,
  delete_single_timer_activity,
  update_multiple_timer_dates,
  update_single_timer_date,
  merge_timer_activities,
  fetchWeekView,
  updateWeekviewActivityNames,
  createWeekViewSingleActivity,
  updateWeekViewSingleActivity,
  updateWeekViewMultipleActivityTimes,
  fetchActivityReports,
  fetchActivityReportsForGraph,
  fetchActivityCounts,
} from "../service/timeTrackingService";

const initialTTState = {
  activityListData: [],
  ttData: null,
  totalTtCount: null,
  isLoading: false,
  error: null,
  timerData: null,
  weekViewData: null,
  reportsData: null,
  reportsDataForGraph: null,
  totalTtReportCount: null,
  startActivityData: {
    project_id: null,
    activity_name: null,
    start_time: null,
  },
  elapsedTime: 0,
  refresh: false,
};

export const timeTrackingSlice = createSlice({
  name: "timeTrackingSlice",
  initialState: initialTTState,
  reducers: {
    updateRefresh: (state) => {
      state.refresh = !state.refresh;
    },
    updateTimer: (state) => {
      if (state.startActivityData && state.startActivityData.start_time) {
        state.elapsedTime = Math.floor(
          (Date.now() - state.startActivityData.start_time) / 1000
        );
      }
    },
    resetActivityAction: (state, action) => {
      state.startActivityData.start_time = null;
      state.startActivityData.project_id = null;
      state.startActivityData.activity_name = null;
      state.elapsedTime = 0;
    },
    addNewActivityList: (state, action) => {
      state.activityListData.push({
        _id: `${Math.floor(Math.random() * 100000) + 1}`,
        activity_name: action.payload.activity_name,
      });
    },
  },
  extraReducers: {
    //  start timer activity
    [start_timer_activity.pending]: (state) => {
      state.isLoading = true;
    },
    [start_timer_activity.fulfilled]: (state, action) => {
      state.startActivityData.start_time = new Date(
        action.payload.data.start_time
      ).getTime();
      state.startActivityData.project_id = action.payload.data.project_id
        ? action.payload.data.project_id
        : null;
      state.startActivityData.activity_name = action.payload.data.activity_name
        ? action.payload.data.activity_name
        : null;
      state.isLoading = false;
    },
    [start_timer_activity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //  stop timer activity
    [stop_timer_activity.pending]: (state) => {
      state.isLoading = true;
    },
    [stop_timer_activity.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.startActivityData.start_time = null;
      state.elapsedTime = 0;
    },

    [stop_timer_activity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //  change start timer activity_name
    [start_timer_change_activity_name.pending]: (state) => {
      state.isLoading = true;
    },
    [start_timer_change_activity_name.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.startActivityData.activity_name = action.payload.data.activity_name
        ? action.payload.data.activity_name
        : null;
    },
    [start_timer_change_activity_name.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //  change start timer project_name
    [start_timer_change_project_name.pending]: (state) => {
      state.isLoading = true;
    },
    [start_timer_change_project_name.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.startActivityData.project_id = action.payload.data.project_id
        ? action.payload.data.project_id
        : null;
    },
    [start_timer_change_project_name.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //  cancel timer activity
    [cancel_start_activity.pending]: (state) => {
      state.isLoading = true;
    },
    [cancel_start_activity.fulfilled]: (state, action) => {
      state.startActivityData.start_time = null;
      state.elapsedTime = 0;
      state.isLoading = false;
    },
    [cancel_start_activity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch active activity
    [fetchActiveActivity.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchActiveActivity.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.startActivityData.start_time = action.payload.data.start_time
        ? new Date(action.payload.data.start_time).getTime()
        : null;
      state.startActivityData.project_id = action.payload.data.project_id
        ? action.payload.data.project_id
        : null;
      state.startActivityData.activity_name = action.payload.data.activity_name
        ? action.payload.data.activity_name
        : null;
    },
    [fetchActiveActivity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create activity list
    [createActivityList.pending]: (state) => {
      state.isLoading = false;
    },

    [createActivityList.fulfilled]: (state, action) => {
      state.isLoading = false;
      // state.activityListData.push(action.payload.data);
    },

    [createActivityList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //fetch all activity list data
    [fetchAllActivityLists.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAllActivityLists.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.activityListData = action.payload.data;
    },
    [fetchAllActivityLists.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //  add single timer activity
    [add_single_timer_activity.pending]: (state) => {
      state.isLoading = true;
    },
    [add_single_timer_activity.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.timerData = action.payload.data;
    },
    [add_single_timer_activity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //fetch all activities
    [fetchAllTimerActivities.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchAllTimerActivities.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.ttData = action.payload.data.data;
      state.totalTtCount = action.payload.data.totalTtCount;
    },
    [fetchAllTimerActivities.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // update multiple activities
    [update_multiple_timer_activities.pending]: (state) => {
      state.isLoading = true;
    },
    [update_multiple_timer_activities.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [update_multiple_timer_activities.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update single activity
    [update_single_timer_activity.pending]: (state) => {
      state.isLoading = true;
    },
    [update_single_timer_activity.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [update_single_timer_activity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // update multiple activity names

    [update_multiple_timer_activity_names.pending]: (state) => {
      state.isLoading = true;
    },
    [update_multiple_timer_activity_names.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [update_multiple_timer_activity_names.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update single activity name

    [update_single_timer_activity_name.pending]: (state) => {
      state.isLoading = true;
    },
    [update_single_timer_activity_name.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [update_single_timer_activity_name.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update multiple project names

    [update_multiple_timer_project_names.pending]: (state) => {
      state.isLoading = true;
    },
    [update_multiple_timer_project_names.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [update_multiple_timer_project_names.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update single project name

    [update_single_timer_project_name.pending]: (state) => {
      state.isLoading = true;
    },
    [update_single_timer_project_name.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [update_single_timer_project_name.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete multiple activities

    [delete_multiple_timer_activities.pending]: (state) => {
      state.isLoading = true;
    },
    [delete_multiple_timer_activities.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [delete_multiple_timer_activities.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete single activity

    [delete_single_timer_activity.pending]: (state) => {
      state.isLoading = true;
    },
    [delete_single_timer_activity.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [delete_single_timer_activity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update multiple activity's dates
    [update_multiple_timer_dates.pending]: (state) => {
      state.isLoading = true;
    },
    [update_multiple_timer_dates.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [update_multiple_timer_dates.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update single activity's date
    [update_single_timer_date.pending]: (state) => {
      state.isLoading = true;
    },
    [update_single_timer_date.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [update_single_timer_date.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update multiple activity's invoices
    [update_multiple_timer_invoices.pending]: (state) => {
      state.isLoading = true;
    },
    [update_multiple_timer_invoices.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [update_multiple_timer_invoices.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update single activity's invoice
    [update_single_timer_invoice.pending]: (state) => {
      state.isLoading = true;
    },
    [update_single_timer_invoice.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [update_single_timer_invoice.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // merge timer activities
    [merge_timer_activities.pending]: (state) => {
      state.isLoading = true;
    },
    [merge_timer_activities.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [merge_timer_activities.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //fetch weekview data
    [fetchWeekView.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchWeekView.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.weekViewData = action.payload.data;
    },
    [fetchWeekView.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update weekview activity names
    [updateWeekviewActivityNames.pending]: (state) => {
      state.isLoading = true;
    },
    [updateWeekviewActivityNames.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateWeekviewActivityNames.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create single activity in weekview
    [createWeekViewSingleActivity.pending]: (state) => {
      state.isLoading = true;
    },
    [createWeekViewSingleActivity.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createWeekViewSingleActivity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update single activity in weekview
    [updateWeekViewSingleActivity.pending]: (state) => {
      state.isLoading = true;
    },
    [updateWeekViewSingleActivity.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateWeekViewSingleActivity.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update multiple activity times in weekview
    [updateWeekViewMultipleActivityTimes.pending]: (state) => {
      state.isLoading = true;
    },
    [updateWeekViewMultipleActivityTimes.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateWeekViewMultipleActivityTimes.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //fetch activity reports data
    [fetchActivityReports.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchActivityReports.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.reportsData = action.payload.data.data;
      state.totalTtReportCount = action.payload.data.totalTtReportCount;
    },
    [fetchActivityReports.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //fetch activity reports data fro graph
    [fetchActivityReportsForGraph.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchActivityReportsForGraph.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.reportsDataForGraph = action.payload.data;
    },
    [fetchActivityReportsForGraph.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //fetch activity ccounts
    [fetchActivityCounts.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchActivityCounts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.totalTtReportCount = action.payload.data.totalTtReportCount;
    },
    [fetchActivityCounts.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export const timeTrackingActions = timeTrackingSlice.actions;

export default timeTrackingSlice.reducer;
