import React from "react";

import Colors from "../../../Constants/Colors";
import Styled from "./StatementStyles";

import Incomes from "./Components/Incomes/Incomes";
import Expenses from "./Components/Expenses/Expenses";
import Taxes from "./Components/Taxes/Taxes";
import ProtectedRoute from "../../auth/ProtectedRoute";
import { useHistory } from "react-router-dom";

export default function Statement() {
  const history = useHistory();
  let urlElements = window.location.href.split("/");

  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        padding: "105px 0 60px 0",
        contain: "content",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center px-2 px-sm-3 px-md-4 px-lg-4"
        style={{ height: "4rem" }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            overflowY: "hidden",
            height: "100%",
          }}
        >
          <Styled.SliderBox
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-flex">
              <div
                onClick={() => {
                  history.push(`/db/statements/incomes`);
                }}
              >
                {urlElements[5] === "incomes" ? (
                  <Styled.SelectedItem>Income statement</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>
                    Income statement
                  </Styled.NotSelectedItem>
                )}
              </div>

              <div
                onClick={() => {
                  history.push(`/db/statements/expenses`);
                }}
              >
                {urlElements[5] === "expenses" ? (
                  <Styled.SelectedItem>Expense report</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>
                    Expense report
                  </Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/statements/taxes`);
                }}
              >
                {urlElements[5] === "taxes" ? (
                  <Styled.SelectedItem>Tax report</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Tax report</Styled.NotSelectedItem>
                )}
              </div>
            </div>
          </Styled.SliderBox>
        </div>
      </div>
      <div className="px-2 px-sm-3 px-md-4 px-lg-4">
        <ProtectedRoute
          path="/db/statements/incomes"
          exact
          component={Incomes}
        />
        <ProtectedRoute
          path="/db/statements/expenses"
          exact
          component={Expenses}
        />
        <ProtectedRoute path="/db/statements/taxes" exact component={Taxes} />
      </div>
    </div>
  );
}
