import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";

import profileSymbol from "../../../../../Assets/assetsnew/profile-image.svg";

import PasswordChange from "./PasswordChange";
import DeleteAccount from "./DeleteAccount";

import LoaderSpin from "../../../Commons/LoaderSpin";

import Styled from "../../SettingStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import {
  updateProfileInfoPic,
  updateUserInfo,
  deleteProfileInfoPic,
  fetchUserSetupData,
} from "../../../../../store/service/AuthService";

import SelectExperienceLevel from "../../../Commons/SelectExperienceLevel";
import SelectProfession from "../../../Commons/SelectProfession";

const UserInfo = () => {
  const [profile_image, setProifile_image] = useState(profileSymbol);
  const dispatch = useDispatch();
  const [submited, setSubmitted] = useState(false);

  const data = useSelector((state) => state.auth.data);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const profileData = useSelector((state) => state.auth.profileData);

  const [radio_btn, set_radio_btn] = useState(false);

  useEffect(() => {
    if (data.profilePic) {
      setProifile_image(data.imageUrl);
    }
  }, [data]);

  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const passwordChangeHandleCloseModal = () => setPasswordChangeModal(false);

  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const deleteAccountHandleCloseModal = () => setDeleteAccountModal(false);

  const {
    register,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      firstName: data && data.firstName,
      lastName: data && data.lastName,
      email: data && data.email,
      profession:
        profileData && profileData.profession
          ? profileData.profession
          : "Select Profession",
      experience_level:
        profileData && profileData.experienceLevel
          ? profileData.experienceLevel
          : "Select Experience Level",
    },
  });

  useEffect(() => {
    set_radio_btn(
      profileData && profileData.organisation === "I work on a team"
        ? true
        : false
    );
  }, []);

  const removeProfilePicHandler = () => {
    setProifile_image(profileSymbol);
    dispatch(deleteProfileInfoPic());
  };

  const validateEmail = (value) => {
    const trimmedValue = value.trim();
    const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i;
    return (
      emailPattern.test(trimmedValue) || "Please enter a valid email address."
    );
  };

  const formsubmit = async (data) => {
    const Obj = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      profession:
        data.profession !== "Select Profession" ? data.profession : null,
      experienceLevel:
        data.experience_level !== "Select Experience Level"
          ? data.experience_level
          : null,

      organisation: radio_btn ? "I work on a team" : "I work solo",
    };

    setSubmitted(true);
    dispatch(updateUserInfo(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchUserSetupData());
        setSubmitted(false);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "fail" }
        );
      }
    });
  };

  const loadFile = (event) => {
    let formData = new FormData();
    const file = event.target.files[0];
    formData.append("profile", file);
    dispatch(updateProfileInfoPic(formData));
    if (file) setProifile_image(URL.createObjectURL(file));
  };

  return (
    <div className="mt-4">
      <TextStyles.FontSize20px className="d-flex justify-content-start mb-4">
        Profile info
      </TextStyles.FontSize20px>
      <form onSubmit={handleSubmit(formsubmit)}>
        <div className="row m-0">
          <div className="col-12 col-lg-8 col-xl-6 p-0">
            <TextStyles.FontSize14px className="mb-3">
              <div className="row">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  <label htmlFor="firstName" style={{ display: "flex" }}>
                    First name
                  </label>
                  <TextStyles.InputRectangle
                    invalid={errors.firstName}
                    id="firstName"
                    type="text"
                    placeholder="First name"
                    name="firstName"
                    {...register("firstName", {
                      required: "First name can't be empty.",
                    })}
                  />
                  {errors.firstName && (
                    <TextStyles.InValidFeedback>
                      {errors.firstName.message}
                    </TextStyles.InValidFeedback>
                  )}
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="lastName" style={{ display: "flex" }}>
                    Last name
                  </label>
                  <TextStyles.InputRectangle
                    invalid={errors.lastName}
                    id="lastName"
                    type="text"
                    placeholder="Last name"
                    name="lastName"
                    {...register("lastName", {
                      required: "Last name can't be empty.",
                    })}
                  />
                  {errors.lastName && (
                    <TextStyles.InValidFeedback>
                      {errors.lastName.message}
                    </TextStyles.InValidFeedback>
                  )}
                </div>
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="email" style={{ display: "flex" }}>
                Email
              </label>
              <TextStyles.InputRectangle
                invalid={errors.email}
                id="email"
                type="email"
                name="email"
                placeholder="Email"
                {...register("email", {
                  validate: validateEmail,
                  onBlur: (e) => setValue("email", e.target.value.trim()),
                })}
                // defaultValue={data.email}
                // {...register("email", {
                //   required: "Email can't be empty.",
                //   pattern: {
                //     value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                //     message: "Please enter valid email address.",
                //   },
                // })}
              />
              {errors.email && (
                <TextStyles.InValidFeedback>
                  {errors.email.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="row">
                <div className="col-12 col-md-6 col-md-6 mb-4 mb-md-0">
                  <label
                    className="mb-3"
                    htmlFor="profession"
                    style={{ display: "flex" }}
                  >
                    Profession
                  </label>
                  <Controller
                    control={control}
                    name="profession"
                    render={({ field: { onChange, value } }) => (
                      <SelectProfession
                        placeholder="Select profession"
                        onChange={onChange}
                        defaultValue={
                          profileData && profileData.profession
                            ? profileData.profession
                            : "Select Profession"
                        }
                      />
                    )}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label
                    className="mb-3"
                    htmlFor="experience_level"
                    style={{ display: "flex" }}
                  >
                    Experience level
                  </label>
                  <Controller
                    control={control}
                    name="experience_level"
                    render={({ field: { onChange, value } }) => (
                      <SelectExperienceLevel
                        placeholder="Select experience level"
                        onChange={onChange}
                        defaultValue={
                          profileData && profileData.experienceLevel
                            ? profileData.experienceLevel
                            : "Select Experience Level"
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3 mt-4">
              <label
                className="mb-3"
                htmlFor="organization"
                style={{ display: "flex" }}
              >
                Organization
              </label>
              <div className="row">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  <div className="d-flex justify-content-start">
                    <RadioButtonCheckedRoundedIcon
                      onClick={() => set_radio_btn(false)}
                      sx={{
                        color: radio_btn ? Colors.font2 : Colors.primary,
                        display: radio_btn ? "none" : "inline",
                        cursor: "pointer",
                      }}
                    />
                    <RadioButtonUncheckedRoundedIcon
                      onClick={() => set_radio_btn(false)}
                      sx={{
                        color: radio_btn ? Colors.font2 : Colors.primary,
                        display: radio_btn ? "inline" : "none",
                        cursor: "pointer",
                      }}
                    />

                    <TextStyles.FontSize14px className="pl-2">
                      I work solo
                    </TextStyles.FontSize14px>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="d-flex justify-content-start">
                    <RadioButtonCheckedRoundedIcon
                      onClick={() => set_radio_btn(true)}
                      sx={{
                        color: radio_btn ? Colors.primary : Colors.font2,
                        display: radio_btn ? "inline" : "none",
                        cursor: "pointer",
                      }}
                    />
                    <RadioButtonUncheckedRoundedIcon
                      onClick={() => set_radio_btn(true)}
                      sx={{
                        color: radio_btn ? Colors.primary : Colors.font2,
                        display: radio_btn ? "none" : "inline",
                        cursor: "pointer",
                      }}
                    />

                    <TextStyles.FontSize14px className="pl-2">
                      I work on a team
                    </TextStyles.FontSize14px>
                  </div>
                </div>
              </div>
            </TextStyles.FontSize14px>
          </div>
          <div className="col-12 col-lg-4 col-xl-6 p-0">
            <Styled.UserProfilePic className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
              <div className="profile-pic">
                <label className="-label" htmlFor="file">
                  <span className="glyphicon glyphicon-camera"></span>
                  <span>Change Image</span>
                </label>
                <input
                  id="file"
                  type="file"
                  onChange={loadFile}
                  accept="image/png, image/jpeg, image/jpg"
                />
                <img
                  src={profile_image}
                  id="output"
                  alt="profilePic"
                  style={{ objectFit: "fill" }}
                />
              </div>
              <TextStyles.GreyFont16px className="mt-3">
                <span
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={removeProfilePicHandler}
                >
                  Remove
                </span>
              </TextStyles.GreyFont16px>
              <TextStyles.FontSize16px
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  color: Colors.primary,
                }}
              >
                <label
                  htmlFor="file"
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Update photo
                </label>
              </TextStyles.FontSize16px>
            </Styled.UserProfilePic>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-12 col-lg-6 p-0">
            <TextStyles.Button
              type="submit"
              disabled={submited && isLoading ? true : false}
              className="mt-3"
              style={{ opacity: submited && isLoading ? 0.5 : 1 }}
            >
              {submited && isLoading ? <LoaderSpin /> : "Save changes"}
            </TextStyles.Button>
          </div>
        </div>
      </form>
      <div className="d-flex flex-wrap flex-md-nowrap gap-5 justify-content-center mt-5">
        {data && data.userType === "email" && (
          <Styled.UserInfoBox>
            <TextStyles.FontSize18px>Password</TextStyles.FontSize18px>
            <TextStyles.GreyFont14px className="my-3">
              If you want to change your password, change your passwod for
              security purpose.
            </TextStyles.GreyFont14px>
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: 36 }}
            >
              <TextStyles.Button
                style={{ width: 180 }}
                onClick={() => setPasswordChangeModal(true)}
              >
                Change password
              </TextStyles.Button>
            </div>
          </Styled.UserInfoBox>
        )}
        <Styled.UserInfoBox>
          <TextStyles.FontSize18px>Danger zone</TextStyles.FontSize18px>
          <TextStyles.GreyFont14px className="my-3">
            If you want to delete your account, please note that this action is
            irreversible and you will not be able to retrieve any of your data.
          </TextStyles.GreyFont14px>
          <div className="d-flex justify-content-center my-3">
            <TextStyles.Button
              onClick={() => setDeleteAccountModal(true)}
              style={{ width: 180 }}
            >
              Close your account
            </TextStyles.Button>
          </div>
        </Styled.UserInfoBox>
      </div>

      {passwordChangeModal && (
        <PasswordChange
          show={passwordChangeModal}
          handleClose={passwordChangeHandleCloseModal}
        />
      )}

      {deleteAccountModal && (
        <DeleteAccount
          show={deleteAccountModal}
          handleClose={deleteAccountHandleCloseModal}
        />
      )}
    </div>
  );
};

export default UserInfo;
