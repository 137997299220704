import { getRequest, deleteRequest, putRequest } from "../../axios/axios";
import URL from "../../axios/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchReminders = createAsyncThunk(
  "reminder/fetch-reminders",
  async () => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      getRequest(URL.FETCH_REMINDERS.url, token)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const dismissedReminder = createAsyncThunk(
  "reminder/dismissed-reminder",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      deleteRequest(URL.DISMISS_REMINDER.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const doneReminder = createAsyncThunk(
  "reminder/done-reminder",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.DONE_REMINDER.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
