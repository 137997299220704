import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: `1px solid  ${Colors.borderInput}`,
    backgroundColor: `${Colors.white} !important`,
    borderRadius: sizes.formInputBorderRadius,
    height: sizes.formInputHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiOutlinedInput-root": {
      padding: "0 !important",
    },

    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      padding: "0 1rem !important",
      fontSize: "0.875rem",
      color: Colors.font1,
    },

    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },

    "&.Mui-focused": {
      border: `1px solid ${Colors.primary} !important`,
    },
  },
  rootError: {
    border: `1px solid ${Colors.red}`,
    background: Colors.inputErrorBg,
    borderRadius: sizes.formInputBorderRadius,
    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      color: Colors.font1,
    },
  },
  option: {
    fontFamily: "Poppins",
    fontSize: "0.875rem",
    color: Colors.font1,
    marginBottom: "2px !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },

  input: {
    "& input::placeholder": {
      fontFamily: "Poppins",
      padding: "none",
      fontSize: "0.875rem",
      color: Colors.font1,
    },
  },
});

export default function SelectSTDCode(props) {
  var { placeholder, onChange, label, invalid } = props;
  const classes = useStyles();
  const value = props.defaultValue;

  return (
    <Autocomplete
      classes={{
        option: classes.option,
      }}
      className={invalid ? classes.rootError : classes.root}
      id="country-select-demo"
      sx={{ width: props.width }}
      options={countries}
      defaultValue={value}
      onChange={(event, value) => {
        onChange(value);
      }}
      getOptionLabel={(option) =>
        `${option.country}(${option.code}) +${option.country_code}`
      }
      isOptionEqualToValue={(option, value) => option.code === value.code}
      autoHighlight
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.country} ({option.code}) +{option.country_code}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          sx={{
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          }}
          {...params}
          label={label}
          classes={{ root: classes.input }}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password",
          }}
        />
      )}
    />
  );
}

const countries = [
  { code: "AD", country: "Andorra", country_code: "376" },
  {
    code: "AE",
    country: "United Arab Emirates",
    country_code: "971",
  },
  { code: "AF", country: "Afghanistan", country_code: "93" },
  {
    code: "AG",
    country: "Antigua and Barbuda",
    country_code: "1-268",
  },
  { code: "AI", country: "Anguilla", country_code: "1-264" },
  { code: "AL", country: "Albania", country_code: "355" },
  { code: "AM", country: "Armenia", country_code: "374" },
  { code: "AO", country: "Angola", country_code: "244" },
  { code: "AQ", country: "Antarctica", country_code: "672" },
  { code: "AR", country: "Argentina", country_code: "54" },
  { code: "AS", country: "American Samoa", country_code: "1-684" },
  { code: "AT", country: "Austria", country_code: "43" },
  {
    code: "AU",
    country: "Australia",
    country_code: "61",
    suggested: true,
  },
  { code: "AW", country: "Aruba", country_code: "297" },
  { code: "AX", country: "Alland Islands", country_code: "358" },
  { code: "AZ", country: "Azerbaijan", country_code: "994" },
  {
    code: "BA",
    country: "Bosnia and Herzegovina",
    country_code: "387",
  },
  { code: "BB", country: "Barbados", country_code: "1-246" },
  { code: "BD", country: "Bangladesh", country_code: "880" },
  { code: "BE", country: "Belgium", country_code: "32" },
  { code: "BF", country: "Burkina Faso", country_code: "226" },
  { code: "BG", country: "Bulgaria", country_code: "359" },
  { code: "BH", country: "Bahrain", country_code: "973" },
  { code: "BI", country: "Burundi", country_code: "257" },
  { code: "BJ", country: "Benin", country_code: "229" },
  { code: "BL", country: "Saint Barthelemy", country_code: "590" },
  { code: "BM", country: "Bermuda", country_code: "1-441" },
  { code: "BN", country: "Brunei Darussalam", country_code: "673" },
  { code: "BO", country: "Bolivia", country_code: "591" },
  { code: "BR", country: "Brazil", country_code: "55" },
  { code: "BS", country: "Bahamas", country_code: "1-242" },
  { code: "BT", country: "Bhutan", country_code: "975" },
  { code: "BV", country: "Bouvet Island", country_code: "47" },
  { code: "BW", country: "Botswana", country_code: "267" },
  { code: "BY", country: "Belarus", country_code: "375" },
  { code: "BZ", country: "Belize", country_code: "501" },
  {
    code: "CA",
    country: "Canada",
    country_code: "1",
    suggested: true,
  },
  {
    code: "CC",
    country: "Cocos (Keeling) Islands",
    country_code: "61",
  },
  {
    code: "CD",
    country: "Congo, Democratic Republic of the",
    country_code: "243",
  },
  {
    code: "CF",
    country: "Central African Republic",
    country_code: "236",
  },
  {
    code: "CG",
    country: "Congo, Republic of the",
    country_code: "242",
  },
  { code: "CH", country: "Switzerland", country_code: "41" },
  { code: "CI", country: "Cote d'Ivoire", country_code: "225" },
  { code: "CK", country: "Cook Islands", country_code: "682" },
  { code: "CL", country: "Chile", country_code: "56" },
  { code: "CM", country: "Cameroon", country_code: "237" },
  { code: "CN", country: "China", country_code: "86" },
  { code: "CO", country: "Colombia", country_code: "57" },
  { code: "CR", country: "Costa Rica", country_code: "506" },
  { code: "CU", country: "Cuba", country_code: "53" },
  { code: "CV", country: "Cape Verde", country_code: "238" },
  { code: "CW", country: "Curacao", country_code: "599" },
  { code: "CX", country: "Christmas Island", country_code: "61" },
  { code: "CY", country: "Cyprus", country_code: "357" },
  { code: "CZ", country: "Czech Republic", country_code: "420" },
  {
    code: "DE",
    country: "Germany",
    country_code: "49",
    suggested: true,
  },
  { code: "DJ", country: "Djibouti", country_code: "253" },
  { code: "DK", country: "Denmark", country_code: "45" },
  { code: "DM", country: "Dominica", country_code: "1-767" },
  {
    code: "DO",
    country: "Dominican Republic",
    country_code: "1-809",
  },
  { code: "DZ", country: "Algeria", country_code: "213" },
  { code: "EC", country: "Ecuador", country_code: "593" },
  { code: "EE", country: "Estonia", country_code: "372" },
  { code: "EG", country: "Egypt", country_code: "20" },
  { code: "EH", country: "Western Sahara", country_code: "212" },
  { code: "ER", country: "Eritrea", country_code: "291" },
  { code: "ES", country: "Spain", country_code: "34" },
  { code: "ET", country: "Ethiopia", country_code: "251" },
  { code: "FI", country: "Finland", country_code: "358" },
  { code: "FJ", country: "Fiji", country_code: "679" },
  {
    code: "FK",
    country: "Falkland Islands (Malvinas)",
    country_code: "500",
  },
  {
    code: "FM",
    country: "Micronesia, Federated States of",
    country_code: "691",
  },
  { code: "FO", country: "Faroe Islands", country_code: "298" },
  {
    code: "FR",
    country: "France",
    country_code: "33",
    suggested: true,
  },
  { code: "GA", country: "Gabon", country_code: "241" },
  { code: "GB", country: "United Kingdom", country_code: "44" },
  { code: "GD", country: "Grenada", country_code: "1-473" },
  { code: "GE", country: "Georgia", country_code: "995" },
  { code: "GF", country: "French Guiana", country_code: "594" },
  { code: "GG", country: "Guernsey", country_code: "44" },
  { code: "GH", country: "Ghana", country_code: "233" },
  { code: "GI", country: "Gibraltar", country_code: "350" },
  { code: "GL", country: "Greenland", country_code: "299" },
  { code: "GM", country: "Gambia", country_code: "220" },
  { code: "GN", country: "Guinea", country_code: "224" },
  { code: "GP", country: "Guadeloupe", country_code: "590" },
  { code: "GQ", country: "Equatorial Guinea", country_code: "240" },
  { code: "GR", country: "Greece", country_code: "30" },
  {
    code: "GS",
    country: "South Georgia and the South Sandwich Islands",
    country_code: "500",
  },
  { code: "GT", country: "Guatemala", country_code: "502" },
  { code: "GU", country: "Guam", country_code: "1-671" },
  { code: "GW", country: "Guinea-Bissau", country_code: "245" },
  { code: "GY", country: "Guyana", country_code: "592" },
  { code: "HK", country: "Hong Kong", country_code: "852" },
  {
    code: "HM",
    country: "Heard Island and McDonald Islands",
    country_code: "672",
  },
  { code: "HN", country: "Honduras", country_code: "504" },
  { code: "HR", country: "Croatia", country_code: "385" },
  { code: "HT", country: "Haiti", country_code: "509" },
  { code: "HU", country: "Hungary", country_code: "36" },
  { code: "ID", country: "Indonesia", country_code: "62" },
  { code: "IE", country: "Ireland", country_code: "353" },
  { code: "IL", country: "Israel", country_code: "972" },
  { code: "IM", country: "Isle of Man", country_code: "44" },
  { code: "IN", country: "India", country_code: "91" },
  {
    code: "IO",
    country: "British Indian Ocean Territory",
    country_code: "246",
  },
  { code: "IQ", country: "Iraq", country_code: "964" },
  {
    code: "IR",
    country: "Iran, Islamic Republic of",
    country_code: "98",
  },
  { code: "IS", country: "Iceland", country_code: "354" },
  { code: "IT", country: "Italy", country_code: "39" },
  { code: "JE", country: "Jersey", country_code: "44" },
  { code: "JM", country: "Jamaica", country_code: "1-876" },
  { code: "JO", country: "Jordan", country_code: "962" },
  {
    code: "JP",
    country: "Japan",
    country_code: "81",
    suggested: true,
  },
  { code: "KE", country: "Kenya", country_code: "254" },
  { code: "KG", country: "Kyrgyzstan", country_code: "996" },
  { code: "KH", country: "Cambodia", country_code: "855" },
  { code: "KI", country: "Kiribati", country_code: "686" },
  { code: "KM", country: "Comoros", country_code: "269" },
  {
    code: "KN",
    country: "Saint Kitts and Nevis",
    country_code: "1-869",
  },
  {
    code: "KP",
    country: "Korea, Democratic People's Republic of",
    country_code: "850",
  },
  { code: "KR", country: "Korea, Republic of", country_code: "82" },
  { code: "KW", country: "Kuwait", country_code: "965" },
  { code: "KY", country: "Cayman Islands", country_code: "1-345" },
  { code: "KZ", country: "Kazakhstan", country_code: "7" },
  {
    code: "LA",
    country: "Lao People's Democratic Republic",
    country_code: "856",
  },
  { code: "LB", country: "Lebanon", country_code: "961" },
  { code: "LC", country: "Saint Lucia", country_code: "1-758" },
  { code: "LI", country: "Liechtenstein", country_code: "423" },
  { code: "LK", country: "Sri Lanka", country_code: "94" },
  { code: "LR", country: "Liberia", country_code: "231" },
  { code: "LS", country: "Lesotho", country_code: "266" },
  { code: "LT", country: "Lithuania", country_code: "370" },
  { code: "LU", country: "Luxembourg", country_code: "352" },
  { code: "LV", country: "Latvia", country_code: "371" },
  { code: "LY", country: "Libya", country_code: "218" },
  { code: "MA", country: "Morocco", country_code: "212" },
  { code: "MC", country: "Monaco", country_code: "377" },
  {
    code: "MD",
    country: "Moldova, Republic of",
    country_code: "373",
  },
  { code: "ME", country: "Montenegro", country_code: "382" },
  {
    code: "MF",
    country: "Saint Martin (French part)",
    country_code: "590",
  },
  { code: "MG", country: "Madagascar", country_code: "261" },
  { code: "MH", country: "Marshall Islands", country_code: "692" },
  {
    code: "MK",
    country: "Macedonia, the Former Yugoslav Republic of",
    country_code: "389",
  },
  { code: "ML", country: "Mali", country_code: "223" },
  { code: "MM", country: "Myanmar", country_code: "95" },
  { code: "MN", country: "Mongolia", country_code: "976" },
  { code: "MO", country: "Macao", country_code: "853" },
  {
    code: "MP",
    country: "Northern Mariana Islands",
    country_code: "1-670",
  },
  { code: "MQ", country: "Martinique", country_code: "596" },
  { code: "MR", country: "Mauritania", country_code: "222" },
  { code: "MS", country: "Montserrat", country_code: "1-664" },
  { code: "MT", country: "Malta", country_code: "356" },
  { code: "MU", country: "Mauritius", country_code: "230" },
  { code: "MV", country: "Maldives", country_code: "960" },
  { code: "MW", country: "Malawi", country_code: "265" },
  { code: "MX", country: "Mexico", country_code: "52" },
  { code: "MY", country: "Malaysia", country_code: "60" },
  { code: "MZ", country: "Mozambique", country_code: "258" },
  { code: "NA", country: "Namibia", country_code: "264" },
  { code: "NC", country: "New Caledonia", country_code: "687" },
  { code: "NE", country: "Niger", country_code: "227" },
  { code: "NF", country: "Norfolk Island", country_code: "672" },
  { code: "NG", country: "Nigeria", country_code: "234" },
  { code: "NI", country: "Nicaragua", country_code: "505" },
  { code: "NL", country: "Netherlands", country_code: "31" },
  { code: "NO", country: "Norway", country_code: "47" },
  { code: "NP", country: "Nepal", country_code: "977" },
  { code: "NR", country: "Nauru", country_code: "674" },
  { code: "NU", country: "Niue", country_code: "683" },
  { code: "NZ", country: "New Zealand", country_code: "64" },
  { code: "OM", country: "Oman", country_code: "968" },
  { code: "PA", country: "Panama", country_code: "507" },
  { code: "PE", country: "Peru", country_code: "51" },
  { code: "PF", country: "French Polynesia", country_code: "689" },
  { code: "PG", country: "Papua New Guinea", country_code: "675" },
  { code: "PH", country: "Philippines", country_code: "63" },
  { code: "PK", country: "Pakistan", country_code: "92" },
  { code: "PL", country: "Poland", country_code: "48" },
  {
    code: "PM",
    country: "Saint Pierre and Miquelon",
    country_code: "508",
  },
  { code: "PN", country: "Pitcairn", country_code: "870" },
  { code: "PR", country: "Puerto Rico", country_code: "1" },
  {
    code: "PS",
    country: "Palestine, State of",
    country_code: "970",
  },
  { code: "PT", country: "Portugal", country_code: "351" },
  { code: "PW", country: "Palau", country_code: "680" },
  { code: "PY", country: "Paraguay", country_code: "595" },
  { code: "QA", country: "Qatar", country_code: "974" },
  { code: "RE", country: "Reunion", country_code: "262" },
  { code: "RO", country: "Romania", country_code: "40" },
  { code: "RS", country: "Serbia", country_code: "381" },
  { code: "RU", country: "Russian Federation", country_code: "7" },
  { code: "RW", country: "Rwanda", country_code: "250" },
  { code: "SA", country: "Saudi Arabia", country_code: "966" },
  { code: "SB", country: "Solomon Islands", country_code: "677" },
  { code: "SC", country: "Seychelles", country_code: "248" },
  { code: "SD", country: "Sudan", country_code: "249" },
  { code: "SE", country: "Sweden", country_code: "46" },
  { code: "SG", country: "Singapore", country_code: "65" },
  { code: "SH", country: "Saint Helena", country_code: "290" },
  { code: "SI", country: "Slovenia", country_code: "386" },
  {
    code: "SJ",
    country: "Svalbard and Jan Mayen",
    country_code: "47",
  },
  { code: "SK", country: "Slovakia", country_code: "421" },
  { code: "SL", country: "Sierra Leone", country_code: "232" },
  { code: "SM", country: "San Marino", country_code: "378" },
  { code: "SN", country: "Senegal", country_code: "221" },
  { code: "SO", country: "Somalia", country_code: "252" },
  { code: "SR", country: "Suriname", country_code: "597" },
  { code: "SS", country: "South Sudan", country_code: "211" },
  {
    code: "ST",
    country: "Sao Tome and Principe",
    country_code: "239",
  },
  { code: "SV", country: "El Salvador", country_code: "503" },
  {
    code: "SX",
    country: "Sint Maarten (Dutch part)",
    country_code: "1-721",
  },
  {
    code: "SY",
    country: "Syrian Arab Republic",
    country_code: "963",
  },
  { code: "SZ", country: "Swaziland", country_code: "268" },
  {
    code: "TC",
    country: "Turks and Caicos Islands",
    country_code: "1-649",
  },
  { code: "TD", country: "Chad", country_code: "235" },
  {
    code: "TF",
    country: "French Southern Territories",
    country_code: "262",
  },
  { code: "TG", country: "Togo", country_code: "228" },
  { code: "TH", country: "Thailand", country_code: "66" },
  { code: "TJ", country: "Tajikistan", country_code: "992" },
  { code: "TK", country: "Tokelau", country_code: "690" },
  { code: "TL", country: "Timor-Leste", country_code: "670" },
  { code: "TM", country: "Turkmenistan", country_code: "993" },
  { code: "TN", country: "Tunisia", country_code: "216" },
  { code: "TO", country: "Tonga", country_code: "676" },
  { code: "TR", country: "Turkey", country_code: "90" },
  {
    code: "TT",
    country: "Trinidad and Tobago",
    country_code: "1-868",
  },
  { code: "TV", country: "Tuvalu", country_code: "688" },
  {
    code: "TW",
    country: "Taiwan, Republic of China",
    country_code: "886",
  },
  {
    code: "TZ",
    country: "United Republic of Tanzania",
    country_code: "255",
  },
  { code: "UA", country: "Ukraine", country_code: "380" },
  { code: "UG", country: "Uganda", country_code: "256" },
  {
    code: "US",
    country: "United States",
    country_code: "1",
    suggested: true,
  },
  { code: "UY", country: "Uruguay", country_code: "598" },
  { code: "UZ", country: "Uzbekistan", country_code: "998" },
  {
    code: "VA",
    country: "Holy See (Vatican City State)",
    country_code: "379",
  },
  {
    code: "VC",
    country: "Saint Vincent and the Grenadines",
    country_code: "1-784",
  },
  { code: "VE", country: "Venezuela", country_code: "58" },
  {
    code: "VG",
    country: "British Virgin Islands",
    country_code: "1-284",
  },
  {
    code: "VI",
    country: "US Virgin Islands",
    country_code: "1-340",
  },
  { code: "VN", country: "Vietnam", country_code: "84" },
  { code: "VU", country: "Vanuatu", country_code: "678" },
  { code: "WF", country: "Wallis and Futuna", country_code: "681" },
  { code: "WS", country: "Samoa", country_code: "685" },
  { code: "XK", country: "Kosovo", country_code: "383" },
  { code: "YE", country: "Yemen", country_code: "967" },
  { code: "YT", country: "Mayotte", country_code: "262" },
  { code: "ZA", country: "South Africa", country_code: "27" },
  { code: "ZM", country: "Zambia", country_code: "260" },
  { code: "ZW", country: "Zimbabwe", country_code: "263" },
];
