import React, { useState } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FolderSharedIcon from "@mui/icons-material/FolderShared";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";

import Colors from "../../../Constants/Colors";
import sizes from "../../../Constants/sizes";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput}`,
    borderRadius: sizes.formInputBorderRadius,
    height: sizes.formInputHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiOutlinedInput-root": {
      padding: "0 2rem 0 0.875rem !important",
      marginRight: "0 !important",
    },

    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      color: Colors.font1,
    },

    "&:hover": {
      border: `1px solid ${Colors.primary}`,
    },

    "&.Mui-focused": {
      border: `1px solid ${Colors.primary}`,
    },
  },
  rootError: {
    border: `1px solid ${Colors.red}`,
    background: Colors.inputErrorBg,
    borderRadius: sizes.formInputBorderRadius,
    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      color: Colors.font1,
    },
  },
  popper: {
    minWidth: "15rem !important",
  },
  groupLabel: {
    fontFamily: "Poppins !important",
    fontSize: "14px !important",
    color: `${Colors.font2} !important`,
  },
  option: {
    fontFamily: "Poppins",
    fontSize: "0.875rem",
    color: Colors.font1,
    marginBottom: "2px !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    display: "inherit !important",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },

  input: {
    "& input::placeholder": {
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      color: Colors.font1,
    },
  },
});

const filter = createFilterOptions();

export const AutoCompleteCreateActivity = (props) => {
  const { placeholder, options, onChange, defaultValue } = props;
  const [value, setValue] = useState(defaultValue ? defaultValue : null);
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{
        popper: classes.popper,
        option: classes.option,
        groupLabel: classes.groupLabel,
      }}
      className={classes.root}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
        if (typeof newValue === "string") {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option.title
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add ${inputValue}`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      sx={{ width: "100%" }}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <AccessTimeIcon
            sx={{ fontSize: 22, color: Colors.font2, marginRight: 2 }}
          />

          {option.title}
        </li>
      )}
      freeSolo
      renderInput={(params) => (
        <TextField
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          {...params}
          classes={{ root: classes.input }}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <AccessTimeIcon sx={{ fontSize: 24, color: Colors.primary }} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const AutoCompleteSelectProject = (props) => {
  const { placeholder, options, onChange, defaultValue } = props;
  const classes = useStyles();

  return (
    <div className="d-flex align-items-center" style={{ width: "100%" }}>
      <Autocomplete
        classes={{
          popper: classes.popper,
          option: classes.option,
          groupLabel: classes.groupLabel,
        }}
        className={classes.root}
        value={defaultValue ? defaultValue : null}
        options={
          options &&
          options
            .filter((d) => d.client_id)
            .sort(
              (a, b) =>
                b.client_id && -b.client_name.localeCompare(a.client_name)
            )
        }
        groupBy={(option) =>
          option.client_name ? option.client_name : "Deleted Client"
        }
        getOptionLabel={(option) => option.title}
        renderOption={(props, option) => (
          <li {...props}>
            <CreateNewFolderOutlinedIcon
              sx={{ fontSize: 24, color: Colors.primary, marginRight: 2 }}
            />
            {option.title}
          </li>
        )}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          value === null ||
          option._id === value._id
        }
        sx={{ width: "100%" }}
        onChange={(event, value) => {
          onChange(value);
        }}
        forcePopupIcon={false}
        renderInput={(params) => {
          return (
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
              {...params}
              classes={{ root: classes.input }}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <CreateNewFolderOutlinedIcon
                      sx={{
                        fontSize: 26,
                        color: Colors.primary,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
    </div>
  );
};

export const AutoCompleteSelectClient = (props) => {
  var { placeholder, options, defaultValue, onChange, label, invalid } = props;
  // const [value, setValue] = useState(defaultValue && defaultValue);
  const classes = useStyles();

  return (
    <Autocomplete
      classes={{
        popper: classes.popper,
        option: classes.option,
      }}
      className={invalid ? classes.rootError : classes.root}
      freeSolo
      value={defaultValue ? defaultValue : null}
      id="combo-box-demo"
      onChange={(event, value) => {
        onChange(value);
      }}
      sx={{ width: "100%" }}
      options={options}
      renderOption={(props, option) => (
        <li {...props}>
          <FolderSharedIcon
            sx={{ fontSize: 24, color: Colors.font2, marginRight: 2 }}
          />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          sx={{
            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          }}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <FolderSharedIcon
                  sx={{ color: Colors.primary, fontSize: 24 }}
                />
              </InputAdornment>
            ),
          }}
          classes={{ root: classes.input }}
          placeholder={placeholder}
          label={label}
        />
      )}
    />
  );
};
