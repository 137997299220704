import React from "react";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import Styles from "./BackAndForwardButtonStyles.js";
import Colors from "../../../Constants/Colors.js";

export const BackButton = () => {
  return (
    <div
      style={{
        width: 55,
        height: 55,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Styles.BackButtonOuterCircle>
        <Styles.BackButtonCircle>
          <ReplyOutlinedIcon
            sx={{ color: Colors.primary, fontSize: 30 }}
            className="backBtn"
          />
        </Styles.BackButtonCircle>
      </Styles.BackButtonOuterCircle>
    </div>
  );
};

export const ForwardButton = () => {
  return (
    <div
      style={{
        width: 55,
        height: 55,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Styles.BackButtonOuterCircle>
        <Styles.BackButtonCircle>
          <ReplyOutlinedIcon
            sx={{
              color: Colors.primary,
              fontSize: 30,
              transform: "scaleX(-1)",
            }}
            className="backBtn"
          />
        </Styles.BackButtonCircle>
      </Styles.BackButtonOuterCircle>
    </div>
  );
};
