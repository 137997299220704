import React from "react";
import Footer from "../Footer/Footer";
import LandingPage from "./LandingPage/LandingPage";
import AboutPage from "./AboutPage/AboutPage";
import Features from "./Features/Features";
import BusinessModal from "./BusinessModel/BusinessModal";
import SuccessStory from "./SuccessStory/SuccessStory";

const WhyZodot = () => {
  return (
    <div>
      <LandingPage />
      <AboutPage />
      <Features />
      <BusinessModal />
      <SuccessStory />
      <Footer />
    </div>
  );
};

export default WhyZodot;
