import React, { useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Todo from "./Todo.jsx";
import Future from "./Future.jsx";
import Done from "./Done.jsx";

import Styled from "../../../clients/editClient/task/TaskStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors.js";

export default function task() {
  const [showInput, setShowInput] = useState(false);
  const [selector, setSelector] = useState("todo");

  const addNewInput = () => {
    setShowInput(true);
    setSelector("todo");
  };

  const updateSelector = (value) => {
    setSelector(value);
    setShowInput(false);
  };

  return (
    <div className="mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <TextStyles.FontSize20px className="d-flex justify-content-start align-item-center">
          Tasks
        </TextStyles.FontSize20px>
        <Styled.Oval onClick={addNewInput}>
          <label htmlFor="addTask" className="mb-1">
            <AddOutlinedIcon
              sx={{
                fontSize: 22,
                color: Colors.white,
                cursor: "pointer",
              }}
            />
          </label>
        </Styled.Oval>
      </div>

      <Styled.SliderBox className="d-flex mt-2">
        <div
          onClick={() => {
            updateSelector("todo");
          }}
        >
          {selector === "todo" ? (
            <Styled.SelectedItem>To do</Styled.SelectedItem>
          ) : (
            <Styled.NotSelectedItem>To do</Styled.NotSelectedItem>
          )}
        </div>
        <div
          onClick={() => {
            updateSelector("future");
          }}
        >
          {selector === "future" ? (
            <Styled.SelectedItem>Future</Styled.SelectedItem>
          ) : (
            <Styled.NotSelectedItem>Future</Styled.NotSelectedItem>
          )}
        </div>
        <div
          onClick={() => {
            updateSelector("done");
          }}
        >
          {selector === "done" ? (
            <Styled.SelectedItem>Done</Styled.SelectedItem>
          ) : (
            <Styled.NotSelectedItem>Done</Styled.NotSelectedItem>
          )}
        </div>
      </Styled.SliderBox>
      <div className="d-flex align-items-center justify-content-between w-100 my-3">
        <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{ maxWidth: 900 }}
        >
          <TextStyles.GreyFont14px className="text-start ml-5 w-100">
            Title
          </TextStyles.GreyFont14px>
          <TextStyles.GreyFont14px className="text-end mr-4 w-100">
            Due
          </TextStyles.GreyFont14px>
        </div>
        <div
          className="w-100 d-none d-sm-flex"
          style={{
            maxWidth: 150,
          }}
        />
        <div
          className="w-100 d-flex d-sm-none"
          style={{
            maxWidth: 50,
          }}
        />
      </div>
      {selector === "todo" && (
        <Todo showInput={showInput} setShowInput={setShowInput} />
      )}

      {selector === "future" && <Future />}
      {selector === "done" && <Done />}
    </div>
  );
}
