import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import ClearIcon from "@mui/icons-material/Clear";

import { addLineItemService } from "../../../../store/service/invoiceService";
import LoaderSpin from "../../Commons/LoaderSpin";
import CloseIcon from "@mui/icons-material/Close";

import Styled from "../../projects/createProject/SetupStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import SelectServiceType from "../../Commons/SelectServiceType";

const AddLineItemService = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.invoices.isLoading);
  const { register, reset, control, handleSubmit, watch } = useForm();

  const serviceRateType = watch("service_rate_type");
  const serviceRate = watch("service_rate");
  const serviceRateCount = watch("service_rate_count");
  let serviceTotal;
  if (serviceRateType === "Flat fee") {
    serviceTotal = serviceRate * 1;
  } else {
    serviceTotal = serviceRate * (serviceRateCount || 1);
  }

  const formsubmit = (data) => {
    const Obj = {
      invoice_id: selectedData._id,
      service_name: data.service_name,
      service_rate: data.service_rate,
      service_rate_type: data.service_rate_type
        ? data.service_rate_type
        : "Flat fee",
      service_rate_count: data.service_rate_count
        ? parseInt(data.service_rate_count)
        : 1,
      desc: data.desc,
    };
    dispatch(addLineItemService(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "addLineSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "addLineFail" }
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight500px"
      fullscreen
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex align-items-center">
          <TextStyles.FontSize20px>Add line item</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="service_name" style={{ display: "flex" }}>
              Service name
            </label>
            <div className="d-flex flex-row align-items-center">
              <TextStyles.InputRectangle
                id="service_name"
                type="text"
                placeholder="Service name"
                name="service_name"
                {...register("service_name")}
              />
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="rate" style={{ display: "flex" }}>
              Rate
            </label>

            <div className="d-flex flex-wrap align-items-center">
              <Styled.inputBox
                style={{ maxWidth: "fit-content" }}
                className="mr-3"
              >
                <span>
                  {selectedData &&
                    selectedData.currency &&
                    selectedData.currency.symbol}
                </span>
                <input
                  style={{ width: 60 }}
                  type="number"
                  step="0.01"
                  id="service_rate"
                  name="service_rate"
                  placeholder="00.00"
                  {...register("service_rate")}
                />
              </Styled.inputBox>
              <div className="mr-2">
                <Controller
                  control={control}
                  name="service_rate_type"
                  render={({ field: { onChange, value } }) => (
                    <SelectServiceType
                      placeholder="Select"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              {serviceRateType && serviceRateType !== "Flat fee" && (
                <TextStyles.FontSize14px className="d-flex flex-wrap align-items-center">
                  <CloseIcon
                    className="mr-2"
                    sx={{ color: Colors.font2, fontSize: 20 }}
                  />
                  <Styled.inputBox style={{ maxWidth: 60 }} className="mr-2">
                    <input
                      type="number"
                      min={"0"}
                      step="0.01"
                      placeholder="00"
                      defaultValue={1}
                      name="service_rate_count"
                      {...register("service_rate_count")}
                      style={{ width: 30, textAlign: "center" }}
                    />
                  </Styled.inputBox>
                  <TextStyles.FontSize14px>
                    {serviceRateType === "Per hour"
                      ? "hours"
                      : serviceRateType === "Per day"
                      ? "days"
                      : serviceRateType === "Per item"
                      ? "items"
                      : serviceRateType === "Per word"
                      ? "words"
                      : ""}
                  </TextStyles.FontSize14px>
                </TextStyles.FontSize14px>
              )}
            </div>
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="description" style={{ display: "flex" }}>
              Description
            </label>
            <TextStyles.Textarea
              rows={4}
              id="description"
              name="desc"
              placeholder="Add a note..."
              {...register("desc")}
            />
          </TextStyles.FontSize14px>

          <div
            className="mb-3"
            style={{
              width: "100%",
              height: "1px",
              border: "0.5px solid #edeef2",
            }}
          />
          <TextStyles.FontSize18px className="mb-3">
            <div className="row">
              <div className="col">
                <div className="d-flex justify-content-start">
                  Service total
                </div>
              </div>
              <div className="col">
                <span
                  className="d-flex justify-content-end"
                  style={{ color: Colors.primary }}
                >
                  {selectedData &&
                    selectedData.currency &&
                    selectedData.currency.symbol}
                  {serviceTotal > 0
                    ? parseFloat(serviceTotal).toFixed(2)
                    : "00.00"}
                </span>
              </div>
            </div>
          </TextStyles.FontSize18px>
          <div
            className="mb-3"
            style={{
              width: "100%",
              height: "1px",
              border: "0.5px solid #edeef2",
            }}
          />
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button
              disabled={isLoading ? true : false}
              style={{
                opacity: isLoading ? 0.5 : 1,
              }}
            >
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddLineItemService;
