import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import TextStyles from "../../../../Constants/TextStyles";
import { fetchTransactionsForGraph } from "../../../../store/service/transactionService";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import NorthSharpIcon from "@mui/icons-material/NorthSharp";

import TrCharts from "../../Transaction/charts/TrCharts";
import Colors from "../../../../Constants/Colors";
import SelectDate from "../../Commons/SelectDate";

const TransactionGraphRectangle = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 0.75rem;
  box-shadow: 0 8px 12px 0 rgba(238, 241, 245, 0.89);
  background-color: ${Colors.white};
  content: contain;
`;

export default function thirdrow() {
  const dispatch = useDispatch();
  const trDataForGraph = useSelector(
    (state) => state.transaction.trDataForGraph
  );

  const userCurrency = useSelector((state) => state.auth.userCurrency);

  // filtered state
  const [filterdDateRange, setFilteredDateRange] = useState(null);

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).format("YYYY-MM-DD");
      end_time = moment(filterdDateRange[0]).format("YYYY-MM-DD");
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(6, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(14, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(29, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(44, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(59, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment(new Date()).format("YYYY-MM-DD");
        end_time = moment(start_time).subtract(89, "d").format("YYYY-MM-DD");
      } else if (filterdDateRange === "This month") {
        const startDateOfThisMonth = moment(new Date())
          .startOf("month")
          .format("YYYY-MM-DD");
        const endDateOfThisMonth = moment(new Date())
          .endOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last month") {
        const endDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        const startDateOfLastMonth = moment(new Date())
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastMonth).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastMonth).format("YYYY-MM-DD");
      } else if (filterdDateRange === "This year") {
        const endDateOfThisYear = moment(new Date())
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfThisYear = moment(new Date())
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfThisYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfThisYear).format("YYYY-MM-DD");
      } else if (filterdDateRange === "Last year") {
        const endDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .endOf("year")
          .format("YYYY-MM-DD");
        const startDateOfLastYear = moment(new Date())
          .subtract(1, "years")
          .startOf("year")
          .format("YYYY-MM-DD");
        start_time = moment(endDateOfLastYear).format("YYYY-MM-DD");
        end_time = moment(startDateOfLastYear).format("YYYY-MM-DD");
      }
    }

    const Obj = {
      start_time,
      end_time,
    };
    dispatch(fetchTransactionsForGraph(Obj));
  }, [filterdDateRange]);

  const currencySymbol = useMemo(() => {
    return (
      userCurrency && userCurrency.currency && userCurrency.currency.symbol
    );
  }, [userCurrency]);

  const transactionIncomesArray = useMemo(() => {
    return (
      trDataForGraph &&
      trDataForGraph
        .filter((d) => d.transaction_type === "income")
        .map((item) => item.total_amount_in_user_currency)
    );
  }, [trDataForGraph]);

  const totalIncomes = useMemo(() => {
    return (
      transactionIncomesArray &&
      transactionIncomesArray.reduce(
        (preValue, curValue) => parseFloat(preValue) + parseFloat(curValue),
        0
      )
    );
  }, [transactionIncomesArray]);

  const transactionExpensesArray = useMemo(() => {
    return (
      trDataForGraph &&
      trDataForGraph
        .filter((d) => d.transaction_type === "expense")
        .map((item) => item.total_amount_in_user_currency)
    );
  }, [trDataForGraph]);

  const totalExpenses = useMemo(() => {
    return (
      transactionExpensesArray &&
      transactionExpensesArray.reduce(
        (preValue, curValue) => parseFloat(preValue) + parseFloat(curValue),
        0
      )
    );
  }, [transactionExpensesArray]);

  const total = useMemo(() => {
    return totalIncomes - totalExpenses;
  }, [totalIncomes, totalExpenses]);

  return (
    <div className="mx-lg-4 mx-md-4 mx-sm-3 mx-2 my-3">
      <TransactionGraphRectangle
        style={{
          display:
            trDataForGraph && trDataForGraph.length > 0 ? "block" : "none",
        }}
      >
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-12  col-lg-6 col-xl-5 d-flex flex-column justify-content-between py-3">
            <div className="d-flex flex-wrap gap-3 justify-content-center justify-content-sm-between">
              <div className="d-flex flex-column ">
                <TextStyles.FontSize20px
                  style={{ fontWeight: 500, fontSize: 24 }}
                >
                  Profit before taxes
                </TextStyles.FontSize20px>

                <TextStyles.FontSize27px
                  className="text-center text-sm-left mt-3"
                  style={{
                    color:
                      total < 0 ? Colors.red : total > 0 ? Colors.success : "",
                    fontWeight: 500,
                    fontSize: 24,
                  }}
                >
                  {total < 0 ? "-" : ""} {currencySymbol}
                  {parseFloat(Math.abs(total)).toFixed(2)}
                </TextStyles.FontSize27px>
              </div>
              <TextStyles.FontSize14px className="d-flex">
                <SelectDate
                  defaultValue={filterdDateRange && filterdDateRange}
                  onChange={(value) => {
                    setFilteredDateRange(value);
                  }}
                />
              </TextStyles.FontSize14px>
            </div>

            <div
              style={{
                height: 1,
                border: "0.5px solid #edeef2",
                width: "100%",
                margin: "10px 0",
              }}
            />
            <div className="d-flex justify-content-between mt-2 w-100">
              <div className="d-flex align-items-center">
                <NorthSharpIcon
                  sx={{
                    color: Colors.success,
                    fontSize: 22,
                  }}
                />

                <TextStyles.FontSize14px
                  style={{ color: Colors.success, fontWeight: 700 }}
                >
                  {currencySymbol}
                  {`${parseFloat(totalIncomes).toFixed(2)} Income`}
                </TextStyles.FontSize14px>
              </div>

              <div className="d-flex align-items-center">
                <NorthSharpIcon
                  sx={{
                    color: Colors.red,
                    fontSize: 22,
                    transform: "rotate(-180deg)",
                  }}
                />

                <TextStyles.FontSize14px
                  style={{ color: Colors.red, fontWeight: 700 }}
                >
                  {currencySymbol}
                  {`${parseFloat(totalExpenses).toFixed(2)} Expenses`}
                </TextStyles.FontSize14px>
              </div>
            </div>
          </div>

          <div className="col-12  col-lg-6 col-xl-7">
            <div className="d-flex justify-content-center justify-content-xl-end justify-content-lg-end ">
              <TrCharts />
            </div>
          </div>
        </div>
      </TransactionGraphRectangle>
    </div>
  );
}
