import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField, InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import Colors from "../../../../Constants/Colors";
import sizes from "../../../../Constants/sizes";
import TextStyles from "../../../../Constants/TextStyles";

const useStyles = makeStyles({
  root: {
    background: Colors.backgroundPink,
    borderRadius: sizes.formInputBorderRadius,
    height: sizes.formInputHeight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    "& .MuiOutlinedInput-root": {
      padding: "0 2rem 0 3rem !important",
    },

    "& .MuiAutocomplete-input": {
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      color: Colors.primary,
    },
    // "&:hover": {
    //   border: `1px solid ${Colors.primary}`,
    // },

    // "&.Mui-focused": {
    //   border: `1px solid ${Colors.primary}`,
    // },
  },

  popper: {
    minWidth: "15rem !important",
  },
  groupLabel: {
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    color: `${Colors.font2} !important`,
  },
  option: {
    fontFamily: "Poppins",
    fontSize: "0.875rem",
    color: Colors.primary,
    marginBottom: "2px !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
    display: "inherit !important",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
  input: {
    "& input::placeholder": {
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      "-webkit-text-fill-color": Colors.font1,
      color: Colors.font1,
    },
    "& input:disabled": {
      fontFamily: "Poppins",
      fontSize: "0.875rem",
      color: `${Colors.primary} !important`,
      "-webkit-text-fill-color": Colors.primary,
    },
  },
});

const filter = createFilterOptions();

export const AutoCompleteCreateActivity = (props) => {
  const { placeholder, options, onChange, defaultValue, autoSelect, disabled } =
    props;

  const classes = useStyles();

  return (
    <Autocomplete
      classes={{
        popper: classes.popper,
        option: classes.option,
      }}
      className={classes.root}
      options={options && options}
      disabled={disabled}
      value={defaultValue ? defaultValue : null}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option.title
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add ${inputValue}`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoSelect={autoSelect}
      id="free-solo-with-text-demo"
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <AccessTimeIcon
            sx={{ fontSize: 22, color: Colors.primary, marginRight: 2 }}
          />
          {option.title}
        </li>
      )}
      freeSolo
      renderInput={(params) => (
        <TextField
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          {...params}
          classes={{ root: classes.input }}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ position: "absolute", left: "1rem" }}
              >
                <AccessTimeIcon sx={{ fontSize: 24, color: Colors.primary }} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const AutoCompleteSelectProject = (props) => {
  const { placeholder, options, onChange, defaultValue, disabled } = props;
  const classes = useStyles();

  return (
    <>
      <Autocomplete
        classes={{
          popper: classes.popper,
          option: classes.option,
          groupLabel: classes.groupLabel,
        }}
        className={classes.root}
        id="grouped-demo"
        options={
          options &&
          options
            .filter((d) => d.client_id)
            .sort(
              (a, b) =>
                b.client_id && -b.client_name.localeCompare(a.client_name)
            )
        }
        disabled={disabled}
        value={defaultValue ? defaultValue : null}
        groupBy={(option) =>
          option.client_name ? option.client_name : "Deleted Client"
        }
        getOptionLabel={(option) => option.title}
        renderOption={(props, option) => (
          <li {...props}>
            <CreateNewFolderOutlinedIcon
              sx={{ fontSize: 24, color: Colors.primary, marginRight: 2 }}
            />
            {option.title}
          </li>
        )}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          value === null ||
          option._id === value._id
        }
        onChange={(event, value) => {
          onChange(value);
        }}
        forcePopupIcon={false}
        renderInput={(params) => {
          return (
            <TextField
              sx={{
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              }}
              {...params}
              classes={{ root: classes.input }}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ position: "absolute", left: "1rem" }}
                  >
                    <CreateNewFolderOutlinedIcon
                      sx={{
                        fontSize: 26,
                        color: Colors.primary,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
      <div
        className="w-100"
        style={{ display: "inline-grid", visibility: "visible", minWidth: 80 }}
      >
        {defaultValue && defaultValue.client_name && (
          <TextStyles.FontSize14px
            style={{
              width: "100%",
              maxWidth: "100%",
              minWidth: "80px",
              padding: "0 1rem",
              display: "flex",
              alignItems: "center",
              background: Colors.backgroundPink,
              color: Colors.primary,
              height: sizes.formInputHeight,
              borderRadius: sizes.formInputBorderRadius,
            }}
          >
            <FolderSharedIcon
              sx={{
                color: Colors.primary,
                fontSize: 24,
                marginRight: 1,
              }}
            />
            <span className="three-dots">{defaultValue.client_name}</span>
          </TextStyles.FontSize14px>
        )}
      </div>
    </>
  );
};
