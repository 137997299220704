import styled from "styled-components";
import Colors from "../../../Constants/Colors";

const SettingRectangle = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 3.25rem;
  border-radius: 0.75rem;
  background-color: ${Colors.white};
  width: 13.75rem;
  box-sizing: content-box;
  height: auto;
  box-shadow: 0 12px 32px rgba(78 153 222 / 16%);
  padding: 1.5rem;
  z-index: 50;
  &::before {
    content: "";
    position: absolute;
    top: -1.25rem;
    right: 1rem;
    border: 10px solid;
    border-color: transparent transparent ${Colors.white} transparent;
  }
`;

const DropdownSpan = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover img {
    filter: brightness(0.95);
  }
`;

const DropdownList = styled.ul`
  font-family: Poppins;
  font-size: 0.875rem;
  color: ${Colors.font1};
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const CreateRectangle = styled.div`
  display: none;
  position: absolute;
  right: -9.375rem;
  top: 3.125rem;
  border-radius: 0.75rem;
  background-color: ${Colors.white};
  width: 15.625rem;
  height: auto;
  box-shadow: 0 12px 32px rgba(103, 142, 178, 0.16);
  padding: 1.5rem 1rem;
  z-index: 50;

  li {
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: 500;
    font-family: Poppins, sans-serif;
    width: 100%;
    cursor: pointer;
    height: 3.125rem;
    padding: 0 0.75rem;
    border-radius: 9px;
    transition: ease-in-out 250ms all;
  }

  li:hover {
    color: ${Colors.primary} !important;
    background-color: ${Colors.backgroundPink};
    padding-left: 40px;
  }

  li:hover svg {
    filter: invert(40%) sepia(52%) saturate(1786%) hue-rotate(277deg)
      brightness(50%) contrast(84%) !important;
  }

  &::before {
    content: "";
    position: absolute;
    top: -1rem;
    right: 12.5rem;
    border: 10px solid;
    border-color: transparent transparent ${Colors.white} transparent;
  }
`;

const CreateBox = styled.div`
  border-radius: 0.75rem;
  background-color: ${Colors.primary};
  height: 3.15rem;
  padding: 0 20px 0 12px;
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }

  &:hover .createBox {
    display: block;
  }
`;

const NotificationElement = styled.div`
  font-family: Poppins;
  font-size: 0.875rem;
  color: ${Colors.font1};
  padding: 1rem 1.25rem;
  background-clip: border-box;
  box-sizing: border-box;

  a {
    color: ${Colors.primary};
    font-weight: 500;
  }
`;

export default {
  SettingRectangle,
  DropdownSpan,
  DropdownList,
  CreateRectangle,
  CreateBox,
  NotificationElement,
};
