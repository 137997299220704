import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const SliderBox = styled.div`
  width: fit-content;
  display: flex;
  height: 3.15rem;
  border-radius: 0.75rem;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  background-color: ${Colors.white};
`;

const SelectedItem = styled.div`
  height: 3.15rem;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  border-radius: 0.75rem;
  cursor: pointer;
  font-family: Poppins;
  font-size: 0.875rem;
  background-color: ${Colors.primary};
  color: ${Colors.white};

  &:hover {
    background-color: ${Colors.hoverButtonColor};
  }

  &:active {
    background-color: ${Colors.primary};
  }
`;

const NotSelectedItem = styled(SelectedItem)`
  color: ${Colors.font2} !important;
  background-color: ${Colors.white} !important;

  &:hover {
    background-color: ${Colors.backgroundPink} !important;
  }
`;

export default {
  SliderBox,
  SelectedItem,
  NotSelectedItem,
};
