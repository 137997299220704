import React from "react";
import { Redirect } from "react-router";

export default function LaunchToEditProject({ location }) {
  const redirectTo = `${location.pathname}/terms`;
  return (
    <div>
      <Redirect to={redirectTo} />
    </div>
  );
}
