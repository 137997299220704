import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const Confidential = (props) => {
  const selectedData = props && props.selectedData;
  const heading =
    selectedData &&
    selectedData.confidential &&
    selectedData.confidential.heading
      ? selectedData &&
        selectedData.confidential &&
        selectedData.confidential.heading
      : "Confidentiality & Non-Disclosure";
  const paragraph =
    selectedData &&
    selectedData.confidential &&
    selectedData.confidential.paragraph
      ? selectedData &&
        selectedData.confidential &&
        selectedData.confidential.paragraph
      : `Both parties shall maintain strict confidentiality of all Confidential Information (as defined in the subsequent sentence) shared by either party under this Agreement. "Confidential Information" includes non-public information marked as confidential by the disclosing party and any information that should reasonably be considered confidential in the context of disclosure, whether or not marked as "Confidential". If a party is legally required to disclose Confidential Information due to a judicial or governmental order, it shall promptly notify the other party to explore alternative legal remedies for maintaining confidentiality. Each party receiving Confidential Information is responsible for any breach caused by its employees, affiliates, representatives, or agents. The receiving party agrees to indemnify and hold the disclosing party harmless from any liabilities, claims, damages, losses, costs, and expenses resulting from such a breach. The obligations under this section shall remain in effect for three years following the termination of this Agreement.
`;

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.confidential &&
          selectedData.confidential.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>{paragraph}</TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default Confidential;
