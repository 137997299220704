import React from "react";
import { CSVLink } from "react-csv";
import Colors from "../../../../Constants/Colors";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const ReportsCSV = ({ data }) => {
  return (
    <div className="d-flex">
      <CSVLink data={data} filename={"timetracking.csv"} target="_blank">
        <div
          style={{
            width: 40,
            height: 40,
            background: Colors.primary,
            borderRadius: "50%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FileDownloadOutlinedIcon sx={{ color: Colors.white }} />
        </div>
      </CSVLink>
    </div>
  );
};

export default ReportsCSV;
