import React, { useState } from "react";
import { useSelector } from "react-redux";

import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./PageOne";

import PCEditClientInfo from "../../ProposalReviews/PCEditClientInfo";
import PCEditBusinessInfo from "../../ProposalReviews/PCEditBusinessInfo";
import Colors from "../../../../../Constants/Colors";

const Client = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const data = useSelector((state) => state.auth.data);
  const businessInfoData = useSelector((state) => state.auth.businessInfoData);

  const [showClientModal, setShowClientModal] = useState(false);
  const [showBusinessModal, setShowBusinessModal] = useState(false);

  const primaryContactData =
    selectedData &&
    selectedData.client_id &&
    selectedData.client_id.contacts &&
    selectedData.client_id.contacts.filter(
      (d) => d.email === (selectedData && selectedData.client_email)
    );
  let primaryContact;
  if (primaryContactData.length === 1) {
    primaryContact = primaryContactData && primaryContactData[0];
  } else {
    primaryContact =
      selectedData &&
      selectedData.client_id &&
      selectedData.client_id.contacts &&
      selectedData.client_id.contacts[0];
  }

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6">
          <Styled.ReviewContentBox style={{ minHeight: 210 }} className="mt-4">
            <Styled.EditButtonToolTip
              className="contract_review_edit_tp"
              onClick={() => setShowClientModal(true)}
            >
              <BorderColorOutlinedIcon
                sx={{ color: Colors.white, fontSize: 20 }}
              />
              <span className="ml-1">Edit</span>
            </Styled.EditButtonToolTip>

            <TextStyles.FontSize20px>Client</TextStyles.FontSize20px>
            <TextStyles.FontSize18px className="d-flex justify-content-start mt-1">
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.company_name}
            </TextStyles.FontSize18px>
            <TextStyles.FontSize14px className="d-flex justify-content-start">
              {primaryContact.contact_name}
            </TextStyles.FontSize14px>
            <TextStyles.GreyFont14px className="d-flex justify-content-start">
              {primaryContact.email}
            </TextStyles.GreyFont14px>
            <TextStyles.GreyFont14px className="d-flex justify-content-start">
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.street}
              {selectedData &&
              selectedData.client_id &&
              selectedData.client_id.suite
                ? ", "
                : ""}
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.suite}
              {selectedData &&
              selectedData.client_id &&
              selectedData.client_id.city
                ? ", "
                : ""}
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.city}
              {selectedData &&
              selectedData.client_id &&
              selectedData.client_id.state
                ? ", "
                : ""}
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.state}
              {selectedData &&
              selectedData.client_id &&
              selectedData.client_id.country
                ? ", "
                : ""}
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.country}
              {selectedData &&
              selectedData.client_id &&
              selectedData.client_id.zip_code
                ? ", "
                : ""}
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.zip_code}
              {selectedData &&
              selectedData.client_id &&
              selectedData.client_id.tax_id_label
                ? ", "
                : ""}
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.tax_id_label}
              {selectedData &&
              selectedData.client_id &&
              selectedData.client_id.tax_id_number
                ? ", "
                : ""}
              {selectedData &&
                selectedData.client_id &&
                selectedData.client_id.tax_id_number}
            </TextStyles.GreyFont14px>
          </Styled.ReviewContentBox>
        </div>
        <div className="col-12 col-md-6">
          <Styled.ReviewContentBox style={{ minHeight: 210 }} className="mt-4">
            <div className="d-flex justify-content-center">
              <Styled.EditButtonToolTip
                className="contract_review_edit_tp"
                onClick={() => setShowBusinessModal(true)}
              >
                <BorderColorOutlinedIcon
                  sx={{ color: Colors.white, fontSize: 20 }}
                />
                <span className="ml-1">Edit</span>
              </Styled.EditButtonToolTip>
            </div>

            <TextStyles.FontSize20px>Contractor</TextStyles.FontSize20px>
            <TextStyles.FontSize18px className="d-flex justify-content-start mt-1">
              {businessInfoData && businessInfoData.business_name
                ? businessInfoData.business_name
                : `${data.firstName} ${data.lastName}`}
            </TextStyles.FontSize18px>
            <TextStyles.GreyFont14px className="d-flex justify-content-start">
              {data && data.email}
            </TextStyles.GreyFont14px>
            <TextStyles.GreyFont14px className="d-flex justify-content-start">
              {businessInfoData && businessInfoData.street}
              {businessInfoData && businessInfoData.suite ? ", " : ""}
              {businessInfoData && businessInfoData.suite}
              {businessInfoData && businessInfoData.city ? ", " : ""}
              {businessInfoData && businessInfoData.city}
              {businessInfoData && businessInfoData.state ? ", " : ""}
              {businessInfoData && businessInfoData.state}
              {businessInfoData && businessInfoData.country ? ", " : ""}
              {businessInfoData && businessInfoData.country}
              {businessInfoData && businessInfoData.zip_code ? ", " : ""}
              {businessInfoData && businessInfoData.zip_code}
              {businessInfoData && businessInfoData.tax_id_label ? ", " : ""}
              {businessInfoData && businessInfoData.tax_id_label}
              {businessInfoData && businessInfoData.tax_id_number ? ", " : ""}
              {businessInfoData && businessInfoData.tax_id_number}
            </TextStyles.GreyFont14px>
          </Styled.ReviewContentBox>
        </div>
      </div>
      {showClientModal && (
        <PCEditClientInfo
          selectedData={selectedData}
          show={showClientModal}
          handleClose={() => setShowClientModal(false)}
        />
      )}
      {showBusinessModal && (
        <PCEditBusinessInfo
          show={showBusinessModal}
          handleClose={() => setShowBusinessModal(false)}
          data={data}
          businessInfoData={businessInfoData}
        />
      )}
    </div>
  );
};

export default Client;
