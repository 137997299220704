import React from "react";
import Acceptance from "./Components/Acceptance.jsx";
import Warranty from "./Components/Warranty";
import Confidential from "./Components/Confidential";
import Compensation from "./Components/Compensation.jsx";
import Responsibility from "./Components/Responsiblity.jsx";

const PageThree = (props) => {
  const selectedData = props && props.selectedData;

  return (
    <div>
      <Acceptance selectedData={selectedData} />
      <Responsibility selectedData={selectedData} />
      <Compensation selectedData={selectedData} />
      <Warranty selectedData={selectedData} />
      <Confidential selectedData={selectedData} />
    </div>
  );
};

export default PageThree;
