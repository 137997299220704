import React, { useEffect, useState, useRef } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { HexColorPicker } from "react-colorful";
import TextStyles from "../../../Constants/TextStyles";
import Colors from "../../../Constants/Colors";

const CustomColorPicker = ({ handleChange, defaultValues }) => {
  const [selected, setSelected] = useState(false);
  const [color, setColor] = useState(
    defaultValues ? defaultValues : Colors.primary
  );
  const elementRef = useRef(null);
  const handleClickOutside = (event) => {
    if (elementRef.current && !elementRef.current.contains(event.target)) {
      setSelected(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const colors = [
    Colors.primary,
    "#f5222d",
    "#f16e20",
    "#13a8a8",
    "#1677ff",
    "#000000",
    "#74767e",
    "#3ad0e6",
    "#21ce86",
  ];

  return (
    <TextStyles.FontSize16px className="d-flex flex-wrap gap-3">
      <div className="d-flex flex-column gap-3">
        <div className="d-flex">
          <input
            type="text"
            value={color || Colors.primary}
            // pattern="[/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/]"
            onChange={(e) => {
              setColor(e.target.value);
              handleChange(e.target.value);
            }}
            style={{
              width: 110,
              height: 48,
              borderRadius: "12px 0 0 12px",
              background: "transparent",
              textAlign: "center",
              border: `1px solid ${Colors.font2}`,
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: 80,
              height: 48,
              borderRadius: "0 12px 12px 0",
              background: "transparent",
              border: `1px solid ${Colors.font2}`,
            }}
          >
            <div
              htmlFor="color"
              style={{
                width: 75,
                height: 48,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setSelected(!selected)}
            >
              <span
                style={{
                  display: "flex",
                  width: 36,
                  height: 36,
                  borderRadius: "50%",
                  background: color || Colors.primary,
                }}
              />

              <span>
                <KeyboardArrowDownIcon sx={{ fontSize: 30 }} />
              </span>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-wrap gap-2 justify-content-around"
          style={{ width: "100%", maxWidth: 200 }}
        >
          {colors.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setColor(item);
                  handleChange(item);
                }}
                style={{
                  width: 45,
                  height: 45,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: color === item ? `3px solid ${item}` : "",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    width: 36,
                    height: 36,
                    borderRadius: "50%",
                    background: item,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      {selected && (
        <div ref={elementRef}>
          <HexColorPicker
            color={color}
            onChange={(color) => {
              setColor(color);
              handleChange(color);
            }}
          />
        </div>
      )}
    </TextStyles.FontSize16px>
  );
};

export default CustomColorPicker;
