import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";

import Dashboard from "./Dashboard";
import InvoiceSetup from "./InvoiceSetup/InvoiceSetup";
import CreateProject from "./ANewProjects/NewProject";
import ReviewInvoices from "../ReviewInvoices/ReviewInvoices";
import Send from "../Send/Send";
import ChooseClient from "./QuickInvoices/ChooseClient";
import QuickSetup from "./QuickInvoices/QuickSetup";

import LoaderSpin from "../../Commons/LoaderSpin";

import Styled from "./CreateInvoiceStyles";
import TextStyles from "../../../../Constants/TextStyles";
import { BackButton } from "../../Commons/BackAndForwardButton";
import Colors from "../../../../Constants/Colors";
import CreateClient from "./QuickInvoices/CreateClient";

const CreateInvoices = ({ location }) => {
  const exitingProjectData = location && location.state && location.state.data;
  const reminder_id = location && location.state && location.state.reminder_id;
  const isLoading = useSelector((state) => state.invoices.isLoading);
  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const history = useHistory();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(
    exitingProjectData ? "eProjectInvoice" : null
  );
  const [next, setNext] = useState(false);
  const [selectedExistProject, setSelectedExistProject] = useState(null);
  const [enteredProjectName, setEnteredProjectName] = useState(null);
  const [selectedCreatedProject, setSelectedCreatedProject] = useState(null);
  const [chooseQuickClient, setChooseQuickClient] = useState(null);
  const [createQuickClient, setCreateQuickClient] = useState(false);
  const [selectedQuickInvoice, setSelectedQuickInvoice] = useState(null);

  useEffect(() => {
    const exitingProject =
      exitingProjectData &&
      projectOptionsData &&
      projectOptionsData
        .filter((data) => data._id === location.state.data._id)
        .map((d) => {
          return {
            _id: d._id ? d._id : null,
            title: d && d.project_name ? d.project_name : null,
            client_id:
              d && d.client_id && d.client_id._id ? d.client_id._id : null,
            client_name:
              d && d.client_id && d.client_id.company_name
                ? d.client_id.company_name
                : null,
          };
        })[0];
    setSelectedExistProject(exitingProject);
  }, [exitingProjectData]);

  const id = selectedExistProject && selectedExistProject._id;

  const selectedProject =
    projectOptionsData &&
    projectOptionsData.filter((data) => data._id === id)[0];

  const pageDisplay = () => {
    if (page === 0) {
      if (next === false)
        return (
          <Dashboard
            selected={selected}
            setSelected={setSelected}
            setNext={setNext}
            selectedExistProject={selectedExistProject}
            setSelectedExistProject={setSelectedExistProject}
            enteredProjectName={enteredProjectName}
            setEnteredProjectName={setEnteredProjectName}
            backBtn={
              <div
                style={{ position: "fixed", left: 20, top: "50vh" }}
                onClick={() => history.goBack()}
              >
                <BackButton />
              </div>
            }
          />
        );
      if (selected === "eProjectInvoice" && next === true) {
        return (
          <InvoiceSetup
            reminder_id={reminder_id}
            page={page}
            setPage={setPage}
            selectedProject={selectedProject}
            backBtn={
              <div
                style={{ position: "fixed", left: 20, top: "50vh" }}
                onClick={() => {
                  setNext(false);
                  setSelected(null);
                }}
              >
                <BackButton />
              </div>
            }
            submitBtn={
              <Styled.SubmitButton disabled={isLoading ? true : false}>
                {isLoading ? <LoaderSpin /> : "Save"}
              </Styled.SubmitButton>
            }
          />
        );
      }
      if (selected === "cProjectInvoice" && next === true) {
        if (selectedCreatedProject) {
          return (
            <InvoiceSetup
              page={page}
              setPage={setPage}
              selectedProject={selectedCreatedProject}
              backBtn={
                <div
                  style={{ position: "fixed", left: 20, top: "50vh" }}
                  onClick={() => {
                    setNext(false);
                    setSelected(null);
                  }}
                >
                  <BackButton />
                </div>
              }
              submitBtn={
                <Styled.SubmitButton disabled={isLoading ? true : false}>
                  {isLoading ? <LoaderSpin /> : "Save"}
                </Styled.SubmitButton>
              }
            />
          );
        } else {
          return (
            <CreateProject
              setNext={setNext}
              setSelected={setSelected}
              enteredProjectName={enteredProjectName}
              setSelectedCreatedProject={setSelectedCreatedProject}
              backBtn={
                <div
                  style={{ position: "fixed", left: 20, top: "50vh" }}
                  onClick={() => {
                    setNext(false);
                    setSelected(null);
                  }}
                >
                  <BackButton />
                </div>
              }
            />
          );
        }
      }
      if (selected === "quickInvoice" && next === true) {
        if (chooseQuickClient === null && createQuickClient === true) {
          return (
            <CreateClient
              setNext={setNext}
              setCreateQuickClient={setCreateQuickClient}
              setChooseQuickClient={setChooseQuickClient}
              backBtn={
                <div
                  style={{ position: "fixed", left: 20, top: "50vh" }}
                  onClick={() => {
                    setNext(true);
                    setCreateQuickClient(false);
                    setSelected("quickInvoice");
                    setSelectedQuickInvoice(null);
                  }}
                >
                  <BackButton />
                </div>
              }
            />
          );
        } else if (chooseQuickClient !== null && createQuickClient === false) {
          return (
            <QuickSetup
              page={page}
              setPage={setPage}
              client_id={chooseQuickClient && chooseQuickClient._id}
              backBtn={
                <div
                  style={{ position: "fixed", left: 20, top: "50vh" }}
                  onClick={() => {
                    setNext(true);
                    setChooseQuickClient(null);
                    setCreateQuickClient(false);
                    setSelected("quickInvoice");
                    setSelectedQuickInvoice(null);
                  }}
                >
                  <BackButton />
                </div>
              }
              submitBtn={
                <Styled.SubmitButton disabled={isLoading ? true : false}>
                  {isLoading ? <LoaderSpin /> : "Save"}
                </Styled.SubmitButton>
              }
            />
          );
        } else {
          return (
            <ChooseClient
              setChooseQuickClient={setChooseQuickClient}
              setCreateQuickClient={setCreateQuickClient}
              selectedQuickInvoice={selectedQuickInvoice}
              setSelectedQuickInvoice={setSelectedQuickInvoice}
              backBtn={
                <div
                  style={{ position: "fixed", left: 20, top: "50vh" }}
                  onClick={() => {
                    setNext(false);
                    setSelected(null);
                  }}
                >
                  <BackButton />
                </div>
              }
            />
          );
        }
      }
    } else if (page === 1) {
      return (
        <ReviewInvoices
          submitBtn={
            <Styled.SubmitButton onClick={() => setPage(page + 1)}>
              Next
            </Styled.SubmitButton>
          }
        />
      );
    } else if (page === 2) {
      return (
        <Send
          backBtn={
            <div
              style={{ position: "fixed", left: 20, top: "50vh" }}
              onClick={() => setPage(page - 1)}
            >
              <BackButton />
            </div>
          }
        />
      );
    }
  };

  return (
    <div
      style={{
        background: Colors.backgroundGrey,
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          background: Colors.white,
          borderBottom: "2px solid #dfe1e9",
          zIndex: 200,
        }}
        className="fixed-top"
      >
        <div className="p-2">
          <TextStyles.FontSize14px
            style={{
              position: "absolute",
              left: "0.75rem",
              top: "0.75rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => history.push("/db/invoices")}
            >
              <ClearIcon
                sx={{
                  fontSize: 27,
                  color: Colors.font2,
                  cursor: "pointer",
                  opacity: 0.7,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize20px
            style={{
              textAlign: "center",
              fontWeight: 500,
              fontSize: 24,
            }}
          >
            Create invoice
          </TextStyles.FontSize20px>
        </div>

        <div className="d-flex">
          <Styled.ProgressBar>
            <div
              className="col"
              style={{
                height: "100%",
                paddingTop: "3px",
                backgroundColor: Colors.primary,
                color: Colors.white,
                justifyContent: "center",
                display: "flex",
              }}
            >
              Setup
            </div>
            <div
              className="col"
              style={
                page > 0
                  ? {
                      height: "100%",
                      paddingTop: "3px",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                    }
                  : { justifyContent: "center", display: "flex" }
              }
            >
              Review
            </div>
            <div
              className="col"
              style={
                page > 1
                  ? {
                      height: "100%",
                      paddingTop: "3px",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                    }
                  : { justifyContent: "center", display: "flex" }
              }
            >
              Send
            </div>
          </Styled.ProgressBar>
        </div>
      </div>
      <div style={{ paddingTop: 100 }}>{pageDisplay()}</div>
    </div>
  );
};

export default CreateInvoices;
