import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { emailSendInvoice } from "../../../../store/service/invoiceService";

import LoaderSpin from "../../Commons/LoaderSpin";

import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import ControlPointSharpIcon from "@mui/icons-material/ControlPointSharp";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import EmailPreview from "./EmailPreview";
import DoneModal from "./DoneModal";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import Styled from "./SendStyles";
import { ReactTooltip } from "../../Commons/ReactTooltip";

import { Popover } from "antd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddTemplateModal from "./AddTemplateModal";
import AddContactModal from "./AddContactModal";

const Send = ({ backBtn }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.invoices.isLoading);
  const selectedInvoice = useSelector(
    (state) => state.invoices.selectedInvoice
  );

  const data = useSelector((state) => state.auth.data);
  const templateData = useSelector((state) => state.auth.templateData);

  const businessInfoData = useSelector((state) => state.auth.businessInfoData);
  const brandAndLogoData = useSelector((state) => state.auth.brandAndLogoData);

  const primaryContactName =
    selectedInvoice &&
    selectedInvoice.client_id &&
    selectedInvoice.client_id.contacts &&
    selectedInvoice.client_id.contacts[0];

  const [addContactModal, setAddContactModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [contact, setContact] = useState(
    selectedInvoice && selectedInvoice.client_id
      ? selectedInvoice.client_id.contacts
      : []
  );

  const invoice_services_array = selectedInvoice.invoice_services;
  const line_item_services_array = selectedInvoice.line_item_services;
  const taxes_array = selectedInvoice.taxes;

  // template
  const [openTemplate, setOpenTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("Default");
  const [addTemplateModal, setAddTemplateModal] = useState(false);

  const hideTemplateHandler = () => {
    setOpenTemplate(false);
  };
  const handleOpenTemplateChange = (newOpen) => {
    setOpenTemplate(newOpen);
  };

  const totalInvoiceServiceAmount = () => {
    let sum = 0;
    invoice_services_array.forEach((d) => {
      if (d.service_rate !== "") {
        sum += parseFloat(d.service_rate) * parseFloat(d.service_rate_count);
      }
    });
    return sum;
  };

  const totalLineItemServiceAmount = () => {
    let sum = 0;
    line_item_services_array.forEach((d) => {
      if (d.service_rate !== "") {
        sum += parseFloat(d.service_rate) * parseFloat(d.service_rate_count);
      }
    });
    return sum;
  };

  const totalServiceAmount =
    totalInvoiceServiceAmount() + totalLineItemServiceAmount();

  const invoiceDiscount = () => {
    let sum = 0;
    if (selectedInvoice && selectedInvoice.discount) {
      if (selectedInvoice.discount.percentage) {
        sum = parseFloat(
          totalServiceAmount *
            parseFloat(selectedInvoice.discount.percentage) *
            0.01
        );
      } else {
        sum = selectedInvoice.discount.value;
      }
    }
    return sum;
  };

  const totalServiceAmountAfterDiscount =
    parseFloat(totalServiceAmount) - parseFloat(invoiceDiscount());

  const totalTaxesAmount = () => {
    let sum = 0;
    taxes_array.forEach((d) => {
      if (d.percentage !== "") {
        sum +=
          (parseFloat(d.percentage) *
            parseFloat(totalServiceAmountAfterDiscount)) /
          100;
      }
    });
    return sum;
  };

  let totalDueAmount = totalServiceAmountAfterDiscount + totalTaxesAmount();

  const removeContact = (data) => {
    const filteredContact = contact.filter((d) => d.email !== data.email);
    setContact(filteredContact);
  };

  const subjectData = `${selectedInvoice.invoice_title} from ${
    businessInfoData && businessInfoData.business_name
      ? businessInfoData.business_name
      : `${data.firstName} ${data.lastName}`
  }`;

  const [subject, setSubject] = useState(subjectData);

  const textData = `Hi ${
    primaryContactName ? primaryContactName.contact_name : ""
  },\n\nI hope you're doing well. Attached, please find the invoice for our recent work together.\n\nIf you have any questions or concerns, feel free to let me know.\n\nThank you for your continued partnership!\n\nUntil next time,\n${
    businessInfoData && businessInfoData.business_name
      ? businessInfoData.business_name
      : `${data.firstName} ${data.lastName}`
  }`;

  const [text, setText] = useState(textData);

  const { register, reset, watch, handleSubmit } = useForm({
    defaultValues: {
      email_subject: subjectData,
      email_text: textData,
    },
  });

  const revisedSubject = watch("email_subject");
  const revisedText = watch("email_text");

  useEffect(() => {
    setSubject(revisedSubject);
  }, [revisedSubject]);

  useEffect(() => {
    setText(revisedText);
  }, [revisedText]);

  useEffect(() => {
    if (selectedTemplate !== "Default") {
      const filteredTemplate =
        templateData &&
        templateData.filter(
          (item) => item.template_name === selectedTemplate
        )[0];

      setSubject(filteredTemplate.template_subject);
      setText(filteredTemplate.template_message);
      reset({
        email_subject: filteredTemplate.template_subject,
        email_text: filteredTemplate.template_message,
      });
    } else {
      setSubject(subjectData);
      setText(textData);
      reset({
        email_subject: subjectData,
        email_text: textData,
      });
    }
  }, [selectedTemplate]);

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedInvoice._id,
      email_subject: item.email_subject,
      email_text: item.email_text,
      email_template: item.email_template,
      emails_sent_to: contact.map((d) => {
        return { email: d.email };
      }),
      status: "Sent",
      invoiced_amount: totalDueAmount,
      total_tax: totalTaxesAmount(),
    };
    dispatch(emailSendInvoice(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setShowDoneModal(true);
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "emailSendInvoiceFail" }
        );
      }
    });
  };

  const templateContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.TemplateLi
          onClick={() => {
            setSelectedTemplate("Default");
            hideTemplateHandler();
          }}
        >
          Default
        </Styled.TemplateLi>
        {templateData &&
          templateData.map((item, index) => {
            return (
              <Styled.TemplateLi
                key={index}
                onClick={() => {
                  setSelectedTemplate(item.template_name);
                  hideTemplateHandler();
                }}
                style={{ minWidth: "4rem" }}
              >
                <span className="three-dots"> {item.template_name}</span>
              </Styled.TemplateLi>
            );
          })}

        <Styled.TemplateLi
          className="d-flex mt-2"
          onClick={() => {
            setAddTemplateModal(true);
            hideTemplateHandler();
          }}
        >
          <AddCircleIcon sx={{ fontSize: 16, color: Colors.primary }} />
          <span
            className="ml-1"
            style={{
              color: Colors.primary,
              fontWeight: 500,
              textDecoration: "1px solid underline",
            }}
          >
            New template
          </span>
        </Styled.TemplateLi>
      </TextStyles.FontSize14px>
    );
  };

  return (
    <div className="px-lg-4 px-md-4 px-sm-3 px-2">
      <div
        className="d-flex justify-content-center w-100"
        style={{ contain: "content" }}
      >
        <div style={{ width: "100%", maxWidth: 650 }}>
          <TextStyles.FontSize20px className="d-flex justify-content-center mb-3">
            Compose email
          </TextStyles.FontSize20px>
          <Styled.SendBox>
            <TextStyles.FontSize14px className="mb-2">
              To
            </TextStyles.FontSize14px>

            <div className="d-flex gap-2 justify-content-between align-items-center">
              <Styled.ChipsRectangle>
                {contact &&
                  contact.map((item, index) => {
                    return (
                      <div key={index}>
                        <ReactTooltip
                          anchorId={`contact${index}`}
                          content={item.email}
                          variant={"brandColor"}
                          color={Colors.white}
                        />
                        <Styled.ChipRectangle id={`contact${index}`}>
                          <TextStyles.FontSize14px
                            className="d-inline-block mr-2"
                            style={{ color: Colors.primary }}
                          >
                            {item.contact_name}
                          </TextStyles.FontSize14px>
                          <CancelSharpIcon
                            onClick={() => removeContact(item)}
                            sx={{
                              fontSize: 16,
                              color: Colors.primary,
                              cursor: "pointer",
                            }}
                          />
                        </Styled.ChipRectangle>
                      </div>
                    );
                  })}
              </Styled.ChipsRectangle>
              <ControlPointSharpIcon
                onClick={() => setAddContactModal(true)}
                sx={{ color: Colors.font1, fontSize: 24, cursor: "pointer" }}
              />
            </div>
          </Styled.SendBox>
          <form onSubmit={handleSubmit(formsubmit)}>
            <Styled.SendBox className="my-4">
              <div className="d-flex flex-wrap flex-sm-nowrap gap-3 justify-content-between">
                <TextStyles.FontSize14px className="w-100">
                  <TextStyles.InputRectangle
                    placeholder="Subject"
                    // defaultValue={subject}
                    name="email_subject"
                    {...register(`email_subject`, {
                      required: "Subject can't be empty.",
                    })}
                  />
                </TextStyles.FontSize14px>
                <div
                  className="w-100"
                  style={{ maxWidth: "7.5rem", marginLeft: "auto" }}
                >
                  <Styled.Template>
                    <Popover
                      placement="bottomRight"
                      zIndexPopup={150}
                      // title={"Hello"}
                      content={templateContent}
                      trigger="click"
                      open={openTemplate}
                      onOpenChange={handleOpenTemplateChange}
                      overlayClassName="email-template-popover"
                    >
                      <TextStyles.FontSize14px
                        style={{
                          cursor: "pointer",
                          color: Colors.primary,
                          fontWeight: 500,
                          width: "100%",
                          height: "100%",
                          padding: "0 15px 0 20px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="three-dots"
                          style={{
                            width: "6rem",
                          }}
                        >
                          {selectedTemplate}
                        </div>
                        <ArrowDropDownOutlinedIcon
                          sx={{
                            color: Colors.primary,
                            fontSize: 27,
                            transform: openTemplate ? "rotate(180deg)" : "",
                          }}
                        />
                      </TextStyles.FontSize14px>
                    </Popover>
                  </Styled.Template>
                </div>
              </div>
              <TextStyles.FontSize14px className="mt-4">
                <TextStyles.Textarea
                  className="textarea_padding_right"
                  rows={8}
                  placeholder="Add Text..."
                  name="email_text"
                  {...register(`email_text`, {
                    required: "Email text can't be empty.",
                  })}
                />
              </TextStyles.FontSize14px>
            </Styled.SendBox>
            <div className="pb-3">
              <div className="d-flex gap-3 justify-content-between">
                <TextStyles.CancelButton
                  onClick={() => setShowPreviewModal(true)}
                >
                  Preview email
                </TextStyles.CancelButton>
                <TextStyles.Button
                  disabled={contact.length === 0 || isLoading ? true : false}
                >
                  {isLoading && addContactModal === false ? (
                    <LoaderSpin />
                  ) : (
                    "Send"
                  )}
                </TextStyles.Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {backBtn}

      {showPreviewModal && (
        <EmailPreview
          show={showPreviewModal}
          handleClose={() => setShowPreviewModal(false)}
          data={data}
          businessInfoData={businessInfoData}
          selectedInvoiceData={selectedInvoice}
          subject={subject}
          text={text}
          totalDueAmount={totalDueAmount}
          brandAndLogoData={brandAndLogoData}
        />
      )}

      {addTemplateModal && (
        <AddTemplateModal
          show={addTemplateModal}
          handleClose={() => setAddTemplateModal(false)}
        />
      )}
      {addContactModal && (
        <AddContactModal
          contact={contact}
          setContact={setContact}
          show={addContactModal}
          handleClose={() => setAddContactModal(false)}
        />
      )}

      {showDoneModal && (
        <DoneModal
          show={showDoneModal}
          handleClose={() => setShowDoneModal(false)}
          data={data}
          selectedInvoiceData={selectedInvoice}
        />
      )}
    </div>
  );
};

export default Send;
