import React, { Component } from "react";
import "./SideDrawer.css";
import Backdrop from "./Backdrop/Backdrop";
import NavBarMobile from "./NavBarMobile/NavBarMobile";
import Auxillary from "../../../HOC/Auxillary/Auxillary";

class SideDrawer extends Component {
  state = { open: this.props.open };

  handleSidebar = () => {
    this.setState({ open: !this.props.open });
  };
  render() {
    let sideClasses = ["SideDrawer", "Close"];
    if (this.props.open) {
      sideClasses = ["SideDrawer", "Open"];
    }

    return (
      <Auxillary>
        <Backdrop clicked={this.props.close} show={this.props.open} />
        <div className={sideClasses.join(" ")}>
          <NavBarMobile close={this.props.close} />
        </div>
      </Auxillary>
    );
  }
}

export default SideDrawer;
