import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextStyles from "../../../../../Constants/TextStyles";
import { insertNotes } from "../../../../../store/service/clientService";

export default function Notes() {
  const timeout = useRef();
  const dispatch = useDispatch();
  const { c_data_position } = useSelector((state) => state.clients);

  const updateClientData = (data) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      let obj = {
        notes: data,
        client_id: c_data_position._id,
      };
      dispatch(insertNotes(obj));
    }, 1000);
  };

  return (
    <div>
      <TextStyles.FontSize20px className="py-4" style={{ display: "flex" }}>
        Notes
      </TextStyles.FontSize20px>

      <TextStyles.FontSize14px>
        <TextStyles.Textarea
          rows={15}
          placeholder="Add a note..."
          defaultValue={c_data_position.notes}
          onChange={(e) => {
            updateClientData(e.target.value);
          }}
        />
      </TextStyles.FontSize14px>
    </div>
  );
}
