import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../Assets/assetsnew/backend_logo_with_name.webp";
import loginBg from "../../Assets/frontendImages/loginBg.svg";

import { postRequest } from "./../../axios/axios";
import URL from "../../axios/constant";
import { toast } from "react-toastify";
import LoaderSpin from "../NewDashboard/Commons/LoaderSpin";
import TextStyles from "../../Constants/TextStyles";
import Styled from "./AuthStyles";
import Colors from "../../Constants/Colors";
import { authActions } from "../../store/storage/authSlice";

const VerifyResetPassword = () => {
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const resetData = useSelector((state) => state.auth.resetData);
  const [resendLoading, setResendLoading] = useState(false);

  const handleChange = (value) => setOTP(value);
  const submitHandler = async () => {
    const Obj = {
      email: resetData.email,
      token: OTP,
    };
    setIsLoading(true);
    postRequest(URL.VERIFY_RESET_PASSWORD.url, {}, Obj)
      .then((data) => {
        if (data && data.data && data.data.success) {
          setIsLoading(false);
          history.push("/db/reset-password");

          toast.success(
            <TextStyles.FontSize14px>
              {data.data.message}
            </TextStyles.FontSize14px>,
            { toastId: "success" }
          );

          setOTP("");
        }
      })
      .catch((err) => {
        setOTP("");
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>,
            { toastId: "fail" }
          );
        }

        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>,
            { toastId: "error" }
          );
        }
      });
  };

  const resendOTP = async () => {
    const obj = {
      email: resetData.email,
    };
    setResendLoading(true);
    postRequest(URL.RESET_PASSWORD.url, {}, obj)
      .then((data) => {
        if (data && data.data && data.data.success) {
          setResendLoading(false);
          dispatch(authActions.resetPassword(data.data.data));

          toast.success(
            <TextStyles.FontSize14px>
              {data.data.message}
            </TextStyles.FontSize14px>,
            { toastId: "success" }
          );
        }
      })
      .catch((err) => {
        setResendLoading(false);

        if (err && err.response && err.response.data) {
          toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>,
            { toastId: "fail" }
          );
        }

        if (err.isAxiosError) {
          toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>,
            { toastId: "fail" }
          );
        }
      });
  };

  return (
    <div style={{ backgroundColor: "#fafbfc" }}>
      <div
        className="fixed-top"
        style={{
          backgroundColor: "#fafbfc",
          width: "100%",
          zIndex: 1000,
          boxShadow: "0 2px 4px 2px rgba(180, 180, 200, 0.2)",
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center px-2 px-sm-3"
          style={{ height: 80, width: "100%" }}
        >
          <TextStyles.FontSize27px
            className="d-flex justify-content-center"
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/")}
          >
            <img src={logo} alt="logo" style={{ maxWidth: 220 }} />
          </TextStyles.FontSize27px>
          <TextStyles.FontSize16px className="d-flex justify-content-end align-items-center w-100">
            <div className="d-none d-sm-flex mr-3">
              Already have an account?
            </div>
            <Styled.LinkSpan
              className="text-center"
              onClick={() => history.push("/db/login")}
            >
              Log in
            </Styled.LinkSpan>
          </TextStyles.FontSize16px>
        </div>
      </div>
      <div style={{ paddingTop: 80 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: `url(${loginBg})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            minHeight: "calc(100vh - 80px)",
            width: "100%",
          }}
        >
          <div className="container-fluid pb-3">
            <div className="d-flex justify-content-center align-items-center">
              <Styled.Box>
                <div className="d-flex justify-content-center mb-4">
                  <TextStyles.FontSize27px style={{ fontWeight: 500 }}>
                    Enter verification code
                  </TextStyles.FontSize27px>
                </div>
                <TextStyles.FontSize16px className="d-flex justify-content-center mb-3">
                  <OtpInput
                    inputStyle={{
                      width: 50,
                      height: 50,
                      marginRight: 5,
                      borderRadius: 3,
                      borderTop: `3px solid ${Colors.font2}`,
                      boxShadow: "0 2px 4px 0 rgb(17 17 17 / 50%)",
                    }}
                    value={OTP}
                    onChange={handleChange}
                    numInputs={6}
                    isInputNum={true}
                    isInputSecure={false}
                    focusStyle={
                      OTP.length < 6
                        ? {
                            borderTop: `3px solid ${Colors.success}`,
                            borderBottom: `2px solid ${Colors.primary}`,
                            borderLeft: `2px solid ${Colors.primary}`,
                            borderRight: `2px solid ${Colors.primary}`,
                          }
                        : ""
                    }
                  />
                </TextStyles.FontSize16px>

                <TextStyles.GreyFont16px className="d-flex justify-content-center mb-3">
                  <button
                    type="submit"
                    onClick={resendOTP}
                    disabled={resendLoading}
                  >
                    <Styled.Link style={{ color: Colors.primary }}>
                      Resend
                    </Styled.Link>
                  </button>
                </TextStyles.GreyFont16px>

                <div className="d-flex justify-content-center">
                  <TextStyles.Button
                    style={{
                      fontSize: 16,
                    }}
                    onClick={submitHandler}
                    disabled={OTP.length < 6}
                  >
                    {isLoading ? <LoaderSpin /> : "Verify"}
                  </TextStyles.Button>
                </div>
              </Styled.Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyResetPassword;
