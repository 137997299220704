import React from "react";
import Slider from "react-slick";
import "./Carousel.css";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import PartnerImg1 from "../../../../Assets/frontendImages/partner-image-01.svg";
import PartnerImg2 from "../../../../Assets/frontendImages/partner-image-02.svg";
import PartnerImg3 from "../../../../Assets/frontendImages/partner-image-03.svg";
import PartnerImg4 from "../../../../Assets/frontendImages/partner-image-04.svg";
import PartnerImg5 from "../../../../Assets/frontendImages/partner-image-05.svg";

const Carousel = () => {
  const settings = {
    focusOnSelect: true,
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    cssEase: "linear",
    className: "reviewLandingPageCarousel",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 992,

        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="feature_container">
        <div
          className="d-flex justify-content-center"
          style={{ maxWidth: 120, height: 50, marginBottom: 10 }}
        >
          <img
            src={PartnerImg1}
            alt="PartnerImg1"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
        <div className="rating_text">4.5/5</div>
        <div>
          <Rating
            name="rating"
            value={4.5}
            precision={0.5}
            readOnly
            emptyIcon={<StarIcon fontSize="inherit" />}
          />
        </div>
      </div>
      <div className="feature_container">
        <div
          className="d-flex justify-content-center"
          style={{ maxWidth: 120, height: 50, marginBottom: 10 }}
        >
          <img
            src={PartnerImg2}
            alt="PartnerImg2"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
        <div className="rating_text">4.3/5</div>
        <div>
          <Rating
            name="rating"
            value={4.5}
            precision={0.1}
            readOnly
            emptyIcon={<StarIcon fontSize="inherit" />}
          />
        </div>
      </div>
      <div className="feature_container">
        <div
          className="d-flex justify-content-center"
          style={{ maxWidth: 120, height: 50, marginBottom: 10 }}
        >
          <img
            src={PartnerImg3}
            alt="PartnerImg3"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
        <div className="rating_text">4.7/5</div>
        <div>
          <Rating
            name="rating"
            value={4.6}
            precision={0.2}
            readOnly
            emptyIcon={<StarIcon fontSize="inherit" />}
          />
        </div>
      </div>
      <div className="feature_container">
        <div
          className="d-flex justify-content-center"
          style={{ maxWidth: 120, height: 50, marginBottom: 10 }}
        >
          <img
            src={PartnerImg4}
            alt="PartnerImg4"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
        <div className="rating_text">4.5/5</div>
        <div>
          <Rating
            name="rating"
            value={4.5}
            precision={0.5}
            readOnly
            emptyIcon={<StarIcon fontSize="inherit" />}
          />
        </div>
      </div>
      <div className="feature_container">
        <div
          className="d-flex justify-content-center"
          style={{ maxWidth: 120, height: 50, marginBottom: 10 }}
        >
          <img
            src={PartnerImg5}
            alt="PartnerImg5"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
        <div className="rating_text">4.6/5</div>
        <div>
          <Rating
            name="rating"
            value={4.6}
            precision={0.2}
            readOnly
            emptyIcon={<StarIcon fontSize="inherit" />}
          />
        </div>
      </div>
    </Slider>
  );
};

export default React.memo(Carousel);
