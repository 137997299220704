import React from "react";
import ClearIcon from "@mui/icons-material/Clear";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import LoaderSpin from "../../Commons/LoaderSpin";
import TextStyles from "../../../../Constants/TextStyles";
import { updateTemplate } from "../../../../store/service/AuthService";
import Colors from "../../../../Constants/Colors";

const EditTemplateModal = ({
  show,
  handleClose,
  updateRefresh,
  setUpdateRefresh,
  selectedData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      template_name: selectedData && selectedData.template_name,
      template_subject: selectedData && selectedData.template_subject,
      template_message: selectedData && selectedData.template_message,
    },
  });

  const formsubmit = (data) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      template_name: data.template_name,
      template_subject: data.template_subject,
      template_message: data.template_message,
    };
    dispatch(updateTemplate(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "fail" }
        );
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
      scrollable={true}
      centered
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <div
          className="modal-header"
          style={{ padding: "21px 24px 10px", border: "none" }}
        >
          <TextStyles.FontSize20px className="d-flex justify-content-start mb-4 w-100">
            <span className="three-dots"> Email message template</span>
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </div>

        <div className="modal-body" style={{ padding: "0 24px" }}>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="template_name">Template name</label>
            <TextStyles.InputRectangle
              invalid={errors.template_name}
              id="template_name"
              type="text"
              placeholder="Write template name"
              name="template_name"
              {...register("template_name", {
                required: "Template's name can't be empty.",
              })}
            />
            {errors.template_name && (
              <TextStyles.InValidFeedback>
                {errors.template_name.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="template_subject">Subject</label>
            <TextStyles.InputRectangle
              invalid={errors.template_subject}
              id="template_subject"
              type="text"
              placeholder="Write subject"
              name="template_subject"
              {...register("template_subject", {
                required: "Template's subject can't be empty.",
              })}
            />
            {errors.template_subject && (
              <TextStyles.InValidFeedback>
                {errors.template_subject.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>

          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="template_message">Message</label>
            <TextStyles.Textarea
              rows={8}
              invalid={errors.template_message}
              id="template_message"
              type="text"
              placeholder="Write message"
              name="template_message"
              {...register("template_message", {
                required: "Template's message can't be empty.",
              })}
            />

            {errors.template_message && (
              <TextStyles.InValidFeedback>
                {errors.template_message.message}
              </TextStyles.InValidFeedback>
            )}
          </TextStyles.FontSize14px>
        </div>

        <div
          className="modal-footer"
          style={{
            width: "100%",
            display: "inline-block",
            padding: "10px 18px 15px",
            border: "none",
          }}
        >
          <div className="row">
            <TextStyles.Button
              disabled={isLoading ? true : false}
              style={{
                opacity: isLoading ? 0.5 : 1,
              }}
            >
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditTemplateModal;
