import React from "react";
import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

import Colors from "../../../../../../../Constants/Colors";

const PaymentTerms = (props) => {
  const selectedData = props && props.selectedData;

  const heading = "Payment Terms";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.payment_terms &&
          selectedData.payment_terms.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px>
          Client acknowledges the significance of prompt payments to independent
          contractors and aims to sustain a constructive working relationship
          with the Contractor for seamless project progress. Payments for each
          invoice submitted by the Contractor to the Client shall be settled
          within
          <span
            className="mx-1"
            style={{ color: Colors.primary, fontWeight: 500 }}
          >
            {selectedData &&
              selectedData.payment_terms &&
              selectedData.payment_terms.payment_due}
          </span>
          of receipt. In the event of delayed payments, the Contractor retains
          the right to suspend work until the payment is received.
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
    </div>
  );
};

export default PaymentTerms;
