import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editClient } from "../../../../../store/service/clientService";

import Contact from "../contacts/Contact.jsx";
import LoaderSpin from "../../../Commons/LoaderSpin";

import { toast } from "react-toastify";
import TextStyles from "../../../../../Constants/TextStyles";

const AddressAndContacts = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.clients.isLoading);
  const c_data_position = useSelector((state) => state.clients.c_data_position);
  const [submited, setSubmitted] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      company_name: c_data_position && c_data_position.company_name,
      street: c_data_position && c_data_position.street,
      suite: c_data_position && c_data_position.suite,
      city: c_data_position && c_data_position.city,
      zip_code: c_data_position && c_data_position.zip_code,
      country: c_data_position && c_data_position.country,
      state: c_data_position && c_data_position.state,
      tax_id_label: c_data_position && c_data_position.tax_id_label,
      tax_id_number: c_data_position && c_data_position.tax_id_number,
    },
  });

  const addressFormSubmit = async (data) => {
    const Obj = {
      client_id: c_data_position._id,
      company_name: data.company_name,
      zip_code: data.zip_code,
      street: data.street,
      suite: data.suite,
      city: data.city,
      country: data.country,
      state: data.state,
      tax_id_label: data.tax_id_label,
      tax_id_number: data.tax_id_number,
    };
    setSubmitted(true);
    dispatch(editClient(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setSubmitted(false);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "fail" }
        );
      }
    });
  };

  return (
    <>
      <div className="d-flex mt-4 w-100">
        <div className="w-100" style={{ maxWidth: 700 }}>
          <form onSubmit={handleSubmit(addressFormSubmit)}>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="company_name" style={{ display: "flex" }}>
                Company name
              </label>
              <TextStyles.InputRectangle
                invalid={errors.company_name}
                // style={{ color: Colors.font2 }}
                id="company_name"
                type="text"
                // readOnly
                name="company_name"
                placeholder="Company name"
                {...register("company_name", {
                  required: "Company name can't be empty.",
                })}
              />
              {errors.company_name && (
                <TextStyles.InValidFeedback>
                  {errors.company_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="row">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                  <label htmlFor="street_address" style={{ display: "flex" }}>
                    Street address
                  </label>
                  <TextStyles.InputRectangle
                    id="street_address"
                    type="text"
                    placeholder="Street address"
                    name="street"
                    {...register("street")}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <label htmlFor="suite_address" style={{ display: "flex" }}>
                    Suite address
                  </label>
                  <TextStyles.InputRectangle
                    id="suite_address"
                    type="text"
                    placeholder="suite address"
                    name="suite"
                    {...register("suite")}
                  />
                </div>
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="row">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                  <label htmlFor="city" style={{ display: "flex" }}>
                    City
                  </label>
                  <TextStyles.InputRectangle
                    id="city"
                    type="text"
                    placeholder="City"
                    name="city"
                    {...register("city")}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <label htmlFor="zip_code" style={{ display: "flex" }}>
                    Zip code
                  </label>
                  <TextStyles.InputRectangle
                    id="zip_code"
                    type="text"
                    placeholder="Zip code"
                    name="zip_code"
                    {...register("zip_code")}
                  />
                </div>
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="row">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                  <label htmlFor="country" style={{ display: "flex" }}>
                    Country
                  </label>
                  <TextStyles.InputRectangle
                    id="country"
                    type="text"
                    placeholder="Country"
                    name="country"
                    {...register("country")}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <label htmlFor="state" style={{ display: "flex" }}>
                    State
                  </label>
                  <TextStyles.InputRectangle
                    id="state"
                    type="text"
                    placeholder="State"
                    name="state"
                    {...register("state")}
                  />
                </div>
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="row">
                <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                  <label htmlFor="tax_id_label" style={{ display: "flex" }}>
                    Tax ID label
                  </label>
                  <TextStyles.InputRectangle
                    id="tax_id_label"
                    type="text"
                    placeholder="Tax ID label"
                    name="tax_id_label"
                    {...register("tax_id_label")}
                  />
                </div>
                <div className="col-12 col-sm-6">
                  <label htmlFor="tax_id_number" style={{ display: "flex" }}>
                    Tax ID number
                  </label>
                  <TextStyles.InputRectangle
                    id="tax_id_number"
                    type="text"
                    placeholder="Tax ID number"
                    name="tax_id_number"
                    {...register("tax_id_number")}
                  />
                </div>
              </div>
            </TextStyles.FontSize14px>
            <div className="mb-3">
              <TextStyles.Button
                type="submit"
                disabled={submited && isLoading ? true : false}
                style={{ opacity: submited && isLoading ? 0.5 : 1 }}
              >
                {submited && isLoading ? <LoaderSpin /> : "Save changes"}
              </TextStyles.Button>
            </div>
          </form>
        </div>
      </div>
      <Contact />
    </>
  );
};

export default AddressAndContacts;
