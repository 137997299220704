import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Workload.module.css";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";

const Workload = () => {
  return (
    <div className={`${styles.section}`}>
      <div className={`container-fluid ${styles.container_padding}`}>
        <div
          className="d-flex gap-3 gap-md-5 flex-column align-items-center"
          style={{ overflow: "hidden" }}
        >
          <div className="text-center w-100 mt-3 mt-md-0" data-aos={"fade-up"}>
            <div className={`${styles.heading} mb-3`}>
              Leave the workload to Zodot!
            </div>
            <div className="d-flex justify-content-center">
              <div
                className={`${styles.text_font1} mb-3`}
                style={{ maxWidth: 700 }}
              >
                Let our cloud-based productivity tool take care of the
                nitty-gritty, leaving you free to focus on what truly matters.
              </div>
            </div>

            <div className="d-flex justify-content-center mb-3">
              <NavLink
                className={styles.more_button}
                style={{ textDecoration: "none" }}
                to="/db/signup"
              >
                <span>Try Zodot for free</span>
                <span className={`${styles.farword_arrow}`}>
                  {" "}
                  <EastOutlinedIcon sx={{ fontSize: 24 }} />
                </span>
              </NavLink>
            </div>

            <div className={`${styles.text_font2}`}>
              Access all features – 100% free!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workload;
