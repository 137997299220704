import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Transactions from "./Transactions/Transaction.jsx";
import ProjectsUi from "./Projects/Projects.jsx";
import InvoicesUi from "./Invoices/Invoices.jsx";
import AddressAndContacts from "./AddressAndContacts/AddressAndContacts.jsx";
import Tasks from "./task/task.jsx";
import Notes from "./Notes/notes.jsx";

import Styled from "../clientStyles";
import Colors from "../../../../Constants/Colors";
import { BackButton } from "../../Commons/BackAndForwardButton.jsx";
import ProtectedRoute from "../../../auth/ProtectedRoute.js";
import { getClientById } from "../../../../store/service/clientService.js";
import { useDispatch } from "react-redux";

export default function editClients() {
  const history = useHistory();
  const dispatch = useDispatch();
  let urlElements = window.location.href.split("/");
  const id = urlElements[5];

  useEffect(() => {
    dispatch(getClientById({ _id: id }));
  }, []);

  return (
    <div
      style={{
        backgroundColor: Colors.backgroundGrey,
        minHeight: "100vh",
        padding: "105px 0 60px 0",
        contain: "content",
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center px-2 px-sm-3 px-md-4 px-lg-4"
        style={{ height: "4rem" }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            overflowY: "hidden",
            height: "100%",
          }}
        >
          <Styled.SliderBox
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-flex flex-row">
              <div
                onClick={() => {
                  history.push(`/db/clients/${id}/tasks`);
                }}
              >
                {urlElements[6] === "tasks" ? (
                  <Styled.SelectedItem>Tasks</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Tasks</Styled.NotSelectedItem>
                )}
              </div>

              <div
                onClick={() => {
                  history.push(`/db/clients/${id}/address`);
                }}
              >
                {urlElements[6] === "address" ? (
                  <Styled.SelectedItem>Address & Contacts</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>
                    Address & Contacts
                  </Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/clients/${id}/projects`);
                }}
              >
                {urlElements[6] === "projects" ? (
                  <Styled.SelectedItem>Projects</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Projects</Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/clients/${id}/invoices`);
                }}
              >
                {urlElements[6] === "invoices" ? (
                  <Styled.SelectedItem>Invoices</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Invoices</Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/clients/${id}/transactions`);
                }}
              >
                {urlElements[6] === "transactions" ? (
                  <Styled.SelectedItem>Transactions</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Transactions</Styled.NotSelectedItem>
                )}
              </div>
              <div
                onClick={() => {
                  history.push(`/db/clients/${id}/notes`);
                }}
              >
                {urlElements[6] === "notes" ? (
                  <Styled.SelectedItem>Notes</Styled.SelectedItem>
                ) : (
                  <Styled.NotSelectedItem>Notes</Styled.NotSelectedItem>
                )}
              </div>
            </div>
          </Styled.SliderBox>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 25,
          }}
          onClick={() => history.push("/db/clients")}
        >
          <BackButton />
        </div>
      </div>

      <div className="px-2 px-sm-3 px-md-4 px-lg-4">
        <ProtectedRoute path="/db/clients/:id/tasks" exact component={Tasks} />
        <ProtectedRoute
          path="/db/clients/:id/address"
          exact
          component={AddressAndContacts}
        />
        <ProtectedRoute
          path="/db/clients/:id/projects"
          exact
          component={ProjectsUi}
        />
        <ProtectedRoute
          path="/db/clients/:id/invoices"
          exact
          component={InvoicesUi}
        />
        <ProtectedRoute
          path="/db/clients/:id/transactions"
          exact
          component={Transactions}
        />
        <ProtectedRoute path="/db/clients/:id/notes" exact component={Notes} />
      </div>
    </div>
  );
}
