import React from "react";
import styles from "./UpButton.module.css";
import NorthSharpIcon from "@mui/icons-material/NorthSharp";

const UpButton = () => {
  return (
    <div
      style={{
        width: 55,
        height: 55,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={styles.circle}>
        <NorthSharpIcon className={styles.upBtn} />
      </div>
    </div>
  );
};

export default UpButton;
