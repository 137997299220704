import React from "react";
import GoverningLaw from "./Components/GoverningLaw.jsx";
import ForceMejeure from "./Components/ForceMejeure.jsx";
import Notices from "./Components/Notices.jsx";
import AppropriateConduct from "./Components/AppropriateConduct.jsx";
import Miscellaneous from "./Components/Miscellaneous.jsx";
import EntireContract from "./Components/EntireContract.jsx";
import RightToAuthorship from "./Components/RightToAuthorship";
import PrivacyData from "./Components/PrivacyData.jsx";

const PageSix = () => {
  return (
    <div>
      <RightToAuthorship />
      <GoverningLaw />
      <ForceMejeure />
      <Notices />
      <AppropriateConduct />
      <PrivacyData />
      <Miscellaneous />
      <EntireContract />
    </div>
  );
};

export default PageSix;
