import React from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    fontWeight: "400 !important",
    borderRadius: "6px !important",
    fontFamily: "Poppins !important",
    fontSize: "1rem !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiOutlinedInput-input": {
      padding: "0.4rem 1rem",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "& .MuiOutlinedInput-input.MuiSelect-select": {
      fontSize: "1rem !important",
      fontFamily: "Poppins !important",
    },
  },

  listMenu: {
    color: Colors.font1,
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "1rem !important",
    fontFamily: "Poppins !important",

    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },

    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

const clientStatusOptions = [
  {
    id: "1",
    value: "all",
    label: "All statuses",
  },
  {
    id: "2",
    value: "active",
    label: "Active",
  },
  {
    id: "3",
    value: "archived",
    label: "Archived",
  },
];

export default function SelectClientStatus(props) {
  const classes = useStyles();

  return (
    <div>
      <TextField
        className={classes.root}
        id="input-with-icon-textfield"
        select
        defaultValue="active"
        variant="outlined"
        onChange={props.onChange}
      >
        {clientStatusOptions &&
          clientStatusOptions.map((data, index) => (
            <MenuItem
              key={index}
              value={data.value}
              className={classes.listMenu}
            >
              <FiberManualRecordIcon
                sx={{
                  display: data.label === "All statuses" ? "none" : "",
                  color:
                    data.label === "Active"
                      ? Colors.success
                      : data.label === "Archived"
                      ? "#c3c3c6"
                      : Colors.white,
                  fontSize: 15,
                  margin: "0 6px 0 0",
                }}
              />
              {data.label}
            </MenuItem>
          ))}
      </TextField>
    </div>
  );
}
