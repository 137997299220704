import React from "react";
import servicesvg from "../../../../Assets/assetsnew/service.svg";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import Styled from "./SetupStyles";
import TextStyles from "../../../../Constants/TextStyles";
import SelectServiceType from "../../Commons/SelectServiceType";
import Colors from "../../../../Constants/Colors";

const Services = ({
  register,
  fields,
  remove,
  append,
  control,
  Controller,
  currencySymbol,
}) => {
  return (
    <div>
      <Styled.SetupBox className="mb-4">
        <div className="accordion-item" style={{ border: "none" }}>
          <button
            style={{
              boxShadow: "none",
              background: Colors.white,
              padding: "0px 0px",
            }}
            className="accordion-button collapsed mb-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseTwo"
            aria-expanded="false"
            aria-controls="panelsStayOpen-collapseTwo"
          >
            <div className="d-flex gap-2 align-items-center">
              <img
                src={servicesvg}
                alt="serviceBtn"
                className="mr-1 mr-sm-2 mr-md-4"
              />
              <div>
                <TextStyles.FontSize20px>Services</TextStyles.FontSize20px>
                <TextStyles.GreyFont14px>
                  Describe and price the services you’ll be delivering.
                </TextStyles.GreyFont14px>
              </div>
            </div>
          </button>
          <div
            id="panelsStayOpen-collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="panelsStayOpen-headingTwo"
          >
            {fields.map((item, index) => {
              return (
                <div key={item.id} className="services_container">
                  <TextStyles.FontSize14px className="mb-3">
                    <label htmlFor="service_name" style={{ display: "flex" }}>
                      Service name
                    </label>
                    <div className="d-flex flex-row align-items-center">
                      <TextStyles.InputRectangle
                        style={{ width: "85%" }}
                        id="service_name"
                        type="text"
                        placeholder="Service name"
                        name="service_name"
                        {...register(`servicesList.${index}.service_name`)}
                      />
                      <span className="ml-4">
                        {fields.length !== 1 && (
                          <DeleteForeverRoundedIcon
                            sx={{ color: Colors.font2 }}
                            onClick={() => remove(index)}
                            className="deleteBtn"
                          />
                        )}
                      </span>
                    </div>
                  </TextStyles.FontSize14px>
                  <TextStyles.FontSize14px className="mb-3">
                    <label htmlFor="service_rate" style={{ display: "flex" }}>
                      Rate
                    </label>
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="mr-3">
                        <Styled.inputBox>
                          <span>{currencySymbol}</span>
                          <input
                            style={{ width: 80 }}
                            type="number"
                            id="service_rate"
                            name="service_rate"
                            step={"0.001"}
                            min={"0"}
                            placeholder="00.00"
                            {...register(`servicesList.${index}.service_rate`)}
                          />
                        </Styled.inputBox>
                      </div>
                      <div>
                        <Controller
                          control={control}
                          defaultValue={"Flat fee"}
                          name={`servicesList.${index}.service_rate_type`}
                          render={({ field: { onChange, value } }) => (
                            <SelectServiceType
                              placeholder="Select"
                              onChange={onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </TextStyles.FontSize14px>
                  <TextStyles.FontSize14px className="mb-3">
                    <label htmlFor="description" style={{ display: "flex" }}>
                      Description
                    </label>
                    <TextStyles.Textarea
                      rows={4}
                      id="description"
                      name="desc"
                      placeholder="Add a note..."
                      {...register(`servicesList.${index}.desc`)}
                    />
                  </TextStyles.FontSize14px>

                  <div
                    className="mb-3"
                    style={{
                      width: "100%",
                      height: "1px",
                      border: "0.5px solid #edeef2",
                    }}
                  />
                </div>
              );
            })}

            <TextStyles.FontSize14px
              className="d-flex justify-content-end"
              style={{ cursor: "pointer" }}
              onClick={() =>
                append({
                  service_name: "",
                  service_rate: "",
                  service_rate_type: "Flat fee",
                  desc: "",
                })
              }
            >
              <AddCircleOutlineOutlinedIcon sx={{ color: Colors.font1 }} />
              <span className="ml-2">Add another service</span>
            </TextStyles.FontSize14px>
          </div>
        </div>
      </Styled.SetupBox>
    </div>
  );
};

export default Services;
