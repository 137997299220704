import React from "react";
import { Modal } from "react-bootstrap";

import TextStyles from "../../../Constants/TextStyles";
import Colors from "../../../Constants/Colors";
const RemoveModal = (props) => {
  const {
    confirmDialog,
    showRemoveModal,
    handleCloseRemoveModal,
    title,
    content,
  } = props;

  return (
    <Modal
      show={showRemoveModal}
      onHide={handleCloseRemoveModal}
      centered
      contentClassName="ToMakeModalBorderRudius12px"
    >
      <Modal.Body>
        <TextStyles.FontSize20px className="d-flex justify-content-start my-2">
          Remove {title}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px className="d-flex justify-content-start mb-3">
          Are you sure you want to remove the {title}?
        </TextStyles.FontSize14px>
        <TextStyles.FontSize14px className="mb-4">
          {content}
        </TextStyles.FontSize14px>

        <div className="d-flex flex gap-3 justify-content-between align-items-center my-2">
          <TextStyles.CancelButton
            color={Colors.font2}
            border={Colors.font2}
            hoverColor={Colors.font1}
            backgroundColor={Colors.white}
            onClick={handleCloseRemoveModal}
          >
            <div className="d-flex justify-content-center">Cancel</div>
          </TextStyles.CancelButton>
          <TextStyles.Button
            color={Colors.white}
            backgroundColor={Colors.red}
            onClick={() => confirmDialog.onConfirm()}
          >
            <div className="d-flex justify-content-center">Remove</div>
          </TextStyles.Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RemoveModal;
