import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const SetupBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem 1.25rem;
  border-radius: 0.75rem;
  border: 1px solid #b2b6c5;
  background-color: ${Colors.white};
  @media (max-width: 576px) {
    padding: 1rem;
  }
`;

const Selected = styled.div`
  height: 3.15rem;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  width: 100%;
  min-width: 10.937rem;
  justify-content: center;
  cursor: pointer;
  background-color: ${Colors.primary};
  color: ${Colors.white};
`;

const NotSelected = styled(Selected)`
  color: ${Colors.primary};
  background-color: ${Colors.backgroundPink};

  &:hover {
    background-color: ${Colors.primary};
    color: ${Colors.white};
  }
`;

export default {
  SetupBox,
  Selected,
  NotSelected,
};
