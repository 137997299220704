import moment from "moment";
import { useState, useEffect } from "react";
import Constant from "../Commons/Constant";
import { getRequest } from "../../../axios/axios";

export const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return size;
};

export const diffDate = (end_date, start_date) => {
  let endDate = moment(end_date).format("DD MMM YYYY");
  let startDate = moment(start_date).format("DD MMM YYYY");
  let diff = moment(moment(new Date(endDate)).add(1, "d")).diff(
    new Date(startDate),
    "d"
  );
  let days;
  if (diff === 1) {
    days = "1 day";
  } else if (diff === -1) {
    days = "- 1 day";
  } else if (diff === 0) {
    days = "0 day";
  } else {
    days = `${diff} days`;
  }
  return days;
};

export const diffHours = (start_date, end_date) => {
  let edate = new Date(end_date) || new Date();
  let diff = Math.abs(edate - new Date(start_date));
  let days = diff / (1000 * 60 * 60);
  return days;
};

export const msToTime = (duration) => {
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor(duration / (1000 * 60 * 60));
  hours = Math.floor(hours) < 10 ? `0${Math.floor(hours)}` : Math.floor(hours);
  minutes =
    Math.floor(minutes) < 10 ? `0${Math.floor(minutes)}` : Math.floor(minutes);
  seconds =
    Math.floor(seconds) < 10 ? `0${Math.floor(seconds)}` : Math.floor(seconds);

  if (isNaN(hours)) {
    hours = "00";
  }
  if (isNaN(minutes)) {
    minutes = "00";
  }
  if (isNaN(seconds)) {
    seconds = "00";
  }
  return { hours, minutes, seconds };
};

export const diffWeek = (start_date, end_date) => {
  let diff = Math.abs(new Date(end_date) - new Date(start_date));
  let weeks = Math.ceil(diff / (1000 * 60 * 60 * 24 * 7));
  return weeks;
};

export const getFileFromUrl = async (url, name) => {
  try {
    let response = await fetch(url);
    let data = await response.blob();
    const file = new File([data], name, {
      type: data.type,
    });
    return file;
  } catch (error) {
    console.log(error);
  }
};

export const formatTime = (timers) => {
  const getSeconds = `00${Math.floor(timers) % 60}`.slice(-2);
  const minutes = `${Math.floor(timers / 60)}`;
  const getMinutes = `00${minutes % 60}`.slice(-2);
  const getHours = `00${Math.floor(timers / 3600)}`.slice(-2);
  return `${getHours} : ${getMinutes} : ${getSeconds}`;
};

export const getCountryNameByUserLocation = async () => {
  try {
    const { data } = await getRequest(
      `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`
    );
    const countryCode = data && data.country;
    const country = Constant.countryOptions.find(
      (data) => data.code === countryCode
    );
    return country;
  } catch (error) {
    console.log("Error in fetching country code:", error);
  }
};

export const weekviewNumberFormat = (num) => {
  if (Number.isInteger(num)) {
    return num;
  } else {
    return parseFloat(num.toFixed(2));
  }
};
