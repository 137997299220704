import styled from "styled-components";
import Colors from "../../../../../Constants/Colors";

const ReviewContainer = styled.div`
  width: 100%;
  padding: 1rem 1.5rem;
  boxshadow: 0px 0px 9px 0px lightgrey;
  background: ${Colors.white};
  @media (max-width: 576px) {
    padding: 1rem 0.5rem;
  }
`;

const ReviewBox = styled.div`
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: ${Colors.backgroundGrey};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  @media (max-width: 576px) {
    padding: 1.5rem 1rem;
  }
`;

const TableHead = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  background-color: ${Colors.tableHeadColor};
`;

const TableCell = styled.div`
  border-radius: 0.75rem;
  padding: 1rem;
  border-bottom: 2px solid #f4efef;
`;

export default {
  ReviewContainer,
  ReviewBox,
  TableHead,
  TableCell,
};
