import React from "react";
import { CSVLink } from "react-csv";
import Colors from "../../../Constants/Colors";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

const TransactionCSV = ({ data }) => {
  return (
    <div className="d-flex">
      <CSVLink data={data} filename={"transaction.csv"} target="_blank">
        <div
          style={{
            width: 40,
            height: 40,
            background: Colors.primary,
            borderRadius: "50%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SaveAltIcon sx={{ color: Colors.white, fontSize: 25 }} />
        </div>
      </CSVLink>
    </div>
  );
};

export default TransactionCSV;
