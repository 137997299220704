import React from "react";
import Footer from "../Footer/Footer";
import LandingPage from "./LandingPage/LandingPage";
import ContactForm from "./ContactForm/ContactForm";

const ContactUs = () => {
  return (
    <div>
      <LandingPage />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default ContactUs;
