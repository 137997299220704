import React from "react";
import SelectCurrency from "../../../Commons/SelectCurrency";
import TextStyles from "../../../../../Constants/TextStyles";
import {
  fetchUserSetupData,
  setUserCurrency,
} from "../../../../../store/service/AuthService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Currency = () => {
  const dispatch = useDispatch();
  const userCurrency = useSelector((state) => state.auth.userCurrency);

  const currencyHandleChange = (value) => {
    const Obj = {
      currency: value,
    };
    dispatch(setUserCurrency(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchUserSetupData());
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "fail" }
        );
      }
    });
  };

  return (
    <div>
      <TextStyles.FontSize16px className="d-flex my-3">
        Select currency
      </TextStyles.FontSize16px>
      <div style={{ maxWidth: 400 }}>
        <SelectCurrency
          placeholder="Select currency"
          onChange={(value) => {
            console.log(value);
            if (value) {
              currencyHandleChange(value);
            }
          }}
          defaultValue={userCurrency && userCurrency.currency}
        />
      </div>
    </div>
  );
};

export default Currency;
