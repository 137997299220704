import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import LoaderSpin from "../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import { updateAddSexualHarassmentCluasePC } from "../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../Constants/Colors";
import SwitchButton from "../../../Commons/SwitchButton";

const AppropriateConductModal = ({
  show,
  handleClose,
  heading,
  selectedData,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const [selector, setSelector] = useState(
    selectedData &&
      selectedData.add_sexual_harassment_clause &&
      selectedData.add_sexual_harassment_clause
        .add_sexual_harassment_clause_require === true
      ? true
      : false
  );

  const paragraph =
    selector === true &&
    selectedData.add_sexual_harassment_clause &&
    selectedData.add_sexual_harassment_clause.paragraph &&
    selectedData.add_sexual_harassment_clause.paragraph !==
      "Note: This section shows if only applicable."
      ? selectedData.add_sexual_harassment_clause.paragraph
      : selector === true
      ? `Client and Contractor are committed to maintaining a professional relationship free from any form of harassment or offensive conduct. If Contractor experiences any harassing behavior from Client or Client's staff during the term of this Agreement, Contractor will promptly notify Client and request appropriate corrective action. If the reported behavior persists after Contractor's second notice, it will be considered a breach of this Agreement, allowing Contractor to terminate the Agreement in accordance with the Term and Termination Clause. Contractor will be entitled to receive full payment for the ordered project or planned monthly service hours, without prejudice to any other rights or remedies available to Contractor under the law.`
      : `Note: This section shows if only applicable.`;

  const { register, reset, handleSubmit, setValue } = useForm({
    defaultValues: {
      heading: heading,
      paragraph: paragraph,
    },
  });

  useEffect(() => {
    setValue("paragraph", paragraph);
  }, [selector]);

  const formsubmit = (item) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      add_sexual_harassment_clause: {
        required: true,
        heading: item.heading,
        paragraph: item.paragraph,
        add_sexual_harassment_clause_require: selector === true ? true : false,
      },
    };

    dispatch(updateAddSexualHarassmentCluasePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            Clause updated successfully!
          </TextStyles.FontSize14px>,
          { toastId: "updateHarrasmentSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateHarrasmentFail" }
        );
      }
    });
  };

  return (
    <Modal
      scrollable={true}
      show={show}
      onHide={handleClose}
      fullscreen
      dialogClassName="ToMakeModalWidth600px"
      contentClassName="ToMakeModalBorderRudius12px"
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Modal.Header className="d-flex align-items-center">
          <TextStyles.FontSize20px className="d-flex justify-content-start">
            Edit contract
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline">Headline</label>
            <TextStyles.InputRectangle
              placeholder={heading}
              id="headline"
              name="heading"
              style={{ opacity: selector === false ? 0.5 : "" }}
              {...register("heading")}
              readOnly={selector === false ? true : false}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="mb-3">
            <label htmlFor="headline">Paragraph</label>
            <TextStyles.Textarea
              rows={8}
              style={{ opacity: selector === false ? 0.5 : "" }}
              placeholder="Add text here"
              id="paragraph"
              name="paragraph"
              {...register("paragraph")}
              readOnly={selector === false ? true : false}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize18px className="mb-3">
            Options
          </TextStyles.FontSize18px>
          <TextStyles.GreyFont14px className="d-flex gap-3 justify-content-between align-items-center">
            <div className="d-flex" style={{ minWidth: 100 }}>
              <span className="three-dots">Add sexual harassment clause?</span>
            </div>
            <div className="d-flex justify-content-end">
              <SwitchButton
                defaultChecked={selector}
                onChange={(event) => setSelector(event.target.checked)}
              />
            </div>
          </TextStyles.GreyFont14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-between">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading ? true : false}>
              {isLoading ? <LoaderSpin /> : "Update"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AppropriateConductModal;
