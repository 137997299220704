import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

import Colors from "../../../../../../../Constants/Colors";

const Deposit = (props) => {
  const selectedData = props && props.selectedData;

  const heading = "Deposit";

  return (
    <div
      style={{
        display:
          selectedData.deposit && selectedData.deposit.required === true
            ? "inline"
            : "none",
      }}
    >
      {selectedData.billing_scheduled &&
        selectedData.billing_scheduled.deposit &&
        selectedData.billing_scheduled.deposit.require_deposit === true && (
          <Styled.ReviewContentBox className="mt-4">
            <TextStyles.FontSize20px className="mb-2">
              {heading}
            </TextStyles.FontSize20px>
            <TextStyles.FontSize14px>
              {selectedData.billing_scheduled &&
              selectedData.billing_scheduled.deposit &&
              selectedData.billing_scheduled.deposit.require_deposit ===
                true ? (
                <>
                  Upon the acceptance of this Agreement, a deposit of
                  <span
                    className="mx-2"
                    style={{ color: Colors.primary, fontWeight: 500 }}
                  >
                    {selectedData.currency.symbol}
                    {parseFloat(
                      selectedData.billing_scheduled.deposit.value || 0
                    ).toFixed(2)}
                  </span>
                  is required before work will commence.
                </>
              ) : (
                <>Note: This section shows if only applicable.</>
              )}
            </TextStyles.FontSize14px>
          </Styled.ReviewContentBox>
        )}
    </div>
  );
};

export default Deposit;
