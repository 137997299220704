import React from "react";
import NonSolicit from "./Components/NonSolicit.jsx";
import NonCompete from "./Components/NonCompete.jsx";
import RelationshiWithParty from "./Components/RelationshipWithParty.jsx";
import TermAndTermination from "./Components/TermAndTermination.jsx";
import OwnershipRights from "./Components/OwnershipRights";
import ContractorTaxId from "./Components/ContractorTaxId.jsx";
import HeadthAndMedicalInsurance from "./Components/HealthInsurance.jsx";

const PageFour = () => {
  return (
    <div>
      <OwnershipRights />
      <NonSolicit />
      <NonCompete />
      <RelationshiWithParty />
      <TermAndTermination />
      <ContractorTaxId />
      <HeadthAndMedicalInsurance />
    </div>
  );
};

export default PageFour;
