import React from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Colors from "../../../Constants/Colors";

const useStyles = makeStyles({
  root: {
    border: `1px solid ${Colors.borderInput} !important`,
    backgroundColor: Colors.white,
    fontWeight: "400 !important",
    borderRadius: "6px !important",
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    "&:hover": {
      border: `1px solid ${Colors.primary} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  select: {
    display: "flex !important",
    justifyContent: "start",
    alignItems: "center !important",
    padding: "12px 32px 12px 12px !important",
  },

  listMenu: {
    color: Colors.font1,
    fontWeight: "400 !important",
    marginBottom: "2px !important",
    fontSize: "0.875rem !important",
    fontFamily: "Poppins !important",
    "&:hover": {
      backgroundColor: `${Colors.backgroundPink} !important`,
      border: "none",
    },
    '&[aria-selected="true"]': {
      backgroundColor: `${Colors.backgroundPink} !important`,
    },
  },
});

export default function SelectActivity(props) {
  const options = props.options;
  const classes = useStyles();

  return (
    <div>
      <Select
        classes={{
          select: classes.select,
        }}
        className={classes.root}
        value={props.defaultValue ? props.defaultValue : "0"}
        id="grouped-select"
        label="Grouping"
        onChange={props.onChange}
        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
      >
        <MenuItem value={0} className={classes.listMenu}>
          <AccessTimeIcon
            sx={{ fontSize: 20, color: Colors.primary, marginRight: 1 }}
          />
          All activities
        </MenuItem>
        {options &&
          options.map((data) => (
            <MenuItem
              key={data._id}
              value={data.activity_name}
              className={classes.listMenu}
            >
              <AccessTimeIcon
                sx={{ fontSize: 20, color: Colors.primary, marginRight: 1 }}
              />

              {data.activity_name}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}
