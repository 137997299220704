import React, { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Colors from "../../Constants/Colors";

const PasswordToggle = () => {
  const [visible, setVisibility] = useState(false);

  const Icons = visible ? (
    <VisibilityOffIcon
      sx={{ color: Colors.font2, fontSize: 22 }}
      onClick={() => setVisibility((visible) => !visible)}
    />
  ) : (
    <VisibilityIcon
      sx={{ color: Colors.font2, fontSize: 22 }}
      onClick={() => setVisibility((visible) => !visible)}
    />
  );

  const InputType = visible ? "text" : "password";

  return [InputType, Icons];
};

export default PasswordToggle;
