import React from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import { editInvoiceTitle } from "../../../../store/service/invoiceService";
import LoaderSpin from "../../Commons/LoaderSpin";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

const EditInvoiceTitle = ({ show, handleClose, selectedData }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.invoices.isLoading);
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      invoice_title: selectedData && selectedData.invoice_title,
    },
  });

  const formsubmit = (data) => {
    const Obj = {
      invoice_id: selectedData._id,
      invoice_title: data.invoice_title,
    };
    dispatch(editInvoiceTitle(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();

        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "editInvoiceTitleSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "editInvoiceTitleFail" }
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalOnRight500px"
        fullscreen
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          className="modal-content"
          style={{ height: "100%" }}
        >
          <Modal.Header className="d-flex align-items-center">
            <TextStyles.FontSize20px>Invoice label</TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body className="mr-0 mr-sm-1 mr-md-2 mr-lg-3">
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="invoice_title"> Invoice label</label>
              <TextStyles.InputRectangle
                invalid={errors.invoice_title}
                type="text"
                id="invoice_title"
                name="invoice_title"
                placeholder="Invoice title"
                {...register("invoice_title", {
                  required: "Invoice title can't be empty.",
                })}
              />
              {errors.invoice_title && (
                <TextStyles.InValidFeedback>
                  {errors.invoice_title.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button
                disabled={isLoading ? true : false}
                style={{
                  opacity: isLoading ? 0.5 : 1,
                }}
              >
                {isLoading ? <LoaderSpin /> : "Save"}
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditInvoiceTitle;
