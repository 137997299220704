import { createSlice } from "@reduxjs/toolkit";
import {
  createTransaction,
  fetchTransactions,
  fetchTransactionsForGraph,
  deleteTransaction,
  editTransaction,
  fetchTransactionCounts,
} from "../service/transactionService";

const initialTransactionState = {
  transaction: null,
  trTotalCount: null,
  trDataForGraph: null,
  isLoading: false,
  error: null,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState: initialTransactionState,
  reducers: {},
  extraReducers: {
    //TODO: fetch transaction

    [fetchTransactions.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTransactions.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.transaction = action.payload.data.data;
      state.trTotalCount = action.payload.data.trTotalCount;
    },
    [fetchTransactions.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //TODO: fetch transaction for graph

    [fetchTransactionsForGraph.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTransactionsForGraph.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.trDataForGraph = action.payload.data;
    },
    [fetchTransactionsForGraph.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //TODO: ADD expenses api
    [createTransaction.pending]: (state) => {
      state.isLoading = true;
    },
    [createTransaction.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createTransaction.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //TODO: delete transaction
    [deleteTransaction.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteTransaction.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deleteTransaction.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //Todo: edit expense
    [editTransaction.pending]: (state) => {
      state.isLoading = true;
    },
    [editTransaction.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [editTransaction.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //TODO: fetch transaction counts

    [fetchTransactionCounts.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTransactionCounts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.trTotalCount = action.payload.data.trTotalCount;
    },
    [fetchTransactionCounts.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});
export const trAction = transactionSlice.actions;
export default transactionSlice.reducer;
