import React from "react";
import { NavLink } from "react-router-dom";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";

import styles from "./AdditionalFeatures.module.css";

const AdditionalFeatures = () => {
  return (
    <div className={styles.section}>
      <div className="container-fluid py-3 py-md-4">
        <div
          className={`${styles.heading} text-center mb-1`}
          data-aos={"fade-up"}
        >
          Additional features
        </div>

        <div
          className={`${styles.heading_text} text-center mb-3 mb-sm-4`}
          data-aos={"fade-up"}
        >
          Easily manage your ideal workflow with
          <span className={`${styles.heading_text_brand} mx-2`}>Zodot's</span>
          building blocks
        </div>

        <div
          className="row d-flex justify-content-between align-items-center"
          data-aos={"fade-up"}
        >
          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Generate detailed reports
              </div>

              <div className={`${styles.container_text} text-start mb-2`}>
                Effortlessly generate detailed reports in Zodot for insights
                into logged hours and project invoicing. Analyze productivity
                trends, make informed decisions, and optimize workflows based on
                data-driven insights.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Download reports anytime
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                Zodot offers the convenience of downloading detailed time
                tracking reports directly from its ‘Week view’ feature. Analyze
                data offline, share insights with stakeholders, and easily
                maintain records.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Plan efficiently
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                Use Zodot's ‘Week view’ for visual time tracking per project.
                Identify activity levels and ensure productivity. If you miss
                tracking your hours, you can even edit the logged hours in the
                weekly view.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Stay on top of billing
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                Track not only the hours you've worked but also what tasks
                you've billed for. Zodot makes it easy to see what you've
                invoiced, helping you bill clients accurately and keep your
                business transparent.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div data-color="primary" className={`${styles.feature_container}`}>
              <div className={`${styles.feature_container_heading} mb-4`}>
                Track work hours with graphs
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                See how much work you get done each week with Zodot's graphs.
                You can see where you spent most of your time, notice any
                patterns in your work habits, and use that information for
                priority management.
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-6 col-md-6 col-lg-4"
            data-aos={"zoom-in"}
          >
            <div
              data-color="secondary"
              className={`${styles.feature_container}`}
            >
              <div className={`${styles.feature_container_heading} mb-4`}>
                Boost efficiency with Zodot
              </div>
              <div className={`${styles.container_text} text-start mb-2`}>
                With Zodot’s time tracking, monitor project budgets in real
                time. Easily track expenses, connect seamlessly for accurate
                money management with detailed reports for smart project
                decisions.
              </div>
            </div>
          </div>
        </div>

        <NavLink
          data-aos={"fade-up"}
          className={`d-block text-decoration-none mx-auto ${styles.more_button}`}
          to="/db/signup"
        >
          Track now
          <span className={`${styles.forward_arrow}`}>
            <EastOutlinedIcon sx={{ fontSize: 24 }} />
          </span>
        </NavLink>
      </div>
    </div>
  );
};

export default AdditionalFeatures;
