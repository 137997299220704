import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateAddSexualHarassmentCluasePC } from "../../../../../../store/service/proposalAndContractService";
import RemoveModal from "../../../../Commons/RemoveModal";
import AppropriateConductModal from "../../PageModals/AppropriateConductModal";
import Colors from "../../../../../../Constants/Colors";

const AppropriateConduct = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);

  const heading =
    selectedData &&
    selectedData.add_sexual_harassment_clause &&
    selectedData.add_sexual_harassment_clause.heading
      ? selectedData.add_sexual_harassment_clause.heading
      : "Appropriate Conduct";

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      add_sexual_harassment_clause: {
        required: false,
        heading: null,
        paragraph: null,
        add_sexual_harassment_clause_require: false,
      },
    };
    dispatch(updateAddSexualHarassmentCluasePC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Clause hidden successfully!
          </TextStyles.FontSize14px>,
          { toastId: "updateHarrasmentSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateHarrasmentFail" }
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.add_sexual_harassment_clause &&
          selectedData.add_sexual_harassment_clause.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        {selectedData &&
        selectedData.add_sexual_harassment_clause &&
        selectedData.add_sexual_harassment_clause
          .add_sexual_harassment_clause_require === true ? (
          <>
            {" "}
            <Styled.EditButtonToolTip
              className="contract_review_edit_tp"
              style={{ marginLeft: -60 }}
              onClick={() => setShowModal(true)}
            >
              <BorderColorOutlinedIcon
                sx={{ color: Colors.white, fontSize: 20 }}
              />
              <span className="ml-1">Edit</span>
            </Styled.EditButtonToolTip>
            <Styled.EditButtonToolTip
              backgroundColor="#383838"
              className="contract_review_edit_tp"
              style={{ marginLeft: 60 }}
              onClick={() => {
                setShowRemoveModal(true);
                setConfirmDialog({
                  onConfirm: hideClauseHandler,
                });
              }}
            >
              <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
              <span className="ml-1">Hide</span>
            </Styled.EditButtonToolTip>
          </>
        ) : (
          <Styled.EditButtonToolTip
            className="contract_review_edit_tp"
            backgroundColor={Colors.white}
            color={Colors.font1}
            onClick={() => setShowModal(true)}
          >
            show
          </Styled.EditButtonToolTip>
        )}

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        {selectedData &&
        selectedData.add_sexual_harassment_clause &&
        selectedData.add_sexual_harassment_clause
          .add_sexual_harassment_clause_require === true &&
        selectedData.add_sexual_harassment_clause.paragraph ? (
          <div style={{ whiteSpace: "break-spaces" }}>
            {selectedData.add_sexual_harassment_clause.paragraph}
          </div>
        ) : selectedData &&
          selectedData.add_sexual_harassment_clause &&
          selectedData.add_sexual_harassment_clause
            .add_sexual_harassment_clause_require === true ? (
          <TextStyles.FontSize14px>
            Client and Contractor are committed to maintaining a professional
            relationship free from any form of harassment or offensive conduct.
            If Contractor experiences any harassing behavior from Client or
            Client's staff during the term of this Agreement, Contractor will
            promptly notify Client and request appropriate corrective action. If
            the reported behavior persists after Contractor's second notice, it
            will be considered a breach of this Agreement, allowing Contractor
            to terminate the Agreement in accordance with the Term and
            Termination Clause. Contractor will be entitled to receive full
            payment for the ordered project or planned monthly service hours,
            without prejudice to any other rights or remedies available to
            Contractor under the law.
          </TextStyles.FontSize14px>
        ) : (
          <>Note: This section shows if only applicable.</>
        )}
      </Styled.ReviewContentBox>

      {showModal && (
        <AppropriateConductModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          heading={heading}
          selectedData={selectedData}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};
export default AppropriateConduct;
