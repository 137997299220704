import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Colors from "../../../Constants/Colors";
import Badge from "@mui/material/Badge";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.red,
    },
  },
});

const CustomBadge = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Badge badgeContent={props.badgeContent} color="primary">
        {props.children}
      </Badge>
    </ThemeProvider>
  );
};

export default CustomBadge;
