import React from "react";
import "./Backdrop.css";

const Backdrop = (props) => {
  let classes = "Backdrop";
  if (props.show) {
    classes = "Backdrop show";
  }
  return <div className={classes} onClick={props.clicked} />;
};

export default Backdrop;
