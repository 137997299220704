import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import PCEditProjectInfo from "../../ProposalReviews/PCEditProjectInfo";

import TextStyles from "../../../../../Constants/TextStyles";
import { diffDate } from "../../../Commons/utils";
import { ReactTooltipWithIcon } from "../../../Commons/ReactTooltip";

const Project = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showProjectModal, setShowProjectModal] = useState(false);
  return (
    <div>
      <div
        onClick={() => setShowProjectModal(true)}
        style={{ cursor: "pointer" }}
        id={`edit_project_info`}
      >
        <TextStyles.FontSize20px className="mt-2">Term</TextStyles.FontSize20px>
        <ReactTooltipWithIcon
          anchorId={`edit_project_info`}
          content="Edit project info"
          place="right"
          variant="grey"
        />
        <div className="d-flex gap-2 justify-content-start align-items-center mt-3">
          <TextStyles.FontSize16px className="mr-2">
            Starts:
          </TextStyles.FontSize16px>
          <TextStyles.GreyFont14px className="d-flex" style={{ minWidth: 50 }}>
            <span className="three-dots">
              {moment(selectedData && selectedData.project_start_date).format(
                "DD MMM YYYY"
              )}
            </span>
          </TextStyles.GreyFont14px>
        </div>

        {selectedData && selectedData.project_end_date && (
          <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
            <TextStyles.FontSize16px className="mr-2">
              Ends:
            </TextStyles.FontSize16px>
            <TextStyles.GreyFont14px
              className="d-flex"
              style={{ minWidth: 50 }}
            >
              <span className="three-dots">
                {moment(selectedData.project_end_date).format("DD MMM YYYY")}
              </span>
            </TextStyles.GreyFont14px>
          </div>
        )}

        {selectedData &&
          selectedData.project_end_date &&
          selectedData.project_start_date && (
            <div className="d-flex gap-2 justify-content-start align-items-center mt-2">
              <TextStyles.FontSize16px className="mr-2">
                Duration:
              </TextStyles.FontSize16px>
              <TextStyles.GreyFont14px
                className="d-flex"
                style={{ minWidth: 30 }}
              >
                <span className="three-dots">
                  {diffDate(
                    selectedData.project_end_date,
                    selectedData.project_start_date
                  )}
                </span>
              </TextStyles.GreyFont14px>
            </div>
          )}
      </div>
      <div
        className="my-3"
        style={{
          width: "100%",
          height: "1px",
          border: "0.5px solid #edeef2",
        }}
      />
      {showProjectModal && (
        <PCEditProjectInfo
          selectedData={selectedData}
          show={showProjectModal}
          handleClose={() => setShowProjectModal(false)}
        />
      )}
    </div>
  );
};

export default Project;
