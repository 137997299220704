import React from "react";
import LandingPage from "./LandingPage/LandingPage.jsx";
import Features from "./Features/Features.jsx";
import Services from "./Services/Services.jsx";
import Footer from "../Footer/Footer.jsx";
import Testinomials from "./Testinomials/Testinomials.jsx";
import Workload from "./Workload/Workload.jsx";
import CTAOne from "./CTAOne/CTAOne.jsx";

const HomePage = () => {
  return (
    <div>
      <LandingPage />
      <Features />
      <CTAOne />
      <Services />
      <Testinomials />
      <Workload />
      <Footer />
    </div>
  );
};

export default HomePage;
