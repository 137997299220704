import React from "react";
import NonSolicit from "./Components/NonSolicit.jsx";
import NonCompete from "./Components/NonCompete.jsx";
import RelationshiWithParty from "./Components/RelationshipWithParty.jsx";
import TermAndTermination from "./Components/TermAndTermination.jsx";

import OwnershipRights from "./Components/OwnershipRights";
import ContractorTaxId from "./Components/ContractorTaxId.jsx";
import HealthInsurance from "./Components/HealthInsurance.jsx";

const PageFour = (props) => {
  const selectedData = props && props.selectedData;

  return (
    <div>
      <OwnershipRights selectedData={selectedData} />
      <NonSolicit selectedData={selectedData} />
      <NonCompete selectedData={selectedData} />
      <RelationshiWithParty selectedData={selectedData} />
      <TermAndTermination selectedData={selectedData} />
      <ContractorTaxId selectedData={selectedData} />
      <HealthInsurance selectedData={selectedData} />
    </div>
  );
};

export default PageFour;
