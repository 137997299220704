import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";

import QuickSetup from "../../../../invoices/CreateInvoices/QuickInvoices/QuickSetup";
import ReviewInvoices from "../../../../invoices/ReviewInvoices/ReviewInvoices";
import Send from "../../../../invoices/Send/Send";
import LoaderSpin from "../../../../Commons/LoaderSpin";
import Styled from "../../../../invoices/CreateInvoices/CreateInvoiceStyles";
import TextStyles from "../../../../../../Constants/TextStyles";
import { BackButton } from "../../../../Commons/BackAndForwardButton";
import Colors from "../../../../../../Constants/Colors";

const CreateInvoices = () => {
  const isLoading = useSelector((state) => state.invoices.isLoading);
  const c_data_position = useSelector((state) => state.clients.c_data_position);
  const history = useHistory();
  const [page, setPage] = useState(0);

  const pageDisplay = () => {
    if (page === 0) {
      return (
        <QuickSetup
          page={page}
          setPage={setPage}
          client_id={c_data_position._id}
          backBtn={
            <div
              style={{ position: "fixed", left: 20, top: "50vh" }}
              onClick={() => history.goBack()}
            >
              <BackButton />
            </div>
          }
          submitBtn={
            <Styled.SubmitButton disabled={isLoading ? true : false}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </Styled.SubmitButton>
          }
        />
      );
    } else if (page === 1) {
      return (
        <ReviewInvoices
          submitBtn={
            <Styled.SubmitButton onClick={() => setPage(page + 1)}>
              Next
            </Styled.SubmitButton>
          }
        />
      );
    } else if (page === 2) {
      return (
        <Send
          backBtn={
            <div
              style={{ position: "fixed", left: 20, top: "50vh" }}
              onClick={() => setPage(page - 1)}
            >
              <BackButton />
            </div>
          }
        />
      );
    }
  };

  return (
    <div>
      <div
        style={{
          background: Colors.white,
          borderBottom: "2px solid #dfe1e9",
        }}
      >
        <div
          style={{
            background: Colors.white,
            borderBottom: "2px solid #dfe1e9",
            zIndex: 200,
          }}
          className="fixed-top"
        >
          <div className="p-2">
            <TextStyles.FontSize14px
              style={{
                position: "absolute",
                left: "0.75rem",
                top: "0.75rem",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => history.goBack()}
              >
                <ClearIcon
                  sx={{
                    fontSize: 27,
                    color: Colors.font2,
                    cursor: "pointer",
                    opacity: 0.7,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                />
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize20px
              style={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: 24,
              }}
            >
              Create invoice
            </TextStyles.FontSize20px>
          </div>

          <div className="d-flex">
            <Styled.ProgressBar>
              <div
                className="col"
                style={{
                  height: "100%",
                  paddingTop: "3px",
                  backgroundColor: Colors.primary,
                  color: Colors.white,
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Setup
              </div>
              <div
                className="col"
                style={
                  page > 0
                    ? {
                        height: "100%",
                        paddingTop: "3px",
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        justifyContent: "center",
                        display: "flex",
                      }
                    : { justifyContent: "center", display: "flex" }
                }
              >
                Review
              </div>
              <div
                className="col"
                style={
                  page > 1
                    ? {
                        height: "100%",
                        paddingTop: "3px",
                        backgroundColor: Colors.primary,
                        color: Colors.white,
                        justifyContent: "center",
                        display: "flex",
                      }
                    : { justifyContent: "center", display: "flex" }
                }
              >
                Send
              </div>
            </Styled.ProgressBar>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: 100 }}>{pageDisplay()}</div>
    </div>
  );
};

export default CreateInvoices;
