import { createSlice } from "@reduxjs/toolkit";
import {
  createQuickTask,
  createTask,
  fetchTasks,
  editTask,
  editTaskDueDate,
  deleteTask,
  updateTaskStatus,
} from "../service/dashboardService";

const dashboardInitialState = {
  taskData: null,
  taskTotalCount: null,
  isLoading: false,
  error: null,
  refreshHeaderTask: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardInitialState,
  reducers: {
    updateNewCreatedTaskFromHeader: (state) => {
      state.refreshHeaderTask = !state.refreshHeaderTask;
    },
  },

  extraReducers: {
    // create quick task
    [createQuickTask.pending]: (state) => {
      state.isLoading = true;
    },
    [createQuickTask.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (state.taskData === null) {
        state.taskData = [action.payload.data];
      } else {
        state.taskData.unshift(action.payload.data);
      }
    },
    [createQuickTask.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create task
    [createTask.pending]: (state) => {
      state.isLoading = true;
    },
    [createTask.fulfilled]: (state, action) => {
      state.isLoading = false;
      // state.taskData.unshift(action.payload.data);
    },
    [createTask.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch tasks

    [fetchTasks.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTasks.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.taskData = action.payload.data.data;
      state.taskTotalCount = action.payload.data.taskTotalCount;
    },
    [fetchTasks.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit task
    [editTask.pending]: (state) => {
      state.isLoading = true;
    },

    [editTask.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [editTask.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // edit task due date
    [editTaskDueDate.pending]: (state) => {
      state.isLoading = true;
    },

    [editTaskDueDate.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [editTaskDueDate.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //delete task
    [deleteTask.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteTask.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deleteTask.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //Change task status
    [updateTaskStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [updateTaskStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateTaskStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
