import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NoReminderImage from "../../../../Assets/assetsnew/no-remainder.svg";

import Styled from "./fourthrowStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import DismissModal from "../../Commons/DismissModal";
import { toast } from "react-toastify";

import {
  dismissedReminder,
  doneReminder,
  fetchReminders,
} from "../../../../store/service/reminderService";
import { paidInvoice } from "../../../../store/service/invoiceService";
import LoaderSpin from "../../Commons/LoaderSpin";

const Reminder = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const remindersData = useSelector((state) => state.reminders.reminders);
  const isLoading = useSelector((state) => state.reminders.isLoading);
  const [showDismissModal, setShowDismissModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseDismissModal = () => setShowDismissModal(false);

  useEffect(() => {
    dispatch(fetchReminders());
  }, []);

  const dismissReminderHandler = (data) => {
    const Obj = {
      _id: data._id,
    };
    dispatch(dismissedReminder(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchReminders());
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "dismissReminderSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "dismissReminderFail" }
        );
      }
    });
  };

  const doneReminderHandler = (data) => {
    const Obj = {
      _id: data._id,
    };
    dispatch(doneReminder(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchReminders());
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "doneReminderSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "doneReminderFail" }
        );
      }
    });
  };

  const sendInvoiceHandler = (data) => {
    history.push({
      pathname: "/db/invoices/new",
      state: { data: data && data.project_id, reminder_id: data._id },
    });
  };

  const recordPaymentHandler = (data) => {
    const Obj = {
      _id: data && data.invoice_id,
      reminder_id: data && data._id,
    };
    dispatch(paidInvoice(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        dispatch(fetchReminders());
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "recordPaymentSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "recordPaymentFail" }
        );
      }
    });
  };

  return (
    <div>
      {remindersData && remindersData.length > 0 ? (
        remindersData.map((data, index) => {
          return (
            <Styled.TodoRectangle
              key={index}
              style={{
                cursor: "auto",
                marginTop: 2,
                borderRadius:
                  remindersData.length === 1
                    ? "12px"
                    : remindersData.length - 1 === index
                    ? "0 0 12px 12px"
                    : remindersData.length - remindersData.length === index
                    ? "12px 12px 0  0"
                    : "",
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
                <div
                  className="d-flex justify-content-between align-items-center three-dots w-100"
                  style={{ maxWidth: 900 }}
                >
                  <TextStyles.FontSize14px className="d-flex align-items-center three-dots w-100">
                    <div
                      className="d-flex align-items-center"
                      style={{ width: 30 }}
                    >
                      {" "}
                      <MonetizationOnOutlinedIcon
                        sx={{
                          color: Colors.font2,
                          cursor: "pointer",
                          fontSize: 24,
                        }}
                      />
                    </div>

                    <div
                      className="d-flex flex-column w-100 ml-2"
                      style={{ minWidth: 50 }}
                    >
                      <TextStyles.FontSize14px className="text-start three-dots w-100">
                        {data.task_name}
                      </TextStyles.FontSize14px>
                      <TextStyles.GreyFont14px className="text-start three-dots w-100">
                        {data.project_id && data.project_id.project_name}
                      </TextStyles.GreyFont14px>
                    </div>
                  </TextStyles.FontSize14px>
                  <div className="d-flex align-items-center justify-content-end three-dots w-100">
                    <TextStyles.GreyFont14px
                      className="mr-3 three-dots"
                      style={{ minWidth: 50 }}
                    >
                      {data.client_id && data.client_id.company_name}
                    </TextStyles.GreyFont14px>
                    <TextStyles.FontSize14px
                      className="three-dots"
                      style={{ minWidth: 50 }}
                    >
                      {moment(data.due_date).format("DD MMM YYYY")}
                    </TextStyles.FontSize14px>
                  </div>
                </div>

                <div
                  style={{
                    width: 50,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <div>
                    <div className="dropdown">
                      <button
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <MoreHorizIcon
                          fontSize="large"
                          sx={{
                            background: Colors.white,
                            cursor: "pointer",
                            borderRadius: "6px",
                            padding: "2px 4px",
                            height: "30px",
                            color: "grey",
                          }}
                        />
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {data.task_name !== "Invoice deposit" &&
                          !data.invoice_id && (
                            <li>
                              <button className="dropdown-item">
                                <TextStyles.FontSize14px
                                  onClick={() => sendInvoiceHandler(data)}
                                >
                                  Send invoice
                                </TextStyles.FontSize14px>
                              </button>
                            </li>
                          )}

                        {data.invoice_id && (
                          <li>
                            <button className="dropdown-item">
                              <TextStyles.FontSize14px
                                onClick={() => recordPaymentHandler(data)}
                              >
                                Record full payment
                              </TextStyles.FontSize14px>
                            </button>
                          </li>
                        )}

                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={() => doneReminderHandler(data)}
                            >
                              Done
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item">
                            <TextStyles.FontSize14px
                              onClick={() => {
                                setShowDismissModal(true);
                                setConfirmDialog({
                                  onConfirm: () => dismissReminderHandler(data),
                                });
                              }}
                            >
                              Dismiss
                            </TextStyles.FontSize14px>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Styled.TodoRectangle>
          );
        })
      ) : isLoading && remindersData === null ? (
        <Styled.ListBox>
          <LoaderSpin color={Colors.primary} />
        </Styled.ListBox>
      ) : (
        <Styled.ListBox>
          <img src={NoReminderImage} alt="no-reminder-img" />
          <TextStyles.GreyFont18px className="mt-2">
            There are no reminders yet.
            <br />
            (Zodot will remind you to send invoices and follow up on invoices)
          </TextStyles.GreyFont18px>
        </Styled.ListBox>
      )}

      <DismissModal
        title="reminder"
        confirmDialog={confirmDialog}
        showDismissModal={showDismissModal}
        handleCloseDismissModal={handleCloseDismissModal}
      />
    </div>
  );
};

export default Reminder;
