import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import ClearIcon from "@mui/icons-material/Clear";
import { addAndEditInvoiceTaxes } from "../../../../store/service/invoiceService";
import LoaderSpin from "../../Commons/LoaderSpin";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import TextStyles from "../../../../Constants/TextStyles";
import Styled from "./Taxes";
import Colors from "../../../../Constants/Colors";

const InvoiceTaxes = ({
  show,
  handleClose,
  selectedData,
  totalServiceAmount,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.invoices.isLoading);

  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      taxes:
        selectedData && selectedData.taxes && selectedData.taxes.length > 0
          ? selectedData.taxes
          : [{}],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "taxes",
  });

  const formsubmit = (data) => {
    const Obj = {
      invoice_id: selectedData._id,
      taxes: data.taxes,
    };

    dispatch(addAndEditInvoiceTaxes(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();

        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "editInvoiceTaxSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "editInvoiceTaxFail" }
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="ToMakeModalOnRight500px"
        fullscreen
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          className="modal-content"
          style={{ height: "100%" }}
        >
          <Modal.Header className="d-flex align-items-center">
            <TextStyles.FontSize20px>Taxes</TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            {fields.map((item, index) => {
              const percentage = watch(`taxes.${index}.percentage`);
              const dValue =
                percentage && (totalServiceAmount * percentage) / 100;
              return (
                <Styled.taxBox key={item.id}>
                  <TextStyles.FontSize14px className="mb-3">
                    <label htmlFor="tax_label" style={{ display: "flex" }}>
                      Tax label
                    </label>
                    <div className="d-flex flex-row align-items-center">
                      <TextStyles.InputRectangle
                        style={{ width: "85%" }}
                        invalid={
                          errors.taxes &&
                          errors.taxes[index] &&
                          errors.taxes[index].tax_label
                        }
                        id="tax_label"
                        type="text"
                        placeholder="Tax label"
                        name="tax_label"
                        {...register(`taxes.${index}.tax_label`, {
                          required: {
                            value: true,
                            message: "Tax label can't be empty!",
                          },
                        })}
                      />
                      <span className="ml-4">
                        {fields.length !== 1 && (
                          <DeleteForeverRoundedIcon
                            className="deleteBtn"
                            onClick={() => remove(index)}
                            sx={{ color: Colors.font2, fontSize: 24 }}
                          />
                        )}
                      </span>
                    </div>
                    {errors.taxes &&
                      errors.taxes[index] &&
                      errors.taxes[index].tax_label && (
                        <TextStyles.InValidFeedback>
                          {errors.taxes &&
                            errors.taxes[index] &&
                            errors.taxes[index].tax_label &&
                            errors.taxes[index].tax_label.message}
                        </TextStyles.InValidFeedback>
                      )}
                  </TextStyles.FontSize14px>
                  <div className="mb-3">
                    <div className="d-flex flex-wrap align-items-center">
                      <TextStyles.FontSize14px className="mr-3">
                        <label htmlFor="value" style={{ display: "flex" }}>
                          Percentage
                        </label>
                        <Styled.inputBox
                          style={{ maxWidth: 110 }}
                          className="mr-3"
                          invalid={
                            errors.taxes &&
                            errors.taxes[index] &&
                            errors.taxes[index].percentage
                          }
                        >
                          <input
                            style={{
                              width: 55,
                              textAlign: "right",
                              background: "transparent",
                            }}
                            className="percentage"
                            type="number"
                            step="0.01"
                            id="percentage"
                            name="percentage"
                            placeholder="0.00"
                            {...register(`taxes.${index}.percentage`, {
                              required: {
                                value: true,
                                message: "Please enter valid percentage value!",
                              },
                              min: {
                                value: 1,
                                message: "Please enter more than zero!",
                              },
                              max: {
                                value: 99,
                                message: "Please enter less than 100!",
                              },
                            })}
                          />
                          <span>%</span>
                        </Styled.inputBox>
                      </TextStyles.FontSize14px>
                      <div
                        className="mr-3"
                        style={{ fontSize: 27, marginTop: 20 }}
                      >
                        =
                      </div>
                      <TextStyles.FontSize14px>
                        <label htmlFor="value" style={{ display: "flex" }}>
                          Value
                        </label>
                        <Styled.inputBox style={{ maxWidth: 110, width: 100 }}>
                          <span>
                            {selectedData &&
                              selectedData.currency &&
                              selectedData.currency.symbol}
                          </span>
                          <span>
                            {dValue ? parseFloat(dValue).toFixed(2) : "00.00"}
                          </span>
                        </Styled.inputBox>
                      </TextStyles.FontSize14px>
                    </div>
                    {errors.taxes &&
                      errors.taxes[index] &&
                      errors.taxes[index].percentage && (
                        <TextStyles.InValidFeedback className="mt-2">
                          {errors.taxes &&
                            errors.taxes[index] &&
                            errors.taxes[index].percentage &&
                            errors.taxes[index].percentage.message}
                        </TextStyles.InValidFeedback>
                      )}
                  </div>
                  <div
                    className="my-3"
                    style={{
                      width: "100%",
                      height: "1px",
                      border: "0.5px solid #edeef2",
                    }}
                  />
                </Styled.taxBox>
              );
            })}
            <TextStyles.FontSize14px className="d-flex justify-content-end">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => append({ tax_label: "", percentage: "" })}
              >
                <AddCircleOutlineIcon
                  sx={{ color: Colors.font1, fontSize: 22 }}
                />
                <span className="ml-2">Add another tax</span>
              </span>
            </TextStyles.FontSize14px>
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button
                disabled={isLoading ? true : false}
                style={{
                  opacity: isLoading ? 0.5 : 1,
                }}
              >
                {isLoading ? <LoaderSpin /> : "Save"}
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default InvoiceTaxes;
