import {
  postRequest,
  deleteRequest,
  putRequest,
  getRequest,
} from "../../axios/axios";
import URL from "../../axios/constant";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchPC = createAsyncThunk("pc/fetchPC", async (data) => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    postRequest(URL.FETCH_PROPOSAL_CONTRACT.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});

export const createPC = createAsyncThunk("pc/createPC", async (data) => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    postRequest(URL.CREATE_PROPOSAL_CONTRACT.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});

export const updateProjectDetailPC = createAsyncThunk(
  "pc/updateProjectDetailPc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_PROJECT_DETAILS_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateClientDetailPC = createAsyncThunk(
  "pc/updateClientDetailPc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_CLIENT_DETAILS_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateServiceListPC = createAsyncThunk(
  "pc/updateServiceListPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.EDIT_SERVICE_LIST_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateBillingSchedulePC = createAsyncThunk(
  "pc/updateBillingSchedulePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.EDIT_BILLING_SCHEDULE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createAddSectionTextPC = createAsyncThunk(
  "pc/createAddSectionTextPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_ADD_SECTION_TEXT_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteAddSectionTextPC = createAsyncThunk(
  "pc/deleteAddSectionTextPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      deleteRequest(URL.DELETE_ADD_SECTION_TEXT_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateAddSectionTextPC = createAsyncThunk(
  "pc/updateAddSectionTextPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_ADD_SECTION_TEXT_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createAddSectionImagePC = createAsyncThunk(
  "pc/createAddSectionImagePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_ADD_SECTION_IMAGE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteAddSectionImagePC = createAsyncThunk(
  "pc/deleteAddSectionImagePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      deleteRequest(URL.DELETE_ADD_SECTION_IMAGE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateAddSectionImagePC = createAsyncThunk(
  "pc/updateAddSectionImagePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_ADD_SECTION_IMAGE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createAddSectionTablePC = createAsyncThunk(
  "pc/createAddSectionTablePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_ADD_SECTION_TABLE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteAddSectionTablePC = createAsyncThunk(
  "pc/deleteAddSectionTablePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      deleteRequest(URL.DELETE_ADD_SECTION_TABLE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateAddSectionTablePC = createAsyncThunk(
  "pc/updateAddSectionTablePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_ADD_SECTION_TABLE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createAddSectionColumnPC = createAsyncThunk(
  "pc/createAddSectionColumnPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_ADD_SECTION_COLUMN_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteAddSectionColumnPC = createAsyncThunk(
  "pc/deleteAddSectionColumnPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      deleteRequest(URL.DELETE_ADD_SECTION_COLUMN_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateAddSectionColumnPC = createAsyncThunk(
  "pc/updateAddSectionColumnPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_ADD_SECTION_COLUMN_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createAddSectionFilePC = createAsyncThunk(
  "pc/createAddSectionFilePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_ADD_SECTION_FILE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateAddSectionFilePC = createAsyncThunk(
  "pc/updateAddSectionFilePc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_ADD_SECTION_FILE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deleteAddSectionFilePC = createAsyncThunk(
  "pc/deleteAddSectionFilePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      deleteRequest(URL.DELETE_ADD_SECTION_FILE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateSignaturePC = createAsyncThunk(
  "pc/updateSignaturePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_SIGNATURE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createContact = createAsyncThunk(
  "pc/create_contact",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      postRequest(URL.CREATE_CLIENT_CONTACT.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const emailSendPC = createAsyncThunk("pc/emailSendPc", async (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    postRequest(URL.EMAIL_SEND_PC.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});

export const contractSettingsPC = createAsyncThunk(
  "pc/contractSettingsPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.CONTRACT_SETTINGS_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const contractExpireDatePC = createAsyncThunk(
  "pc/contractExpireDatePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.CONTRACT_EXPIRE_DATE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateResponsibilityPC = createAsyncThunk(
  "pc/updateResponsibilityPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_RESPONSIBILITY_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateCompensationPC = createAsyncThunk(
  "pc/updateCompensationPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_COMPENSATION_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateAcceptancePC = createAsyncThunk(
  "pc/updateAcceptancePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_ACCEPTANCE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateWarrantyPC = createAsyncThunk(
  "pc/updateWarrantyPc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_WARRANTY_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateConfidentialPC = createAsyncThunk(
  "pc/updateConfidentialPc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_CONFIDENTIAL_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateOwnershipRightPC = createAsyncThunk(
  "pc/updateOwnershipRightPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_OWNERSHIP_RIGHT_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateNonSolicitPC = createAsyncThunk(
  "pc/updateNonSolicitPc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_NON_SOLICIT_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateAddNonCompletePC = createAsyncThunk(
  "pc/updateAddNonCompletePc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_ADD_NON_COMPLETE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateRelationshipOfPartiesPC = createAsyncThunk(
  "pc/updateRelationshipOfPartiesPc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_RELATIONSHIP_OF_PARTIES_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateProjectTerminationNoticePeriodPC = createAsyncThunk(
  "pc/updateProjectTerminationNoticePeriodPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(
        URL.UPDATE_PROJECT_TERMINATION_NOTICE_PERIOD_PC.url,
        token,
        data
      )
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateCancellationFeePC = createAsyncThunk(
  "pc/updateCancelationFeePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_CANCELLATION_FEE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updatePaymentTermsPC = createAsyncThunk(
  "pc/updatePaymentTermsPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_PAYMENT_TERMS_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateContractorTaxIdPC = createAsyncThunk(
  "pc/updateContractorTaxIdPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_CONTRACTOR_TAX_PAYEER_ID_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateHealthInsurancePC = createAsyncThunk(
  "pc/updateHealthInsurancePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_HEALTH_INSURANCE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateLatePaymentPC = createAsyncThunk(
  "pc/updateLatePaymentPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_LATE_PAYMENT_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateDepositPC = createAsyncThunk(
  "pc/updateDepositPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_DEPOSIT_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateReimbursementPC = createAsyncThunk(
  "pc/updateReimbursementPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_REIMBURSEMENT_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateTerminationRequestPC = createAsyncThunk(
  "pc/updateTerminationRequestPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_TERMINATION_REQUEST_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateChangesPC = createAsyncThunk(
  "pc/updateChangesPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_CHANGES_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateLiabilityLimitationPC = createAsyncThunk(
  "pc/updateLiabilityLimitationPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_LIABILITY_LIMITATION_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateRetainRightsPC = createAsyncThunk(
  "pc/updateRetainRightsPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_RETAIN_RIGHTS_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateGoverningLawPC = createAsyncThunk(
  "pc/updateGoverningLawPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_GOVERNING_LAW_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateForceMejeurePC = createAsyncThunk(
  "pc/updateForceMejeurePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_FORCE_MEJEURE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);
export const updateNoticesPC = createAsyncThunk(
  "pc/updateNoticesPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_NOTICES_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateAddSexualHarassmentCluasePC = createAsyncThunk(
  "pc/updateAddSexualHarassmentClausePc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_ADD_SEXUAL_HARASSMENT_CLAUSE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updatePrivacyDataPC = createAsyncThunk(
  "pc/updatePrivacyDataPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_PRIVACY_DATA_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateMiscellaneousPC = createAsyncThunk(
  "pc/updateMiscellaneousPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_MISCELLANEOUS_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateEntireContractPC = createAsyncThunk(
  "pc/updateEntireContractPc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_ENTIRE_CONTRACT_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const createAddClausePC = createAsyncThunk(
  "pc/addAddClausePc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.CREATE_ADDCLAUSE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const updateAddClausePC = createAsyncThunk(
  "pc/updateAddClausePc",
  async (data) => {
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      putRequest(URL.UPDATE_ADDCLAUSE_PC.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const deletePC = createAsyncThunk("pc/deletePc", async (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    deleteRequest(URL.DELETE_PC.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});

export const dismissedPcByUser = createAsyncThunk("pc/dismissed", async (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    putRequest(URL.DISMISSED_PC_BY_USER.url, token, data)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});

export const approvedPcByUser = createAsyncThunk(
  "pc/approvedPc",
  async (data) => {
    const token = localStorage.getItem("token");

    return new Promise((resolve, reject) => {
      putRequest(URL.APPROVED_PC_BY_USER.url, token, data)
        .then((data) => {
          if (data.data.success) resolve(data.data);
        })
        .catch((err) => {
          if (err.response) {
            reject(err.response.data.message);
          } else {
            reject(err);
          }
        });
    });
  }
);

export const fetchPCCounts = createAsyncThunk("pc/fetchPcCounts", async () => {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    getRequest(URL.FETCH_PC_COUNTS.url, token)
      .then((data) => {
        if (data.data.success) resolve(data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err);
        }
      });
  });
});
