import React from "react";
import styles from "./Features.module.css";
import { NavLink } from "react-router-dom";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import TaskFeature1 from "../../../../Assets/frontendImages/TaskFeature1.avif";
import TaskFeature2 from "../../../../Assets/frontendImages/TaskFeature2.avif";
import TaskFeature3 from "../../../../Assets/frontendImages/TaskFeature3.avif";
import TaskFeature4 from "../../../../Assets/frontendImages/TaskFeature4.avif";

const Features = () => {
  return (
    <div className={`${styles.section}`}>
      <div className="container-fluid py-3 py-md-4" style={{ maxWidth: 1100 }}>
        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100">
            <div
              data-aos={"fade-right"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={TaskFeature1}
                alt="TaskFeature1"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="text-start mt-3 mt-md-0 w-100">
            <div className="w-100" data-aos={"fade-left"}>
              <div className={`${styles.heading} mb-3`}>
                Stay on course with your targets
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Let Zodot do the heavy lifting for business growth by managing
                tasks and reminders. Add your own to-do's whenever and arrange
                them as you please. Your tasks and reminders are prominently
                displayed on your dashboard.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">Get started now</span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="text-start mt-3 mt-md-0 w-100 order-1 order-md-0">
            <div className="w-100" data-aos={"fade-right"}>
              <div className={`${styles.heading} mb-3`}>
                Be the master of your workflow
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Track the progress of your project’s task with Zodot’s online
                time tracking. Change the status of your task in progress to a
                task completed from the dashboard only.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">Get organized now</span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 order-0 order-md-1">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={TaskFeature2}
                alt="TaskFeature2"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center mb-3 mb-md-5"
          style={{ overflow: "hidden" }}
        >
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 ">
            <div
              data-aos={"fade-right"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={TaskFeature3}
                alt="TaskFeature3"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="text-start mt-3 mt-md-0 w-100">
            <div className="w-100" data-aos={"fade-left"}>
              <div className={`${styles.heading} mb-3`}>Stay in the zone</div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Keep your productivity on track and increase your priority
                management using the dashboard. It reveals tasks as they become
                actionable. If a task isn't suitable for immediate attention,
                you can reschedule it for a later time. You can even set the
                frequency of task repetition from the dashboard only.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Maximize productivity
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>

                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex gap-3 gap-md-5 flex-column flex-md-row align-items-center"
          style={{ overflow: "hidden" }}
        >
          <div className="text-start mt-3 mt-md-0 w-100 order-1 order-md-0">
            <div className="w-100" data-aos={"fade-right"}>
              <div className={`${styles.heading} mb-3`}>
                Task generation made easy
              </div>
              <div className={`${styles.text_font1} mb-3 mb-md-5`}>
                Create tasks according to your deadlines, projects, and contract
                details. Be it sending invoices, signing contracts, or handling
                expenses, it's all set for you.
              </div>
              <div className="d-flex mb-3">
                <NavLink
                  className={styles.more_button}
                  style={{ textDecoration: "none" }}
                  to="/db/signup"
                >
                  <span className="d-none d-sm-inline">
                    Simplify tasks with Zodot
                  </span>
                  <span className="d-inline d-sm-none">Start now</span>
                  <span className={`${styles.farword_arrow}`}>
                    {" "}
                    <EastOutlinedIcon sx={{ fontSize: 24 }} />
                  </span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-3 mt-md-0 pb-3 w-100 order-0 order-md-1">
            <div
              data-aos={"fade-left"}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: 500,
                maxHeight: 500,
              }}
            >
              <img
                src={TaskFeature4}
                alt="TaskFeature4"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
