import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNotifications,
  ViewedNotifications,
} from "../service/notificationService";

const initialNotificationState = {
  notifications: null,
  totalCount: null,
  page: 1,
  isNotViewed: null,
  isLoading: false,
  error: null,
};

export const notificationSlice = createSlice({
  name: "reminder",
  initialState: initialNotificationState,
  reducers: {},
  extraReducers: {
    //TODO: fetch notifications
    [fetchNotifications.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchNotifications.fulfilled]: (state, action) => {
      state.isLoading = false;
      if (action.payload.data.page > 1) {
        state.notifications.push(...action.payload.data.data);
      } else {
        state.notifications = action.payload.data.data;
      }
      state.totalCount = action.payload.data.totalCount;
      state.isNotViewed = action.payload.data.isNotViewed;
      state.page = action.payload.data.page;
    },
    [fetchNotifications.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //TODO: viewed notifications
    [ViewedNotifications.pending]: (state) => {
      state.isLoading = true;
    },
    [ViewedNotifications.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload.data;
      state.isNotViewed = null;
    },
    [ViewedNotifications.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});
export const notificationAction = notificationSlice.actions;
export default notificationSlice.reducer;
