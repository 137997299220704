import React from "react";
import { Modal } from "react-bootstrap";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import LoaderSpin from "../../../../Commons/LoaderSpin";
import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../../../Constants/TextStyles";

import { createAddSectionColumnPC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";

const AddSectionColumnModal = ({
  show,
  handleClose,
  selectedData,
  position,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const formsubmit = (data) => {
    const Obj = {
      pc_id: selectedData._id,
      column_title: data.column_title,
      input: {
        input_one: data.input_one,
        input_two: data.input_two,
        input_three: data.input_three,
      },
      position: position,
    };
    dispatch(createAddSectionColumnPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        reset();
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "createAddSectionSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "createAddSectionFail" }
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        scrollable={true}
        fullscreen
        dialogClassName="ToMakeModalWidth600px"
        contentClassName="ToMakeModalBorderRudius12px"
      >
        <form
          onSubmit={handleSubmit(formsubmit)}
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <Modal.Header
            className="d-flex align-items-center"
            style={{ borderBottom: "0px" }}
          >
            <TextStyles.FontSize20px className="d-flex justify-content-start">
              Add column
            </TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>

          <Modal.Body>
            <TextStyles.FontSize14px className="mb-2">
              <label htmlFor="column_title" style={{ display: "flex" }}>
                Column title
              </label>
              <TextStyles.InputRectangle
                id="column_title"
                invalid={errors.column_title}
                type="text"
                placeholder="Section title (e.g, Overview and goals )"
                name="column_title"
                {...register("column_title", {
                  required: "Column title can not be empty.",
                })}
              />
              {errors.column_title && (
                <TextStyles.InValidFeedback>
                  {errors.column_title.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>

            <TextStyles.FontSize14px className="d-flex gap-2 gap-sm-3 justify-content-between align-items-center">
              <div className="d-flex justify-content-start align-items-start w-100">
                <TextareaAutosize
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    padding: "1rem 0.6rem",
                    border: errors.input_one
                      ? `1px solid ${Colors.red}`
                      : `1px solid ${Colors.font2}`,
                    background: errors.input_one
                      ? Colors.inputErrorBg
                      : Colors.white,
                    borderRadius: 12,
                  }}
                  minRows={15}
                  id="textarea"
                  placeholder="Type here..."
                  name="input_one"
                  {...register("input_one", {
                    required: "Column can not be empty.",
                  })}
                />
                {errors.input_one && (
                  <TextStyles.InValidFeedback>
                    {errors.input_one.message}
                  </TextStyles.InValidFeedback>
                )}
              </div>
              <div className="d-flex justify-content-start align-items-start w-100">
                <TextareaAutosize
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    padding: "1rem 0.6rem",
                    border: errors.input_two
                      ? `1px solid ${Colors.red}`
                      : `1px solid ${Colors.font2}`,
                    background: errors.input_two
                      ? Colors.inputErrorBg
                      : Colors.white,
                    borderRadius: 12,
                  }}
                  minRows={15}
                  id="textarea"
                  placeholder="Type here..."
                  name="input_two"
                  {...register("input_two", {
                    required: "Column can not be empty.",
                  })}
                />
                {errors.input_two && (
                  <TextStyles.InValidFeedback>
                    {errors.input_two.message}
                  </TextStyles.InValidFeedback>
                )}
              </div>
              <div className="d-flex justify-content-start align-items-start w-100">
                <TextareaAutosize
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    padding: "1rem 0.6rem",
                    border: errors.input_one
                      ? `1px solid ${Colors.red}`
                      : `1px solid ${Colors.font2}`,
                    background: errors.input_one
                      ? Colors.inputErrorBg
                      : Colors.white,
                    borderRadius: 12,
                  }}
                  minRows={15}
                  id="textarea"
                  name="input_three"
                  placeholder="Type here..."
                  {...register("input_three", {
                    required: "Column can not be empty.",
                  })}
                />
                {errors.input_three && (
                  <TextStyles.InValidFeedback>
                    {errors.input_three.message}
                  </TextStyles.InValidFeedback>
                )}
              </div>
            </TextStyles.FontSize14px>
          </Modal.Body>

          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-around align-items-center">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button
                disabled={isLoading ? true : false}
                style={{
                  opacity: isLoading ? 0.5 : 1,
                }}
              >
                {isLoading ? <LoaderSpin /> : "Save"}
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default AddSectionColumnModal;
