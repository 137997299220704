import React from "react";

import TextStyles from "../../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";

const NonCompete = (props) => {
  const selectedData = props && props.selectedData;

  const heading =
    selectedData &&
    selectedData.add_non_complete &&
    selectedData.add_non_complete.heading
      ? selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.heading
      : "Non-Compete";
  const paragraph =
    selectedData &&
    selectedData.add_non_complete &&
    selectedData.add_non_complete.paragraph
      ? selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.paragraph
      : "Note: This section only show if applicable.";

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.add_non_complete &&
          selectedData.add_non_complete.required === true
            ? "inline"
            : "none",
      }}
    >
      {selectedData &&
        selectedData.add_non_complete &&
        selectedData.add_non_complete.add_non_complete_clause_require ===
          true && (
          <Styled.ReviewContentBox className="mt-4">
            <TextStyles.FontSize20px className="mb-2">
              {heading}
            </TextStyles.FontSize20px>
            <TextStyles.FontSize14px>
              {selectedData &&
              selectedData.add_non_complete &&
              selectedData.add_non_complete.add_non_complete_clause_require ===
                true
                ? selectedData.add_non_complete.add_non_complete_clause_text
                : paragraph}
            </TextStyles.FontSize14px>
          </Styled.ReviewContentBox>
        )}
    </div>
  );
};

export default NonCompete;
