import React from "react";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import AgreementPdf from "./agreementPdf";

const generatePdfDocument = async (
  data,
  userData,
  businessInfoData,
  brandAndLogoData
) => {
  const blob = await pdf(
    <AgreementPdf
      selectedData={data}
      data={userData}
      businessInfoData={businessInfoData}
      brandAndLogoData={brandAndLogoData}
    />
  ).toBlob();
  saveAs(blob, data && data.project_name);
};

export default generatePdfDocument;
