import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { postRequest } from "./../../axios/axios";
import URL from "../../axios/constant";
import logo from "../../Assets/assetsnew/backend_logo_with_name.webp";
import loginBg from "../../Assets/frontendImages/loginBg.svg";
import TextStyles from "../../Constants/TextStyles";
import LoaderSpin from "../NewDashboard/Commons/LoaderSpin";
import Colors from "../../Constants/Colors";
import Styled from "./AuthStyles";

const SuccessSignup = (props) => {
  const history = useHistory();
  const { registeredEmail } = props && props.history && props.history.location;
  const [isLoading, setIsLoading] = useState(false);
  const resendEmail = async () => {
    setIsLoading(true);
    postRequest(URL.RESEND_EMAIL.url, {}, { email: registeredEmail })
      .then((data) => {
        if (data && data.data && data.data.success) {
          setIsLoading(false);
          toast.success(
            <TextStyles.FontSize14px>
              {data.data.message}
            </TextStyles.FontSize14px>,
            {
              toastId: "success",
            }
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err.response && err.response.data) {
          return toast.error(
            <TextStyles.FontSize14px>
              {err.response.data.message}
            </TextStyles.FontSize14px>,
            { toastId: "fail" }
          );
        }
        if (err.isAxiosError) {
          return toast.error(
            <TextStyles.FontSize14px>{err.message}</TextStyles.FontSize14px>,
            { toastId: "error" }
          );
        }
      });
  };
  return (
    <div style={{ backgroundColor: "#fafbfc" }}>
      <div
        className="fixed-top"
        style={{
          backgroundColor: "#fafbfc",
          width: "100%",
          zIndex: 1000,
          boxShadow: "0 2px 4px 2px rgba(180, 180, 200, 0.2)",
        }}
      >
        <div
          className="d-flex justify-content-between align-items-center px-2 px-sm-3"
          style={{ height: 80, width: "100%" }}
        >
          <TextStyles.FontSize27px
            className="d-flex justify-content-center"
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/")}
          >
            <img src={logo} alt="logo" style={{ maxWidth: 220 }} />
          </TextStyles.FontSize27px>
          <TextStyles.FontSize16px className="d-flex justify-content-end align-items-center w-100">
            <div className="d-none d-sm-flex mr-3">Don’t have an account?</div>
            <Styled.LinkSpan
              className="text-center"
              onClick={() => history.push("/db/signup")}
            >
              Sign up
            </Styled.LinkSpan>
          </TextStyles.FontSize16px>
        </div>
      </div>
      <div style={{ paddingTop: 80 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: `url(${loginBg})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            minHeight: "calc(100vh - 80px)",
            width: "100%",
          }}
        >
          <div className="container-fluid pb-4">
            <div className="d-flex justify-content-center align-items-center">
              <Styled.Box>
                <TextStyles.FontSize27px
                  className="text-center mb-4"
                  style={{ fontWeight: 500, lineHeight: "1.2" }}
                >
                  Check your inbox for a verification email!
                </TextStyles.FontSize27px>
                <div className="text-center mb-4">
                  <TextStyles.GreyFont16px>
                    Didn’t get the email?
                    <span className="pl-2" style={{ color: Colors.font1 }}>
                      Resend or Sign up with a different email.
                    </span>
                  </TextStyles.GreyFont16px>
                </div>
                <div className="d-flex justify-content-center mb-4">
                  <TextStyles.Button
                    disabled={isLoading}
                    onClick={resendEmail}
                    style={{
                      fontWeight: 500,
                      fontSize: 16,
                    }}
                  >
                    {isLoading ? <LoaderSpin /> : " Resend email"}
                  </TextStyles.Button>
                </div>
                <div className="d-flex justify-content-center">
                  <TextStyles.FontSize16px>
                    Email already verified?
                    <Styled.Link
                      onClick={() => history.push("/db/login")}
                      style={{
                        color: Colors.primary,
                        paddingLeft: 6,
                        fontWeight: 500,
                      }}
                    >
                      Login now
                    </Styled.Link>
                  </TextStyles.FontSize16px>
                </div>
              </Styled.Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessSignup;
