import { createSlice } from "@reduxjs/toolkit";
import { deleteUserAccount } from "../service/deleteUserAccountService";

const initialDeleteUserAccountState = {
  isLoading: false,
  error: null,
};

export const deleteUserAccountSlice = createSlice({
  name: "deleteUserAccount",
  initialState: initialDeleteUserAccountState,
  reducers: {},
  extraReducers: {
    // TODO: delete user account
    [deleteUserAccount.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteUserAccount.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [deleteUserAccount.error]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});
export const deleteUserAccountAction = deleteUserAccountSlice.actions;
export default deleteUserAccountSlice.reducer;
