import { createSlice } from "@reduxjs/toolkit";
import {
  fetchUserSetupData,
  hideSetupInfo,
  updateUserInfo,
  getUserInfo,
  updateUserPassword,
  updateBusinessInfo,
  updateProfileInfoPic,
  getProfileInfoPic,
  deleteProfileInfoPic,
  fetchBusinessInfo,
  setUserCurrency,
  getUserCurrency,
  updateBrandColor,
  updateBrandLogo,
  removeBrandLogo,
  updateLogoAlignment,
  fetchBrandAndLogo,
  fetchTemplates,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  fetchUserTourSetupData,
  updateUserTourSetupData,
} from "../service/AuthService";

const initialAuthState = {
  isLoading: false,
  error: false,
  resetData: null,
  userSetupData: null,
  userTourSetupData: null,
  data: null,
  profileData: null,
  businessInfoData: null,
  userCurrency: null,
  brandAndLogoData: {
    brand_color: null,
    brand_logo: null,
    logo_alignment: null,
  },
  templateData: null,
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialAuthState,
  reducers: {
    login: (state, action) => {
      state.data = action.payload;
    },
    resetPassword: (state, action) => {
      state.resetData = action.payload;
    },
    logout: () => {
      localStorage.clear();
      localStorage.setItem("logout", Date.now().toString());
    },
  },
  extraReducers: {
    //  user setup info
    [fetchUserSetupData.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchUserSetupData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userSetupData = action.payload.data;
    },
    [fetchUserSetupData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //  user setup info
    [hideSetupInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [hideSetupInfo.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [hideSetupInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update user info
    [updateUserInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [updateUserInfo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.profileData = action.payload.data;
      state.data.firstName = action.payload.data.parent_user_id.firstName;
      state.data.lastName = action.payload.data.parent_user_id.lastName;
      state.data.email = action.payload.data.parent_user_id.email;
    },
    [updateUserInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update user info
    [getUserInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserInfo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.profileData = action.payload.data;
    },
    [getUserInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update password
    [updateUserPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [updateUserPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateUserPassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // business Info
    [updateBusinessInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [updateBusinessInfo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.businessInfoData = action.payload.data;
    },
    [updateBusinessInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update user profile
    [updateProfileInfoPic.pending]: (state) => {
      state.isLoading = false;
    },
    [updateProfileInfoPic.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.profilePic = action.payload.data.profilePic;
      state.data.imageUrl = action.payload.data.imageUrl;
    },
    [updateProfileInfoPic.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // get user profile
    [getProfileInfoPic.pending]: (state) => {
      state.isLoading = false;
    },
    [getProfileInfoPic.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.profilePic =
        action.payload.data.profilePic && action.payload.data.profilePic;
      state.data.imageUrl =
        action.payload.data.imageUrl && action.payload.data.imageUrl;
    },
    [getProfileInfoPic.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete user profile
    [deleteProfileInfoPic.pending]: (state) => {
      state.isLoading = false;
    },
    [deleteProfileInfoPic.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data.profilePic = null;
      state.data.imageUrl = null;
    },
    [deleteProfileInfoPic.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch Business Info
    [fetchBusinessInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchBusinessInfo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.businessInfoData = action.payload.data;
    },
    [fetchBusinessInfo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // set User Currency
    [setUserCurrency.pending]: (state) => {
      state.isLoading = true;
    },
    [setUserCurrency.fulfilled]: (state, action) => {
      state.userCurrency = action.payload.data;
      state.isLoading = false;
    },
    [setUserCurrency.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // get User Currency
    [getUserCurrency.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserCurrency.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userCurrency = action.payload.data;
    },
    [getUserCurrency.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch brand and logo
    [fetchBrandAndLogo.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchBrandAndLogo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.brandAndLogoData.brand_color =
        action.payload.data && action.payload.data.brand_color
          ? action.payload.data.brand_color
          : null;
      state.brandAndLogoData.brand_logo =
        action.payload.data && action.payload.data.brand_logo
          ? action.payload.data.brand_logo
          : null;
      state.brandAndLogoData.logo_alignment =
        action.payload.data && action.payload.data.logo_alignment
          ? action.payload.data.logo_alignment
          : null;
    },

    [fetchBrandAndLogo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update brand Color
    [updateBrandColor.pending]: (state) => {
      state.isLoading = true;
    },
    [updateBrandColor.fulfilled]: (state, action) => {
      state.brandAndLogoData.brand_color = action.payload.data.brand_color;
      state.isLoading = false;
    },
    [updateBrandColor.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update brand logo
    [updateBrandLogo.pending]: (state) => {
      state.isLoading = true;
    },
    [updateBrandLogo.fulfilled]: (state, action) => {
      state.brandAndLogoData.brand_logo = action.payload.data.brand_logo;
      state.isLoading = false;
    },
    [updateBrandLogo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // remove brand logo
    [removeBrandLogo.pending]: (state) => {
      state.isLoading = true;
    },
    [removeBrandLogo.fulfilled]: (state, action) => {
      state.brandAndLogoData.brand_logo = action.payload.data.brand_logo;
      state.isLoading = false;
    },
    [removeBrandLogo.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    // update logo alignement
    [updateLogoAlignment.pending]: (state) => {
      state.isLoading = true;
    },
    [updateLogoAlignment.fulfilled]: (state, action) => {
      state.brandAndLogoData.logo_alignment =
        action.payload.data.logo_alignment;
      state.isLoading = false;
    },
    [updateLogoAlignment.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // fetch templates
    [fetchTemplates.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchTemplates.fulfilled]: (state, action) => {
      state.templateData = action.payload.data;
      state.isLoading = false;
    },
    [fetchTemplates.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // create template
    [createTemplate.pending]: (state) => {
      state.isLoading = true;
    },
    [createTemplate.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createTemplate.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update template
    [updateTemplate.pending]: (state) => {
      state.isLoading = true;
    },
    [updateTemplate.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateTemplate.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // delete template
    [deleteTemplate.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteTemplate.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [deleteTemplate.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    //  fetch user tour setup info
    [fetchUserTourSetupData.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchUserTourSetupData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userTourSetupData = action.payload.data;
    },
    [fetchUserTourSetupData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    // update user tour setup info
    [updateUserTourSetupData.pending]: (state) => {
      state.isLoading = true;
    },
    [updateUserTourSetupData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.userTourSetupData = action.payload.data;
    },
    [updateUserTourSetupData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
