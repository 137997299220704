import React from "react";
import { useForm } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";

import LoaderSpin from "../../Commons/LoaderSpin";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { createClient } from "../../../../store/service/clientService";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

export default function createClinets({
  show,
  handleClose,
  setUpdateRefresh,
  updateRefresh,
}) {
  const dispatch = useDispatch();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const isLoading = useSelector((state) => state.clients.isLoading);

  const formSubmit = async (data) => {
    const Obj = {
      contact_email: data.email,
      company_name: data.company,
      contact_name: data.name,
      street: data.street,
      suite: data.suite,
      city: data.city,
      zip_code: data.zipcode,
      country: data.country,
      state: data.state,
    };

    dispatch(createClient(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        setUpdateRefresh(!updateRefresh);
        toast.success(
          <TextStyles.FontSize14px>
            {data.payload.message}
          </TextStyles.FontSize14px>,
          { toastId: "success" }
        );
        reset();
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          {
            toastId: "fail",
          }
        );
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        fullscreen
        dialogClassName="ToMakeFullscreenModal"
      >
        <div className="modal-header d-flex justify-content-center">
          <TextStyles.FontSize14px
            style={{
              position: "absolute",
              left: "1rem",
              top: "1.25rem",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            <ClearIcon
              sx={{
                fontSize: 30,
                color: Colors.font2,
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize20px
            className="d-flex justify-content-center"
            style={{ fontWeight: 500, fontSize: "1.5rem" }}
          >
            Create client
          </TextStyles.FontSize20px>
        </div>
        <div className="modal-body d-flex justify-content-center">
          <div style={{ maxWidth: 600, width: "100%" }}>
            <form onSubmit={handleSubmit(formSubmit)}>
              <TextStyles.FontSize14px className="my-3">
                <label htmlFor="compnay_name" style={{ display: "flex" }}>
                  Company name
                </label>
                <TextStyles.InputRectangle
                  invalid={errors.company}
                  id="company_name"
                  type="text"
                  placeholder="Company name"
                  name="company"
                  {...register("company", {
                    required: "Company can't be empty.",
                  })}
                />
                {errors.company && (
                  <TextStyles.InValidFeedback>
                    {errors.company.message}
                  </TextStyles.InValidFeedback>
                )}
              </TextStyles.FontSize14px>

              <TextStyles.FontSize14px className="mb-3">
                <label htmlFor="contact_name" style={{ display: "flex" }}>
                  Contact name
                </label>
                <TextStyles.InputRectangle
                  invalid={errors.name}
                  id="contact_name"
                  type="text"
                  placeholder="Contact name"
                  name="name"
                  {...register("name", {
                    required: "Name can't be empty.",
                  })}
                />
                {errors.name && (
                  <TextStyles.InValidFeedback>
                    {errors.name.message}
                  </TextStyles.InValidFeedback>
                )}
              </TextStyles.FontSize14px>

              <TextStyles.FontSize14px className="mb-3">
                <label htmlFor="contact_email" style={{ display: "flex" }}>
                  Contact email
                </label>
                <TextStyles.InputRectangle
                  invalid={errors.email}
                  id="contact_email"
                  type="email"
                  placeholder="Contact email"
                  name="email"
                  {...register("email", {
                    required: "Email can't be empty.",
                    pattern: {
                      value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
                      message: "Please enter valid email address.",
                    },
                  })}
                />
                {errors.email && (
                  <TextStyles.InValidFeedback>
                    {errors.email.message}
                  </TextStyles.InValidFeedback>
                )}
              </TextStyles.FontSize14px>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <TextStyles.FontSize14px className="mb-3">
                    <label htmlFor="street" style={{ display: "flex" }}>
                      Street
                    </label>
                    <TextStyles.InputRectangle
                      id="street"
                      type="text"
                      placeholder="Street"
                      name="street"
                      {...register("street")}
                    />
                  </TextStyles.FontSize14px>
                </div>
                <div className="col-12 col-sm-6">
                  <TextStyles.FontSize14px className="mb-3">
                    <label htmlFor="suite" style={{ display: "flex" }}>
                      Suite
                    </label>
                    <TextStyles.InputRectangle
                      id="suite"
                      type="text"
                      placeholder="Suite"
                      name="suite"
                      {...register("suite")}
                    />
                  </TextStyles.FontSize14px>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <TextStyles.FontSize14px className="mb-3">
                    <label htmlFor="city" style={{ display: "flex" }}>
                      City
                    </label>
                    <TextStyles.InputRectangle
                      id="city"
                      type="text"
                      placeholder="City"
                      name="city"
                      {...register("city")}
                    />
                  </TextStyles.FontSize14px>
                </div>
                <div className="col-12 col-sm-6">
                  <TextStyles.FontSize14px className="mb-3">
                    <label htmlFor="zipcode" style={{ display: "flex" }}>
                      Zip code
                    </label>
                    <TextStyles.InputRectangle
                      id="zipcode"
                      type="text"
                      placeholder="Zip code"
                      name="zipcode"
                      {...register("zipcode")}
                    />
                  </TextStyles.FontSize14px>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <TextStyles.FontSize14px className="mb-3">
                    <label htmlFor="country" style={{ display: "flex" }}>
                      Country
                    </label>
                    <TextStyles.InputRectangle
                      id="country"
                      type="text"
                      placeholder="Country"
                      name="country"
                      {...register("country")}
                    />
                  </TextStyles.FontSize14px>
                </div>
                <div className="col-12 col-sm-6">
                  <TextStyles.FontSize14px className="mb-3">
                    <label htmlFor="state" style={{ display: "flex" }}>
                      State
                    </label>
                    <TextStyles.InputRectangle
                      id="state"
                      type="text"
                      placeholder="State"
                      name="state"
                      {...register("state")}
                    />
                  </TextStyles.FontSize14px>
                </div>
              </div>
              <div className="pb-4">
                <TextStyles.Button
                  disabled={isLoading ? true : false}
                  style={{
                    opacity: isLoading ? 0.5 : 1,
                  }}
                >
                  {isLoading ? <LoaderSpin /> : "Create client"}
                </TextStyles.Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
}
