import React from "react";
import { Modal } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";
import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";

const DepositModal = ({ show, handleClose, heading, paragraph }) => {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        contentClassName="ToMakeModalBorderRudius12px"
      >
        <form style={{ display: "flex", flexDirection: "column" }}>
          <Modal.Header className="d-flex align-items-center">
            <TextStyles.FontSize20px className="d-flex justify-content-start">
              Edit contract
            </TextStyles.FontSize20px>
            <ClearIcon
              sx={{
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="headline">Headline</label>
              <TextStyles.InputRectangle
                placeholder={heading}
                id="headline"
                name="heading"
                defaultValue={heading}
                style={{ opacity: 0.6 }}
                readOnly
              />
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="headline">Paragraph</label>
              <TextStyles.Textarea
                rows={8}
                placeholder="Add text here"
                id="paragraph"
                name="paragraph"
                defaultValue={paragraph}
                style={{ opacity: 0.6 }}
                readOnly
              />
            </TextStyles.FontSize14px>
          </Modal.Body>
          <Modal.Footer style={{ display: "inline-block" }}>
            <div className="d-flex gap-3 justify-content-between">
              <TextStyles.CancelButton onClick={handleClose}>
                Cancel
              </TextStyles.CancelButton>
              <TextStyles.Button onClick={handleClose}>
                Update
              </TextStyles.Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default DepositModal;
