import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateCompensationPC } from "../../../../../../store/service/proposalAndContractService";
import CompensationModal from "../../PageModals/CompensationModal";
import RemoveModal from "../../../../Commons/RemoveModal";
import Colors from "../../../../../../Constants/Colors";

const Compensation = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const handleCloseRemoveModal = () => setShowRemoveModal(false);
  const heading =
    selectedData &&
    selectedData.compensation &&
    selectedData.compensation.heading
      ? selectedData &&
        selectedData.compensation &&
        selectedData.compensation.heading
      : "Compensation";
  const paragraph =
    selectedData &&
    selectedData.compensation &&
    selectedData.compensation.paragraph
      ? selectedData &&
        selectedData.compensation &&
        selectedData.compensation.paragraph
      : `Client will pay the Contractor a compensation at the decided rate (Per hour, day, item or word), based on invoices submitted by Contractor to the Client on basis (Once, weekly, bi-weekly, monthly) within the due date of receipt of said invoices. Contractor acknowledges and agrees that Contractor must accurately and truthfully report to the Client the number of working hours per each pay period (the “Payroll Worksheet”).`;

  const hideClauseHandler = () => {
    const Obj = {
      _id: selectedData._id,
      compensation: {
        required: false,
        heading: null,
        paragraph: null,
      },
    };
    dispatch(updateCompensationPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleCloseRemoveModal();
        toast.success(
          <TextStyles.FontSize14px>
            Cluase hidden successfully!
          </TextStyles.FontSize14px>,
          { toastId: "updateCompensationSuccess" }
        );
      } else {
        toast.error(
          <TextStyles.FontSize14px>
            {data.error.message}!
          </TextStyles.FontSize14px>,
          { toastId: "updateCompensationFail" }
        );
      }
    });
  };

  return (
    <div
      style={{
        display:
          selectedData &&
          selectedData.compensation &&
          selectedData.compensation.required === true
            ? "inline"
            : "none",
      }}
    >
      <Styled.ReviewContentBox className="mt-4">
        <Styled.EditButtonToolTip
          onClick={() => setShowModal(true)}
          className="contract_review_edit_tp"
          style={{ marginLeft: -60 }}
        >
          <BorderColorOutlinedIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ml-1">Edit</span>
        </Styled.EditButtonToolTip>
        <Styled.EditButtonToolTip
          backgroundColor="#383838"
          className="contract_review_edit_tp"
          style={{ marginLeft: 60 }}
          onClick={() => {
            setShowRemoveModal(true);
            setConfirmDialog({
              onConfirm: hideClauseHandler,
            });
          }}
        >
          <VisibilityOffIcon sx={{ color: Colors.white, fontSize: 20 }} />
          <span className="ml-1">Hide</span>
        </Styled.EditButtonToolTip>

        <TextStyles.FontSize20px className="mb-2">
          {heading}
        </TextStyles.FontSize20px>
        <TextStyles.FontSize14px style={{ whiteSpace: "break-spaces" }}>
          {paragraph}
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>
      {showModal && (
        <CompensationModal
          selectedData={selectedData}
          paragraph={paragraph}
          heading={heading}
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
      {confirmDialog && (
        <RemoveModal
          title={heading}
          confirmDialog={confirmDialog}
          showRemoveModal={showRemoveModal}
          handleCloseRemoveModal={handleCloseRemoveModal}
        />
      )}
    </div>
  );
};

export default Compensation;
